'use strict';

var cov_1kdahmr8mv = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_ar.js',
      hash = '92095a95474b868698a055088395c7398729e1dc',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_ar.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3001,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2911,
          column: 3
        }
      },
      '2': {
        start: {
          line: 2915,
          column: 20
        },
        end: {
          line: 2915,
          column: 24
        }
      },
      '3': {
        start: {
          line: 2916,
          column: 26
        },
        end: {
          line: 2916,
          column: 50
        }
      },
      '4': {
        start: {
          line: 2917,
          column: 26
        },
        end: {
          line: 2917,
          column: 43
        }
      },
      '5': {
        start: {
          line: 2919,
          column: 19
        },
        end: {
          line: 2919,
          column: 24
        }
      },
      '6': {
        start: {
          line: 2921,
          column: 2
        },
        end: {
          line: 2925,
          column: 3
        }
      },
      '7': {
        start: {
          line: 2922,
          column: 4
        },
        end: {
          line: 2922,
          column: 22
        }
      },
      '8': {
        start: {
          line: 2923,
          column: 4
        },
        end: {
          line: 2923,
          column: 52
        }
      },
      '9': {
        start: {
          line: 2924,
          column: 4
        },
        end: {
          line: 2924,
          column: 35
        }
      },
      '10': {
        start: {
          line: 2928,
          column: 2
        },
        end: {
          line: 2987,
          column: 3
        }
      },
      '11': {
        start: {
          line: 2930,
          column: 4
        },
        end: {
          line: 2986,
          column: 5
        }
      },
      '12': {
        start: {
          line: 2932,
          column: 21
        },
        end: {
          line: 2941,
          column: 7
        }
      },
      '13': {
        start: {
          line: 2942,
          column: 18
        },
        end: {
          line: 2942,
          column: 44
        }
      },
      '14': {
        start: {
          line: 2944,
          column: 22
        },
        end: {
          line: 2950,
          column: 7
        }
      },
      '15': {
        start: {
          line: 2953,
          column: 6
        },
        end: {
          line: 2981,
          column: 9
        }
      },
      '16': {
        start: {
          line: 2957,
          column: 8
        },
        end: {
          line: 2959,
          column: 9
        }
      },
      '17': {
        start: {
          line: 2958,
          column: 10
        },
        end: {
          line: 2958,
          column: 33
        }
      },
      '18': {
        start: {
          line: 2960,
          column: 8
        },
        end: {
          line: 2960,
          column: 56
        }
      },
      '19': {
        start: {
          line: 2961,
          column: 8
        },
        end: {
          line: 2961,
          column: 34
        }
      },
      '20': {
        start: {
          line: 2964,
          column: 31
        },
        end: {
          line: 2964,
          column: 55
        }
      },
      '21': {
        start: {
          line: 2965,
          column: 27
        },
        end: {
          line: 2965,
          column: 58
        }
      },
      '22': {
        start: {
          line: 2966,
          column: 8
        },
        end: {
          line: 2976,
          column: 9
        }
      },
      '23': {
        start: {
          line: 2967,
          column: 10
        },
        end: {
          line: 2967,
          column: 109
        }
      },
      '24': {
        start: {
          line: 2968,
          column: 10
        },
        end: {
          line: 2968,
          column: 47
        }
      },
      '25': {
        start: {
          line: 2970,
          column: 10
        },
        end: {
          line: 2970,
          column: 77
        }
      },
      '26': {
        start: {
          line: 2971,
          column: 10
        },
        end: {
          line: 2974,
          column: 11
        }
      },
      '27': {
        start: {
          line: 2972,
          column: 12
        },
        end: {
          line: 2972,
          column: 71
        }
      },
      '28': {
        start: {
          line: 2973,
          column: 12
        },
        end: {
          line: 2973,
          column: 77
        }
      },
      '29': {
        start: {
          line: 2975,
          column: 10
        },
        end: {
          line: 2975,
          column: 41
        }
      },
      '30': {
        start: {
          line: 2979,
          column: 8
        },
        end: {
          line: 2979,
          column: 66
        }
      },
      '31': {
        start: {
          line: 2980,
          column: 8
        },
        end: {
          line: 2980,
          column: 39
        }
      },
      '32': {
        start: {
          line: 2984,
          column: 6
        },
        end: {
          line: 2984,
          column: 56
        }
      },
      '33': {
        start: {
          line: 2985,
          column: 6
        },
        end: {
          line: 2985,
          column: 82
        }
      },
      '34': {
        start: {
          line: 2990,
          column: 4
        },
        end: {
          line: 2990,
          column: 57
        }
      },
      '35': {
        start: {
          line: 2991,
          column: 4
        },
        end: {
          line: 2999,
          column: 5
        }
      },
      '36': {
        start: {
          line: 2992,
          column: 6
        },
        end: {
          line: 2994,
          column: 7
        }
      },
      '37': {
        start: {
          line: 2993,
          column: 8
        },
        end: {
          line: 2993,
          column: 49
        }
      },
      '38': {
        start: {
          line: 2995,
          column: 6
        },
        end: {
          line: 2997,
          column: 7
        }
      },
      '39': {
        start: {
          line: 2996,
          column: 8
        },
        end: {
          line: 2996,
          column: 49
        }
      },
      '40': {
        start: {
          line: 2998,
          column: 6
        },
        end: {
          line: 2998,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 29
          },
          end: {
            line: 1,
            column: 30
          }
        },
        loc: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 3001,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 2954,
            column: 12
          },
          end: {
            line: 2954,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2954,
            column: 26
          },
          end: {
            line: 2962,
            column: 7
          }
        },
        line: 2954
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 2963,
            column: 12
          },
          end: {
            line: 2963,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2963,
            column: 29
          },
          end: {
            line: 2977,
            column: 7
          }
        },
        line: 2963
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 2978,
            column: 13
          },
          end: {
            line: 2978,
            column: 14
          }
        },
        loc: {
          start: {
            line: 2978,
            column: 24
          },
          end: {
            line: 2981,
            column: 7
          }
        },
        line: 2978
      },
      '4': {
        name: 'fetchTranslations',
        decl: {
          start: {
            line: 2989,
            column: 11
          },
          end: {
            line: 2989,
            column: 28
          }
        },
        loc: {
          start: {
            line: 2989,
            column: 45
          },
          end: {
            line: 3000,
            column: 3
          }
        },
        line: 2989
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2921,
            column: 2
          },
          end: {
            line: 2925,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2921,
            column: 2
          },
          end: {
            line: 2925,
            column: 3
          }
        }, {
          start: {
            line: 2921,
            column: 2
          },
          end: {
            line: 2925,
            column: 3
          }
        }],
        line: 2921
      },
      '1': {
        loc: {
          start: {
            line: 2921,
            column: 6
          },
          end: {
            line: 2921,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2921,
            column: 6
          },
          end: {
            line: 2921,
            column: 43
          }
        }, {
          start: {
            line: 2921,
            column: 48
          },
          end: {
            line: 2921,
            column: 90
          }
        }],
        line: 2921
      },
      '2': {
        loc: {
          start: {
            line: 2928,
            column: 2
          },
          end: {
            line: 2987,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2928,
            column: 2
          },
          end: {
            line: 2987,
            column: 3
          }
        }, {
          start: {
            line: 2928,
            column: 2
          },
          end: {
            line: 2987,
            column: 3
          }
        }],
        line: 2928
      },
      '3': {
        loc: {
          start: {
            line: 2930,
            column: 4
          },
          end: {
            line: 2986,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2930,
            column: 4
          },
          end: {
            line: 2986,
            column: 5
          }
        }, {
          start: {
            line: 2930,
            column: 4
          },
          end: {
            line: 2986,
            column: 5
          }
        }],
        line: 2930
      },
      '4': {
        loc: {
          start: {
            line: 2930,
            column: 8
          },
          end: {
            line: 2930,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2930,
            column: 8
          },
          end: {
            line: 2930,
            column: 46
          }
        }, {
          start: {
            line: 2930,
            column: 50
          },
          end: {
            line: 2930,
            column: 108
          }
        }, {
          start: {
            line: 2930,
            column: 113
          },
          end: {
            line: 2930,
            column: 154
          }
        }],
        line: 2930
      },
      '5': {
        loc: {
          start: {
            line: 2939,
            column: 15
          },
          end: {
            line: 2939,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2939,
            column: 15
          },
          end: {
            line: 2939,
            column: 50
          }
        }, {
          start: {
            line: 2939,
            column: 54
          },
          end: {
            line: 2939,
            column: 58
          }
        }],
        line: 2939
      },
      '6': {
        loc: {
          start: {
            line: 2957,
            column: 8
          },
          end: {
            line: 2959,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2957,
            column: 8
          },
          end: {
            line: 2959,
            column: 9
          }
        }, {
          start: {
            line: 2957,
            column: 8
          },
          end: {
            line: 2959,
            column: 9
          }
        }],
        line: 2957
      },
      '7': {
        loc: {
          start: {
            line: 2966,
            column: 8
          },
          end: {
            line: 2976,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2966,
            column: 8
          },
          end: {
            line: 2976,
            column: 9
          }
        }, {
          start: {
            line: 2966,
            column: 8
          },
          end: {
            line: 2976,
            column: 9
          }
        }],
        line: 2966
      },
      '8': {
        loc: {
          start: {
            line: 2966,
            column: 12
          },
          end: {
            line: 2966,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2966,
            column: 12
          },
          end: {
            line: 2966,
            column: 23
          }
        }, {
          start: {
            line: 2966,
            column: 27
          },
          end: {
            line: 2966,
            column: 48
          }
        }, {
          start: {
            line: 2966,
            column: 52
          },
          end: {
            line: 2966,
            column: 96
          }
        }, {
          start: {
            line: 2966,
            column: 101
          },
          end: {
            line: 2966,
            column: 134
          }
        }],
        line: 2966
      },
      '9': {
        loc: {
          start: {
            line: 2971,
            column: 10
          },
          end: {
            line: 2974,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2971,
            column: 10
          },
          end: {
            line: 2974,
            column: 11
          }
        }, {
          start: {
            line: 2971,
            column: 10
          },
          end: {
            line: 2974,
            column: 11
          }
        }],
        line: 2971
      },
      '10': {
        loc: {
          start: {
            line: 2971,
            column: 14
          },
          end: {
            line: 2971,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2971,
            column: 14
          },
          end: {
            line: 2971,
            column: 37
          }
        }, {
          start: {
            line: 2971,
            column: 41
          },
          end: {
            line: 2971,
            column: 66
          }
        }, {
          start: {
            line: 2971,
            column: 71
          },
          end: {
            line: 2971,
            column: 101
          }
        }],
        line: 2971
      },
      '11': {
        loc: {
          start: {
            line: 2991,
            column: 4
          },
          end: {
            line: 2999,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2991,
            column: 4
          },
          end: {
            line: 2999,
            column: 5
          }
        }, {
          start: {
            line: 2991,
            column: 4
          },
          end: {
            line: 2999,
            column: 5
          }
        }],
        line: 2991
      },
      '12': {
        loc: {
          start: {
            line: 2992,
            column: 6
          },
          end: {
            line: 2994,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2992,
            column: 6
          },
          end: {
            line: 2994,
            column: 7
          }
        }, {
          start: {
            line: 2992,
            column: 6
          },
          end: {
            line: 2994,
            column: 7
          }
        }],
        line: 2992
      },
      '13': {
        loc: {
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        }, {
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        }],
        line: 2995
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0, 0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1kdahmr8mv.s[0]++;
angular.module('app').config(function ($translateProvider) {
  cov_1kdahmr8mv.f[0]++;

  var data = (cov_1kdahmr8mv.s[1]++, {
    COUNTRIES: {
      AD: 'أندورا',
      AE: 'الإمارات العربية المتحدة ',
      AF: 'أفغانستان',
      AG: 'أنتيغوا وبربودا',
      AI: 'أنغويلا',
      AL: 'ألبانيا',
      AM: 'أرمينيا',
      AN: 'جزر الأنتيل الهولندية',
      AO: 'أنغولا',
      AQ: 'القارة القطبية الجنوبية',
      AR: 'الأرجنتين',
      AS: 'ساموا الأمريكية',
      AT: 'النمسا',
      AU: 'أستراليا',
      AW: 'أروبا',
      AX: 'جزر أولاند',
      AZ: 'أذربيجان',
      BA: 'البوسنة و الهرسك',
      BB: 'بربادوس',
      BD: 'بنغلاديش',
      BE: 'بلجيكا',
      BF: 'بوركينا فاسو',
      BG: 'بلغاريا',
      BH: 'البحرين',
      BI: 'بوروندي',
      BJ: 'بنين',
      BM: 'برمودا',
      BN: 'بروناي دار السلام',
      BO: 'بوليفيا',
      BR: 'البرازيل',
      BS: 'الباهاماس',
      BT: 'بوتان',
      BV: 'جزيرة بوفيه',
      BW: 'بوتسوانا',
      BY: 'روسيا البيضاء',
      BZ: 'بِليز',
      CA: 'كندا',
      CC: 'جزر كوكوس',
      CD: 'جمهورية الكونغو الديمقراطية ',
      CF: 'جمهورية أفريقيا الوسطى',
      CG: ' الكونغو ',
      CH: 'سويسرا ',
      CI: 'ساحل العاج ',
      CK: ' جزر كوك ',
      CL: 'تشيلي',
      CM: 'الكاميرون',
      CN: 'الصين',
      CO: 'كولومبيا',
      CR: 'كوستاريكا ',
      CS: 'صربيا والجبل الأسود',
      CU: ' كوبا ',
      CV: 'الرأس الأخضر',
      CX: 'جزيرة كريسماس',
      CY: ' قبرص ',
      CZ: 'التشيك',
      DE: 'ألمانيا ',
      DJ: 'جيبوتي ',
      DK: 'الدنمارك ',
      DM: ' دومينيكا ',
      DO: 'جمهورية الدومينيكان',
      DZ: 'الجزائر',
      EC: 'الإكوادور',
      EE: 'إستونيا',
      EG: 'مصر ',
      EH: 'الصحراء الغربية ',
      ER: 'إريتريا ',
      ES: ' إسبانيا ',
      ET: ' إثيوبيا ',
      FI: ' فنلندا ',
      FJ: ' فيجي ',
      FK: ' جزر فوكلاند ',
      FM: 'ولايات ميكرونيسيا المتحدة',
      FO: ' جزر الفارو ',
      FR: ' فرنسا ',
      GA: ' الغابون ',
      GB: ' المملكة المتحدة ',
      GD: ' غرينادا ',
      GE: ' جورجيا ',
      GF: ' غويانا الفرنسية ',
      GG: 'جيرنسي',
      GH: ' غانا ',
      GI: ' جبل طارق ',
      GL: ' جرينلاند ',
      GM: ' غامبيا ',
      GN: ' غينيا ',
      GP: 'غوادلوب',
      GQ: 'غينيا الاستوائية',
      GR: ' اليونان ',
      GS: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
      GT: 'غواتيمالا',
      GU: ' جوام ',
      GW: ' غينيا-بيساو ',
      GY: ' غيانا ',
      HK: ' هونغ كونغ ',
      HM: 'جزيرة هيرد وجزر ماكدونالد',
      HN: ' هندوراس ',
      HR: ' كرواتيا ',
      HT: ' هايتي ',
      HU: ' المجر ',
      ID: ' أندونيسيا ',
      IE: 'إيرلندا',
      IL: ' إسرائيل ',
      IM: 'جزيرة مان',
      IN: ' الهند ',
      IO: 'إقليم المحيط الهندي البريطاني',
      IQ: ' العراق ',
      IR: ' إيران ',
      IS: ' آيسلندا ',
      IT: ' إيطاليا ',
      JE: 'جيرزي',
      JM: ' جمايكا ',
      JO: ' الأردن ',
      JP: ' اليابان ',
      KE: ' كينيا ',
      KG: ' قيرغيزستان ',
      KH: 'كمبوديا',
      KI: ' كيريباتي ',
      KM: 'جزر القمر',
      KN: ' سانت كيتس ونيفس ',
      KP: ' كوريا الشمالية ',
      KR: ' كوريا الجنوبية ',
      KW: ' الكويت ',
      KY: 'جزر الكايمن',
      KZ: ' كازاخستان ',
      LA: ' لاوس ',
      LB: ' لبنان ',
      LC: ' سانت لوسيا ',
      LI: ' ليختنشتين ',
      LK: ' سريلانكا ',
      LR: ' ليبيريا ',
      LS: ' ليسوتو ',
      LT: ' لتوانيا ',
      LU: ' لوكسمبورغ ',
      LV: ' لاتفيا ',
      LY: ' ليبيا ',
      MA: ' المغرب ',
      MC: ' موناكو ',
      MD: 'مولدوفا',
      MG: ' مدغشقر ',
      MH: ' جزر مارشال ',
      MK: ' جمهورية مقدونيا ',
      ML: ' مالي ',
      MM: ' ميانمار ',
      MN: ' منغوليا ',
      MO: ' ماكاو ',
      MP: ' جزر ماريانا الشمالية ',
      MQ: ' مارتينيك ',
      MR: ' موريتانيا ',
      MS: ' مونتسيرات ',
      MT: ' مالطا ',
      MU: ' موريشيوس ',
      MV: ' المالديف ',
      MW: ' مالاوي ',
      MX: ' المكسيك ',
      MY: ' ماليزيا ',
      MZ: ' موزمبيق ',
      NA: ' ناميبيا ',
      NC: ' كاليدونيا الجديدة ',
      NE: ' النيجر ',
      NF: 'جزيرة نورفوك',
      NG: ' نيجيريا ',
      NI: ' نيكاراجوا ',
      NL: ' هولندا ',
      NO: ' النرويج ',
      NP: ' نيبال ',
      NR: ' ناورو ',
      NU: ' نييوي ',
      NZ: ' نيوزيلندا ',
      OM: ' عُمان ',
      PA: ' بنما ',
      PE: ' بيرو ',
      PF: ' بولينيزيا الفرنسية ',
      PG: ' بابوا غينيا الجديدة ',
      PH: ' الفليبين ',
      PK: ' باكستان ',
      PL: ' بولندا ',
      PM: 'سانت بيير وميكولون',
      PN: ' جزر بيتكيرن ',
      PR: ' بورتوريكو ',
      PS: ' الأراضي الفلسطينية ',
      PT: ' البرتغال ',
      PW: ' بالاو ',
      PY: ' باراغواي ',
      QA: ' قطر ',
      RE: ' ريونيون ',
      RO: ' رومانيا ',
      RU: ' روسيا ',
      RW: ' رواندا ',
      SA: ' المملكة العربية السعودية ',
      SB: ' جزر سليمان ',
      SC: ' سيشيل ',
      SD: ' السودان ',
      SE: ' السويد ',
      SG: ' سنغافورة ',
      SH: 'سانت هيلنا',
      SI: ' سلوفينيا ',
      SJ: 'سفالبارد ويان ماين',
      SK: ' سلوفاكيا ',
      SL: ' سيراليون ',
      SM: ' سان مارينو ',
      SN: ' السنغال ',
      SO: ' الصومال ',
      SR: ' سورينام ',
      ST: ' ساو تومي وبرينسيبي ',
      SV: ' إلسلفادور ',
      SY: ' سوريا ',
      SZ: 'سوازيلاند',
      TC: 'جزر توركس وكايكوس',
      TD: 'تشاد',
      TF: 'الأراضي الجنوبية الفرنسية',
      TG: ' توغو ',
      TH: 'تايلاند',
      TJ: ' طاجيكستان ',
      TK: ' توكيلو ',
      TL: ' تيمور الشرقية ',
      TM: ' تركمانستان ',
      TN: ' تونس ',
      TO: ' تونغا ',
      TR: ' تركيا ',
      TT: ' ترينيداد وتوباغو ',
      TV: ' توفالو ',
      TW: ' تايوان ',
      TZ: ' تنزانيا ',
      UA: ' أوكرانيا ',
      UG: ' أوغندا ',
      UM: 'جزر الولايات المتحدة الصغيرة النائية',
      US: ' الولايات المتحدة\t ',
      UY: 'أوروغواي',
      UZ: 'أوزبكستان ',
      VA: ' الكرسي الرسولي ',
      VC: 'سانت فينسنت والغرينادين',
      VE: ' فنزويلا ',
      VG: 'جزر العذراء البريطانية ',
      VI: 'جزر العذراء الأمريكية',
      VN: ' فيتنام ',
      VU: ' فانواتو ',
      WF: 'واليس وفوتونا',
      WS: ' ساموا ',
      YE: ' اليمن ',
      YT: ' مايوت ',
      ZA: ' جنوب أفريقيا ',
      ZM: ' زامبيا ',
      ZW: ' زمبابوي '
    },
    MESSAGE: {
      CONVERSATION: 'محادثة',
      WRITE_MSG: 'أدخل رسالة',
      AUCUN: 'لا توجد رسالة',
      TITLE: 'رسائلي',
      READ_ALL: 'قراءة كل الرسائل',
      INBOX: 'البريد الوارد',
      SEND_BOX: 'البريد المرسَل',
      DRAFT_BOX: 'المسودّات',
      TRASH_BOX: 'سلة المهملات',
      REFRESH: 'تحديث',
      REPLY: 'الرد',
      IMPORTANT: 'هام',
      VIEW_MSG: 'عرض الرسالة',
      TO: 'إلى (مستخدم دلتا)',
      TO_: 'مستخدم DELTA',
      PIECEJ: 'إرفاق الملفات',
      TOEMAIL: 'إلى (بريد إلكتروني خارجي)',
      EMAILS: 'رسائل البريد الإلكتروني',
      MAILEXTERNE: 'البريد الإلكتروني الخارجي',
      SUBJECT: 'الموضوع',
      FROM: 'من',
      DRAFT: 'كتابة مسودّة',
      DISCARD: 'تجاهل',
      CMP_MSG: 'كتابة الرسالة',
      SEND: 'إرسال',
      EMAIL_BOX: 'صندوق البريد',
      FOLDER: 'المجلد',
      REMOVE: 'إزالة',

      RECHARGEMENT: 'ينعش',
      MARKREAD: 'علامة القراءة',
      MARKNOREAD: 'علامة غير مقروء',
      MARKIMPORTANT: 'ضع علامة كمهمة',
      MARKTRASH: 'لحذف',

      ADDEMAIL: 'أضف جهة اتصال',

      SEND_OK: 'رسالة مرسلة',
      SEND_SAVE: 'رسالة مسجلة',
      SEND_NO: 'خطأ في إرسال الرسالة',
      SEND_NO_T: 'المرجو اعادة المحاولة',

      ADDUSER: 'إضافة المستخدم',
      SIGNATURE: 'التوقيعات',
      SIGNATURE_SEND: 'إلى النهاية',
      SIGNATURE_ERROR: 'خطأ',
      SIGNATURE_CREATE: 'قم بإنشاء توقيع',
      SIGNATURE_ADD: 'خلق',
      SIGNATURE_TITLE: 'عنوان التوقيع',
      SIGNATURE_DESIGN: 'تعيين',
      SIGNATURE_ANNULER: 'لالغاء',
      SIGNATURE_AJOUTER: 'لإضافة',
      SIGNATURE_MODIFIER: 'يحرر',
      SIGNATURE_LIST: 'قائمة التوقيعات',
      SIGNATURE_DEFAULT: 'حدد كتوقيع افتراضي',
      SIGNATURE_NODEFAULT: 'قم بإلغاء التحديد كتوقيع افتراضي',

      ERROR: {
        TO: 'الرجاء اختيار الجهة المستلمة!',
        SUBJECT: 'اكتب الموضوع',
        EM: 'أدخل البريد الإلكتروني'
      }
    },
    LOGIN: {
      PASSWORD_EXPIRED_S: 'تنتهي صلاحية كلمة مرورك في غضون {{days}} يوم',
      PASSWORD_EXPIRED: 'ستنتهي صلاحية كلمة مرورك خلال يوم واحد',
      PROFILE: 'الملف الشخصي',
      LOGOUT: 'تسجيل الخروج'
    },
    HELP: {
      LEGAL: 'إشعار قانوني',
      QUICK_START: 'يساعد',
      USER_MANUAL: 'دليل المستخدم',
      FAQ: 'الأسئلة الشائعة',
      WEBINAIRS: 'حلقات دراسية عبر الإنترنت',
      SUPPORT: 'الاتصال بالدعم التقني',
      LEGAL_NOTICE_LINK: 'https://delta-monitoring.com/service-level-agreement/',
      USER_FEEDBACK: "تعليقات المستخدم"
    },
    ALERT: {
      ACTIVATE: 'تنشيط تنبيه المشكلة',
      SMS: 'رسالة قصيرة',
      EMAIL: 'بريد إلكتروني',
      IND_TEXT: 'تنبيهات المؤشرات',
      DOCUMENT: 'وثائق جديدة',
      MORE: 'عرض كل التنبيهات',
      ALL: 'كل التنبيهات',
      NBR_J_L: 'إرسال تنبيه',
      SEND: 'يوم (أيام) قبل التاريخ المحدد',
      HELP: {
        TITLE: 'تم ضبط المنبه لإرسال رسالة تنبيه إلى أعضاء الفريق لتذكيرهم بإدخال البيانات المتعلقة بالأنشطة والمؤشرات المسندة'
      }
    },
    TODO: {
      TITLE: 'قائمة المهام',
      LABEL: 'التسمية'
    },
    HOME: {
      WELCOME: 'برنامج تعاوني على الإنترنت للرصد والتقييم',
      USER: 'اسم المستخدم',
      PASS: 'كلمة المرور',
      LOGIN: 'تسجيل الدخول',
      FOOTER: 'مراقبة دلتا',
      CONNECTED_PLURAL: 'متصل حاليا: {{user}}</b> مستخدمين',
      CONNECTED_SINGLE: 'متصل حاليا: 1</b> مستخدم'
    },
    HEADER: {
      LOGO: 'شعار',
      TITLE: 'عنوان التقرير',
      FIRST_LINE: 'الخط الأول',
      SECOND_LINE: 'السطر الثاني',
      THIRD_LINE: 'الخط الثالث',
      REPORT_TITLE: 'عنوان التقرير'
    },
    COMMON: {
      ACTIVE_SUP: "تنشيط الحذف المؤقت",
      ALERTE_UNIT: 'حدد وحدة ٪ نوع.',
      STEPONE: 'منصة',
      STEP: 'خطوات',
      VALUE: 'قيمة',
      ADDSTEP: 'حدد مراحل الإنجاز',
      ADDSTEP_VIEW: 'يشير نوع النشاط المحدد إلى أنه سيتم اتباع النشاط كعملية بمراحل الإنجاز',
      EXPORT_POWERBI: 'تصدير إلى Power BI',
      LOADED_MSG: 'من فضلك انتظر ، العلاج قيد التقدم ...',
      EMAIL_UPLOADED: 'لقد قمت باستيراد {{_nb}} بريد إلكتروني (عناوين)',
      UPLOAD_CSV: 'استيراد من ملف CSV',
      UPLOAD_CSV_RECOMMENDATION: "تأكد من تسمية رأس كل عمود في ملف .CSV",
      UPLOAD_CSV_RECOMMENDATION_HEADERS: 'البريد الإلكتروني (إلزامي) ، الاسم والرقم (إذا كان ذلك ممكنًا).',
      DATE_MAJ: 'اخر تاريخ تحديث',
      SELECT_ALL: 'اختيار الكل',
      UNSELECT_ALL: 'إلغاء اختيار الكل',
      ANALYSE_ERR_EXISTE: 'العنوان مستخدم بالفعل',
      FORMULAIRE_ERR_EXISTE: 'النموذج موجود بالفعل',

      ERR_MSG: 'الرجاء ملء هذا الحقل.',
      COL: 'الأعمدة',
      COL_S: 'تحديد الأعمدة',
      SV: 'حفظ',
      SV_R: 'حفظ التقرير',

      UPLOAD_DOC: 'مرفق',
      SELECT_OPTION: 'حدد خيارًا',
      SELECT_OPTION_: 'حدد خيارًا',
      REQUIRED: 'إلزامي',
      CHECKING: 'تدقيق ...',
      ERROR_AUTHORIZE_LOCATION: 'ليس لديك حقوق في هذه المنطقة',
      ONLINE: 'عبر الانترنت',
      OFFLINE: 'غير متصل على الانترنت',
      ALREADY_EXIST: 'هذا المتغير متواجد بالفعل!',
      SEE_MORE: 'عرض المزيد...',
      UNAVAILABLE: 'غير متاح',
      SELECT_UNIT: 'اختر وحدة',
      CONDITIONS: 'أدوات الفرز',
      FORMATTING: 'تطبيق الصيغة',
      CHART: 'المخطط',
      OVERWRITE_DATA: 'الكتابة فوق البيانات',
      SHARE: 'شارِك',
      GOOD: 'جيد',
      BAD: 'سيء',
      MEDIUM: 'متوسط',
      PORTFOLIO_VISIBLE: 'ظاهر في الحافظة',
      NO_DATA: 'لا توجد بيانات',
      HELP: 'مساعدة',
      TASK_DISPLAY_FORMAT: 'day dd month yyyy',
      DAY_MAJOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      WEEK_MINOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      FLAVOR: 'المفضلات',
      CHECK_ALL: 'تحديد الكل',
      UNCHECK_ALL: 'إلغاء تحديد الكل',
      PLANNING: 'مجدول',
      NOT_PLANNING: 'غير مجدول',
      UPDATE: 'تحديث',
      BY: 'بواسطة ',
      CHANGE_PHOTO: 'تغيير الصورة',
      ENTER: 'أدخل',
      FILTER: 'الفرز حسب',
      AGGREGATE: 'المجموع',
      DESEGREGATE: 'التصنيف',
      TAKE_PICTURE: 'التقط صورة',
      REPLY_TREE: 'اضغط لإظهار عرض الشجرة',
      VALIDATE_DATA: 'التحقق من صحة البيانات',
      VALIDATED_DATA: 'بيانات تم التحقق منها',
      ALL_DATA: 'كل البيانات',
      VALIDATION: 'التحقق من الصحة',

      SATELLITE: 'القمر الصناعي',
      GRAYSCALE: 'التدرج الرمادي',
      CITIES: 'المدن',
      DAY: 'اليوم',
      EXPORT_DATA: 'تصدير البيانات',
      IMPORT_DATA: 'استيراد البيانات',
      DENIED_LOGIN: 'اذهب إلى صفحة تسجيل الدخول',
      DENIED: 'تم رفض الوصول',
      FULLSCREEN: 'عرض وضع ملء الشاشة',
      EXIT_FULLSCREEN: 'الخروج من وضع ملء الشاشة',
      FULL_GRAPH: 'ملأ',
      WORKFLOW: 'سير العمل',
      DATA: 'البيانات',
      GPS: {
        LONGITUDE: 'خط الطول',
        LATITUDE: 'خط العرض',
        ALTITUDE: 'ارتفاع'
      },
      RIGHT: {
        VALIDATION_SELECT: 'التحقق من صحة المحدد',
        ONLYVALDATE: 'البيانات التي تم التحقق من صحتها فقط',
        NONVALIDATED: 'باطل',
        INVALIDATED: 'يجري التحقق من صحة',
        VALIDATED: 'صالح',
        DELVALIDATED: 'للحذف',
        SELECTVAL: 'حدد حالة',
        VALIDATE: 'تصديق',
        VALIDATE_STATUS: 'حالة التحقق',
        VIEWING: 'عرض',
        UPDATING: 'تعديل',
        DELETING: 'حذف',
        PAGE: 'الوظيفة',
        EDIT_DELETE: 'تعديل / حذف',
        DATA: 'البيانات',
        METADATA: 'البيانات الوصفية '
      },
      STATUT: {
        NOSTART: 'لم يبدأ بعد',
        PROGRESS: 'قيد التنفيذ',
        DONE: 'مكتمل'
      },
      SAVE: 'حفظ',
      VIRGULE: '.',
      NEXT: 'التالى',
      PREV: 'السابق',
      ADD: ' أضف',
      EDIT: 'تعديل',
      DELETE: 'حذف',
      PRINT: 'طباعة',
      SELECT: 'تحديد',
      YEAR: 'سنة',
      SEM: 'نصف سنة',
      QUATR: 'أربعة أشهر',
      TRIM: 'ربع سنة',
      BIM: 'فترة شهرية',
      MOIS: 'شهر',
      PERIOD: 'فترة',
      YEAR_SHORT: 'س',
      SEM_SHORT: 'ن.س',
      QUATR_SHORT: 'أ.أ',
      TRIM_SHORT: 'ر.س',
      BIM_SHORT: 'ف.ش',
      MOIS_SHORT: 'ش',
      DISPLAY: 'عرض',
      VALIDATE: 'موافق',
      CANCEL: 'إلغاء',
      CONTINUE: 'المواصلة',
      CONFIRM: 'تأكيد الحذف',
      CONFIRM_RESTORE: 'تأكيد الاستعادة',
      YES: 'نعم',
      NO: 'لا',
      ERROR_DELETE: 'لا يمكن الحذف!',
      ERROR_: 'خطأ',
      ERROR_UPDATE: 'لا يمكن التحديث!',
      ERROR_ADD: 'لا يمكن الإضافة!',
      ERROR_CODE: 'الرمز مستخدم بالفعل',
      TITLE_ERROR_ADD: 'تم اكتشاف خطأ أثناء التحديث',
      TITLE_ERROR_DELETE: 'البيانات لها تبعيات',
      DATE_FORMAT: 'MM/DD/YYYY',
      DATE_FORMAT_COMPLET: 'MM/DD/YYYY HH:mm',
      DATE_FORMAT_MM_YYYY: 'MMMM YYYY',
      HISTORQ: 'الأخيرة',
      CLOSE: 'إغلاق',
      DATE_DP: 'تاريخ البدء',
      ACTUAL: 'التاريخ الفعلي',
      DATE_DR: 'تاريخ البدء',
      DATE_FP: 'تاريخ الانتهاء',
      DATE_FR: 'تاريخ الانتهاء',
      H_DEB: 'وقت البدء',
      H_FIN: 'وقت الانتهاء',
      LIEU: 'الموقع',
      MSG_ADD: 'تمت إضافة السجل',
      MSG_MODIF: 'إكتمل التحديث!',
      MSG_DEL: 'اكتمل الحذف!',
      TBORD: 'لوحة البيانات',
      YEAR_ALL: 'الكل (*)',
      ALL: 'الكل (*)',
      ALL_E: 'الكل (*)',
      NONE: 'لا شيء',
      NONE_E: 'لا شيء',
      SEARCH: 'بحث',
      TYPECHART: 'نوع المخطط',
      LINECHART: 'مخطط بياني خطي',
      BARCHART: 'مخطط بياني بالأعمدة',
      PIECHART: 'مخطط بياني دائري',
      POLARCHART: 'مخطط بياني قطبي',
      MIXED_CHART: 'مخطط بياني مختلط',
      BEGIN: 'البداية',
      END: 'النهاية',
      LESS_THAN: 'أقل من',
      GREAT_THAN: 'أكثر من',
      EGAL_TO: 'يساوي',
      LESS_THAN_E: 'أقل من',
      GREAT_THAN_E: 'أكثر من',
      EGAL_TO_E: 'يساوي',
      OPEN: 'فتح',
      DOWNLOAD: 'تحميل',
      QUIT: 'إلغاء',
      OPEN_DASHBOARD: 'انقر لعرض لوحة البيانات',
      DESCRIPTION: 'وصف',
      MORE: 'المزيد...',
      MORE_DBL: 'انقر مرتين لعرض التفاصيل',
      DASHBOARD: 'لوحة البيانات',
      ERROR: {
        NOMATCH: 'لا يوجد تطابق في النتائج',
        REQUIRED: 'إلزامي',
        DBL: 'تم إسناد هذا الرمز بالفعل',
        DBL_REF: 'تم إسناد هذا المرجع بالفعل',
        DBL_LAB: 'تم إسناد هذا الإسم بالفعل',
        DBL_: 'تم إسناد هذا الإسم بالفعل',
        NONE_DATA: 'لم يتم تحديد أي بيانات',
        MIN_CAR: 'طول {{field}} الأدنى هو {{value}}',
        MAX_CAR: 'طول {{field}} الأقصى هو {{value}}',
        EMAIL: 'البريد الإلكتروني غير صالح',
        CODE_ERROR: 'يجب أن يساوي الطول {{value}}',
        CODE_ERROR_COM: 'يجب أن يساوي الطول {{value}} ويجب أن يبدأ بـ {{begin}}',
        DATE_RANGE: 'يجب أن يكون تاريخ البداية قبل تاريخ النهاية',
        DATE: 'تاريخ غير صالح',
        FINANCETOTAL: 'يجب أن يكون المجموع مساوياً لـ 100٪',
        INVALID_URL: 'العنوان غير صحيح'
      },
      EXPORT_WORD: 'التحويل إلى Word ',
      EXPORT_EXCEL: 'التحويل إلى Excel',
      EXPORT_PDF: 'التحويل إلى PDF',
      EXPORT_JSON: 'التحويل إلى JSON',
      STREETMAP: 'خارطة الطريق',
      NEW_FEAUTURE: 'عرض الميزات الجديدة',
      DATE_FORMAT_DISPLAY: 'تنسيق التواريخ'
    },
    MENU_SHORT: {
      LAYERS: 'خريطة القاعدة',
      LAYER: 'خريطة القاعدة',
      RISKS_MANAGEMENT: 'إدارة المخاطر',
      PROBLEM_MANAGEMENT: 'مشاكل',
      RISKS: 'المخاطر',
      PROBLEM: 'مشاكل',
      MISSION_MANAGEMENT: 'إدارة المهمات',
      MISSION_TYPES: 'أنواع المهمة',
      COMMUNITIES: 'المجتمعات',
      REPORT_PLAN: 'التخطيط للتقارير',
      REPORT_TRACKING: 'تتبع التقارير',
      FUNDS: 'إنفاق الأموال',
      ACTIVITY_PLAN_BUDGET: 'الخطة والميزانية',
      PROJECT_TIMELINE: 'الجدول الزمني للمشروع',
      RISK_MANAGEMENT: 'إدارة المخاطر',
      PROJECT_MGT: 'إدارة',
      ACTIVITY_REPORT: 'تقارير المشروع',
      CUSTOM_RIGHT: 'حقوق مخصصة',
      HOME: 'الرئيسية',
      ALERT: 'تنبيهات',
      D_B: 'الإعدادات',
      DO: 'حافظة المشاريع',
      PROJECT: 'المشاريع',
      PROJECT_SINGLE: 'المشاريع',
      D_T: 'التقسيم الزمني',
      D_P: 'الفترة المشمولة بالتقرير',
      D_PL: 'تخطيط ثلاثي المستويات',
      UN: 'الوحدات',
      AGENCIES: 'الوكالات',
      AC: 'أصحاب المصلحة',
      FINANCING: 'التمويل',
      S_F: 'مصادر التمويل',
      P_G: 'المواقع',
      S_G: 'التقسيم الجغرافي',
      T_I: 'فئات مؤشرات الأداء',
      C_I: 'تصنيف مؤشرات الأداء',
      IN: 'المؤشرات (KPIs)',
      IN_T: 'المؤشرات (KPIs)',
      IN_P: 'مؤشرات أداء مشتركة بين المشاريع',
      R_F: 'إطار العمل الخاص بنتائج مؤشرات الأداء',
      R_F_: 'إطار العمل الخاص بالنتائج',
      P_L: 'تخصيص التسمية',
      P_LO: 'الإطار المنطقي',
      C_B: 'فئة الميزانية',
      P_CL: 'الإطار المنطقي',
      P_CUSTOM: 'القوالب',
      P_CR: 'سلسلة النتائج',
      ST: 'قالب الإطار المنطقي',
      ST_CL: 'الإطار المنطقي للهيكل',
      ST_CR: 'سلسلة النتائج للهيكل',
      P_B: 'خطة الميزانية',
      P_M: 'خطة المشتريات',
      FL: 'عَلَم',
      T_A: 'فئة النشاط',
      AGENDA: 'جدول الأعمال',
      FILE: 'غرفة الدردشة',
      ANALYTICS: 'لوحات البيانات',
      PL: 'الأنشطة',
      P_A: 'مراقبة الأنشطة',
      P_AN_PTBA: 'الخطة السنوية (AWPB)',
      IMP_BUDG: 'استيراد الميزانية',
      G_F_PF: 'إنشاء ملف بصيغة محددة مسبقا',
      I_B: 'استيراد الميزانيات',
      P_I: 'مؤشرات الخطة',
      S_E: 'المراقبة',
      S_A: 'مراقبة {{level}}',
      I_C: 'استيراد التكاليف',
      I_COUT: 'جار استيراد التكاليف',
      S_PTBA: 'المراقبة',
      S_PTBP: 'الأداء السنوي للمشروع',
      T_B_A: 'لوحة البيانات الخاصة بالأنشطة',
      C_GANTT: 'الجدول الزمني للمشروع',
      P_AC: 'خطة العمل',
      R_P: 'استفسارات مخصصة',
      MA: 'العقود',
      S_M: 'مراقبة العقود',
      S_PPM: 'مراقبة PP',
      S_I: 'مراقبة المؤشر',
      S_I_IOV: 'المؤشرات',
      S_I_G: 'التتبع الجغرافي للمؤشرات',
      T_B_I: 'لوحة البيانات الخاصة بالمؤشرات',
      SAP_VIS: 'التصور المكاني',
      SY: 'ملخص',
      C_L: 'الإطار المنطقي',
      S_C_L: 'مراقبة الإطار المنطقي',
      S_C: 'السياق',
      G_R: 'إدارة المخاطر',
      P_M_R: 'خطة تخفيف المخاطر',
      F_P: 'العوامل الإيجابية',
      E_T: 'الدروس المكتسبة',
      B_D: 'التعلم',
      B_D_: 'مشاركة المعرفة والتعلم',
      DB_P: 'قواعد بيانات مخصصة',
      T_RA: 'تقارير الموظفين',
      CA_R: 'تتبع التقارير',
      C_D: 'الوثائق',
      PH: 'مكتبة الوسائط',
      AD: 'المستخدمون',
      G_U: 'المستخدمون',
      GR_U: 'المجموعات',
      U_C: 'المستخدمون المتصلون',
      J_C: 'سجل الاتصالات',
      R_I: 'الملفات الشخصية',
      FORMULAR_: 'جمع البيانات',
      FORMULAR: 'جمع البيانات',
      DESIGN_FORMS: 'نماذج التصميم',
      COLLECT_DATA: 'جمع البيانات',
      ANALYZE_DATA: 'التحليل وإعداد التقارير',
      ANALYSE: 'تقارير مخصصة',
      DASHBOARD: 'لوحات البيانات',
      DASHBOARD_: 'لوحات البيانات',
      ANALYTICS_GIS: 'تحليل مكاني',
      AUDITS: 'مراجعة الحسابات',
      SECURITY: 'الأمان',
      DELETED_ITEMS: 'العناصر المحذوفة',
      ADMIN: 'المشرف'
    },
    MENU: {
      LAYERS: 'خريطة القاعدة',
      LAYER: 'خريطة القاعدة',
      RECOMMENDATIONS: 'توصية',
      RISKS_MANAGEMENT: 'إدارة المخاطر',
      PROBLEM_MANAGEMENT: 'مشاكل',
      RISKS: 'المخاطر',
      PROBLEM: 'مشاكل',
      MISSION_MANAGEMENT: 'إدارة المهمات',
      MISSION: 'البعثات',
      MISSION_TYPES: 'أنواع المهمة',
      COMMUNITIES: 'المجتمعات',
      REPORT_PLAN: 'التخطيط للتقارير',
      REPORT_TRACKING: 'تتبع التقارير',
      FUNDS: 'إنفاق الأموال',
      SECTORS: 'القطاعات',
      PROJECT_MGT: 'إدارة',
      ACTIVITY_REPORT: 'تقارير المشروع',
      DASHBOARDS: 'لوحات البيانات',
      PROJECTS: 'المشاريع',
      HOME: 'الرئيسية',
      ALERT: 'التنبيهات',
      D_B: 'الإعدادات',
      DO: 'حافظة المشاريع',
      PROJECT: 'المشاريع',
      D_T: 'تقسيم الوقت',
      D_P: 'فترة تقديم التقارير',
      D_PL: 'تخطيط ثلاثي المستويات',
      UN: 'وحدات القياس',
      FINANCING: 'التمويل',
      AGENCIES: 'الوكالات المنفِّذة',
      AC: 'أصحاب المصلحة',
      S_F: 'مصادر التمويل',
      P_G: 'المواقع',
      Plan_G: 'المواقع',
      S_G: 'التقسيم الجغرافي',
      STRUCT_G: 'التقسيم الجغرافي',
      T_I: 'فئات مؤشرات الأداء',
      C_I: 'تصنيف مؤشرات الأداء',
      IN: 'المؤشرات (KPIs)',
      IN_T: 'المؤشرات (KPIs)',
      IN_P: 'مؤشرات أداء مشتركة بين المشاريع',
      R_F: 'إطار العمل الخاص بنتائج مؤشرات الأداء',
      R_F_: 'إطار العمل الخاص بنتائج المؤشرات',
      P_L: 'تخصيص التسمية',
      P_LO: 'الإطار المنطقي',
      C_B: 'فئات الميزانية',
      P_CL: 'الإطار المنطقي',
      P_CUSTOM: 'قوالب الإطار المنطقي',
      P_CR: 'سلسلة النتائج',
      ST: 'قالب الإطار المنطقي',
      ST_CL: 'الإطار المنطقي للهيكل',
      ST_CR: 'سلسلة النتائج للهيكل',
      P_B: 'خطة الميزانية',
      P_M: 'خطة المشتريات',
      FL: 'عَلَم',
      T_A: 'فئات الأنشطة',
      AGENDA: 'جدول الأعمال',
      FEEDBACK: 'الملاحظات',
      FILE: 'غرفة الدردشة',
      PL: 'الأنشطة',
      P_A: 'مراقبة الأنشطة',
      P_AN_PTBA: 'الخطة السنوية (AWPB)',
      IMP_BUDG: 'استيراد الميزانيات',
      G_F_PF: 'إنشاء ملف بصيغة محددة مسبقا',
      I_B: 'استيراد الميزانيات',
      P_I: 'مؤشرات الخطة',
      S_E: 'المراقبة',
      S_A: 'مراقبة {{level}}',
      I_C: 'استيراد التكاليف',
      I_COUT: 'جار استيراد التكاليف',
      S_PTBA: 'المراقبة',
      S_PTBP: 'الأداء السنوي للمشروع',
      T_B_A: 'لوحة البيانات الخاصة بالأنشطة',
      C_GANTT: 'الجدول الزمني للمشروع',
      P_AC: 'خطة العمل',
      R_P: 'استفسارات مخصصة',
      MA: 'العقود',
      S_M: 'مراقبة العقود',
      S_I: 'مراقبة المؤشرات',
      S_I_IOV: 'المؤشرات',
      S_I_G: 'التتبع الجغرافي للمؤشرات',
      T_B_I: 'لوحة البيانات الخاصة بالمؤشرات',
      SAP_VIS: 'التصور المكاني',
      SY: 'ملخص',
      C_L: 'الإطار المنطقي',
      S_C_L: 'مراقبة الإطار المنطقي',
      S_C: 'السياق',
      G_R: 'إدارة المخاطر ',
      P_M_R: 'خطة تخفيف المخاطر ',
      F_P: 'العوامل الإيجابية',
      E_T: 'الدروس المكتسبة',
      B_D: 'التعلم',
      B_D_: 'مشاركة المعرفة والتعلم',
      DB_P: 'قواعد بيانات مخصصة',
      T_RA: 'تقارير الموظفين',
      CA_R: 'تتبع التقارير',
      C_D: 'الوثائق',
      PH: 'مكتبة الوسائط',
      AD: 'المستخدمون',
      G_U: 'المستخدمون',
      GR_U: 'مجموعات المستخدمين',
      U_C: 'المستخدمون المتصلون',
      J_C: 'سجل الاتصالات',
      R_I: 'ملفات تعريف المستخدمين',
      FORMULAR_: 'جمع البيانات',
      FORMULAR: 'جمع البيانات',
      ANALYTICS: 'لوحات البيانات',
      ANALYTICS_CUSTOM_REPORT: 'تقارير مخصصة',
      ANALYTICS_CUSTOM_DASHBOARD: 'لوحات البيانات',
      ANALYTICS_GIS: 'تحليل مكاني',
      DESIGN_FORMS: 'نماذج التصميم',
      COLLECT_DATA: 'جمع البيانات',
      ANALYZE_DATA: 'التحليل وإعداد التقارير',
      ANALYSE: 'تقارير مخصصة',
      DASHBOARD: 'لوحات البيانات',
      DASHBOARD_: 'لوحات البيانات',
      AUDITS: 'مراجعة الحسابات',
      SECURITY: 'إعدادات الأمان',
      DATA: 'النماذج',
      ADMIN: 'الإدارة'
    },
    LAYER: {
      NAME: 'العنوان',
      URL: 'الرابط',
      HELP: {
        NAME: 'العنوان',
        URL: 'رابط الخريطة الأساسية (https://...{x}{y}{z})'
      },
      ERROR: {
        URL: 'رابط غير صالح'
      }
    },
    PROGRAM: {
      EDIT: 'تعديل الحافظة',
      EDIT_PROGRAM: 'تعديل الحافظة',
      EDIT_HEADERS: {
        DETAIL: 'تفاصيل الحافظة',
        DESCRIPTION: 'وصف الحافظة',
        ME: 'تحديد الفترات والمعالم',
        PERIODS: 'المعايير والثوابت من الفترات،',
        MILESTONES: 'المعالم',
        OPTIONS: 'خيارات'
      },
      TITLE: 'الحافظة',
      NAME: 'الإسم',
      DESCRIPTION: 'الوصف',
      HELP: {
        NAME: 'اكتب إسم الحافظة',
        DESCRIPTION: 'اكتب وصفا للحافظة'
      }
    },
    DEFAULT_LOGFRAME: {
      TITLE: 'قوالب الإطار المنطقي',
      SUBTITLE: 'قالب الإطار المنطقي',
      LABEL: 'الإسم',
      LEVELS: 'مستوى الإطار المنطقي',
      SEARCH: 'البحث حسب الإسم',
      HELP: {
        NEW_LEVEL: 'إضافة مستوى جديد',
        LABEL: 'اكتب إسم الإطار المنطقي'
      }
    },
    SECTORS: {
      NAME: 'الإسم'
    },
    FINANCING: {
      EDIT_TITLE: 'التمويل',
      SOURCE: 'مصدر التمويل',
      AGREEMENT: 'اتفاقية التمويل رقم ',
      TYPE: 'اكتب',
      TYPES: {
        GRANT: 'منحة',
        LOAN: 'يقرض',
        DONATION: 'هبة',
        BUDGET_SUPPORT: 'دعم الميزانية'
      }
    },
    PORTFOLIO: {
      GO_TO_PORTFOLIO: 'عرض المشاريع',
      EDIT_TITLE: 'المحفظة',
      CLOSE: 'العودة إلى المحافظ',
      OPEN: 'إدارة المحفظة',
      CODE: 'اختصار المشروع',
      LABEL: 'إسم المشروع',
      TITLE: 'المحافظ',
      ADD: 'إضافة محفظة',
      ERROR: {
        DBL: 'تم تعيين اختصار المحفظة بالفعل',
        DATE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء',
        DATE_BEGIN_SUP: 'يجب أن يكون تاريخ البدء أكبر من {{X}}}',
        DATE_BEGIN_INF: 'يجب أن يكون تاريخ البدء أقل من {{X}}}',
        DATE_END_SUP: 'يجب أن يكون تاريخ الانتهاء أكبر من {{X}}}',
        DATE_END_INF: 'يجب أن يكون تاريخ الانتهاء أقل من {{X}}}',
        DELETE: 'هذا القطاع يستخدم حاليًا'
      }
    },
    PROJECT: {
      ACTIVE_SMS: 'قم بتنشيط إرسال تنبيهات الرسائل القصيرة',
      ACTIVE_SMS_WARNING: 'سيؤدي تنشيط هذا الخيار إلى تحمل تكاليف إضافية.\n' + 'يرجى الاتصال بـ DELTAGIS للتفعيل الفعال للخيار.',
      NEWS: 'أخبار',

      CUSTOM_FIELD_1: 'حقول التسمية التوضيحية 1',
      CUSTOM_FIELD_2: 'حقول التسمية التوضيحية 2',
      OPTION_ACTIVITIES: 'خيارات الإدارة',
      OPTION_INDICATORS: 'مؤشرات الخيارات',
      OPTION_OTHERS: 'خيارات أخرى',
      CUSTOM_FIELD: 'استخدام الحقول المخصصة',
      INDEX_PERFORMANCE: 'الأداء',
      CROSS_CUTTING_KPIS: 'إدارة مؤشرات الأداء',
      PORTFOLIO_CROSS_CUTTING_KPIS: 'عزل المؤشرات الشاملة للمحفظة',
      MISSIONS: 'متابعة المهمة',
      RISK: 'إدارة المخاطر',
      FOLLOW_RISK: 'مراقبة المخاطر',
      FOLLOW_PERFORM: 'مراقبة الاداء',
      TYPE: 'اكتب',
      TYPES: {
        PROJECT: 'المشروع',
        PROGRAM: 'برنامج',
        OTHER: 'آخر'
      },
      FILTERS: 'المرشحات',
      CLOSE: 'اغلاق',
      TO_FILTER: 'منقي',
      CLEAR_FILTERS: 'تمحو',
      ACTOR: 'المسؤول',
      ACTORS: 'المسؤول',
      ACTOR_NONE: 'ما من احد',
      STATUS_PROGRESS: 'تقدم',
      STATUS_PROGRESS_: 'تقدم',
      DASHBOARDS: 'لوحة تحكم المشاريع',
      GO_TO_PROJECT: 'انتقل إلى المشروع',
      SUMMARY: 'أداء المشروع',
      SELECT_SECTORS: 'القطاعات',
      SECTORS: 'القطاعات',
      PROJECTS: 'المشاريع',
      PROJECTS_COL: 'المشروع',
      EDIT: 'تعديل',
      GEO_MAP: 'خريطة مرسومة جغرافيا',
      SECTOR: 'القطاع',
      FINANCING: 'مصدر التمويل',
      AMOUNT: 'المبلغ',
      ADD_: 'يضيف',
      FINANCIAL_DATA: 'البيانات التمويلية',
      PAGING_SUMMERY: '{{current}} من أصل {{total}} صفحات ({{count}} مشاريع)',
      PAGING_ELEMENT_SUMMERY: '{{current}} من أصل {{total}} صفحات ({{count}} عناصر)',
      AGENCIES: 'الوكالات المنفّذة',
      SELECT_AGENCIES: 'حدد الوكالات المنفّذة',
      DISPLAY_DATA: 'استخدم البوابة لعرض البيانات',
      LOCATION: 'إحداثيات الموقع',
      ADD: {
        OPTION_1: 'المشروع',
        OPTION_2: 'الحافظة'
      },
      LINK: {
        TITLE: 'ربط البيانات التالية بالحافظات',
        UNITS: 'الوحدات',
        FINANCIAL_SOURCES: 'مصادر التمويل',
        LOCALITIES: 'المواقع',
        LOGICAL_FRAME: 'الإطار المنطقي',
        FLAG: 'عَلَم',
        TYPE_ACTIVITIES: 'فئات النشاط',
        TYPE_INDICATORS: 'فئات مؤشرات الأداء الرئيسية',
        TYPE_REPORT: 'التقارير',
        FORM: 'النماذج'
      },
      SELECT_PERIOD: 'حدد الفترة',
      SEARCH_PROJECT: 'البحث عن طريق الرمز أو التسمية',
      FILTER: 'الفرز حسب:',
      MILESTONES: {
        REALIZE: 'مكتمل',
        TITLE: 'الإنجازات',
        MILESTONE: 'الإنجاز',
        PLANNED_DATE: 'التاريخ المخطط له',
        ACTUAL_DATE: 'التاريخ الفعلي',
        ACTUAL: 'القيمة الفعلية',
        COMMENTS: 'تعليقات',
        PLANNED: 'مخطط له'
      },
      DESCRIPTION: 'وصف',
      GLOBAL_PLAN: 'استخدم التخطيط العام',
      PERIODIC_FOLLOW: 'وتيرة تقديم التقارير',
      SETTING_FOLLOW: 'إعدادات المراقبة',
      ACTIVITY_WEIGHT: 'استخدم الأوزان',
      ACTIVITY_WEIGHT_AN: 'سنويا',
      START_MONTH: 'بداية السنة المالية',
      FISCAL_YEAR: 'السنة المالية',
      LOGFRAME: 'الإطار المنطقي',
      RESBAS_MONIT: 'مراقبة مبنية على النتائج',
      CUSTOM_LOGFRAME: 'إطار منطقي مخصص',
      ME_METHOD: 'قوالب الإطار المنطقي',
      ME_METHOD_SELECT: 'حدد القالب الخاص بالإطار المنطقي للمشروع',
      FILTER_BY: 'الفرز حسب',
      PRJ: 'مَلَفّ',
      DASHBOARD: 'مؤشرات أداء مشتركة بين المشاريع',
      TITLE: 'مَلَفّ',
      NEWP: 'أضف',
      FROM: 'من',
      TO: 'إلى',
      ADDRESS: 'العنوان',
      CONTACT: 'مسؤول الاتصال (نقطة الاتصال)',
      PROCESS: 'التقدم المحرز...',
      COMPLETE: 'الإستكمال مع ',
      PROJECT: 'المشروع',
      EDIT_PROJECT: 'تعديل المشروع',
      INFO: 'معلومات',
      CODE: 'اختصار المشروع',
      LABEL: 'إسم المشروع',
      CURRENCY: 'العملة',
      D_DEB: 'تاريخ البداية',
      D_FIN: 'تاريخ الانتهاء',
      D_DEB_: 'تاريخ البداية',
      D_FIN_: 'تاريخ الانتهاء',
      COOR: 'معلومات الاتصال',
      LINK_GEO_PLAN: 'استخدام مواقع البرنامج',
      ADR: 'المدينة',
      PAYS: 'البلد',
      TEL: 'الهاتف',
      FAX: 'الفاكس',
      MAIL: 'البريد الإلكتروني',
      OTHER: 'التفاصيل',
      AGENC: 'الوكالة المنفذة',
      DESCR: 'الوصف',
      CODE_ERR: 'الرمز غير متاح',
      CONFIRM_DELETE: 'هل تريد حذف هذا المشروع؟',
      SELECT: 'حدد',
      CHANGE_PIC: '(100x100) تغيير الصورة',
      STATUT_BUDGET: 'نشر البيانات المالية ',
      GOAL: 'الهدف العام',
      TARGET: 'المستفيدون',
      AREA: 'منطقة التنفيذ',
      LOCATION_FIELD: 'موقع جغرافي',
      SPECIFIC_OBJECTIVES: 'اهداف محددة',
      IMPACT: 'الأثر المتوقع',
      DATE_MID: 'مراجعة منتصف المدة',
      STATUS: 'الحالة',
      COST: 'التكلفة الإجمالية للمشروع',
      FINAN: 'التمويل',
      MAP: 'موقع المشاريع ',
      GLOBAL_HEADERS: {
        PROJECTS: 'قائمة',
        MAP: 'خريطة',
        INDICATOR: 'مؤشرات أداء مشتركة بين المشاريع'
      },
      EDIT_HEADERS: {
        DETAIL: 'تفاصيل',
        DESCRIPTION: 'وصف',
        ME: 'تحديد الفترات والمعالم',
        PERIODS: 'المعايير والثوابت من الفترات،',
        MILESTONES: 'المعالم',
        OPTIONS: 'خيارات'
      },
      STATUTS: {
        NOT_STARTED: 'لم يبدأ',
        ACTIVE: 'نشِط',
        SUSPENDED: 'موقوف',
        COMPLETED: 'مكتمل',
        ARCHIVED: 'مؤرشف'
      },
      TOOLTIP: {
        CURRENCY: '5 أحرف كحد أقصى ',
        STATUT_BUDGET: 'في حالة تحديد الخيار ، سيتم الكشف عن البيانات المالية كجزء من المعلومات الموضحة في بوابة الموقع.'
      },
      ERROR: {
        CUSTOM_FIELD: 'الرجاء ملء أحد الحقول',
        DELETE: 'يحتوي المشروع على بيانات ولا يمكن حذفه! ',
        DBL: 'تم بالفعل تعيين الاختصار',
        DATE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء ',
        CURRENCY: 'يجب أن يكون طول العملة أقل من 5 أحرف',
        SAVING: 'إذا قمت بتحديث تاريخ بداية السنة المالية أو تكرار إعداد التقارير ، فسيتم حذف جميع البيانات ذات الصلة (المخططة والفعلية).'
      },
      MAP_VIEW: {
        NBR_PROJECT: 'عدد المشاريع',
        NBR: 'رقم',
        INVESTEMENT: 'الاستثمارات ({{currency}} ملايين)',
        MILLIONS: '({{currency}} ملايين)',
        AGENC: 'الوكالة المنفذة',
        PROJECTS: 'المشاريع',
        BUDGET: 'المبلغ ({{currency}} ملايين)',
        LOCATIONS: 'المواقع',
        BACK: 'العودة',
        STATUS: 'الحالة',
        SECTORS: 'القطاعات',
        FUNDING_SOURCE: 'مصادر التمويل',
        SUB_LOCATIONS: 'المواقع الفرعية',
        TOTAL: 'Total'
      },
      DASHBOARD_VIEW: {
        FINANCING_PLAN: 'خطة التمويل',
        DASHBOARD_TYPE: {
          PROJECTS: 'لوحة القيادة',
          RISK: 'المخاطر',
          PROBLEM: 'الحوادث',
          MISSION: 'البعثات'
        },
        FINANCING_TYPE_: 'نوع التمويل',
        DESCRIPTION: 'وصف',
        DESCRIPTION_: 'وصف',
        FINANCIAL_DATA: 'الأداء المالي',
        INDICATOR_PERFORMANCE: ' مؤشرات الأداء',
        INDICATOR_RESULT: 'إطار النتائج',
        EXCHANGE: 'سعر الصرف',
        TOOLTIP_SECTOR_PLURALS: '{{project}} مشاريع وما مجموعه {{amount}} ({{currency}} ملايين)',
        TOOLTIP_SECTOR_SINGLE: 'مشروع واحد وما مجموعه {{amount}} ({{currency}} ملايين)',
        NUMBER_OF_PROJECT: 'عدد المشاريع',
        DECIMAL_CURRENCY: '{{currency}} ملايين',
        FINANCING: 'التمويل',
        CURRENCY: 'العملة',
        PROJECT: 'المشاريع',
        AMOUNT: 'المبلغ',
        DISBURSED: 'منفقة',
        PROJECT_BY_SECTOR: ' حسب القطاع',
        INVESTING_BY_FUNDING_SOURCE: 'الاستثمار حسب مصادر التمويل',
        PROJECT_BY_FUNDING_SOURCE: 'التقسيم حسب مصدر التمويل',
        SOLD: 'متاح',
        RESULTS_FRAMEWORK_STANDARD: 'إطار نتائج مؤشر (الوضع الحالي)',
        RESULTS_FRAMEWORK_MULTI_YEAR: 'إطار نتائج مؤشر (التقدم السنوي)',
        RESULTS_FRAMEWORK: 'إطار النتائج',
        TOTAL_COST: 'التكلفة الإجمالية للمشروع',
        // DISBURSED_RATE: 'معدل الإنفاق',
        DISBURSED_RATE: 'تطور معدل الصرف العام',
        TOTAL_DISBURSED_BY_FUNDING: 'الإنفاق الإجمالي حسب مصدر التمويل',
        FILTER: 'الفرز',
        FINANCING_TYPE: 'مصدر التمويل',
        AGENCIES: 'الوكالات المنفّذة',
        STATUS: 'الحالة',
        SECTORS: 'القطاع',
        LOCATIONS: 'المواقع',
        LOCATION: 'المواقع'
      }
    },
    BTN: {
      ADD: 'أضف',
      MOD: 'تعديل',
      DEL: 'حذف',
      CAN: 'إلغاء',
      PRI: 'طباعة',
      VAL: 'موافق',
      NEX: 'الصفحة التالية',
      PRE: 'الصفحة السابقة'
    },
    DEVISE: {
      TITLE: 'العملة',
      CODE: 'الرمز',
      LABEL: 'الإسم'
    },
    FUNDS: {
      AVAILABLE: 'غير مؤجل',
      MAPPING: 'استيراد البيانات',
      USE_SELECTED_FINANCING: 'استخدام التمويل المحدد',
      USE_CURRENT_DATE: 'استخدام تاريخ اليوم',
      PLEASE_SELECT_COLUMN: 'الرجاء تحديد عمود معين',
      REFERENCE: 'الرقم',
      REFERENCE_FIELD: 'رقم الإنفاق ',
      LABEL: 'الإنفاق',
      EDIT_TITLE: 'الإنفاق',
      DATE_FUNDS: 'التاريخ',
      AMOUNT: 'المبلغ',
      DEVISE: 'العملة',
      RATE: 'المعدل',
      AMOUNT_RATE: 'المبلغ * المعدل',
      HELP: {
        REFERENCE: 'رقم أو مرجع الإنفاق',
        LABEL: 'طبيعة الإنفاق أو الغرض منه',
        DATE_FUNDS: 'تاريخ الإنفاق',
        AMOUNT: 'المبلغ المنفق',
        DEVISE: 'عملة المبلغ المنفق',
        RATE: 'معدل الإنفاق مقابل عملة التمويل',
        AMOUNT_RATE: 'المبلغ (عملة التمويل)'
      },
      FINANCING: 'التمويل',
      DISBURSED: 'منفقة',
      BAR_CHART: 'معدل الإنفاق حسب السنة',
      PIE_CHART: 'الإنفاق الإجمالي حسب السنة'
    },
    FN_UNIT: {
      HELP: {
        COD: 'أدخل رمز الوحدة (10 أحرف)',
        LAB: 'إسم الوحدة'
      },
      ERROR_CODE: 'يجب ألا يتجاوز طول الرمز 10 أحرف',
      SEARCH: 'البحث عن الوحدات حسب الرمز أو الإسم',
      LST_TI: 'وحدات القياس (الأنشطة والمؤشرات)',
      ED_TI: 'تفاصيل الوحدة',
      COD: 'الرمز',
      LAB: 'التسمية',
      TYP: 'النوع',
      FOR: 'الصيغة',
      CB_N: 'رقمية',
      CB_P: '%',
      CB_B: 'منطقية',
      CB_T: 'نص',
      CB_D: 'التاريخ',
      NEWU: 'إنشاء وحدة جديدة ',
      COLU: 'الوحدة',
      CONFIRM_DELETE: 'هل تريد حذف هذه الوحدة؟',
      DECIMAL: 'عدد الأرقام بعد النقطة العشرية'
    },
    CATEGORIE_BUDGET: {
      HELP: {
        COD: 'أدخل رمز الوحدة (10 أحرف)',
        LAB: 'إسم الفئة'
      },
      CODE: 'الرمز',
      CATEGORIE_BUDGET: 'فئة الميزانية',
      TITLE: 'فئات الميزانية',
      LABEL: 'الإسم',
      CONFIRM_DELETE: 'هل تريد حذف هذه الفئة؟'
    },
    AGENCIES: {
      TITLE: 'الوكالات المنفّذة',
      NAME: 'الإسم',
      SHORT_NAME: 'الإسم القصير',
      ADDRESS: 'العنوان',
      CITY: 'المدينة',
      COUNTRY: 'البلد',
      TEL: 'الهاتف',
      FAX: 'الفاكس',
      EMAIL: 'البريد الإلكتروني',
      URL: 'الموقع الإلكتروني',
      HELP: {
        NAME: 'اكتب إسم الوكالة',
        SHORT_NAME: 'اكتب الإسم القصير للوكالة',
        ADDRESS: 'اكتب عنوان الوكالة',
        CITY: 'اكتب المدينة',
        COUNTRY: 'البلد',
        TEL: 'أدخل رقم الهاتف',
        FAX: 'أدخل الفاكس',
        EMAIL: 'أدخل البريد الإلكتروني',
        URL: 'أدخل الموقع الإلكتروني'
      },
      SEARCH: 'إبحث حسب الإسم أو العنوان أو المدينة أو البلد',
      ERROR: {
        DBL_NAME: 'الاسم مسند بالفعل'
      }
    },
    FN_ACTORS: {
      HELP: {
        COD: 'الرمز أو المعرّف أو الاختصار',
        NOM: 'إسم صاحب المصلحة',
        PRE: 'إسم صاحب المصلحة',
        FON: 'الدور / المسؤولية المسندة لصاحب المصلحة',
        ORGANIZATION: 'المنظمة التي ينتمي إليها صاحب المصلحة ',
        GRO: 'مجموعة صاحب المصلحة ',
        EMAIL: 'البريد الإلكتروني لصاحب المصلحة '
      },
      PERIOD_RAPPORT: 'الفترة',
      EDITION_RAPPORT: 'تاريخ الإصدار',
      SEARCH: 'البحث حسب الرمز أو الإسم',
      CATEGORY: 'الفئة',
      CATEGORY_TEAM: 'موظفي المشروع',
      CATEGORY_STAKE: 'أصحاب المصلحة',
      LST_AC: 'أصحاب المصلحة',
      ED_AC: 'عضو فريق',
      COD: 'الرمز',
      NOM: 'الإسم',
      PRE: 'الإسم',
      FON: 'الدور / المسؤولية',
      IMG: 'الصورة',
      ORGANIZATION: 'المنظمة',
      GRO: 'المجموعة',
      GRO_: 'المجموعة',
      SELECT_GRO: 'حدد مجموعة',
      NEWA: 'إنشاء صاحب مصلحة جديد',
      LAB_AC: 'الأنشطة',
      LAB_LSTAC: 'الأنشطة',
      LAB_MI: 'المهام',
      LAB_LSTMI: 'المهام',
      LAB_RE: 'اجتماع',
      LAB_LSTRE: 'اجتماع',
      LAB_RA: 'التقارير',
      LAB_LSTRA: 'التقارير',
      LAB_TYPRA: 'نوع التقرير',
      FILT: 'الفرز',
      ETAV: 'حالة النشاط',
      RESNIVO: 'مستوى المسؤولية',
      CHECK_A1: 'قيد التنفيذ',
      CHECK_A2: 'مكتمل',
      CHECK_A3: 'متأخر عن الموعد',
      CHECK_A4: 'قبل الموعد المحدد',
      CHECK_A5: 'عالمي',
      CHECK_A6: 'محلي',
      CONFIRM_DELETE: 'هل تريد حذف هذا الممثل؟'
    },
    FN_SFIN: {
      APPROVE_DATE: 'تاريخ الموافقة',
      CLOSE_DATE: 'الموعد النهائي',
      SEARCH: 'البحث حسب الرمز أو الإسم',
      NAME: 'الإسم',
      NAME_CONTACT: 'الإسم',
      TEL_CONTACT: 'الهاتف',
      EMAIL_CONTACT: 'عنوان البريد الإلكتروني',
      NAME_CONTACT_LONG: 'جهة الاتصال (الاسم)',
      TEL_CONTACT_LONG: 'جهة اتصال (الهاتف)',
      EMAIL_CONTACT_LONG: 'جهة اتصال (بريد إلكتروني)',
      CONTACT: 'الاتصال بالشخص',
      FINANCE_AMOUNT: 'مبلغ التمويل',
      AMOUNT: 'مبلغ التمويل',
      CURRENCY: 'العملة',
      EXCHANGE_RATE: 'معدل الصرف',
      LST_FIN: 'مصادر التمويل',
      ED_FIN: 'تفاصيل مصدر التمويل',
      CONFIRM_DELETE: 'هل تريد حذف هذا المصدر؟',
      EQUIV: 'المساوي في {{CURRENCY}}',
      ERROR_DATE: 'يجب أن يكون تاريخ الموافقة أقل من تاريخ الإغلاق'
    },
    FN_TYPINDIC: {
      LST_TYP: 'قائمة أنواع المؤشرات ',
      ED_TYP: 'تفاصيل نوع المؤشر',
      COL_COLOR: 'اللون',
      COLOR_BLUE: 'أزرق',
      COLOR_CRYAN: 'أزرق سماوي',
      COLOR_GREEN: 'أخضر',
      COLOR_YELOW: 'أصفر',
      COLOR_ORANGE: 'برتقالي',
      COLOR_RED: 'أحمر',
      COLOR_PINK: 'وردي',
      COLOR_PURPLE: 'أرجواني',
      COLOR_GREY: 'رمادي',
      COLOR_BLACK: 'أسود',
      CONFIRM_DELETE: 'هل تريد إزالة هذا النوع من المؤشرات؟'
    },
    DASHBOARD: {
      PROGRESS_REPORT: 'تقرير التقدم',
      ACTIVITIES_TAB: {
        PHYSIC: '% من الإنجاز',
        FINANCIAL: '% من الميزانية',
        DISBURSED: '% منفقة',
        TITLE: 'التقدم المحرز في النشاط',
        COMPLETED_ACTIVITIES: 'مكتمل',
        NOT_CARRIED_OUT_ACTIVITIES: 'غير منفّذ',
        ON_GOING_ACTIVITIES: 'جاري',
        STATUS: 'الحالة'
      },
      LOCATION: 'الموقع',
      ACTIVITY_COUNT: 'عدد الأنشطة: {{value}}',
      PROJECT_ACTIVITY: '{{value}} من أنشطة المشروع',
      INDICATOR_PROGRESS: 'التقدم المحرز للمؤشر',
      SPATIAL_VIEW: 'عرض مكاني',
      INDICATOR_PROGRESS_COMPARED: 'تقدم المؤشر مقارنة مع الهدف النهائي للمشروع (نهاية المشروع)',
      INDICATOR: 'لوحة بيانات المؤشر',
      ACTIVITY: 'لوحة بيانات النشاط',
      BOOKMARKED: 'تم وضع المؤشر كإشارة مرجعية',
      REFERENCE: 'خط الأساس',
      FINAL: 'نهاية المشروع',
      END_TARGET: 'الهدف النهائي',
      ACTUAL_VALUE: 'القيمة الفعلية',
      END_VALUE: 'نهاية المشروع',
      MAP: 'الخريطة',
      TITLE: 'لوحة بيانات المشروع',
      TITLE_PORTFOLIO: 'لوحة القيادة',
      V_R: 'قيمة المرجع',
      V_C: 'القيمة المستهدفة',
      FROM: 'من',
      TO: 'إلى',
      PROCESS: 'التقدم المحرز...',
      COMPLETE: 'مكتمل',
      SIT_INDIC: 'حالة المؤشرات',
      REP_ACTIVITE: 'أداء المشروع',
      PHY: '% من الهدف ',
      FIN: '% من الميزانية ',
      GRAPHE_PERF: 'رسم الأداء ',
      SUIV_PTBA: 'الأداء السنوي للمشروع ',
      DASHBOARD: 'لوحة البيانات',
      RESUME_PROJECT: 'ملخص المشروع',
      EVOLUTION_TABLE: 'التقدم المحرز للمؤشر',
      REALIZE: 'تم إنجاز % من نهاية المشروع',
      YEAR: 'السنة',
      PERIOD: 'الفترة',
      INDICATORS: 'المؤشرات',
      ACTIVITIES: 'الأنشطة',
      MSG_DBCLK: 'انقر مرتين لمزيد من التفاصيل'
    },
    TIME_DIVISION: {
      PERIOD: 'الفترة',
      PTBA: 'خطة العمل والميزانية السنوية الحالية',
      PLAN_AN: 'الخطة السنوية',
      LEVEL: 'المستوى',
      LEVEL_1: 'عالمي',
      LEVEL_2: 'عام',
      LEVEL_3: 'فترة',
      ALLOW_TOTAL_GLOBAL: 'السماح للإجمالي السنوي بتجاوز العالمي',
      ALLOW_TOTAL_ANNUAL: 'السماح للفترة الإجمالية بتجاوز السنوية',
      START_LEVEL: 'بدء مستوى التخطيط',
      GLOBAL: 'عالمي',
      ANNUAL: 'سنوي',
      PERIODIC: 'فترة',
      HELP: {
        LEVEL_1: 'تسمح الخطة العالمية بوضع أهداف وميزانيات خلال الفترة الكاملة للمشروع',
        LEVEL_2: 'يتم تقسيم الخطة العالمية إلى خطط سنوية لكل سنة من سنوات المشروع',
        LEVEL_3: 'يتم تقسيم كل خطة سنوية إلى خطط على فترات حسب الفترات التي يتم تحديدها بشكل مسبق'
      },
      BEGIN: 'البداية',
      END: 'النهاية',
      LABEL: 'التسمية'
    },
    GEOGRAPHIC_PLAN: {

      IMPORT_SHP: {
        INFO: 'يرجى اختيار الخاصية التي تحتوي على اسم الأماكن',
        PROPERTY: 'الخاصية'
      },
      REFRESH_GEO_CODING_FILE: 'تحديث الترميز الجغرافي من ملف (geojson/shapefile)',
      REFRESH_GEO_CODING: 'تحديث المراجع الجغرافية',
      COUNTRY: 'البلد',
      ADD__: 'إضافة موقع',
      ADD: 'إضافة موقع فرعي',
      SUM: 'المجموع',
      MOY: 'المتوسط',
      MAXI: 'الحد الأقصى',
      MINI: 'الحد الأدنى',
      AGGREGA: 'التجميع',
      GENERATE: 'الاستيراد من الخادم الجغرافي',
      LEVEL: 'المستوى',
      CHAMPPERSO: 'حقول البيانات المعرفة من قبل المستخدم ',
      LABEL: 'التسمية',
      CONFIRM_DELETE: 'هل تريد حذف هذا الحقل؟',
      LONG: 'الطول',
      ERROR_UPDATE_LONG: 'لا يمكن تحديث الطول.',
      CONFIRM_DELETE_LEVEL: 'هل تريد حذف هذا المستوى؟',
      CONFIRM_DELETE_LOCALTION: 'هل تريد حذف هذا الموقع؟',
      CODE: 'الرمز',
      DESCRP: 'الوصف',
      IMPORT: 'الاستيراد من ملف Excel',
      IMPORT_SHAPEFILE: 'الاستيراد من ملف شكل أو ملف geojson',
      ERROR_STRUC: 'تم اكتشاف خطأ في بنية الملف',
      ERROR_FORMAT: 'الرجاء استخدام ملف بصيغة *.xsl!',
      ERROR_CANADD: 'يتم استخدام المواقع!',
      UPLOAD_LABEL: 'المستوى {{level}}...',
      EXPORT: 'التحويل إلى Excel'
    },
    INDICATOR_CAT: {
      TITLE_EDIT: 'تعديل الفئات',
      TITLE: 'فئات التصنيف',
      CODE: 'الرمز',
      LABEL: 'الإسم',
      AGGREGATE: 'التجميع',
      OPTIONS: 'الخيارات',
      OPTION: 'الخيار'
    },
    INDICATOR: {
      VALUE_TO_DISPLAY: 'الخاصية المراد عرضها',
      PORTFOLIOS: 'المحافظ',
      DATA_REFRESH: 'قيم التحديث',
      FIXED_DENOMINATOR: 'مقام ثابت',
      FROM_CALCULATE_DATA_NUM_DEMO: 'البسط / المقام',
      NUMERATOR: 'عداد',
      DENOMINATOR: 'قاسم',
      FROM_CALCULATE_DATA_NUM_DEMO_HELP: '',
      WEIGHT: 'وزن',
      MOY_WEIGHT: 'متوسط الوزن',
      PROJECT_INDICATORS: 'مؤشرات المشروع',
      PROJECT_INDICATOR_SHARE: 'المؤشرات المشتركة',
      ADD_ITEM: 'إضافة مؤشر المشروع',
      OPTION_CATEGORY: 'اختيار',
      PROJECTS: 'المشاريع',
      CROSS_INDICATOR: 'مؤشر مشترك بين المشاريع',
      CALCULATE_DATA: 'حساب',
      ADD_ACTIVITY: 'مؤشر جديد',
      EDIT_ACTIVITY: 'الإضافة من مؤشرات قائمة',
      ADD: 'إضافة مؤشر',
      LAYER_URL: 'عنوان URL للطبقات',
      EDIT_LAYERS: 'أدخل عنوان URL للطبقة',
      USE_COLORS: 'إعدادات ألوان الخريطة',
      USE_LAYERS: 'إعداد طبقات الخريطة',
      OUTPUT: 'النواتج',
      CROSS_PROJECT: {
        NAME: 'الإسم',
        DESCRIPTION: 'الوصف'
      },
      LINK_LOGFRAME: 'رابط الإطار المنطقي',
      NONE_AGGREGATE: 'لا يوجد',
      UNIT_COST: 'تكلفة الوحدة',
      INDICATOR_BASE: 'قائمة المؤشرات',
      SEARCH: 'البحث عن مؤشر',
      SELECT_INDICATOR: 'حدد مؤشرا ',
      SEARCH_LOCATION: 'البحث عن موقع',
      SEARCH_PERIOD: 'بحث فترة',
      PERFORMANCE_INDICATOR: 'مؤشرات الأداء',
      CROSS_INDICATORS: 'المؤشرات الشاملة',
      MAPPING: 'رسم الخريطة',
      CATEGORY: 'فئات التصنيف',
      CROSS_DISAGGREGATION: 'تصنيف مشترك',
      PARALLEL_DISAGGREGATION: 'تصنيف موازي',
      DESCRIPTION_ONG: 'التفاصيل',
      POSSIBLEVALUE: 'القيم الممكنة',
      LESS_THAN: 'يجب أن تكون القيمة أقل من أو تساوي {{valeur}}',
      GREAT_THAN: 'يجب أن تكون القيمة أكبر من أو تساوي {{valeur}}',
      EGAL_TO: 'يجب أن تساوي القيمة {{valeur}}',
      BEETWEEB: 'يجب أن تكون القيمة بين {{valeur_min}} و {{valeur_max}}',
      ERROR_UPDATE: 'حدث خطأ أثناء التحديث!',
      LEGEND: 'إعدادات نظم المعلومات الجغرافية',
      LEGEND_COLOR: 'ألوان مفتاح الخريطة',
      ERROR_DECOUP_PLAN: {
        DATE_COLLECT: 'يجب أن ينتمي تاريخ التحصيل إلى الفترة',
        TITLE: 'خطأ',
        VALUE: 'الرجاء إدخال قيمة',
        DATE_RELEVE: 'الرجاء إدخال التاريخ المخطط له',
        DATE_ALERT: 'الرجاء إدخال تاريخ التنبيه',
        DATE_A: 'يجب أن يكون تاريخ التنبيه أقل من التاريخ المجدول.',
        DATE_RELEVE_T: 'يجب أن يكون التاريخ المخطط ضمن الفترة وضمن مدة المؤشر',
        CATEGORY_VALUE: 'مجموع القيم المصنفة لا يساوي القيمة المجمعة',
        CATEGORY_VALUE_POSITIF: 'يجب أن تكون القيمة المصنفة أكبر من أو تساوي 0',
        CATEGORY_VALUE_PARALLELE: 'تأكد من تصنيف كل قيمة تم إدخالها بشكل صحيح في كل فئة',
        DENOMINATOR_ERROR: 'يجب أن تكون قيمة المقام مختلفة عن 0'
      },
      ENTRY_DATA_HELP: 'يتم إدخال نتائج المؤشر يدويا',
      PROJECT: {
        VALUE: 'القيمة',
        VALUES: 'القيم'
      },
      HELP: {
        TRANS: 'المؤشر المشترك هو مؤشر مشترك بين كل الأنشطة (انظر التفاصيل في الدليل)'
      },
      COUNT_ALL: 'عد',
      DATA_TABLE: {
        TEMPLATE: 'ملف البيانات',
        CALCULATION: 'حساب',
        LOCALITIES: 'الجغرافيا',
        PERIODS: 'الفترة',
        QUERY: 'الاستفسار',
        CALCULATION_ITEM: 'الحقل'
      },
      DOCUMENTS: 'وثائق',
      COMPOSITE: 'مصدر البيانات',
      SELECT: 'القائمة',
      LOCATION: 'الموقع',
      CALMETHOD: 'طريقة الحساب',
      CRITERIA: 'المعايير',
      CODE: 'الرمز',
      INDICATOR_EDIT: 'تفاصيل المؤشر',
      KEY_KPI: 'النواة ',
      NAME: 'الإسم',
      NAME_FRENCH: 'الإسم (بالفرنسية)',
      CALCULATE_RATE: 'في حساب % من الإنجاز {{lastLevel}}',
      NAME_ENG: 'الإسم (بالإنجليزية)',
      DEF: 'التعريف / الوصف',
      UNIT: 'وحدة القياس',
      TYPE: 'الفئة',
      CLASS: 'النوع',
      KEY: 'المؤشرات (KPIs)',
      VAL_POSS: 'القيم الممكنة',
      FORMULA: {
        REQUIRED_ALL: 'جميع مؤشرات الصيغة إلزامية',
        CLEAR: 'لمحو',
        INVALID: 'صيغة غير صحيحة',
        NONEINDIC: 'لا يوجد مؤشر في الصيغة'
      },
      BEGIN: 'تاريخ البدء',
      END: 'تاريخ الانتهاء',
      FREQUENCE: 'تواتر عملية الجمع',
      NIV_GEO: 'إدخال المستوى الجغرافي',
      _NIV_GEO: 'الجغرافيا',
      AGGREGA: 'طريقة التصنيف الجغرافي',
      GROUP_CIBLE: 'المجموعة المستهدفة',
      HYPOTHESE: 'الافتراضات',
      MOY_VERIF: 'وسائل التحقق',
      TECH_COLL: 'تقنية التجميع',
      TARGET: 'الهدف النهائي (نهاية المشروع)',
      REF: 'خط الأساس',
      TARGET_VAL: 'الأهداف',
      REAL_VAL: 'القيم الفعلية',
      VARIANCE: 'الفرق',
      TRANS: 'مؤشر شامل لعدة قطاعات',
      DISPLAY_TRANS: 'المؤشرات الشاملة لعدة قطاعات فقط',
      SIMPL: 'بسيط',
      COMPO: 'معقد',
      CUMUL: 'تراكمي',
      COMPL: 'معقد',
      SUM: 'المجموع',
      MOY: 'المتوسط',
      MAXI: 'الحد الأقصى',
      MINI: 'الحد الأدنى',
      NONE: 'لا شيء',
      MOD_UN: ' سيؤدي تغيير الوحدة إلى فقدان جميع السجلات.\n هل تريد المواصلة؟',
      MOD_NG: 'سيؤدي تغيير المستوى الجغرافي إلى فقدان جميع السجلات.\n هل تريد المواصلة؟',
      MOD_DATE: 'قد يؤدي تغيير التواريخ إلى فقدان سجلات متعددة.\n هل تريد المواصلة؟',
      MOD_PER: ' قد يؤدي تغيير الدورية إلى فقدان سجلات متعددة.\n هل تريد المواصلة؟',
      MOD_CLS: ' سيؤدي تغيير مصدر البيانات إلى فقدان جميع السجلات.\n هل تريد المواصلة؟',
      LABEL_VP: 'التسمية',
      POUND: 'القياس',
      PLAN: 'تخطيط المؤشر',
      PLAN_: {
        TITLE: 'إعداد الأهداف',
        VALUE: 'الهدف'
      },
      TRACK_: {
        ACTUAL_DATE: 'التاريخ الفعلي',
        VALUE: 'أدخل القيمة الفعلية',
        TITLE: 'المؤشر الفعلي'
      },
      DATE_R: 'التاريخ المخطط له',
      VALUE: 'القيمة',
      BUDGET: 'الميزانية',
      COST: 'التكلفة',
      REL: 'تجميع بيانات المؤشر',
      RAPP: 'تقرير المؤشر',
      PIC: 'الصورة',
      IN_MON: 'مراقبة المؤشر',
      MON: 'مراقبة',
      DATA_FIN: 'التكلفة',
      DATA_FIN_: 'التكلفة',
      FOLLOW_FIN: 'تتبع الميزانية/التكلفة',
      DISPLAY_CODE_LOC: 'عرض الرموز الجغرافية',
      COMMENTS: 'التعليقات',
      RESPO: 'الجهة المسؤولة',
      DISPLAY_PL: 'عرض',
      ACHEVE: 'تم إنجاز %',
      SOCIO_ECO: 'اجتماعي واقتصادي',
      SPATIAL: 'مكاني',
      GRAPHE_COMBINE: 'رسوم بيانية مختلطة',
      CONFIRM_DELETE_RELEVE: 'هل تريد حذف هذه البيانات؟',
      CONFIRM_DELETE_PLAN: 'هل تريد حذف هذا التخطيط؟ ',
      CONFIRM_DELETE: 'هل تريد حذف هذا المؤشر؟',
      CONFIRM_DELETE_CROSS: 'ستؤدي إزالة المؤشر إلى حذف البيانات في المشاريع',
      DATE_ALERT: 'تاريخ التنبيه',
      ERRORS: {
        VP_LABEL: 'هذا الإسم مسند مسبقا',
        VP_VALUE: 'هذا القياس مسند مسبقا'
      },
      GLOBAL: {
        TITLE: 'الخطوط الأساسية للمؤشرات والأهداف النهائية',
        TITLE_: 'الهدف والقيم الفعلية',
        LOCATION: 'الموقع',
        BEGIN: 'تاريخ البدء',
        END: 'تاريخ الانتهاء',
        TARGET: 'الهدف النهائي (نهاية المشروع)',
        REF: 'خط الأساس',
        COMMENTS: 'التعليقات',
        ERROR: {
          REF_VALUE_DESEGREGATE: 'مجموع القيم المرجعية المصنفة لا يساوي القيمة المرجعية',
          TARGET_VALUE_DESEGREGATE: 'مجموع قيم الهدف المفصلة لا يساوي القيمة المستهدفة',
          DATE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء ',
          Target_Value: 'الرجاء إدخال الهدف!',
          REF_VALUE: 'الرجاء إدخال القيمة المرجعية!',
          DATE_D: 'الرجاء إدخال تاريخ البدء!',
          DATE_F: 'الرجاء إدخال تاريخ الانتهاء!'
        }
      },
      FROM_ENTRY_DATA: 'إدخال مباشر',
      FROM_CALCULATE_DATA: 'الحساب باستخدام صيغة معينة',
      FROM_DATABASE: 'الملأ من ملف بيانات',
      OVERVIEW_MENU: {
        SETTINGS: 'إعدادات المؤشر',
        BASELINE: 'تحديد الأساسات',
        TARGET: 'تحديد الأهداف',
        ACTUAL: 'تتبع القيم الفعلية',
        REPORT: 'إعداد تقرير'
      },
      MENU: {
        EDIT: 'تعديل',
        PLAN: 'أهداف الخطة',
        RELE: 'تتبع المؤشر',
        FICHE: 'الورقة المرجعية للمؤشر',
        IMAGE: 'الصورة (300 × 400) ',
        RIGHT: 'تعيين الأذونات',
        GLOB_RELE: 'خطوط الأساس والأهداف النهائية ',
        RAPPORT: 'ملاحظة',
        SPATIAL: 'الخريطة',
        LEGEND: 'المفتاح',
        WORKFLOW: 'سير العمل',
        CUSTOM_REPORT: 'تقارير مخصصة'
      },
      RIGHT: {
        TITLE: 'إعداد أذونات المستخدم',
        BASELINE: 'خطوط الأساس والأهداف النهائية',
        TARGET: 'أهداف الخطة',
        ACTUAL: 'تتبع المؤشر'
      },
      PLAN_TARGET: {
        TITLE: 'أهداف المؤشر'
      },
      TRACK: {
        TITLE: 'تتبع المؤشر'
      },
      DASHBOARD: {
        TAB: 'تقرير المؤشر',
        G: 'المشروع الإجمالي',
        Y: 'السنة',
        P: 'الفترة',
        TIMESCALE: 'الجدول الزمني',
        DIFFERENCE: 'الفرق',
        FINAL_TARGET: 'الهدف النهائي للمشروع',
        BASELINE: 'خط الأساس',
        TARGETS: 'الأهداف',
        ACTUALS: 'القيم الفعلية',
        RATE_TARGET: '% من الهدف',
        MAP: 'الخريطة',
        TITLE2: '% من الأهداف حسب',
        TITLE2_: '% من الأهداف حسب المشاريع',
        TITLE1_1: 'مؤشر المراقبة - الإنجازات / الأهداف',
        TITLE1_2: 'التقدم المحرز للمؤشر حسب السنة',
        LEGEND: '% من الأهداف',
        TITLE: 'تقرير المؤشر',
        PAGE1TITLE: 'الأداء (القيم الفعلية مقابل الأهداف)',
        PAGE2TITLE: 'التقدم المحرز للمؤشر',
        PAGE2SUBTITLE1: 'التغيير إلى المؤشر',
        PAGE2SUBTITLE2: 'التقدم المحرز للمؤشر في',
        TITLE_1_3: 'القيم الفعلية مقابل الأهداف حسب',
        TITLE_1_3_: 'القيم الفعلية مقابل الأهداف حسب المشاريع'
      },
      SHEET: {
        SHEET: 'ورقة',
        TITLE: 'الورقة المرجعية للمؤشر',
        NAME: 'إسم المؤشر',
        DESCRP: 'الوصف',
        TYPE: 'الفئة',
        RAT_CL: 'رابط الإطار المنطقي',
        U_MES: 'وحدة القياس',
        PER_COL_SUI: 'وتيرة تقديم التقارير',
        NG: 'المستوى الجغرافي',
        RESPO: 'الفرد (الأفراد) المسؤولون',
        TECH: 'تقنية التجميع',
        SRC: 'مصدر البيانات',
        FORMULA: 'صيغة الحساب',
        VAL_REF: 'القيمة المرجعية',
        VAL: 'القيمة',
        EVO: 'التغيير إلى المؤشر',
        FINAL_TARGET: 'الهدف النهائي (نهاية المشروع)',
        BASELINE: 'خط الأساس',
        TARGETS: 'الأهداف ',
        ACTUALS: 'الأرقام الفعلية'
      },
      SPATIALS: {
        DATA: 'طبقات البيانات',
        LAYERS: 'الطبقات',
        TITLE: 'إعدادات الطبقة',
        LAYER: 'طبقة',
        LABEL: 'التسمية',
        PERIODIC: 'دورية',
        URL: 'عنوان URL',
        PROPERTY: 'القالب',
        DATA_LAYER: 'طبقة البيانات',
        PIN: 'الواسم',
        SERVICE: 'خدمة',
        SERVER_URL: 'مصدر البيانات (عنوان URL للخادم)',
        LAYERS_ID: 'معرف الطبقة',
        ADD_PARAMS: 'معلمات إضافية',
        AUTHORIZATION: 'المصادقة',
        AUTHORIZATION_TOKEN: 'رمز',
        DISPLAY_POPUP: 'عرض المعلومات'

      },
      USER: 'تم إدخال البيانات بواسطة'
    },
    INDICATOR_VISUALISATION: {
      CATEGORIES: 'فئة',
      DESEGREGATE_VALUE: 'إظهار القيم المصنفة',
      CIRCLE: 'دائرة',
      DELTA: 'الطبقة الافتراضية',
      DATA: 'البيانات',
      LAYERS: 'الطبقات',
      LEGEND: 'المفتاح',
      FORMATTING: 'التصميم الشكلي',
      BRIGHTNESS: 'السطوع',
      INDICATOR: 'المؤشر',
      VALUE: 'القيمة',
      YEAR: 'السنة',
      PERIOD: 'الفترة',
      TITLE: 'التصور المكاني',
      SELECT_INDICATOR: 'حدد مؤشرا',
      DISPLAY_DATA: 'عرض البيانات',
      SELECT_GEO: 'حدد مستوى جغرافيا',
      SELECT_LAYER: 'حدد الطبقات',
      COLLECTED_DATA: 'البيانات المجمعة',
      ERROR_INTERVAL: 'خطأ في الفواصل الزمنية'
    },
    LOGICAL_PLAN: {
      CROSS_CUTTING_KPIS: 'عرض المؤشرات الشاملة',
      RATE_INDIC_HELP: 'بتحديد هذا الخيار ، ستتم مراقبة الأنشطة فقط عن طريق المؤشرات الموجودة في وحدة المؤشر في DELTA',
      ADD: 'أضف',
      HAS_INDIC: 'إسناد مؤشر',
      LABEL: 'الإسم',
      LONG: 'الطول',
      ERROR_UPDATE_LONG: 'لا يمكن تحديث الطول.',
      CONFIRM_DELETE_LEVEL: 'هل تريد حذف هذا المستوى',
      CONFIRM_DELETE: 'هل تريد حذف هذه البيانات؟',
      CODE: 'الرمز',
      DESCRP: 'الوصف',
      LABEL_C: 'الإسم',
      LABEL_L: 'الوصف',
      IMPORT: 'استيراد الإطار المنطقي',
      WEIGHT_AUTO: 'وزن تلقائي',
      BUDGET: 'الميزانية',
      COST: 'التكلفة الفعلية',
      SUM_WEIGHT: 'الوزن الإجمالي',
      BY: 'بناء على',
      WEIGHT: 'الوزن',
      RATE_INDIC: 'التقدم المحرز للنشاط مقاس بمؤشرات الأداء الرئيسية ',
      ERROR_STRUC: 'تم اكتشاف خطأ في بنية الملف',
      ERROR_FORMAT: 'الرجاء استخدام ملف بصيغة *.xsl!',
      ERROR_CANADD: 'العنصر مستخدم!',
      UPLOAD_LABEL: 'المستوى {{level}}...',
      HELP: {
        CODE: 'أدخل رمز {{LIBELLE_NCL}}',
        LABEL_C: 'أدخل {{LIBELLE_NCL}}',
        LABEL_L: 'وصف {{LIBELLE_NCL}}'
      },
      ERROR: {
        DELETE_ACTIVITY: ' يحتوي النشاط على بيانات ولا يمكن حذفه! ',
        DELETE: 'يحتوى العنصر على بيانات ولا يمكن حذفه!',
        DELETE_INDICATOR: 'مؤشر واحد على الأقل مرتبط بالعنصر!'
      }
    },
    FLAG: {
      LABEL: 'أسطورة اللون',
      MIN_VALUE_INDICATOR: 'مؤشر القيمة أكبر من أو يساوي',
      MIN: 'الأدنى',
      MAX: 'الأقصى',
      COLOR: 'اللون',
      MIN_VALUE: 'القيمة أكبر من أو تساوي',
      ERROR: {
        LABEL: 'هذه التسمية متواجدة بالفعل',
        CHEVAL: 'الفاصل الزمني يتداخل مع واحد آخر',
        IN: 'الفاصل الزمني متواجد في واحد آخر',
        OUT: 'الفاصل الزمني يتضمن واحدا آخر'
      }
    },
    FRAME_RESULT: {
      DISPLAY_NO_CUMUL_DATA: 'عرض القيم غير التراكمية',
      TITLE_2: 'إطار نتائج (الوضع الحالي)',
      TITLE_1: 'إطار نتائج (التقدم السنوي)',
      FINAL_TARGET: 'القيمة المستهدفة النهائية',
      OPEN_CHART: 'إظهار الرسم البياني',
      DETAILS: 'أظهر التفاصيل',
      ACTUAL: 'الحالي',
      VALUE: 'القيمة',
      DATE: 'التاريخ',
      ALL_LOCATION: 'جميع المواقع',
      ALL_PROJECT: 'كل المشاريع',
      COMMENT: 'تعليق',
      FORMAT: 'الصيغة المعيارية',
      INDIC: 'المؤشر',
      T_I: 'الفرز',
      IMPACT: 'التأثير',
      TARGET: 'الهدف',
      REF: 'خط الأساس',
      UNIT: 'الوحدة',
      TREEVIEW: 'عرض الشجرة',
      LISTVIEW: 'إطار العمل الخاص بنتائج المؤشرات',
      ONLY_CROSS: 'الشاملة لعدة قطاعات فقط',
      DISPLAY_LEVEL: 'عرض المستويات',
      DISPLAY: 'عرض'
    },
    ACTIVITY: {
      UPLOAD_INDEX: 'رابط برنامج المحاسبة',
      ENDING_ACTIVITY: 'انتهى هذا النشاط',
      NAME_ACT: 'وصف',
      LINK_PL: 'ارتباط الإطار المنطقي',
      SETTINGS: 'إعدادات النشاط',
      YTD: 'المجموع',
      UNIT_: 'الوحدة',

      ONLY_CROSS: 'تقاطع',
      ONLY_PLAN: 'مخطط',
      ONLY_FINANCIAL: 'الميزانية',
      COMPLETED_ACTIVITIES: 'مكتمل',
      ON_GOING_ACTIVITIES: 'جارٍ',
      NOT_CARRIED_OUT_ACTIVITIES: 'لم تبدأ',
      REVIEW_PLAN: 'مراجعة الخطة',

      BUDGET_BREAKDOWN: 'تقسيم الميزانية',
      TREEVIEW: 'عرض الشجرة',
      DISPLAY_LEVEL: 'عرض المستويات',
      ENDING: 'مكتمل',
      DISPLAY: 'عرض',
      ACTIVITIES: 'الأنشطة',
      ADD: 'إضافة نشاط',
      DBL_PERIOD: 'انقر مرتين لعرض الفترات',
      UPLOAD: {
        USE_BUDGET_BREAKDOWN: 'مع تقسيم الميزانية',
        ACTIVITY: 'النشاط',
        LOCALITY: 'الموقع',
        DATE: 'التاريخ',
        AMOUNT: 'المبلغ',
        CATEGORYBUDGET: 'فئة الميزانية',
        FINANCING: 'التمويل'
      },
      DISTRIBUTE: {
        CATEGORY: 'الفئة',
        FINANCE: 'مصدر التمويل',
        AMOUNT: 'المبلغ',
        TITLE: 'توزيع المبلغ',
        SUBTITLE: 'المبلغ المراد توزيعه',
        SELECT_CATEGORY: 'حدد الفئة',
        SELECT_FINANCE: 'حدد مصدر التمويل',
        ERROR_FINANCE: 'يجب أن يساوي المجموع المبلغ المراد توزيعه '
      },
      FROM: {
        FROM: 'من',
        DOCUMENT: 'مكتبة الوثائق',
        LOCAL: 'ملف درايف محلي',
        CLOUD: 'سحابة'
      },
      CROSS_ACTIVITY: 'نشاط شامل لعدة قطاعات',
      DISPLAY_CROSS_ACTIVITY: 'النشاطات الشاملة لعدة قطاعات فقط',
      OUTPUT: 'النواتج',
      RATE: 'المعدل',
      FUNDING_SOURCE: 'مصدر التمويل',
      PROJECT: 'المشروع',
      AGENCIES: 'الوكالات المنفّذة',
      RELATED_INDICATOR: 'المؤشرات',
      SEARCH: 'البحث في نشاط',
      URL: 'رابط خارجي',
      DOCUMENT: 'وثيقة ',
      DOCUMENTS: 'وثائق',
      ERROR_DOCUMENT: 'الرجاء ملء كل الحقول',
      NAME: 'إسم الملف',
      START: 'البداية',
      END: 'النهاية',
      UNIT: 'وحدة القياس',
      TYPE: 'الفئة',
      DISPLAY_UNIT_COST: 'عرض تكلفة الوحدة',
      UNIT_COST: 'تكلفة الوحدة',
      CAL_AUTO: 'حساب تلقائي',
      BUDGET: 'الميزانية',
      COST_A: 'التكلفة الفعلية',
      RESPO: 'الجهة المسؤولة',
      COMMENT: 'التعليقات',
      IMPUT: 'المدخلات',
      TOTAL: 'المجموع',
      CONT_LOC: 'متابعة العقد حسب الموقع',
      CONT_UNK: 'العقد فقط',
      NUM: 'الرقم',
      OWN: 'المالك',
      OBJECT: 'الموضوع',
      COST: 'التكلفة',
      SIGN: 'التوقيع',
      BEGIN_CONTRACT: 'البداية',
      END_CONTRACT: 'النهاية',
      BEGIN_D: 'تاريخ البدء',
      END_D: 'تاريخ الانتهاء',
      SELECT_ACT: 'حدد صاحب المصلحة',
      ACTOR_IMPL: 'أصحاب المصلحة',
      UPLOAD_FINANCE: 'استيراد ميزانية/التكلفة',
      UPLOAD_FINANCE_CATEGORY: 'استيراد تقسيم التكلفة/الميزانية',
      DEFINITION: 'التفاصيل',
      NOTE: 'ملاحظات',
      ROLE: 'الدور',
      TOTAL_ALERT: 'يجب أن يساوي المجموع 100',
      STEP: 'منصة',
      ERROR: {
        SOURCE: 'يجب أن تتراوح النسبة المئوية بين 0 و 100'
      },
      HELP: {
        CAL_AUTO: 'إذا تم اختيارهما، سيتم حساب كل من الميزانية والتكلفة الفعلية أو هما معا تلقائيا واستنادا على الكمية وتكلفة الوحدة'
      },
      MENU: {
        PLAN: 'نشاط الخطة ',
        EDIT: 'تعديل',
        REAL: 'تتبع النشاط',
        IMAGE: 'الصورة (300 × 400) ',
        PERMISSIONS: 'الأذونات',
        WORKFLOW: 'سير العمل'
      },
      WORKFLOW: {
        TITLE: 'سير العمل',
        LEVEL: 'المستوى',
        NAME: 'الإسم',
        COLOR: 'اللون',
        USER: 'المستخدم',
        REAL: 'تتبع النشاط',
        SELECT_USER: 'حدد المستخدم'
      },
      PLAN: {
        PLANNING: 'مخطط',
        TO_REVIEW: 'تراجع',
        ERROR_PERIOD_YEAR: 'لم يتم تحديد فترة خاصة بهذه السنة',
        CONFIRM_DELETE: 'هل تريد حذف هذه الخطة؟',
        TITLE: 'الخطة',
        REVIEW: 'منقّح',
        INIT: 'مبدئي',
        REVIEW_: 'تخطيط منقّح',
        INIT_: 'تخطيط أولي',
        GLOBAL: 'عالمي',
        DURATION: 'تخطيط',
        TYPE: 'النوع',
        YEAR: 'السنة',
        PERIOD: 'الفترة',
        PLANNED: 'مخطط له',
        LOCATION: 'الموقع',
        AMOUNT: 'الهدف',
        COST: 'الميزانية',
        BEGIN: 'البداية',
        END: 'النهاية',
        AMOUNT_PLANNED: 'مخطط له',
        COST_PLANNED: 'الميزانية',
        BEGIN_PLANNED: 'البداية المخطط لها',
        END_PLANNED: 'النهاية المخطط لها',
        ERROR_FINANCE: 'يجب أن يكون مجموع النسب المئوية مساوياً لـ 100٪ ',
        ERROR_SUP_QTE: 'يجب أن تكون الكمية أكبر من أو تساوي {{QTE_ERROR}} ',
        ERROR_INF_QTE: 'يجب أن تكون الكمية أقل من أو تساوي {{QTE_ERROR}} ',
        ERROR_SUP_BUDGET: 'يجب أن تكون الميزانية أكبر من أو تساوي {{BUDGET_ERROR}} ',
        ERROR_INF_BUDGET: 'يجب أن تكون الميزانية أقل من أو تساوي {{BUDGET_ERROR}}',
        ERROR_PLAN_ANNUEL: 'لم يتم تحديد التخطيط السنوي ',
        ERROR_DATE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء',
        ERROR_QTE: 'يجب أن تكون الكمية إيجابية ',
        ERROR_BUDGET: 'يجب أن تكون الميزانية إيجابية ',
        ERROR_COST: 'يجب أن تكون التكلفة إيجابية',
        ERROR_PERIOD: 'يجب أن تكون الفترة بين {{BEGIN}} و {{END}}',
        HELP: {
          AMOUNT: 'أدخل الهدف المخطط له',
          COST: 'أدخل الميزانية المخصصة '
        },
        SEARCH_LOCATION: 'ابحث عن موقع'
      },

      REAL: {
        ACTUAL: 'القيمة الفعلية',
        TITLE: 'تتبع',
        LOCATION: 'الموقع',
        AMOUNT: 'القيمة الفعلية',
        COST: 'التكلفة',
        BEGIN: 'تاريخ البدء',
        END: 'تاريخ الانتهاء',
        AMOUNT_ACTUAL: 'أدخل القيمة الفعلية',
        COST_ACTUAL: 'التكلفة',
        BEGIN_ACTUAL: 'بداية القيمة الفعلية',
        END_ACTUAL: 'نهاية القيمة الفعلية',
        COMMENTS: 'تعليقات',
        HELP: {
          AMOUNT: 'أدخل القيمة الفعلية',
          COST: 'أدخل التكلفة'
        }
      },
      DASHBOARD: {
        REFERENCE: 'خط الأساس',
        FINAL: 'هدف النهاية',
        PROJECT: 'المشروع',
        YEAR: 'السنة',
        HELP: {
          SHARE: 'مشاركة لوحة البيانات'
        },
        RATE_TARGET: '% من الهدف ',
        RATE_BUDGET: '% من الميزانية ',
        DISPLAY_COST: 'عرض التكلفة / الميزانية ',
        ALL_LOC: 'كل المواقع ',
        ANY_LOC: 'أي موقع',
        BY_LOC: 'حسب الموقع',
        TITLE: 'التقرير',
        RATE_PHY: '% من الهدف ',
        RATE_FINANC: '% من الميزانية ',
        TITLE2: 'اكتمال % من الموقع',
        TITLE2_: 'اكتمال % من المشروع',
        TITLE1_1: 'ملخص التقدم المحرز للمؤشر',
        TITLE1_2: 'التقدم المحرز للمؤشر لكل سنة',
        TITLE_GRAPHE_SECOND_PERIOD: '% من الأهداف والميزانيات المنجزة حسب موقع ',
        TITLE_GRAPHE_SECOND_YEAR: '% من الأهداف والميزانيات المنجزة حسب الموقع ـ سنة',
        TITLE_GRAPHE_SECOND_YEAR_PROJECT: '% من الأهداف والميزانيات المنجزة حسب المشروع ـ سنة',
        TITLE_GRAPHE_SECOND: 'تقرير النشاط',
        TITLE_GRAPHE_SECOND_PROJECT: '% من الأهداف والميزانيات المنجزة حسب المشروع',
        TITLE_GRAPHE_FIRST_YEAR: '% من الأهداف والميزانيات المنجزة (سنة ) في الموقع:',
        TITLE_GRAPHE_FIRST: '% من الأهداف والميزانيات المنجزة حسب الموقع',
        TITLE_GRAPHE_FIRST_PROJECT: '% من الأهداف والميزانيات المنجزة حسب المشروع'
      },
      AWPB: {
        TITLE: 'خطة العمل والميزانية السنوية (AWPB)',
        TITLE_YEAR: 'برنامج عمل وميزانية متعددة السنوات'
      }
    },
    CROSS_ACTIVITY: {
      CODE_ACTIVITY: 'الرمز',
      NAME_ACTIVITY: 'الإسم',
      DATE_BEGIN_ACTIVITY: 'البداية ',
      DATE_END_ACTIVITY: 'النهاية',
      UNIT_COST_ACTIVITY: 'تكلفة الوحدة',
      CAL_AUTO: 'حساب تلقائي',
      COMMENTS_ACTIVITY: 'التعليقات'
    },
    PLAN_BUDGET_ACTIVITY: {
      ACTUAL_RATE: '% من القيمة الفعلية',
      BUDGET_RATE: '% من الميزانية',
      STATUS_GRAPH: 'رسم بياني لحالة النشاط',
      ACTUALS: 'القيمة الفعلية',
      TARGETS: 'الهدف',
      ACTIVITY_PROGRESS: 'التقدم المحرز للنشاط'
    },
    GANTT: {
      TITLE: 'مخطط غانت للنشاط',
      LIMIT: 'حدود الترتيب الزمني',
      TYPE: 'التخطيط',
      START: 'البداية',
      END: 'النهاية',
      DISPLAY: 'عرض أشرطة المستوى العلوي',
      LEGEND: 'المفتاح',
      PLAN: 'مخطط له',
      ACTUAL: 'القيمة الفعلية',
      ASSIGN_TO: 'حدد',
      ACTOR: 'تم الإسناد إلى ',
      GEO: 'الجغرافيا',
      ZOOM: 'التكبير',
      REAL: 'القيمة الفعلية للنشاط',
      ACTIVITY: 'النشاط ',
      ACTUAL_BEGIN: 'البداية',
      ACTUAL_END: 'النهاية',
      PLAN_BEGIN: 'مخطط له',
      PLAN_END: 'النهاية',
      YEAR: 'السنة',
      MONTH: 'الشهر',
      WEEK: 'الأسبوع',
      TIMELINE: 'عرض الجدول الزمني',
      ON_GOING_ACTIVITY: 'الأنشطة الجارية',
      DELAYED_ACTIVITY: 'الأنشطة المتأخرة',
      NOT_STARTED_ACTIVITY: 'الأنشطة التي لم تبدأ',
      COMPLETED_ACTIVITY: 'الأنشطة المكتملة'
    },
    CONTRACT: {
      LOC: 'الموقع',
      N_MAR: 'رقم العقد',
      PROGRESS: 'التقدم المحرز',
      STEP: 'الخطوة'
    },
    PROCUREMENT: {
      CONFIRM_DELETE: 'هل تريد حذف هذه المشتريات؟',
      CODE: 'الرمز',
      LABEL: 'الإسم'
    },
    REPORT_CAT: {
      LABEL: 'الفئة',
      PERIOD: 'الدورية',
      DELAI: 'الموعد النهائي',
      RECIPIENT: 'المتلقي الرئيسي',
      FORMAT: 'قالب التقرير',
      RESPO: 'تم الإسناد إلى ',
      SELECT: 'استيراد القالب',
      CHANGE: 'تغيير',
      REMOVE: 'إزالة',
      CONFIRM_DELETE: 'هل تريد حذف هذه الفئة من التقارير؟',
      PERSON_ALERT: 'أصحاب المصلحة المراد تنبيههم',
      HELP: {
        DELAI: 'عدد الأيام',
        LABEL: 'أدخل فئة الوثيقة'
      }
    },
    DOCUMENT: {
      DELETE_FOLDER: 'احذف المجلد',
      DELETE_FILE: 'حذف الملف',
      SUCCESS_ADD_FOLDER: 'تمت إضافة المجلد',
      DOCUMENT_DATA: 'بيانات المستند',
      COVER: 'صفحة الغلاف',
      VIEW: 'عدد المشاهدات',
      DOWNLOAD: 'التحميلات',
      NEWFOLDER: 'إنشاء مجلد جديد',
      NOT_VALID_EMAIL_OR_URL: 'بريد إلكتروني أو عنوان غير صالح',
      CONTACT: 'الاتصال (البريد الإلكتروني أو الموقع الإلكتروني)',
      LANGUAGE: 'اللغة',
      ADD_INFO: 'حدد المجلد لرفع الوثيقة',
      RIGHT_CLICK_INFO: 'انقر على اليمين لإضافة عنصر أو تعديله',
      DBL_CLICK_INFO: 'انقر مرتين لفتح العنصر ',
      SEE_DETAILS: 'عرض التفاصيل',
      FILTERS: 'أدوات الفرز',
      PAGING_SUMMERY: '{{current}} من أصل {{total}} صفحات ({{count}} ملفات)',
      FORMAT: 'صيغة الوثيقة',
      FORMATS: {
        TEXT: 'نص',
        IMAGE: 'صورة',
        VIDEO: 'فيديو',
        AUDIO: 'صوت',
        PDF: 'ملف PDF'
      },
      FILTER: {
        PROPERTY: 'الحقل',
        OPERAND: 'الشرط',
        VALUE: 'القيمة'
      },
      ACTIONS: {
        ADD_FOLDER: 'مجلد جديد',
        EDIT_FOLDER: 'تعديل المجلد',
        REFRESH: 'تحديث',
        ADD_FROM_FILE: 'رفع وثيقة',
        ADD_FROM_LINK: 'الرفع من خلال رابط',
        ADD_EDIT_DOC: 'كتابة وثيقة'
      },
      FILE_NAME: 'الملف',
      REPORT: 'المحتوى',
      INSERT_DELTA: 'إدخال عنصر دلتا',
      CLS: 'مجلد الوثيقة',
      MAIN: 'وصف الوثيقة',
      ADD_FILE: 'مجلد جديد',
      ADD_FILE_: 'قضية',
      ADD_DOC: 'رفع وثيقة...',
      ADD_DOC_EXT: 'الرفع من خلال رابط',
      ADD_DOC_RED: 'كتابة وثيقة',
      CODE: 'الرمز',
      DESCRIP: 'الوصف',
      CONFIRM_DELETE_FOLDER: 'هل أنت متأكد من حذف هذا المجلد وجميع محتوياته؟',
      CONFIRM_DELETE_THEME: 'هل تريد حذف هذه السمة؟',
      CONFIRM_DELETE_TYPE: 'هل تريد حذف هذا النوع؟',
      CONFIRM_DELETE_PHYS: 'هل تريد حذف هذا الموقع؟',
      CONFIRM_DELETE_FILE: 'هل تريد حذف هذه الوثيقة؟',
      RECIPIENT: 'مشاركة الوثيقة',
      RECIPIENT_TOOLTIP: 'شارك الوثيقة مع',
      REFERENCE: 'المرجع',
      PUBLICATION: 'تاريخ النشر',
      PUBLIC: 'العرص على البوابة',
      PUBLIC_HELP: 'في حالة تحديد هذا الخيار، ستصبح الوثيقة سهلة الوصول عبر البوابة العامة',
      TITLE: 'العنوان',
      TITLE_HELP: 'أدخل عنوان الوثيقة',
      URL_HELP: 'أدخل رابط الـ URL الخاص بالوثيقة',
      AUTHOR: 'المؤلف (المؤلفون)',
      DOC_TYPE: 'الفئة',
      DOC_TYPES: 'الفئات',
      THEME: 'الموضوع',
      THEMES: 'المواضيع',
      PHYS_LOC: 'الموقع',
      SUMMARY: 'الموجز',
      SUMMARY_HELP: 'أدخل موجزا مختصرا للوثيقة هنا',
      LABEL: 'التسمية',
      USERS: 'المستخدمون',
      UPLOAD: 'رفع وثيقة',
      SELECT_DOC_TYPE: 'حدد فئة الوثيقة',
      SELECT_PHYS_LOC: 'حدد الموقع',
      SELECT_THEME: 'حدد الموضوع'
    },
    COMMUNITIES: {
      CHATS: 'الحديث',
      SUCCESS_ADD: 'تمت إضافة المجتمع',
      SUCCESS_UPDATE: 'المجتمع المعدّل',
      SUCCESS_DELETE: 'تم حذف المجتمع',
      IMAGE: 'الصورة',
      NAME: 'الإسم',
      DESCRIPTION: 'الوصف',
      MEMBERS: 'الأعضاء',
      EDIT_COMMUNITY: 'تعديل المجتمع',
      REQUIRED: 'مطلوب!',
      DOUBLE: 'تم إسناد هذا الإسم بالفعل!'
    },
    DOCUMENT_CALENDAR: {
      NEW_DOC: 'إضافة وثيقة جديدة',
      EXIST: 'الاختيار من مكتبة الوثائق',
      DOCUMENT: 'الوثيقة',
      SELECT_FOLDER: 'حدد المجلد الذي تريد تخزين الوثيقة فيه'
    },
    PHOTOLIBRARY: {
      PHOTOS: 'الصور',
      VIDEOS: 'أشرطة فيديو',
      REF: 'المرجع',
      TITLE: 'العنوان',
      DATE: 'التاريخ',
      PUBLIC: 'العرض على البوابة',
      LOCATION: 'الموقع',
      LAT: 'خط العرض (GPS)',
      LONG: 'خط الطول (GPS)',
      AUTHOR: 'المؤلف',
      SUMMARY: 'ملخص',
      URL_VIDEO: 'عنوان URL للفيديو (يوتوب)',
      ADD_DOC: 'إضافة صورة',
      ADD_DOC_: 'صورة',
      FOLDER: 'مجلد',
      ADD_FILE_: 'قضية',
      ADD_FILE: 'مجلد جديد',
      ADD_VIDEO: 'إضافة فيديو',
      ADD_VIDEO_: 'فيديو',
      CONFIRM_DELETE_PHOTO: 'هل تريد حذف هذه الصورة؟',
      CONFIRM_DELETE_VIDEO: 'هل تريد حذف هذا الفيديو؟',
      TITLE_ERROR_DELETE: 'المجلد ليس فارغًا'
    },
    G_U: {
      RIGHT_INFO: 'حدد ملف تعريف المستخدم وفقًا للمشاريع التي لديه حق الوصول إليها',
      PASSWORD_CHANGED: 'تم تغيير كلمة السر',
      DISPLAY_DASHBOARD: 'عرض لوحة القيادة',
      DISPLAY_MAP: 'عرض الخريطة',
      FUNCTION: 'عمل',
      STANDARD_USER: 'مستخدم عادي',
      ADMIN_USER: 'مدير',
      VIEW_USER: 'مُشاهد',
      COLLECT_USER: 'جمع البيانات',
      COLLECTER_USER: 'جمع البيانات',
      CUSTOM_USER: 'حقوق مخصصة',
      NO_ACCESS: 'لا يمكن الوصول',
      NO_ACCESS_PORTFOLIO: 'لا يمكن الوصول إلى المشاريع',
      PROJECT: 'المشروع',
      DETAIL: 'تفاصيل المستخدم',
      PROFILE: 'الملف الشخصي للمستخدم',
      EMAIL: 'البريد الإلكتروني',
      NAME: 'الإسم العائلي',
      SUP: 'مستخدم ذو امتيازات دخول شاملة',
      PRENOM: 'الإسم الشخصي',
      AGENCIES: 'الوكالة المنفّذة',
      MDP: 'كلمة المرور',
      CONF: 'تأكيد كلمة المرور',
      ACTOR: 'أصحاب المصلحة',
      STAFF: 'صاحب المصلحة',
      CHANGE_PASSWORD: 'تغيير كلمة المرور',
      NEW_PASSWORD: 'كلمة مرور جديدة',
      SEARCH: 'البحث عبر البريد الإلكتروني والإسم العائلي',
      SELECT_AGENCIES: 'حدد الوكالة المنفّذة',
      SELECT_STAFF: 'حدد صاحب المصلحة',
      EXPIRE_HELP: 'تاريخ انتهاء صلاحية كلمة المرور',
      EXPIRE: 'تاريخ انتهاء الصلاحية',
      ACTORLIST: 'تعيين أصحاب مصلحة',
      CONFIRM_DELETE: 'هل تريد حذف هذا المستخدم؟',
      ERROR_EMAIL: 'هذا المستخدم موجود بالفعل',
      ERROR_PSW: '8 أحرف كحد أدنى',
      ERROR_CONF_PSW: 'كلمات المرور غير متطابقة',
      VALIDATION_RIGHT: 'تأكيد صحة البيانات',
      VALIDATION_RIGHT_SET: 'تعيين أذونات التحقق من صحة البيانات ',
      VALIDATION_RIGHT_TITLE: 'حدد المستخدمين الذين يتم التحقق من صحة بياناتهم بواسطة {{users}} ',
      PORTFOLIO_USER: 'الوصول إلى الحافظة',
      EDIT_PROFIL: 'انقر لعرض التفاصيل',
      EDIT_PROFIL_USER: 'حساب المستخدم',
      ERROR_PSW_NOT_CONFORM: 'كلمة المرور غير متطابقة',
      ERROR_PSW_NOT_EXIST: 'غير مخول للتحديث',
      ERROR__NOT_ALLOW: 'غير مخول للتحديث',
      SEX: 'الجنس',
      MALE: 'ذكر ',
      FEMALE: 'أنثى',
      UNLOCK: 'فتح',
      LOCK: 'قفل',
      HELP: {
        EMAIL: 'البريد الإلكتروني مستخدم لتسجيل الدخول'
      },
      FEEDBACK: 'اقتراحاتك',
      FEEDBACK_ADD: 'شارك اقتراحاتك',
      FEEDBACK_DETAIL: "تفاصيل الاقتراح"

    },
    GR_U: {
      HELP: {
        TITLE: ' إنشاء مجموعة وإسناد الجغرافيا والإطار المنطقي لأعضاء المجموعة',
        CODE: ' أدخل اسم المجموعة',
        LABEL: ' أدخل وصف المجموعة'
      },
      CODE: 'الإسم',
      LABEL: 'الوصف',
      CONFIRM_DELETE: 'هل تريد حذف هذه المجموعة؟',
      USER: 'تعيين مستخدمين',
      LOCATION: 'المواقع',
      LOCATION_ONLY: 'الموقع فقط',
      LOCATION_ALL: 'مواقع فرعية',
      C_ONLY: 'فقط',
      C_ALL: 'تضمين العناصر الفرعية'
    },
    RIGHT: {
      HELP: {
        TITLE: 'تعيين أذونات المستخدم والامتيازات وفقا لملفات التعريف الخاصة بهم ',
        LABEL: 'أدخل اسم ملف التعريف ',
        DESCRIPTION: 'الوصف'
      },
      USER: 'المستخدمون',
      ADMIN: 'المسؤول',
      PRIVIL: 'الأذونات',
      LABEL: 'الملف الشخصي',
      DESCRIPTION: 'الوصف',
      CONFIRM_DELETE: 'هل تريد حذف هذا الملف الشخصي؟'
    },
    TYPACTIVITY: {
      LST_TYP: 'أنواع النشاط',
      ED_TYP: 'تفاصيل نوع المؤشر',
      COL_COLOR: 'اللون',
      COLOR_BLUE: 'أزرق',
      COLOR_CRYAN: 'أزرق سماوي',
      COLOR_GREEN: 'أخضر',
      COLOR_YELOW: 'أصفر',
      COLOR_ORANGE: 'برتقالي',
      COLOR_RED: 'أحمر',
      COLOR_PINK: 'وردي',
      COLOR_PURPLE: 'أرجواني',
      COLOR_GREY: 'رمادي',
      COLOR_BLACK: 'أسود',
      CONFIRM_DELETE: 'هل تريد إزالة هذا النوع من النشاط؟'
    },
    FORMULAR: {
      SURVEY_DELETE: "سحق النوبات",
      SURVEY_VERSION: "إصدار",
      IMPORT_SURVEY: "نماذج حلول المسح",
      IMPORT_INTERVIEW: "مقابلة (ق)",
      SURVEY_JETON: "تعديل الرمز المميز",
      IMPORTED_INTERVIEW: "المقابلة المستوردة (ق)",
      IMPORTE_DATA: "المستورد",
      SURVEY_JETON_LIB: "حل مسح جيتون",
      SURVEY_SET_JETON: "توليد رمز؟",
      SURVEY_USER: "إسم المستخدم",
      SURVEY_PASSE: "كلمة المرور",
      SURVEY_GENERER: "يولد",

      COLLECT_MODE_SURVEY: 'استيراد البيانات من حلول المسح',
      COLLECTORS: 'جامعي',
      LINK_GEO: 'رابط للخطة الجغرافية',
      ERRORVIDEVAL: 'الرجاء إدخال الخيار والقيمة',
      ERRORVIDE: 'الرجاء إدخال الخيار',
      ERROREXISTEVAL: 'الخيار أو القيمة موجودة بالفعل',
      ERROREXISTE: 'الخيار موجود بالفعل',

      ARCHIVATE: 'أرشيف',
      KOBO_API_EDIT: 'رمز واجهة برمجة تطبيقات Kobotools',
      ADD_FROM_KOBO: 'إضافة من Kobotool',
      KOBO_API: 'رمز واجهة برمجة التطبيقات',
      ERROR_KOBO_API: 'رمز API غير صحيح',
      ALREADY_SAVE: 'الحفظ دائمًا',
      HOW_TO_FIND_TOKEN: 'كيف يمكنني العثور على رمز API؟',
      REFRESH: 'تحديث البيانات',
      KOBO_FORM_LIST: 'قائمة المشاريع',

      FORM_SAVED: 'تم حفظ النموذج!',
      SUCCESS_IMPORT: 'تم استيراد البيانات!',
      FAIL_IMPORT: 'فشل الاستيراد',
      CHART_STRUCTURE: 'الإعدادات',
      CHART_STRUCTURE_TITLE: 'إعدادات المخطط',
      SURVEY: 'استبيان',
      ERROR: {
        NAME_REQUIRED: 'الإسم مطلوب',
        OPTION_REQUIRED: 'الخيار مطلوب',
        UNKNOWN_ERROR: 'حدث خطأ أثناء الاستيراد',
        KOBO_TOKEN_ERROR: 'الرمز المميز لواجهة برمجة التطبيقات غير صحيح'
      },
      SAVE: 'حفظ',
      SAVE_MSG: 'تم حفظ السجل',
      DOWNLOAD_QR_CODE: 'تحميل رمز الاستجابة السريعة (QR)',
      GENERATE_URL: 'إنشاء عنوان للإدخال الخارجي',
      COPIED_URL: 'تم النسخ',
      COPY_URL: 'نسخ',
      COLLECTION: 'النماذج',
      COLLECTIONS: 'النماذج',
      DISPLAY_IMAGE: 'عرض الصورة',
      ADD: 'إضافة نموذج',
      LAYOUT: 'الهيئة',
      STRUCTURE: 'الحقول',
      ADD_SECTION: 'قسم جديد',
      ADD_DATA: 'إضافة بيانات',
      COLOR: 'اللون',
      SEARCH: 'البحث عن نموذج',
      SEARCH_ANALYZE: 'البحث عن تحليل',
      TITLE: 'جمع البيانات ـ نماذج التصميم',
      SELECT_TEMPLATE: 'حدد نموذجا',
      CONDITION: 'الشرط',
      CONDITION_HELP: 'حدد الشرط الخاص بالحقل',
      PRESET: 'إعداد الخيارات مسبقا',
      HELP_OPTION: 'أدخل خيارا',
      TITLE_I: 'إسم النموذج',
      PRIVATE: 'خاص',
      SINGLUAR: 'عنصر واحد',
      MAIN: 'فريد',
      PLURAL: 'عناصر عديدة',
      MULTILINE: 'متعدد الخطوط',
      LIMIT: 'الحد الأقصى للأحرف',
      FORMAT: 'الصيغة',
      LIST_OPTION: 'خيارات القائمة',
      MULTI_CHOISE: 'السماح بخيارات متعددة',
      CUSTOM_FIELD: 'خيارات القائمة',
      CUSTOM_DATA: 'النماذج',
      REQUIRED: 'مطلوب',
      REQUIRED_NO_FIELD: 'الرجاء سحب حقل إلى هذه المنطقة',
      COLUMN: 'العمود',
      CONFIRM_STRUCTURE: 'تم حذف نموذج فرعي واحد على الأقل. \nهل تريد المواصلة؟',
      ERROR_GLOBAL: 'لم يتم تعبئة بعض الحقول المطلوبة ',
      USERS: 'المستخدمون',
      IMPORT_DATA: 'استيراد البيانات',
      COLLECT_DATA: 'جمع ومراجعة البيانات',
      PIN: 'الواسم',
      CHOISE_COLLECT_MODE: 'حدد وضع تجميع البيانات',
      COLLECT_MODE_INTERN: 'عرض شاشة تجميع البيانات',
      COLLECT_MODE_MOBILE: 'تجميع البيانات عبر جهاز محمول',
      COLLECT_MODE_LINK: 'أرسل رابط للإدخال الخارجي للبيانات',
      COLLECT_MODE_EXCEL: 'استيراد البيانات من ملف Excel',
      FIELD: {
        EXCEL: 'Excel',
        PPTX: 'PowerPoint',
        TEXT: 'النص',
        LIST: 'القائمة',
        DATE: 'التاريخ',
        NUMBER: 'الرقم',
        CHOISE: 'الخيار',
        FILE: 'الملف',
        PICTURE: 'الصورة',
        VIDEO: 'الفيديو',
        AUDIO: 'الصوت',
        LOCATION: 'إحداثيات الموقع',
        FORMULAR: 'نموذج فرعي',
        PATH: 'المسار',
        SCORE: 'النتيجة',
        OPTION: 'الخيار',
        VALUE: 'القيمة ',
        POLYGON: 'مضلع'
      },
      MENU: {
        STRUCTURE: 'الهيكل',
        RECORD: 'السجل (السجلات)',
        RIGHT: 'الأذونات',
        EDIT: 'تعديل النموذج',
        COLLECT: 'جمع البيانات',
        REVIEW: 'مراجعة البيانات',
        ANALYZE: 'التحليل وإعداد التقارير'
      },
      CONFIRM_DELETE: 'هل تريد حذف هذا القالب؟',
      CONFIRM_RECORD_DELETE: 'هل تريد حذف هذا العنصر؟',
      OWN: 'قالبي (قوالبي)',
      ALL: 'كل القوالب',
      REQUEST: 'التحليلات',
      REQUEST_TITLE: 'العنوان',
      TEMPLATE: 'النموذج',
      ANALYSE: {
        SAVE: 'حفظ',
        TABS: {
          TABLE_PIVOT: 'جدول محوري',
          TABLE_SIMPLE: 'جدول',
          GRAPH: 'رسم بياني',
          TABLE: 'جدول',
          MAP: 'خريطة',
          PIE_CHART: 'مخطط دائري ',
          MIXED_CHART: 'مخططات مختلطة'
        },
        TABLE: {
          COLUMNS: 'الأعمدة'
        },
        MAP: {
          CATEGORY: 'الفئة',
          VALUE: 'القيمة',
          FIELD: 'الحقل',
          LEGEND: 'المفتاح'
        },
        CHART: {
          FIELD: 'الحقل',
          CATEGORY: 'الفئة',
          OPERATION: 'التصنيف',
          NONE: 'لا شيء',
          COUNT: 'العد',
          VALUE: 'القيمة',
          SUM: 'المجموع',
          AVG: 'المتوسط',
          MAX: 'الحد الأقصى',
          MIN: 'الحد الأدنى',
          VARIANCE: 'الفرق',
          TYPE: 'النوع'
        },
        TYPE_CHART: {
          AREACHART: 'مخطط مساحيّ',
          BARCHART: 'مخطط بالأعمدة',
          COLUMNCHART: 'مخطط عمودي',
          LINECHART: 'مخطط خطي',
          PIECHART: 'مخطط دائري'
        },
        LAYERS: {
          TITLE: 'الطبقات',
          NAME: 'الإسم',
          FIELD: 'الحقل',
          COLOR: 'اللون'
        }
      }
    },

    ANALYSE: {
      BACKGROUND_COLOR_TITLE: 'لون خلفية العنوان',
      COLOR_TITLE: 'لون العنوان',
      SUCCESS_ADD: 'تمت إضافة التقرير',
      FIX_VALUE: 'إصلاح القيمة',
      VARIABLE_VALUE: 'قيمة المتغير',
      VARIABLE_NAME: 'إسم المتغير',
      VARIABLES: 'المتغيرات',
      AS_PORTFOLIO: 'الحافظة',
      AS_PORTFOLIO_: 'حصة مع محفظة',
      COLLECTED_DATA: 'البيانات المجمعة',
      SERIES: 'سلسلة',
      SEARCH: 'البحث في تقرير',
      SWITCH: 'قم بتعيين المتغيرات في العمود ',
      TYPE_REP: 'تمثيل النوع',
      TYPE_1: 'الجدول',
      TYPE_2: 'الرسم البياني',
      TYPE_3: 'البيانات',
      DIM: 'بناء على',
      DIM1: 'الصفوف',
      DIM2: 'عرض البيانات حسب',
      COLUMNS: 'الأعمدة',
      FIELD: 'الحقل',
      INDICATOR: 'المؤشر',
      DESCRIPTION: 'الوصف',
      SETTINGS: 'الإعدادات',
      FILTERS: 'أدوات الفرز',
      SELECT_ALL: 'اختيار الكل',
      UNSELECT_ALL: 'إلغاء اختيار الكل',
      FIELDS: {
        DATE: {
          BEGIN_PLAN: 'البداية المخطط لها',
          END_PLAN: 'النهاية المخطط لها',
          BEGIN_ACTUAL: 'بداية القيمة الفعلية',
          END_ACTUAL: 'نهاية القيمة الفعلية',
          T: 'الاكتمال'
        },
        PHYSIC: {
          P: 'الهدف',
          R: 'القيمة الفعلية ',
          E: 'المتغير',
          T: '% من الأهداف'
        },
        PHYSIC_INDIC: {
          P: 'قيمة الهدف',
          R: 'القيمة الفعلية'
        },
        BUDGET: {
          P: 'الميزانية',
          R: 'التكلفة الفعلية',
          E: 'التباين في الميزانية',
          T: '% من الميزانية'
        }
      },
      MAIN: 'المعلمات',
      PUBLIC: 'عامة',
      AS_DASHBORD: 'الحفظ في لوحة البيانات',
      OPERATION: 'العملية',
      CONDITION: 'أدوات الفرز',
      PG: 'المواقع',
      TITLE: 'عنوان التقرير',
      DESCRIPTION_REPORT: 'وصف التقرير',
      PERIODE: 'الفترة',
      FINANCING_SOURCE: 'مصدر التمويل',
      CAT_BUDGET: 'فئة الميزانية',
      RATE: 'المعدل',
      RUBRIQUE: 'الحقل',
      SUBRUBRIQUE: 'حقل فرعي',
      VALEUR: 'القيمة',
      PARAMETER: 'المعيار',
      DEFAULT_VALUE: 'القيمة الافتراضية',
      ERROR_SYNTAXE: 'خطأ في الصياغة',
      ERROR_COND_EXIST: 'بعض الشروط غير موجودة',
      ERROR_COND_NOT_EXIST: 'لم يتم استخدام جميع الشروط ',
      LIST_COND: 'إضافة وسيلة فرز',
      RUBOP: {
        EGAL: 'يساوي',
        DIFF: 'لا يساوي',
        SUPP: 'أكبر من',
        SUPP_EGAL: 'أكبر من أو يساوي',
        INF: 'أقل من',
        INF_EGAL: 'أقل من أو يساوي',
        BEGIN: 'إبدأ بـ',
        NOT_BEGIN: 'لا تبدأ بـ',
        END: 'ينتهي بـ ',
        NOT_END: 'لا ينتهي بـ',
        CONTENT: 'يحتوى على',
        NOT_CONTENT: 'لا يحتوى على',
        NULL: 'إنه null',
        NOT_NULL: 'ليس null'
      },
      RUB: {
        ACTEUR: 'الجهة المسؤولة ',
        LOCAL: 'رمز الموقع',
        CAD_LOG: '\'{{planlog}}\' الرمز',
        YEAR: 'السنة',
        FINANCING_SOURCE: 'رمز مصدر التمويل',
        CAT_BUDGET: 'فئة الميزانية'
      },
      OPS: {
        MIN: 'الحد الأدنى',
        MAX: 'الحد الأقصى',
        AVG: 'المتوسط',
        SUM: 'المجموع',
        NONE: 'لا شيء',
        COUNT: 'العد'
      },
      ENCH_COND: 'الفارزون التسلسليون و / أو',
      CONFIRM_DELETE: 'هل تريد حذف هذا العرض؟',
      TYPE: {
        TABLE: 'الجدول',
        DONUT: 'مخطط دائري',
        COU_HISTO: 'مخطط موحد',
        WIDGET: 'الأداة'
      },
      SERIE: 'السلسلة',
      FILL: 'تعبئة',
      TYPE_COU_HISTO: {
        LINE: 'مخطط خطي',
        BAR: 'مخطط بالأعمدة'
      }
    },
    CUSTOM_DASHBOARD: {
      DISPLAY_MAIN_PAGE: 'عرض على الصفحة الرئيسية',
      SEARCH: 'البحث في لوحات البيانات',
      ACTIVITIES: 'الأنشطة',
      INDICATORS: 'المؤشرات',
      FORMS: 'النماذج',
      GLOBAL_TITLE: 'لوحات البيانات',
      TITLE: 'العنوان',
      VIEWS: 'البحث في التحليلات',
      VIEWS_OPTION: 'قائمة الاستفسارات',
      ANALYSE: 'التحليل',
      HEIGHT: 'الارتفاع',
      WIDTH: 'العرض',
      CONFIRM_DELETE: 'هل تريد حذف لوحة البيانات هذه؟',
      DISPLAY: 'عرض كل لوحات البيانات',
      OWN: 'لوحات البيانات الخاصة بي',
      FAVORITE: 'لوحات البيانات المفضلة',
      OTHER: 'أخرى'
    },
    AGENDA: {
      LIST: 'قائمة',
      MONTH: 'شهر',
      WEEK: 'أسبوع',
      DAY: 'يوم',
      TODAY: 'اليوم',
      TITLE: 'العنوان',
      DESCRIPTION: 'الوصف',
      BEGIN: 'البداية',
      END: 'النهاية',
      INFO: 'المهمة / الحدث',
      USER: 'شارك مع'
    },
    COMMENT: {
      NEW_COMMENTS: 'مشاركات جديدة',
      SUCCESS_ADD: 'تمت إضافة التعليق',
      COMMENT: 'التعليقات',
      SHARE: 'شارك التعليقات',
      USER: 'المستخدمون',
      WRITE: 'كتابة تعليق',
      FILE: 'غرفة دردشة الفريق',
      TO_COMMENT: 'التعليق',
      CANCEL_TO_COMMENT: 'إلغاء',
      DISPLAY_COMMENT: 'عرض التعليقات',
      HIDE_COMMENT: 'إخفاء التعليقات',
      DISPLAY_MORE_COMMENT: 'عرض المزيد من التعليقات'
    },
    WORKFLOW: {
      MENU: {
        ACTIVITIES: 'الأنشطة',
        INDICATOR: 'المؤشر',
        FORM: 'النموذج',
        DOCUMENTS: 'الوثائق'
      },
      ACTIVITIES: {
        TRACK: 'تتبع'
      },
      VALIDATE: 'التحقق من صحة البيانات',
      COMMENTS: 'التعليقات'
    },
    DELETEDITEMS: {
      OBJECT: 'العنصر',
      PROJECT: 'المشروع',
      DATE: 'التاريخ',
      REFRESH: 'الاستعادة'
    },
    AUDITS: {
      USERS: 'المستخدمون',
      FILTER: 'الفرز',
      ACTIONS: 'الإجراءات',
      DELETE: 'حذف',
      UPDATE: 'تحديث',
      CREATE: 'إنشاء',
      TO_DATE: 'إلى',
      FROM_DATE: 'من',
      PAGING_SUMMERY: '{{current}} من أصل {{total}} صفحات ({{count}} عناصر)',
      ACTION: 'الإجراء',
      ENTITY: 'الجهة',
      DESCRIPTION: 'الوصف',
      USER: 'المستخدم',
      DATE: 'التاريخ',
      ENTITIES: {
        TYPE_MISSION: 'نوع المهمة',
        MISSION: 'مهمة',
        DISBURSEMENT: 'الإنفاق',
        AGREEMENT: 'التمويل',
        AGENCIES: 'الوكالة',
        PROJET: 'المشروع',
        UNITES: 'الوحدة',
        ACTEUR: 'صاحب المصلحة',
        FINANCEMENT: 'مصدر التمويل',
        NIVEAUGEO: 'المستوى (جغرافيا)',
        CHAMPSPERSO: 'حقل مخصص',
        LOCALITE: 'الموقع',
        NIVEAUCL: 'المستوى (منطقيا)',
        CADRELOGIQUE: 'الإطار المنطقي',
        TYPEACTIVITY: 'فئة النشاط',
        DERNIERNIVEAU: 'النشاط',
        TYPEINDICATEUR: 'فئات مؤشرات الأداء',
        INDICATEURSCATEGORIE: 'فئة التصنيف',
        INDICATEURSCATEGORIEOPTION: 'خيار (فئة التصنيف)',
        INDICATEURS: 'المؤشر',
        ANALYSE: 'تقرير مخصص',
        DASHBOARD: 'لوحة البيانات',
        TYPERAPPORT: 'فئة الوثيقة',
        CALENDARRAPPORT: 'التقرير',
        CLASSEUR: 'المجلد',
        RAPPORTS: 'الوثيقة',
        CLASSEURPHOTOTHEQUE: 'المجلد (مكتبة الوسائط)',
        PHOTOTHEQUE: 'مكتبة الوسائط',
        FORMULAR: 'النموذج',
        RECORDS: 'السجل (نموذج)',
        FORMLARREQUEST: 'الاستفسار (نموذج)',
        USER: 'المستخدم',
        GROUPUSER: 'مجموعة المستخدمين',
        PROFILUSER: 'الملف الشخصي للمستخدمين',
        CATEGORYBUDGET: 'فئة الميزانية',
        DISTRIBUTEAMOUNT: 'التهوية',
        GLOBAL_PLAN: 'تخطيط (عالمي)',
        ANNUAL_PLAN: 'تخطيط (سنوي)',
        PERIODIC_PLAN: 'تخطيط (بالفترات)',
        REALIZE: 'تتبع النشاط'
      }
    },
    SECURITY: {
      ATTEMPTS: 'محاولات فاشلة متتالية',
      TIMEOUT: 'مهلة'
    },
    MISSIONS: {
      TITLE_MISSION: 'مهمة',
      SELECT_TYPE: 'حدد نوع المهمة',
      LIEU_MISSION: 'موقع',
      OBJECT_MISSION: 'بالموضوع',
      MEMBERS: 'أعضاء',
      SELECT_MEMBERS: 'حدد أعضاء البعثة',
      MISSION: 'مهمة',
      TYPE: 'اكتب',
      DATE_BEGIN: 'تاريخ البداية',
      DATE_END: 'تاريخ الانتهاء',
      RESPONSIBLE: 'مسؤول',
      REPORT: 'نقل',
      NO_REPORT: 'لا توجد وثيقة مرتبطة'
    },
    REQUEST: {
      DATE_COURRIER: 'تاريخ الطلب',
      COMMENTAIRE: 'تعليقات',
      DOCS_SUPPORT: 'مذكرة مساعدة',
      STATUT: 'حالة الطلب',
      DATE_STATUT: 'تاريخ الحالة',
      DATE_ECHEANCE: 'الموعد النهائي',
      BUDGET: 'الميزانية',
      MONTANTDMD: 'المبلغ المطلوب (FCFA)',
      SITUATION_REQUETE: 'حالة الطلب',
      DATE: 'تاريخ',
      RESPONSABLE: 'المسؤول',
      ECHEANCE: 'الموعد النهائي',
      PJ: 'مرفق',
      OBJET: 'موضوع الطلب',
      // MONTANTDMD: 'المبلغ المطلوب',
      PERSCONTACTS: 'الأشخاص المطلعين',
      ADDRQTE: 'إضافة طلب',
      ADDSUIVIRQTE: 'إضافة متابعة للطلب',
      HISTORQ: 'سجل المتابعة'
    },
    RISK: {
      CRITICALITY: {
        TITLE: 'الحرج',
        HIGH: 'متوسط',
        MEDIUM: 'واسطة',
        WEAK: 'ضعيف'
      },
      DELAY: 'المدة (أيام)',
      TYPE: 'اكتب',
      RISK: 'مخاطرة',
      PROBLEM: 'مشكلة',
      NATURE_RISK: 'طبيعة سجية',
      CURRENT_POSITION: 'حفظ الموقف الحالي',
      CURRENT_POSITION_MARK: 'نقل ماركر',
      CURRENT_POSITION_MARK_: 'على الخريطة لاسترداد الموقف',
      DESCRIPTION: 'وصف',
      TITLE_RISK: 'مخاطرة/مشكلة',
      IMPACT: 'التأخير',
      RESOLVE_DATE_RISK: 'تاريخ القرار',
      FINANCE: 'الأثر',
      DELAY_: 'تأثير',

      IMPACT_FINANCE: 'الأثر المالي',
      IMPACT_DELAY: 'تأثير التأخير',
      PROBABILITY: 'احتمالا',
      DATE: 'بتاريخ',
      STATUS: 'حالة',
      RECOMMANDATIONS: 'التوصيات',
      IMPACTS: {
        HIGH: 'متوسط',
        MEDIUM: 'واسطة',
        LOW: 'قليل'
      },
      STATUS_LIST: {
        UNRESOLVED: 'لم تحل',
        IN_PROGRESS: 'في تقدم',
        RESOLVED: 'تم الحل'

      }
    },
    PROBLEM: {
      PROBLEM: 'المشاكل المصادفة',
      DATE_PROBLEM: 'تاريخ',
      NATURE_PROBLEM: 'طبيعة سجية',
      DESCRIPTION: 'وصف',
      IMPACT_DELAY: 'تأثير التأخير',
      IMPACT_FINANCE: 'الأثر المالي',
      RESPONSIBLE: 'تم عمل تقرير بواسطة',
      RECOMMANDATIONS: 'التوصيات'
    },
    RECOMMENDATION: {
      DESCRIPTION: 'توصية',
      RESPONSIBLE: 'مسؤول',
      DEADLINE: 'الموعد النهائي',
      STATUS: 'حالة',
      STATUS_LIST: {
        EXECUTED: 'حقق',
        NOT_EXECUTED: 'لم ينفذ',
        IN_PROGRESS: 'الجارn'
      }

    },
    EXPORT_DATA: {
      TYPES: 'أنواع البيانات',
      EXPORT: 'تصدير البيانات',
      LOADED: 'بيانات (بيانات) تم إنشاؤها',
      SHARE: 'شارك مع...'
    },
    FEEDBACK: {
      EMPTY_FEEDBACK: 'لا توجد اقتراحات',
      NOTE_LEVEL: 'ما هو مستوى رضاك؟',
      LAST_UPDATE_DATE: 'آخر تحديث في {{date}}',
      SEARCH_FEEDBACK: 'البحث عن موضوع أو رسالة',
      SEND: 'إرسال',
      BACK: 'رجوع',
      STATUS_SEND: 'حالة الإرسال',
      FORM: {
        OBJECT_PLACEHOLDER: 'الموضوع',
        MESSAGE_PLACEHOLDER: 'الرسالة',
        ERROR_SEND_FEEDBACK: 'خطأ في الإرسال، يرجى المحاولة مرة أخرى',
        SUCCESS_SEND_FEEDBACK: 'تم إرسال اقتراحك بنجاح'
      }
    }
  });

  // Appeler la fonction pour récupérer les traductions
  var CODE_LANG = (cov_1kdahmr8mv.s[2]++, 'ar');
  var CODE_LANG_STORE = (cov_1kdahmr8mv.s[3]++, 'TRANSLATE_' + CODE_LANG);
  var CHECK_TRANSLATE = (cov_1kdahmr8mv.s[4]++, 'DELTA_TRANSLATE');

  var translated = (cov_1kdahmr8mv.s[5]++, false);
  // Translate not active use data origine
  cov_1kdahmr8mv.s[6]++;
  if ((cov_1kdahmr8mv.b[1][0]++, localStorage.getItem(CHECK_TRANSLATE)) && (cov_1kdahmr8mv.b[1][1]++, localStorage.getItem(CHECK_TRANSLATE) == 0)) {
    cov_1kdahmr8mv.b[0][0]++;
    cov_1kdahmr8mv.s[7]++;

    translated = true;
    cov_1kdahmr8mv.s[8]++;
    console.log('TRANSLATE ORIGNINALE ' + CODE_LANG);
    cov_1kdahmr8mv.s[9]++;
    fetchTranslations(data, false);
  } else {
    cov_1kdahmr8mv.b[0][1]++;
  }

  cov_1kdahmr8mv.s[10]++;
  if (!translated) {
    cov_1kdahmr8mv.b[2][0]++;
    cov_1kdahmr8mv.s[11]++;

    // Translate activated
    if ((cov_1kdahmr8mv.b[4][0]++, !localStorage.getItem(CODE_LANG_STORE)) || (cov_1kdahmr8mv.b[4][1]++, angular.isUndefined(localStorage.getItem(CODE_LANG_STORE))) || (cov_1kdahmr8mv.b[4][2]++, localStorage.getItem(CODE_LANG_STORE) == '')) {
      cov_1kdahmr8mv.b[3][0]++;


      var params = (cov_1kdahmr8mv.s[12]++, {
        action: "getTranslateDataByLang",
        li_bksb: "Connexion",
        lang_bksb: CODE_LANG,
        method: 1,
        t_bksb: 'bk' + new Date().getTime() + 'sb',
        n: window.location.search.substr(1),
        space: (cov_1kdahmr8mv.b[5][0]++, localStorage.getItem('DELTA_TOKEN')) || (cov_1kdahmr8mv.b[5][1]++, null),
        data: JSON.stringify(data)
      });
      var URL = (cov_1kdahmr8mv.s[13]++, "/app/data/web_server.php");
      // Convertir l'objet params en une chaîne JSON
      var CONTENT = (cov_1kdahmr8mv.s[14]++, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      });

      // Effectuer la requête POST
      cov_1kdahmr8mv.s[15]++;
      fetch(URL, CONTENT).then(function (response) {
        cov_1kdahmr8mv.f[1]++;
        cov_1kdahmr8mv.s[16]++;

        // console.log('response 1');
        // console.log(response);
        if (response.ok) {
          cov_1kdahmr8mv.b[6][0]++;
          cov_1kdahmr8mv.s[17]++;

          return response.json();
        } else {
          cov_1kdahmr8mv.b[6][1]++;
        }
        cov_1kdahmr8mv.s[18]++;
        console.log('Erreur HTTP : ' + response.status);
        cov_1kdahmr8mv.s[19]++;
        return { error_state: true };
      }).then(function (dataReponse) {
        cov_1kdahmr8mv.f[2]++;

        var TAILLE_ORIGINE = (cov_1kdahmr8mv.s[20]++, Object.keys(data).length);
        var TAILLE_NEW = (cov_1kdahmr8mv.s[21]++, Object.keys(dataReponse).length);
        cov_1kdahmr8mv.s[22]++;
        if ((cov_1kdahmr8mv.b[8][0]++, dataReponse) && (cov_1kdahmr8mv.b[8][1]++, dataReponse.COUNTRIES) && (cov_1kdahmr8mv.b[8][2]++, angular.isUndefined(dataReponse.error_state)) && (cov_1kdahmr8mv.b[8][3]++, TAILLE_NEW >= TAILLE_ORIGINE - 10)) {
          cov_1kdahmr8mv.b[7][0]++;
          cov_1kdahmr8mv.s[23]++;

          console.log('(200) - TRANSLATE OK  ' + CODE_LANG + ': (' + TAILLE_NEW + ' - vs - ' + TAILLE_ORIGINE + ')');
          cov_1kdahmr8mv.s[24]++;
          fetchTranslations(dataReponse, true);
        } else {
          cov_1kdahmr8mv.b[7][1]++;
          cov_1kdahmr8mv.s[25]++;

          console.log('(200 & ERROR)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
          cov_1kdahmr8mv.s[26]++;
          if ((cov_1kdahmr8mv.b[10][0]++, dataReponse.error_state) && (cov_1kdahmr8mv.b[10][1]++, dataReponse.edit_language) && (cov_1kdahmr8mv.b[10][2]++, dataReponse.edit_language == 0)) {
            cov_1kdahmr8mv.b[9][0]++;
            cov_1kdahmr8mv.s[27]++;

            console.log('edit_language: ' + dataReponse.edit_language);
            cov_1kdahmr8mv.s[28]++;
            localStorage.setItem(CHECK_TRANSLATE, dataReponse.edit_language);
          } else {
            cov_1kdahmr8mv.b[9][1]++;
          }
          cov_1kdahmr8mv.s[29]++;
          fetchTranslations(data, false);
        }
      }).catch(function (error) {
        cov_1kdahmr8mv.f[3]++;
        cov_1kdahmr8mv.s[30]++;

        console.log('(500)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
        cov_1kdahmr8mv.s[31]++;
        fetchTranslations(data, false);
      });
    } else {
      cov_1kdahmr8mv.b[3][1]++;
      cov_1kdahmr8mv.s[32]++;

      console.log('TRANSLATE LOCALSTORAGE ' + CODE_LANG);
      cov_1kdahmr8mv.s[33]++;
      fetchTranslations(JSON.parse(localStorage.getItem(CODE_LANG_STORE)), false);
    }
  } else {
    cov_1kdahmr8mv.b[2][1]++;
  }

  function fetchTranslations(dataLang, save) {
    cov_1kdahmr8mv.f[4]++;
    cov_1kdahmr8mv.s[34]++;

    $translateProvider.translations(CODE_LANG, dataLang);
    cov_1kdahmr8mv.s[35]++;
    if (save) {
      cov_1kdahmr8mv.b[11][0]++;
      cov_1kdahmr8mv.s[36]++;

      if (localStorage.getItem(CHECK_TRANSLATE)) {
        cov_1kdahmr8mv.b[12][0]++;
        cov_1kdahmr8mv.s[37]++;

        localStorage.removeItem(CHECK_TRANSLATE);
      } else {
        cov_1kdahmr8mv.b[12][1]++;
      }
      cov_1kdahmr8mv.s[38]++;
      if (localStorage.getItem(CODE_LANG_STORE)) {
        cov_1kdahmr8mv.b[13][0]++;
        cov_1kdahmr8mv.s[39]++;

        localStorage.removeItem(CODE_LANG_STORE);
      } else {
        cov_1kdahmr8mv.b[13][1]++;
      }
      cov_1kdahmr8mv.s[40]++;
      localStorage.setItem(CODE_LANG_STORE, JSON.stringify(dataLang));
    } else {
      cov_1kdahmr8mv.b[11][1]++;
    }
  };
});