'use strict';

var cov_22vidcdndn = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_en.js',
      hash = 'b18ab2d84f15357e24d4eb2aab730361ed272690',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_en.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3012,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2922,
          column: 3
        }
      },
      '2': {
        start: {
          line: 2926,
          column: 20
        },
        end: {
          line: 2926,
          column: 24
        }
      },
      '3': {
        start: {
          line: 2927,
          column: 26
        },
        end: {
          line: 2927,
          column: 50
        }
      },
      '4': {
        start: {
          line: 2928,
          column: 26
        },
        end: {
          line: 2928,
          column: 43
        }
      },
      '5': {
        start: {
          line: 2930,
          column: 19
        },
        end: {
          line: 2930,
          column: 24
        }
      },
      '6': {
        start: {
          line: 2932,
          column: 2
        },
        end: {
          line: 2936,
          column: 3
        }
      },
      '7': {
        start: {
          line: 2933,
          column: 4
        },
        end: {
          line: 2933,
          column: 22
        }
      },
      '8': {
        start: {
          line: 2934,
          column: 4
        },
        end: {
          line: 2934,
          column: 52
        }
      },
      '9': {
        start: {
          line: 2935,
          column: 4
        },
        end: {
          line: 2935,
          column: 35
        }
      },
      '10': {
        start: {
          line: 2939,
          column: 2
        },
        end: {
          line: 2998,
          column: 3
        }
      },
      '11': {
        start: {
          line: 2941,
          column: 4
        },
        end: {
          line: 2997,
          column: 5
        }
      },
      '12': {
        start: {
          line: 2943,
          column: 21
        },
        end: {
          line: 2952,
          column: 7
        }
      },
      '13': {
        start: {
          line: 2953,
          column: 18
        },
        end: {
          line: 2953,
          column: 44
        }
      },
      '14': {
        start: {
          line: 2955,
          column: 22
        },
        end: {
          line: 2961,
          column: 7
        }
      },
      '15': {
        start: {
          line: 2964,
          column: 6
        },
        end: {
          line: 2992,
          column: 9
        }
      },
      '16': {
        start: {
          line: 2968,
          column: 8
        },
        end: {
          line: 2970,
          column: 9
        }
      },
      '17': {
        start: {
          line: 2969,
          column: 10
        },
        end: {
          line: 2969,
          column: 33
        }
      },
      '18': {
        start: {
          line: 2971,
          column: 8
        },
        end: {
          line: 2971,
          column: 56
        }
      },
      '19': {
        start: {
          line: 2972,
          column: 8
        },
        end: {
          line: 2972,
          column: 34
        }
      },
      '20': {
        start: {
          line: 2975,
          column: 31
        },
        end: {
          line: 2975,
          column: 55
        }
      },
      '21': {
        start: {
          line: 2976,
          column: 27
        },
        end: {
          line: 2976,
          column: 58
        }
      },
      '22': {
        start: {
          line: 2977,
          column: 8
        },
        end: {
          line: 2987,
          column: 9
        }
      },
      '23': {
        start: {
          line: 2978,
          column: 10
        },
        end: {
          line: 2978,
          column: 109
        }
      },
      '24': {
        start: {
          line: 2979,
          column: 10
        },
        end: {
          line: 2979,
          column: 47
        }
      },
      '25': {
        start: {
          line: 2981,
          column: 10
        },
        end: {
          line: 2981,
          column: 77
        }
      },
      '26': {
        start: {
          line: 2982,
          column: 10
        },
        end: {
          line: 2985,
          column: 11
        }
      },
      '27': {
        start: {
          line: 2983,
          column: 12
        },
        end: {
          line: 2983,
          column: 71
        }
      },
      '28': {
        start: {
          line: 2984,
          column: 12
        },
        end: {
          line: 2984,
          column: 77
        }
      },
      '29': {
        start: {
          line: 2986,
          column: 10
        },
        end: {
          line: 2986,
          column: 41
        }
      },
      '30': {
        start: {
          line: 2990,
          column: 8
        },
        end: {
          line: 2990,
          column: 66
        }
      },
      '31': {
        start: {
          line: 2991,
          column: 8
        },
        end: {
          line: 2991,
          column: 39
        }
      },
      '32': {
        start: {
          line: 2995,
          column: 6
        },
        end: {
          line: 2995,
          column: 56
        }
      },
      '33': {
        start: {
          line: 2996,
          column: 6
        },
        end: {
          line: 2996,
          column: 82
        }
      },
      '34': {
        start: {
          line: 3001,
          column: 4
        },
        end: {
          line: 3001,
          column: 57
        }
      },
      '35': {
        start: {
          line: 3002,
          column: 4
        },
        end: {
          line: 3010,
          column: 5
        }
      },
      '36': {
        start: {
          line: 3003,
          column: 6
        },
        end: {
          line: 3005,
          column: 7
        }
      },
      '37': {
        start: {
          line: 3004,
          column: 8
        },
        end: {
          line: 3004,
          column: 49
        }
      },
      '38': {
        start: {
          line: 3006,
          column: 6
        },
        end: {
          line: 3008,
          column: 7
        }
      },
      '39': {
        start: {
          line: 3007,
          column: 8
        },
        end: {
          line: 3007,
          column: 49
        }
      },
      '40': {
        start: {
          line: 3009,
          column: 6
        },
        end: {
          line: 3009,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 29
          },
          end: {
            line: 1,
            column: 30
          }
        },
        loc: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 3012,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 2965,
            column: 12
          },
          end: {
            line: 2965,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2965,
            column: 26
          },
          end: {
            line: 2973,
            column: 7
          }
        },
        line: 2965
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 2974,
            column: 12
          },
          end: {
            line: 2974,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2974,
            column: 29
          },
          end: {
            line: 2988,
            column: 7
          }
        },
        line: 2974
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 2989,
            column: 13
          },
          end: {
            line: 2989,
            column: 14
          }
        },
        loc: {
          start: {
            line: 2989,
            column: 24
          },
          end: {
            line: 2992,
            column: 7
          }
        },
        line: 2989
      },
      '4': {
        name: 'fetchTranslations',
        decl: {
          start: {
            line: 3000,
            column: 11
          },
          end: {
            line: 3000,
            column: 28
          }
        },
        loc: {
          start: {
            line: 3000,
            column: 45
          },
          end: {
            line: 3011,
            column: 3
          }
        },
        line: 3000
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2936,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2936,
            column: 3
          }
        }, {
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2936,
            column: 3
          }
        }],
        line: 2932
      },
      '1': {
        loc: {
          start: {
            line: 2932,
            column: 6
          },
          end: {
            line: 2932,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2932,
            column: 6
          },
          end: {
            line: 2932,
            column: 43
          }
        }, {
          start: {
            line: 2932,
            column: 48
          },
          end: {
            line: 2932,
            column: 90
          }
        }],
        line: 2932
      },
      '2': {
        loc: {
          start: {
            line: 2939,
            column: 2
          },
          end: {
            line: 2998,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2939,
            column: 2
          },
          end: {
            line: 2998,
            column: 3
          }
        }, {
          start: {
            line: 2939,
            column: 2
          },
          end: {
            line: 2998,
            column: 3
          }
        }],
        line: 2939
      },
      '3': {
        loc: {
          start: {
            line: 2941,
            column: 4
          },
          end: {
            line: 2997,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2941,
            column: 4
          },
          end: {
            line: 2997,
            column: 5
          }
        }, {
          start: {
            line: 2941,
            column: 4
          },
          end: {
            line: 2997,
            column: 5
          }
        }],
        line: 2941
      },
      '4': {
        loc: {
          start: {
            line: 2941,
            column: 8
          },
          end: {
            line: 2941,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2941,
            column: 8
          },
          end: {
            line: 2941,
            column: 46
          }
        }, {
          start: {
            line: 2941,
            column: 50
          },
          end: {
            line: 2941,
            column: 108
          }
        }, {
          start: {
            line: 2941,
            column: 113
          },
          end: {
            line: 2941,
            column: 154
          }
        }],
        line: 2941
      },
      '5': {
        loc: {
          start: {
            line: 2950,
            column: 15
          },
          end: {
            line: 2950,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2950,
            column: 15
          },
          end: {
            line: 2950,
            column: 50
          }
        }, {
          start: {
            line: 2950,
            column: 54
          },
          end: {
            line: 2950,
            column: 58
          }
        }],
        line: 2950
      },
      '6': {
        loc: {
          start: {
            line: 2968,
            column: 8
          },
          end: {
            line: 2970,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2968,
            column: 8
          },
          end: {
            line: 2970,
            column: 9
          }
        }, {
          start: {
            line: 2968,
            column: 8
          },
          end: {
            line: 2970,
            column: 9
          }
        }],
        line: 2968
      },
      '7': {
        loc: {
          start: {
            line: 2977,
            column: 8
          },
          end: {
            line: 2987,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2977,
            column: 8
          },
          end: {
            line: 2987,
            column: 9
          }
        }, {
          start: {
            line: 2977,
            column: 8
          },
          end: {
            line: 2987,
            column: 9
          }
        }],
        line: 2977
      },
      '8': {
        loc: {
          start: {
            line: 2977,
            column: 12
          },
          end: {
            line: 2977,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2977,
            column: 12
          },
          end: {
            line: 2977,
            column: 23
          }
        }, {
          start: {
            line: 2977,
            column: 27
          },
          end: {
            line: 2977,
            column: 48
          }
        }, {
          start: {
            line: 2977,
            column: 52
          },
          end: {
            line: 2977,
            column: 96
          }
        }, {
          start: {
            line: 2977,
            column: 101
          },
          end: {
            line: 2977,
            column: 134
          }
        }],
        line: 2977
      },
      '9': {
        loc: {
          start: {
            line: 2982,
            column: 10
          },
          end: {
            line: 2985,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2982,
            column: 10
          },
          end: {
            line: 2985,
            column: 11
          }
        }, {
          start: {
            line: 2982,
            column: 10
          },
          end: {
            line: 2985,
            column: 11
          }
        }],
        line: 2982
      },
      '10': {
        loc: {
          start: {
            line: 2982,
            column: 14
          },
          end: {
            line: 2982,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2982,
            column: 14
          },
          end: {
            line: 2982,
            column: 37
          }
        }, {
          start: {
            line: 2982,
            column: 41
          },
          end: {
            line: 2982,
            column: 66
          }
        }, {
          start: {
            line: 2982,
            column: 71
          },
          end: {
            line: 2982,
            column: 101
          }
        }],
        line: 2982
      },
      '11': {
        loc: {
          start: {
            line: 3002,
            column: 4
          },
          end: {
            line: 3010,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3002,
            column: 4
          },
          end: {
            line: 3010,
            column: 5
          }
        }, {
          start: {
            line: 3002,
            column: 4
          },
          end: {
            line: 3010,
            column: 5
          }
        }],
        line: 3002
      },
      '12': {
        loc: {
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        }, {
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        }],
        line: 3003
      },
      '13': {
        loc: {
          start: {
            line: 3006,
            column: 6
          },
          end: {
            line: 3008,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3006,
            column: 6
          },
          end: {
            line: 3008,
            column: 7
          }
        }, {
          start: {
            line: 3006,
            column: 6
          },
          end: {
            line: 3008,
            column: 7
          }
        }],
        line: 3006
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0, 0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_22vidcdndn.s[0]++;
angular.module('app').config(function ($translateProvider) {
  cov_22vidcdndn.f[0]++;

  var data = (cov_22vidcdndn.s[1]++, {
    COUNTRIES: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AN: 'Netherlands Antilles',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BM: 'Bermuda',
      BN: 'Brunei Darussalam',
      BO: 'Bolivia',
      BR: 'Brazil',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Congo, The Democratic Republic of the',
      CF: 'Central African Republic',
      CG: 'Congo',
      CH: 'Switzerland',
      CI: 'Cote D\'Ivoire',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CS: 'Serbia and Montenegro',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EC: 'Ecuador',
      EE: 'Estonia',
      EG: 'Egypt',
      EH: 'Western Sahara',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands (Malvinas)',
      FM: 'Micronesia, Federated States of',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GL: 'Greenland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia and the South Sandwich Islands',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heard Island and Mcdonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Ireland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran, Islamic Republic Of',
      IS: 'Iceland',
      IT: 'Italy',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: 'Korea, Democratic People\'s Republic of',
      KR: 'Korea, Republic of',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Lao People\'s Democratic Republic',
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      LY: 'Libyan Arab Jamahiriya',
      MA: 'Morocco',
      MC: 'Monaco',
      MD: 'Moldova, Republic of',
      MG: 'Madagascar',
      MH: 'Marshall Islands',
      MK: 'Macedonia, The Former Yugoslav Republic of',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macao',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      PN: 'Pitcairn',
      PR: 'Puerto Rico',
      PS: 'Palestinian Territory, Occupied',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Reunion',
      RO: 'Romania',
      RU: 'Russian Federation',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard and Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      ST: 'Sao Tome and Principe',
      SV: 'El Salvador',
      SY: 'Syrian Arab Republic',
      SZ: 'Swaziland',
      TC: 'Turks and Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern Territories',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'Timor-Leste',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan, Province of China',
      TZ: 'Tanzania, United Republic of',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See (Vatican City State)',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VG: 'Virgin Islands, British',
      VI: 'Virgin Islands, U.S.',
      VN: 'Viet Nam',
      VU: 'Vanuatu',
      WF: 'Wallis and Futuna',
      WS: 'Samoa',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe '
    },
    MESSAGE: {
      CONVERSATION: 'Conversation',
      WRITE_MSG: 'Type Message',
      AUCUN: 'No message',
      TITLE: 'My Messages',
      READ_ALL: 'Read all Messages',
      INBOX: 'Inbox',
      SEND_BOX: 'Sent Mail',
      DRAFT_BOX: 'Drafts',
      TRASH_BOX: 'Trash',
      REFRESH: 'Refresh',
      REPLY: 'Reply',
      IMPORTANT: 'Important',
      VIEW_MSG: 'View Message',
      TO: 'To (DELTA User )',
      TO_: 'DELTA User',
      EMAILS: 'Emails',
      TOEMAIL: 'To (External Email)',
      MAILEXTERNE: 'External Email',
      PIECEJ: 'Attaching files',
      SUBJECT: 'Subject',
      FROM: 'From',
      DRAFT: 'Draft',
      DISCARD: 'Discard',
      CMP_MSG: 'Compose Mail',
      SEND: 'Send',
      EMAIL_BOX: 'Mailbox',
      FOLDER: 'Folder',
      REMOVE: 'Remove',

      MARKREAD: 'Mark as read',
      RECHARGEMENT: 'Refresh',
      MARKNOREAD: 'Mark as unread',
      MARKIMPORTANT: 'Mark as important',
      MARKTRASH: 'Move to trash',

      ADDEMAIL: 'Add a contact',

      SEND_OK: 'Message sent',
      SEND_NO: 'Error sending Message',
      SEND_NO_T: 'Try Again',
      SEND_SAVE: 'Message recorded',

      ADDUSER: 'Add user',
      SIGNATURE: 'Signatures',
      SIGNATURE_SEND: 'Done',
      SIGNATURE_ERROR: 'Error',
      SIGNATURE_CREATE: 'Create a signature',
      SIGNATURE_ADD: 'Create',
      SIGNATURE_TITLE: 'Signature title',
      SIGNATURE_DESIGN: 'Designation',
      SIGNATURE_ANNULER: 'Cancel',
      SIGNATURE_AJOUTER: 'Add',
      SIGNATURE_MODIFIER: 'Modify',
      SIGNATURE_LIST: 'List of signatures',
      SIGNATURE_DEFAULT: 'Select as default signature',
      SIGNATURE_NODEFAULT: 'Deselect as default signature',

      ERROR: {
        TO: 'Please select recipient!',
        SUBJECT: 'Enter Subject '
      }
    },
    LOGIN: {
      PASSWORD_EXPIRED_S: 'Your password expires in {{days}} days',
      PASSWORD_EXPIRED: 'Your password will expire in 1 day',
      PROFILE: 'Profile',
      LOGOUT: 'Logout'
    },
    HELP: {
      LEGAL: 'Legal Notice',
      QUICK_START: 'Learning Center',
      USER_MANUAL: 'User Manual',
      FAQ: 'FAQ',
      WEBINAIRS: 'Webinars',
      SUPPORT: 'Contact technical support',
      LEGAL_NOTICE_LINK: 'https://delta-monitoring.com/service-level-agreement/',
      USER_FEEDBACK: "Share your Feedback"
    },
    ALERT: {
      ACTIVATE: 'Activate problem alert',
      SMS: 'SMS',
      EMAIL: 'E-mail',
      IND_TEXT: 'Indicator Alerts',
      DOCUMENT: 'new documents',
      MORE: 'View All Indicator Alerts & Documents',
      ALL: 'All Alerts',
      NBR_J_L: 'Send alert',
      SEND: 'day(s) before due date',
      HELP: {
        TITLE: 'Alert is set to send an alert message to team members to remind them for data entry regarding assigned activities and indicators '
      }
    },
    TODO: {
      TITLE: 'To Do List',
      LABEL: 'Label '
    },
    HOME: {
      WELCOME: 'Collaborative Web based Software for M&E',
      USER: 'Username',
      PASS: 'Password',
      LOGIN: 'Login',
      FOOTER: 'DELTA Monitoring',
      CONNECTED_PLURAL: 'Currently Connected: {{user}}</b> Users',
      CONNECTED_SINGLE: 'Currently Connected: 1</b> User'
    },
    HEADER: {
      LOGO: 'Logo',
      TITLE: 'Report Header',
      FIRST_LINE: 'First Line',
      SECOND_LINE: 'Second Line',
      THIRD_LINE: 'Third Line',
      REPORT_TITLE: 'Report Title'
    },
    COMMON: {
      ACTIVE_SUP: "Activate temporary deletion",
      ALERTE_UNIT: 'Select a %type unit.',
      STEP: 'Stages',
      STEPONE: 'Stage',
      VALUE: 'Value',
      ADDSTEP: 'Define stages of completion',
      ADDSTEP_VIEW: 'The type of activity selected indicates that the activity will be followed as a process with stages of completion',
      EXPORT_POWERBI: 'Export to Power BI',
      LOADED_MSG: 'Please wait, treatment in progress ...',
      EMAIL_UPLOADED: 'You have imported {{_nb}} email(s)',
      UPLOAD_CSV: 'Import from .CSV file',
      UPLOAD_CSV_RECOMMENDATION: "Be sure to name the header of each column in the .CSV file",
      UPLOAD_CSV_RECOMMENDATION_HEADERS: 'Email (mandatory), Name and Number (if applicable).',
      DATE_MAJ: 'Date of last update',
      SELECT_ALL: 'Select All',
      UNSELECT_ALL: 'Unselect All',
      FORMULAIRE_ERR_EXISTE: 'The form already exists',
      ANALYSE_ERR_EXISTE: 'Title already used',
      ERR_MSG: 'Please fill in this field.',
      COL: 'Columns',
      COL_S: 'Select Columns',
      SV: 'Save',
      SV_R: 'Save Report',
      UPLOAD_DOC: 'Attachment',
      SELECT_OPTION: 'Select an option',
      SELECT_OPTION_: 'Select an option',
      REQUIRED: 'Required',
      CHECKING: 'Checking ...',
      ERROR_AUTHORIZE_LOCATION: 'You have no rights on this locality',
      ONLINE: 'Online',
      OFFLINE: 'Offline',
      ALREADY_EXIST: 'The variable already exists!',
      SEE_MORE: 'See More ...',
      UNAVAILABLE: 'Unavailable',
      SELECT_UNIT: 'Select a Unit',
      CONDITIONS: 'Filters',
      FORMATTING: 'Apply Format',
      CHART: 'Chart',
      OVERWRITE_DATA: 'Overwrite data',
      SHARE: 'Share',
      GOOD: 'Good',
      BAD: 'Bad',
      MEDIUM: 'Medium',
      PORTFOLIO_VISIBLE: 'Visible in Portfolio',
      NO_DATA: 'No Data',
      HELP: 'Help',
      TASK_DISPLAY_FORMAT: 'day dd month yyyy',
      DAY_MAJOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      WEEK_MINOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      FLAVOR: 'favorites',
      CHECK_ALL: 'Check all',
      UNCHECK_ALL: 'Uncheck all',
      PLANNING: 'Scheduled',
      NOT_PLANNING: 'Not Scheduled',
      UPDATE: 'Update',
      BY: 'by',
      CHANGE_PHOTO: 'Change photo',
      ENTER: 'Enter',
      FILTER: 'Filter by',
      AGGREGATE: 'Aggregate',
      DESEGREGATE: 'Disaggregate',
      TAKE_PICTURE: 'Take a photo ',
      REPLY_TREE: 'Click to browse tree view',
      VALIDATE_DATA: 'Validate data',
      VALIDATED_DATA: 'Validated Data',
      ALL_DATA: 'All data',
      VALIDATION: 'Validation',

      SATELLITE: 'Satellite',
      GRAYSCALE: 'Grayscale',
      CITIES: 'Cities',
      DAY: 'Day',
      EXPORT_DATA: 'Export Data',
      IMPORT_DATA: 'Import Data',
      DENIED_LOGIN: 'Go to your login page',
      DENIED: 'Access Denied',
      FULLSCREEN: 'View Fullscreen',
      EXIT_FULLSCREEN: 'Exit Fullscreen',
      FULL_GRAPH: 'Fill',
      WORKFLOW: 'Workflow',
      DATA: 'Data',
      GPS: {
        LONGITUDE: 'Longitude',
        LATITUDE: 'Latitude',
        ALTITUDE: 'Altitude'
      },
      RIGHT: {
        VALIDATION_SELECT: 'Validate Selected',
        ONLYVALDATE: 'Only validated data',
        NONVALIDATED: 'Invalid',
        INVALIDATED: 'In process of validation',
        VALIDATED: 'Valid',
        DELVALIDATED: 'To delete',
        SELECTVAL: 'Select a status',
        VALIDATE: 'Validation',
        VALIDATE_STATUS: 'Validation Status',
        VIEWING: 'View',
        UPDATING: 'Edit',
        DELETING: 'Delete',
        PAGE: 'Function',
        EDIT_DELETE: 'Edit / Delete',
        DATA: 'Data',
        METADATA: 'MetaData '
      },
      STATUT: {
        NOSTART: 'Not started',
        PROGRESS: 'In progress',
        DONE: 'Completed '
      },
      SAVE: 'Save',
      VIRGULE: '.',
      NEXT: 'Next',
      PREV: 'Previous',
      ADD: 'Add',
      EDIT: 'Edit',
      DELETE: 'Delete',
      PRINT: 'Print',
      SELECT: 'Select',
      YEAR: 'Year',
      SEM: 'Semester',
      QUATR: 'Quadrimester',
      TRIM: 'Quarter',
      BIM: 'Bimester',
      MOIS: 'Month',
      PERIOD: 'Period',
      YEAR_SHORT: 'Y',
      SEM_SHORT: 'S',
      QUATR_SHORT: 'Qd',
      TRIM_SHORT: 'Q',
      BIM_SHORT: 'B',
      MOIS_SHORT: 'M',
      DISPLAY: 'Display',
      VALIDATE: 'OK',
      CANCEL: 'Cancel',
      CONTINUE: 'Continue',
      CONFIRM: 'Confirm delete',
      CONFIRM_RESTORE: 'Confirm restore',
      YES: 'Yes',
      NO: 'No',
      ERROR_DELETE: 'Can\'t delete!',
      ERROR_: 'Error',
      ERROR_UPDATE: 'Can\'t update!',
      ERROR_ADD: 'Can\'t add!',
      ERROR_CODE: 'Code is already used',
      TITLE_ERROR_ADD: 'An error was detected during the update',
      TITLE_ERROR_DELETE: 'Data has dependencies',
      DATE_FORMAT: 'MM/DD/YYYY',
      DATE_FORMAT_COMPLET: 'MM/DD/YYYY HH:mm',
      DATE_FORMAT_MM_YYYY: 'MMMM YYYY',
      HISTORQ: 'Recent',
      CLOSE: 'Close',
      DATE_DP: 'Start Date',
      ACTUAL: 'Actual Date',
      DATE_DR: 'Start Date',
      DATE_FP: 'End Date',
      DATE_FR: 'End Date',
      H_DEB: 'Start time',
      H_FIN: 'End time',
      LIEU: 'Location',
      MSG_ADD: 'Record added!',
      MSG_MODIF: 'Update completed!',
      MSG_DEL: 'Deletion completed!',
      TBORD: 'Dashboard',
      YEAR_ALL: 'All (*)',
      ALL: 'All (*)',
      ALL_E: 'All (*)',
      NONE: 'None',
      NONE_E: 'None',
      SEARCH: 'Search',
      TYPECHART: 'Chart Type',
      LINECHART: 'Line Chart',
      BARCHART: 'Bar Chart',
      PIECHART: 'Donut Chart',
      POLARCHART: 'Polar Chart',
      MIXED_CHART: 'Mixed Chart',
      BEGIN: 'Begin',
      END: 'End',
      LESS_THAN: 'Less than',
      GREAT_THAN: 'Great than',
      EGAL_TO: 'Equal to',
      LESS_THAN_E: 'Less than',
      GREAT_THAN_E: 'Great than',
      EGAL_TO_E: 'Equal to',
      OPEN: 'Open',
      DOWNLOAD: 'Download',
      QUIT: 'Cancel',
      OPEN_DASHBOARD: 'Click to display the Dashboard',
      DESCRIPTION: 'Description',
      MORE: 'More...',
      MORE_DBL: 'double click for details',
      DASHBOARD: 'Dashboard',

      ERROR: {
        NOMATCH: 'No result match',
        REQUIRED: 'Required',
        DBL: 'The code is already assigned',
        DBL_REF: 'The reference is already assigned',
        DBL_LAB: 'The name is already assigned',
        DBL_: 'Already assigned',
        NONE_DATA: 'No data selected',
        MIN_CAR: 'minimum {{field}} length is {{value}}',
        MAX_CAR: 'maximum {{field}} length is {{value}}',
        EMAIL: 'The email is not valid',
        CODE_ERROR: 'Length must be equal to {{value}}',
        CODE_ERROR_COM: 'Length must be equal to {{value}} and begin with {{begin}}',
        DATE_RANGE: 'The start date must be greater than the end date',
        DATE: 'Invalid date',
        FINANCETOTAL: 'The total must be equal to 100%',
        INVALID_URL: 'The URL is not valid'
      },
      EXPORT_WORD: 'Export to Word',
      EXPORT_EXCEL: 'Export to Excel',
      EXPORT_PDF: 'Export to PDF ',
      EXPORT_JSON: 'Export to JSON',
      STREETMAP: 'Street Map',
      NEW_FEAUTURE: 'Introducing new features',
      DATE_FORMAT_DISPLAY: 'Date format'
    },
    MENU_SHORT: {
      LAYERS: 'Basemaps',
      LAYER: 'Basemap',
      REVIEW: 'Review',

      REVIEW_MANAGEMENT: 'Track reviews',
      MONITORING_REQUETES: 'Track requests',
      MONITORING_PREPARATION: 'Preparation',
      MONITORING_CONDITIONALITIES: 'Conditions',

      RISKS_MANAGEMENT: 'Risk Management',
      PROBLEM_MANAGEMENT: 'Issues',
      RISKS: 'Risks',
      PROBLEM: 'Problems',
      MISSION_MANAGEMENT: 'Mission Management',
      MISSION_TYPES: 'Mission types',
      COMMUNITIES: 'Communities',
      REPORT_PLAN: 'Report Planning',
      REPORT_TRACKING: 'Report Tracking',
      FUNDS: 'Funds Disbursement',
      ACTIVITY_PLAN_BUDGET: 'Plan & Budget',
      PROJECT_TIMELINE: 'Project Timeline',
      RISK_MANAGEMENT: 'Risk Management',
      PROJECT_MGT: 'Management',
      ACTIVITY_REPORT: 'Project Reports',
      CUSTOM_RIGHT: 'Customized rights',
      HOME: 'Home',
      ALERT: 'Alerts',
      D_B: 'Settings',
      DO: 'Projects Portfolio',
      PROJECT: 'Projects',
      PROJECT_SINGLE: 'Project',
      D_T: 'Time division',
      D_P: 'Reporting period ',
      D_PL: '3-level Planning',
      UN: 'Units',
      AGENCIES: 'Agencies',
      AC: 'Stakeholders',
      FINANCING: 'Financing',
      S_F: 'Funding Sources',
      P_G: 'Locations',
      S_G: 'Geography division',
      T_I: 'KPIs Cat.',
      C_I: 'KPI Disaggregation',
      IN: 'Indicators (KPIs)',
      IN_T: 'Indicators (KPIs) ',
      IN_P: 'Cross-projects KPIs',
      R_F: 'KPIs Results Framework',
      R_F_: 'Results Framework',
      P_L: 'Customize Label',
      P_LO: 'Log frame ',
      C_B: 'Budget Cat.',
      P_CL: 'Log Frame',
      P_CUSTOM: 'Templates',
      P_CR: 'Results Chain',
      ST: 'Logframe levels',
      ST_CL: 'Structure Logical Framework',
      ST_CR: 'Structure Results Chain',
      P_B: 'Budget Plan',
      P_M: 'Procurement Plan',
      FL: 'Flag',
      T_A: 'Activity Cat.',
      AGENDA: 'Agenda',
      FILE: 'Chatroom',
      ANALYTICS: 'Dashboards',
      PL: 'Activities',
      P_A: 'Activity Monitoring ',
      P_AN_PTBA: 'Annual Plan (AWPB)',
      IMP_BUDG: 'Import Budget',
      G_F_PF: 'Generate pre-formatted file',
      I_B: 'Import budgets',
      P_I: 'Plan Indicators',
      S_E: 'Monitoring',
      S_A: '{{level}} Monitoring',
      I_C: 'Import Costs',
      I_COUT: 'Importing Costs',
      S_PTBA: 'Monitoring',
      S_PTBP: 'Annual Project Performance',
      T_B_A: 'Activity Dashboard',
      C_GANTT: 'Project Timeline',
      P_AC: 'Action plan',
      R_P: 'Custom Queries',
      MA: 'Contracts',
      S_M: 'Contracts Monitor',
      S_PPM: 'PP monitoring',
      S_I: 'Indicator Monitoring',
      S_I_IOV: 'Indicators',
      S_I_G: 'Geography tracking of Indicators',
      T_B_I: 'Indicators Dashboard',
      SAP_VIS: 'Spatial Visualisation',
      SY: 'Summary',
      C_L: 'Log frame ',
      S_C_L: 'Log frame monitoring',
      S_C: 'Context',
      G_R: 'Risk Management',
      P_M_R: 'Risk mitigation plan',
      F_P: 'Positive Factors',
      E_T: 'Lessons Learned',
      B_D: 'Documents',
      B_D_: 'Knowledge Sharing and Learning',
      DB_P: 'Customized Databases',
      T_RA: 'Staff Reports',
      CA_R: 'Report Tracking',
      C_D: 'Documents',
      PH: 'Media Library',
      AD: 'Users',
      G_U: 'Users',
      GR_U: 'Groups',
      U_C: 'Connected Users',
      J_C: 'Connections Journal',
      R_I: 'Profiles',
      FORMULAR_: 'Data Collect',
      FORMULAR: 'Data Collect',
      DESIGN_FORMS: 'Design Forms',
      COLLECT_DATA: 'Data Collection',
      ANALYZE_DATA: 'Analytics & Reporting',
      ANALYSE: 'Custom Reports',
      DASHBOARD: 'Dashboards',
      DASHBOARD_: 'Dashboards ',
      ANALYTICS_GIS: 'Spatial Analysis',
      AUDITS: 'Access Log',
      SECURITY: 'Security',
      DELETED_ITEMS: 'Deleted Items',
      ADMIN: 'Admin'
    },
    MENU: {
      LAYERS: 'Basemaps',
      LAYER: 'Basemap',
      RECOMMENDATIONS: 'Recommendations',
      RISKS_MANAGEMENT: 'Risk Management',
      PROBLEM_MANAGEMENT: 'Issues',
      RISKS: 'Risks',
      PROBLEM: 'Problems',
      MISSION_MANAGEMENT: 'Mission Management',
      MISSION: 'Mission',
      MISSION_TYPES: 'Mission types',
      COMMUNITIES: 'Communities',
      REPORT_PLAN: 'Report Planning',
      REPORT_TRACKING: 'Report Tracking',
      FUNDS: 'Funds Disbursement',
      SECTORS: 'Sectors',
      PROJECT_MGT: 'Management',
      ACTIVITY_REPORT: 'Project Reports',
      DASHBOARDS: 'Dashboards',
      PROJECTS: 'Projects',
      HOME: 'Home',
      ALERT: 'Alerts',
      D_B: 'Settings',
      DO: 'Projects Portfolio',
      PROJECT: 'Projects',
      D_T: 'Time division',
      D_P: 'Reporting Period ',
      D_PL: '3-level Planning',
      UN: 'Units of measure',
      FINANCING: 'Financing',
      AGENCIES: 'Implementing Agencies',
      AC: 'Stakeholders',
      S_F: 'Funding sources',
      P_G: 'Locations',
      Plan_G: 'Locations',

      S_G: 'Geography division',
      STRUCT_G: 'Geography division',
      T_I: 'KPIs categories',
      TYPE_INDIC: 'Types Indicators',
      C_I: 'KPI Disaggregation',
      IN: 'Indicators (KPIs)',
      IN_T: 'Indicators (KPIs) ',
      IN_P: 'Cross-projects KPIs',
      R_F: 'KPIs Results Framework',
      R_F_: 'Indicators Results Framework',
      P_L: 'Customize Label',
      P_LO: 'Log frame ',
      C_B: 'Budget Categories',
      P_CL: 'Log Frame',
      P_CUSTOM: 'Log Frame Templates',
      P_CR: 'Results Chain',
      ST: 'Logframe levels',
      ST_CL: 'Structure Logical Framework',
      ST_CR: 'Structure Results Chain',
      P_B: 'Budget Plan',
      P_M: 'Procurement Plan',
      FL: 'Flag',
      T_A: 'Activity Categories',
      AGENDA: 'Agenda',
      FEEDBACK: 'Feedback',
      FILE: 'Chatroom',
      PL: 'Activities',
      P_A: 'Activity Monitoring ',
      P_AN_PTBA: 'Annual Plan (AWPB)',
      IMP_BUDG: 'Import Budget',
      G_F_PF: 'Generate pre-formatted file',
      I_B: 'Import budgets',
      P_I: 'Plan Indicators',
      S_E: 'Monitoring',
      S_A: '{{level}} Monitoring',
      I_C: 'Import Costs',
      I_COUT: 'Importing Costs',
      S_PTBA: 'Monitoring',
      S_PTBP: 'Annual Project Performance',
      T_B_A: 'Activity Dashboard',
      C_GANTT: 'Project Timeline',
      P_AC: 'Action plan',
      R_P: 'Custom Queries',
      MA: 'Contracts',
      S_M: 'Contracts Monitor',
      S_I: 'Indicator Monitoring',
      S_I_IOV: 'Indicators',
      S_I_G: 'Geography tracking of Indicators',
      T_B_I: 'Indicators Dashboard',
      SAP_VIS: 'Spatial Visualisation',
      SY: 'Summary',
      C_L: 'Log frame ',
      S_C_L: 'Log frame monitoring',
      S_C: 'Context',
      G_R: 'Risk Management',
      P_M_R: 'Risk mitigation plan',
      F_P: 'Positive Factors',
      E_T: 'Lessons Learned',
      B_D: 'Documents',
      B_D_: 'Knowledge Sharing and Learning',
      DB_P: 'Customized Databases',
      T_RA: 'Staff Reports',
      CA_R: 'Report Tracking',
      C_D: 'Documents',
      PH: 'Media Library',
      AD: 'Users',
      G_U: 'Users Management',
      GR_U: 'User Groups',
      U_C: 'Connected Users',
      J_C: 'Connections journal',
      R_I: 'User Profiles',
      FORMULAR_: 'Data Collection',
      FORMULAR: 'Data Collection',
      ANALYTICS: 'Dashboards',
      ANALYTICS_CUSTOM_REPORT: 'Custom Reports',
      ANALYTICS_CUSTOM_DASHBOARD: 'Dashboards',
      ANALYTICS_GIS: 'Spatial Analysis',
      DESIGN_FORMS: 'Design Forms',
      COLLECT_DATA: 'Data Collection',
      ANALYZE_DATA: 'Analytics & Reporting',
      ANALYSE: 'Custom Reports',
      DASHBOARD: 'Dashboards',
      DASHBOARD_: 'Dashboards ',
      AUDITS: 'Access log',
      SECURITY: 'Security Settings',
      DATA: 'Forms',
      ADMIN: 'Administration'
    },
    LAYER: {
      NAME: 'Title',
      URL: 'Map URL',
      HELP: {
        NAME: 'title',
        URL: 'The URL of the basemap (https://...{x}{y}{z})'
      },
      ERROR: {
        URL: 'Invalid URL'
      }
    },
    PROGRAM: {
      EDIT: 'Edit Portfolio',
      EDIT_PROGRAM: 'Edit Portfolio',
      EDIT_HEADERS: {
        DETAIL: 'Portfolio Details',
        DESCRIPTION: 'Portfolio Description',
        ME: 'Setting Periods and Milestones',
        PERIODS: 'Setting Periods',
        MILESTONES: 'Milestones',
        OPTIONS: 'Options'
      },
      TITLE: 'Portfolio',
      NAME: 'Name',
      DESCRIPTION: 'Description',
      HELP: {
        NAME: 'Enter Portfolio Name',
        DESCRIPTION: 'Enter Portfolio Description '
      }
    },
    DEFAULT_LOGFRAME: {
      TITLE: 'Log frame Templates',
      SUBTITLE: 'Log frame Template',
      LABEL: 'Name',
      LEVELS: 'Log Frame Level',
      SEARCH: 'Search by name',
      HELP: {
        NEW_LEVEL: 'Add new level',
        LABEL: 'Enter Log frame\'s name '
      }
    },
    SECTORS: {
      NAME: 'Name'
    },
    FINANCING: {
      EDIT_TITLE: 'Financing',
      SOURCE: 'Financing Source',
      AGREEMENT: 'Financing Agreement #',
      TYPE: 'Type',
      TYPES: {
        GRANT: 'Grant',
        LOAN: 'Loan',
        DONATION: 'Donation',
        BUDGET_SUPPORT: 'Budget Support'
      }
    },
    PORTFOLIO: {
      GO_TO_PORTFOLIO: 'View projects ...',
      EDIT_TITLE: 'Portfolio',
      CLOSE: 'Back to portfolios',
      OPEN: 'Manage portfolio',
      CODE: 'Acronym',
      LABEL: 'Name',
      TITLE: 'Portfolios list',
      ADD: 'Add Portfolio',
      ERROR: {
        DBL: 'The Portfolio acronym is already assigned',
        DATE: 'The end date must be greater than the start date',
        DATE_BEGIN_SUP: 'The start date must be greater than {{X}}',
        DATE_BEGIN_INF: 'The start date must be less than {{X}}',
        DATE_END_SUP: 'The end date must be greater than {{X}}',
        DATE_END_INF: 'The end date must be less than {{X}}',
        DELETE: 'This sector is currently used'
      }
    },
    PROJECT: {
      ACTIVE_SMS: 'Activate sending SMS alerts',
      ACTIVE_SMS_WARNING: 'Enabling this option will result in additional costs.\n' + 'Please contact DELTAGIS for the effective activation of the option.',
      NEWS: 'News',
      CUSTOM_FIELD_1: 'Label Fields 1',
      CUSTOM_FIELD_2: 'Label Fields 2',
      OPTION_ACTIVITIES: 'Management Options',
      OPTION_INDICATORS: 'Options Indicators',
      OPTION_OTHERS: 'Other Options',
      CUSTOM_FIELD: 'Use custom fields',
      INDEX_PERFORMANCE: 'Performance',
      CROSS_CUTTING_KPIS: 'Manage Cross-cutting KPIs',
      CROSS_CUTTING_KPIS_LIST: 'Cross-cutting KPIs',
      PORTFOLIO_CROSS_CUTTING_KPIS: 'Isolate portfolio cross-cutting Indicators',
      MISSIONS: 'Mission Monitoring',
      RISK: 'Risks Management',
      FOLLOW_RISK: 'Risk Monitoring',
      FOLLOW_PERFORM: 'Performance monitoring',
      TYPE: 'Type',
      TYPES: {
        PROJECT: 'Project',
        PROGRAM: 'Program',
        OTHER: 'Other'
      },
      FILTERS: 'Filters',
      CLOSE: 'Close',
      TO_FILTER: 'Filter',
      CLEAR_FILTERS: 'Clear',
      ACTOR: 'Manager',
      ACTORS: 'Manager',
      ACTOR_NONE: 'None',
      STATUS_PROGRESS: 'Progress',
      STATUS_PROGRESS_: 'Progress',
      DASHBOARDS: 'Projects Dashboards',
      GO_TO_PROJECT: 'Go to Project',
      SUMMARY: 'Project performance',
      SELECT_SECTORS: 'Sectors',
      SECTORS: 'Sectors',
      PROJECTS: 'Projects',
      PROJECTS_COL: 'Project',
      EDIT: 'Edit',
      GEO_MAP: 'Geo Mapped',
      SECTOR: 'Sector',
      FINANCING: 'Financing Source',
      AMOUNT: 'Amount',
      ADD_: 'Add',
      FINANCIAL_DATA: 'Financial Data',
      PAGING_SUMMERY: '{{current}} of {{total}} pages ({{count}} projects)',
      PAGING_ELEMENT_SUMMERY: '{{current}} of {{total}} pages ({{count}} items)',
      AGENCIES: 'Implementing Agencies',
      SELECT_AGENCIES: 'Select Implementing Agencies',
      DISPLAY_DATA: 'Use portal to display data',
      LOCATION: 'GPS Coordinates',
      ADD: {
        OPTION_1: 'Project',
        OPTION_2: 'Portfolio '
      },
      LINK: {
        TITLE: 'Link the following data to the portfolios',
        UNITS: 'Units',
        FINANCIAL_SOURCES: 'Funding Sources',
        LOCALITIES: 'Locations',
        LOGICAL_FRAME: 'Log Frame',
        FLAG: 'Flag',
        TYPE_ACTIVITIES: 'Activity Categories',
        TYPE_INDICATORS: 'KPIs Categories',
        TYPE_REPORT: 'Reports',
        FORM: 'Forms '
      },
      SELECT_PERIOD: 'Select a period',
      SEARCH_PROJECT: 'Search by code or label',
      FILTER: 'Filter by: ',
      MILESTONES: {
        REALIZE: 'Completed',
        TITLE: 'Milestones',
        MILESTONE: 'Milestone',
        PLANNED_DATE: 'Planned Date',
        ACTUAL_DATE: 'Actual Date',
        ACTUAL: 'Actual',
        COMMENTS: 'Comments',
        PLANNED: 'Planned '
      },
      DESCRIPTION: 'Description',
      GLOBAL_PLAN: 'Use Global Planning',
      PERIODIC_FOLLOW: 'Reporting Frequency',
      SETTING_FOLLOW: 'Monitoring Settings',
      ACTIVITY_WEIGHT: 'Use Weights',
      ACTIVITY_WEIGHT_AN: 'Per year',
      START_MONTH: 'Fiscal Year beginning',
      FISCAL_YEAR: 'Fiscal Year',
      LOGFRAME: 'Log Frame',
      RESBAS_MONIT: 'Results-based Monitoring',
      CUSTOM_LOGFRAME: 'Custom Log Frame',
      ME_METHOD: 'Log frame Templates',
      ME_METHOD_SELECT: 'Select Project Log Frame Template',
      FILTER_BY: 'Filter By',
      PRJ: 'Portfolio',
      DASHBOARD: 'Cross-projects KPIs',
      TITLE: 'Portfolio',
      NEWP: 'Add',
      FROM: 'from',
      TO: 'to',
      ADDRESS: 'Address',
      CONTACT: 'Contact Person (Focal Point)',
      PROCESS: 'Progress...',
      COMPLETE: 'Completion with',
      PROJECT: 'Project',
      EDIT_PROJECT: 'Edit Project',
      INFO: 'Information',
      CODE: 'Acronym',
      LABEL: 'Name',
      CURRENCY: 'Currency',
      D_DEB: 'Start Date',
      D_FIN: 'End Date',
      D_DEB_: 'Start',
      D_FIN_: 'End',
      COOR: 'contact Information',
      LINK_GEO_PLAN: 'Use Program\'s localities',
      ADR: 'City',
      PAYS: 'Country',
      TEL: 'Telephone',
      FAX: 'Fax',
      MAIL: 'E-mail',
      OTHER: 'Details',
      AGENC: 'Implementing Agency',
      DESCR: 'Description',
      CODE_ERR: 'Code not available',
      CONFIRM_DELETE: 'Do you want to delete this Project?',
      SELECT: 'Select',
      CHANGE_PIC: 'Change Picture (100x100)',
      STATUT_BUDGET: 'Publish financial data',
      GOAL: 'Overall objective',
      TARGET: 'Beneficiaries',
      AREA: 'Execution area',
      LOCATION_FIELD: 'Geographical  location',
      SPECIFIC_OBJECTIVES: 'Specific objectives',
      IMPACT: 'Expected impact',

      DATE_MID: 'Mid-term Review',
      STATUS: 'Status',
      COST: 'Project Total Cost ',
      FINAN: 'Financing',
      MAP: 'Projects Location',
      GLOBAL_HEADERS: {
        PROJECTS: 'List',
        MAP: 'Map',
        INDICATOR: 'Cross-project KPIs '
      },
      EDIT_HEADERS: {
        DETAIL: 'Details',
        DESCRIPTION: 'Description',
        ME: 'Setting Periods and Milestones',
        PERIODS: 'Setting Periods',
        MILESTONES: 'Milestones',
        OPTIONS: 'Options'
      },
      STATUTS: {
        NOT_STARTED: 'Not Started',
        ACTIVE: 'Active',
        SUSPENDED: 'Suspended',
        COMPLETED: 'Completed ',
        ARCHIVED: 'Archived'
      },
      TOOLTIP: {
        CURRENCY: '5 characters maximum',
        STATUT_BUDGET: 'If this option is checked, the financial data will be disclosed as part of the information shown on the portal.'
      },
      ERROR: {
        CUSTOM_FIELD: 'Please fill in one of the fields',
        DELETE: 'Project has data and cannot be deleted!',
        DBL: 'The acronym is already assigned',
        DATE: 'The end date must be greater than the start date',
        CURRENCY: 'The length of the currency must be less than 5 characters ',
        SAVING: 'If you have updated the Fiscal year beginning date or the reporting frequency, all the related data (planned and actual) will be deleted.'
      },
      MAP_VIEW: {
        NBR_PROJECT: 'Number of Projects',
        NBR: 'Number',
        INVESTEMENT: 'Investment ({{currency}} millions)',
        MILLIONS: '({{currency}} millions)',
        AGENC: 'Implementing Agencies',
        PROJECTS: 'Projects',
        BUDGET: 'Amount ({{currency}} millions)',
        LOCATIONS: 'Localities',
        BACK: 'Back',
        STATUS: 'Status',
        SECTORS: 'Sectors',
        FUNDING_SOURCE: 'Funding Sources',
        SUB_LOCATIONS: 'Sub-localities',
        TOTAL: 'Total'
      },
      DASHBOARD_VIEW: {
        FINANCING_PLAN: 'Financing plan',
        DASHBOARD_TYPE: {
          PROJECTS: 'Dashboard',
          RISK: 'Risks',
          PROBLEM: 'Incidents',
          MISSION: 'Missions'
        },
        FINANCING_TYPE_: 'Type of funding',
        DESCRIPTION: 'Description',
        FINANCIAL_DATA: 'Financial performance',
        INDICATOR_PERFORMANCE: 'Performance indicators',
        INDICATOR_RESULT: 'Results Framework',
        EXCHANGE: 'Exchange Rate',
        TOOLTIP_SECTOR_PLURALS: '{{project}} projects totalising {{amount}} ({{currency}} millions)',
        TOOLTIP_SECTOR_SINGLE: '1 project totalising {{amount}} ({{currency}} millions)',
        NUMBER_OF_PROJECT: 'Number of projects',
        DECIMAL_CURRENCY: '{{currency}} millions',
        FINANCING: 'Financing',
        CURRENCY: 'Currency',
        PROJECT: 'Projects',
        AMOUNT: 'Amount',
        DISBURSED: 'Disbursed',
        PROJECT_BY_SECTOR: 'Breakdown by Sector',
        INVESTING_BY_FUNDING_SOURCE: 'Investment by Funding Sources',
        PROJECT_BY_FUNDING_SOURCE: 'Breakdown by Source of Funding',
        SOLD: 'Available',
        RESULTS_FRAMEWORK_STANDARD: 'Indicators Results Framework (Current status)',
        RESULTS_FRAMEWORK_MULTI_YEAR: 'Indicators Results Framework (Annual progress)',
        RESULTS_FRAMEWORK: 'Results Framework',
        TOTAL_COST: 'Total Project Cost',
        // DISBURSED_RATE: 'Disbursement Rate',
        DISBURSED_RATE: 'Evolution of the overall disbursement rate',
        TOTAL_DISBURSED_BY_FUNDING: 'Total disbursed by funding source',
        FILTER: 'Filter',
        FINANCING_TYPE: 'Funding Source',
        AGENCIES: 'Implementing Agencies',
        STATUS: 'Status',
        SECTORS: 'Sector',
        LOCATIONS: 'Locations',
        LOCATION: 'Location'
      }
    },
    BTN: {
      ADD: 'Add',
      MOD: 'Edit',
      DEL: 'Delete',
      CAN: 'Cancel',
      PRI: 'Print',
      VAL: 'OK',
      NEX: 'Next Page',
      PRE: 'Previous Page '
    },
    DEVISE: {
      TITLE: 'Currency',
      CODE: 'Code',
      LABEL: 'Name'
    },
    FUNDS: {
      AVAILABLE: 'Available',
      MAPPING: 'Import Data',
      USE_SELECTED_FINANCING: 'Use selected financing',
      USE_CURRENT_DATE: 'Use today\'s date',
      PLEASE_SELECT_COLUMN: 'Please select a column',
      REFERENCE: '#',
      REFERENCE_FIELD: 'Disbursement #',
      LABEL: 'Disbursement ',
      EDIT_TITLE: 'Disbursement ',
      DATE_FUNDS: 'Date',
      AMOUNT: 'Amount',
      DEVISE: 'Currency',
      RATE: 'Rate',
      AMOUNT_RATE: 'Amount * Rate ',
      HELP: {
        REFERENCE: '# or Ref of the disbursement',
        LABEL: 'Nature or purpose of disbursement',
        DATE_FUNDS: 'Date of disbursement',
        AMOUNT: 'Amount disbursed',
        DEVISE: 'Currency of amount disbursed',
        RATE: 'Exchange rate against the funding currency',
        AMOUNT_RATE: 'Amount (Funding currency)'
      },
      FINANCING: 'Financing',
      DISBURSED: 'Disbursed',
      BAR_CHART: 'DISBURSEMENT RATE PER YEAR',
      PIE_CHART: 'TOTAL DISBURSED PER YEAR'
    },
    FN_UNIT: {
      HELP: {
        COD: 'Enter unit Code (10 characters)',
        LAB: 'Name of the unit '
      },
      ERROR_CODE: 'Code length must not exceed 10 characters',
      SEARCH: 'Search units by code or name',
      LST_TI: 'Units of measure (activities & indicators) ',
      ED_TI: 'Unit details',
      COD: 'Code',
      LAB: 'Label',
      TYP: 'Type',
      FOR: 'Format',
      CB_N: 'Numeric',
      CB_P: '%',
      CB_B: 'Boolean',
      CB_T: 'Text',
      CB_D: 'Date',
      NEWU: 'Create new Unit',
      COLU: 'Unit',
      CONFIRM_DELETE: 'Do you want to delete this unit?',
      DECIMAL: 'Number of digits after decimal point '
    },
    CATEGORIE_BUDGET: {
      HELP: {
        COD: 'Enter unit Code (10 characters)',
        LAB: 'Name of the category '
      },
      CODE: 'Code',
      CATEGORIE_BUDGET: 'Budget category',
      TITLE: 'Budget categories',
      LABEL: 'Name',
      CONFIRM_DELETE: 'Do you want to delete this category?'
    },
    AGENCIES: {
      TITLE: 'Implementing Agencies',
      NAME: 'Name',
      SHORT_NAME: 'Short Name',
      ADDRESS: 'Address',
      CITY: 'City',
      COUNTRY: 'Country',
      TEL: 'Telephone',
      FAX: 'Fax',
      EMAIL: 'Email',
      URL: 'Website',
      HELP: {
        NAME: 'Enter agency name',
        SHORT_NAME: 'Enter agency short name',
        ADDRESS: 'Enter agency address',
        CITY: 'Enter city',
        COUNTRY: 'Country',
        TEL: 'Enter Telephone',
        FAX: 'Enter Fax',
        EMAIL: 'Enter Email',
        URL: 'Enter Website '
      },
      SEARCH: 'Search by name, address, city, or country',
      ERROR: {
        DBL_NAME: 'Name is already assigned'
      }
    },
    FN_ACTORS: {
      HELP: {
        COD: 'Code, ID, or Acronym',
        NOM: 'Name of the stakeholder',
        PRE: 'Name of the stakeholder',
        FON: 'Role / Responsibility assigned to the stakeholder',
        ORGANIZATION: 'Organization to which the stakeholder belongs.',
        GRO: 'Group of the stakeholder',
        EMAIL: 'Email of the stakeholder.'
      },
      PERIOD_RAPPORT: 'Period',
      EDITION_RAPPORT: 'Edition Date',
      SEARCH: 'Search by Code, Name',
      CATEGORY: 'Category',
      CATEGORY_TEAM: 'Project Staff',
      CATEGORY_STAKE: 'Stakeholders',
      LST_AC: 'Stakeholders',
      ED_AC: 'Team Member',
      COD: 'Code',
      NOM: 'Name',
      PRE: 'Name',
      FON: 'Role / Responsibility',
      IMG: 'Image',
      ORGANIZATION: 'Organization',
      GRO: 'Group',
      GRO_: 'Group',
      SELECT_GRO: 'Select a group',
      NEWA: 'Create new Stakeholder',
      LAB_AC: 'Activities',
      LAB_LSTAC: 'Activities',
      LAB_MI: 'Missions',
      LAB_LSTMI: 'Missions',
      LAB_RE: 'Meeting',
      LAB_LSTRE: 'Meeting',
      LAB_RA: 'Reports',
      LAB_LSTRA: 'Reports',
      LAB_TYPRA: 'Report Type',
      FILT: 'Filter',
      ETAV: 'Activity status',
      RESNIVO: 'Level of Responsibility',
      CHECK_A1: 'In progress',
      CHECK_A2: 'Completed',
      CHECK_A3: 'Behind schedule',
      CHECK_A4: 'Ahead of schedule',
      CHECK_A5: 'Global',
      CHECK_A6: 'Local',
      CONFIRM_DELETE: 'Do you want to delete this Actor?'
    },
    FN_SFIN: {
      APPROVE_DATE: 'Approval Date',
      CLOSE_DATE: 'Closing Date',
      SEARCH: 'Search by Code or Name',
      NAME: 'Name',
      NAME_CONTACT: 'Name',
      TEL_CONTACT: 'Telephone',
      EMAIL_CONTACT: 'Email Address',
      NAME_CONTACT_LONG: 'Contact (Name)',
      TEL_CONTACT_LONG: 'Contact (Telephone)',
      EMAIL_CONTACT_LONG: 'Contact (Email)',
      CONTACT: 'Contact Person',
      FINANCE_AMOUNT: 'Financing Amount',
      AMOUNT: 'Amount',
      CURRENCY: 'Currency',
      EXCHANGE_RATE: 'Rate',
      LST_FIN: 'Funding sources',
      ED_FIN: 'Financing source details',
      CONFIRM_DELETE: 'Do you want to delete this source?',
      EQUIV: 'Equiv. {{CURRENCY}}',
      ERROR_DATE: 'Approval date must be less than the closing date'
    },
    FN_TYPINDIC: {
      LST_TYP: 'List of types of Indicators',
      ED_TYP: 'Details type of indicator',
      COL_COLOR: 'Color',
      COLOR_BLUE: 'Blue',
      COLOR_CRYAN: 'Cyan',
      COLOR_GREEN: 'Green',
      COLOR_YELOW: 'Yellow',
      COLOR_ORANGE: 'Orange',
      COLOR_RED: 'Red',
      COLOR_PINK: 'Pink',
      COLOR_PURPLE: 'Purple',
      COLOR_GREY: 'Grey',
      COLOR_BLACK: 'Black',
      CONFIRM_DELETE: 'Do you want to remove this indicator type?'
    },
    DASHBOARD: {
      ATTENTE: 'Waiting',
      VALIDER: 'validated',

      PROGRESS_REPORT: 'Progress Report',
      ACTIVITIES_TAB: {
        PHYSIC: '% Physic',
        FINANCIAL: '% Budget',
        DISBURSED: '% Disbursed',
        TITLE: 'Activity Progress',
        COMPLETED_ACTIVITIES: 'Completed',
        NOT_CARRIED_OUT_ACTIVITIES: 'Not Carried Out',
        ON_GOING_ACTIVITIES: 'On Going',
        STATUS: 'Status'
      },
      LOCATION: 'Location',
      ACTIVITY_COUNT: 'Number of Activities: {{value}}',
      PROJECT_ACTIVITY: '{{value}} of Project activities',
      ACTIVITY_PROGRESS_LABEL: '{{percent}}% of activities ({{count}}) have a completion rate between {{min}}% and {{max}}%',
      INDICATOR_PROGRESS: 'Indicator Progress',
      SPATIAL_VIEW: 'Spatial View',
      INDICATOR_PROGRESS_COMPARED: 'Indicator progress compared to project Final target (project End)',
      INDICATOR: 'Indicator Dashboard',
      ACTIVITY: '{{activity}} Dashboard',
      BOOKMARKED: 'Bookmarked Indicator',
      REFERENCE: 'Baseline',
      FINAL: 'Project End',
      END_TARGET: 'End Target',
      ACTUAL_VALUE: 'Actual',
      END_VALUE: 'Project End',
      MAP: 'Map',
      TITLE: '{{code}} Dashboard',
      TITLE_PORTFOLIO: 'Dashboard',
      V_R: 'Reference value',
      V_C: 'Target value',
      FROM: 'from',
      TO: 'to',
      PROCESS: 'Progress...',
      COMPLETE: 'Completed',
      SIT_INDIC: 'Indicators Status',
      REP_ACTIVITE: 'Project Performance',
      PHY: '% of Target',
      FIN: '% of Budget',
      GRAPHE_PERF: 'Performance Graphic',
      SUIV_PTBA: 'Project Annual Performance',
      DASHBOARD: 'Dashboard',
      RESUME_PROJECT: 'Project Summary',
      EVOLUTION_TABLE: 'Indicator Progress ',
      REALIZE: '% achieved Project End',
      YEAR: 'Year',
      PERIOD: 'Period',
      INDICATORS: 'Indicators',
      ACTIVITIES: 'Activities',
      MSG_DBCLK: 'Double-click for more details '
    },
    TIME_DIVISION: {
      PERIOD: 'Period',
      PTBA: 'Current AWPB',
      PLAN_AN: 'Annual Plan',
      LEVEL: 'Level',
      LEVEL_1: 'Global',
      LEVEL_2: 'Annual',
      LEVEL_3: 'Period',
      ALLOW_TOTAL_GLOBAL: 'Allow total annual to exceed global',
      ALLOW_TOTAL_ANNUAL: 'Allow total period to exceed annual',
      START_LEVEL: 'Start Planning level',
      GLOBAL: 'Global',
      ANNUAL: 'Annual',
      PERIODIC: 'Period',
      HELP: {
        LEVEL_1: 'The global plan allows to set targets and budgets for the whole Project duration',
        LEVEL_2: 'The global plan is broken down in annual plans for each year of the Project ',
        LEVEL_3: 'Each annual plan is broken down in periodic plans according to the periods set previously '
      },
      BEGIN: 'Begin',
      END: 'End',
      LABEL: 'Label '
    },
    GEOGRAPHIC_PLAN: {

      IMPORT_SHP: {
        INFO: 'Please select the property that contains the name of the locations',
        PROPERTY: 'Property'
      },
      REFRESH_GEO_CODING_FILE: 'Update Geocoding from a file (geojson/shapefile)',
      REFRESH_GEO_CODING: 'Update Georeferencing',
      COUNTRY: 'Country',
      ADD__: 'Add location',
      ADD: 'Add sub location',
      SUM: 'Sum',
      MOY: 'Average',
      MAXI: 'Maximum',
      MINI: 'Minimum',
      AGGREGA: 'Aggregation',
      GENERATE: 'Import from Geographic Server',
      LEVEL: 'Level',
      CHAMPPERSO: 'User defined data fields',
      LABEL: 'Label',
      CONFIRM_DELETE: 'Do you want to delete this field?',
      LONG: 'Length',
      ERROR_UPDATE_LONG: 'Can\'t update the length.',
      CONFIRM_DELETE_LEVEL: 'Do you want to delete this level?',
      CONFIRM_DELETE_LOCALTION: 'Do you want to delete this location?',
      CODE: 'Code',
      DESCRP: 'Description',
      IMPORT: 'Import from Excel File',
      IMPORT_SHAPEFILE: 'Import from a shapefile or geojson file',
      ERROR_STRUC: 'An error has been detected in the file structure',
      ERROR_FORMAT: 'Please use a *.xsl! file',
      ERROR_CANADD: 'Localities are used!',
      UPLOAD_LABEL: 'Level {{level}} ...',
      EXPORT: 'Export to Excel '
    },
    INDICATOR_CAT: {
      TITLE_EDIT: 'EDIT CATEGORIES',
      TITLE: 'Disaggregation Categories',
      CODE: 'Code',
      LABEL: 'Name',
      AGGREGATE: 'Aggregation',
      OPTIONS: 'Options',
      OPTION: 'Option '
    },
    INDICATOR: {
      VALUE_TO_DISPLAY: 'Value to display',
      PORTFOLIOS: 'Portfolios',
      DATA_REFRESH: 'Refresh values',
      FIXED_DENOMINATOR: 'Fixed denominator',
      FROM_CALCULATE_DATA_NUM_DEMO: 'Numerator / Denominator',
      NUMERATOR: 'Numerator',
      DENOMINATOR: 'Denominator',
      FROM_CALCULATE_DATA_NUM_DEMO_HELP: '',
      WEIGHT: 'Weight',
      MOY_WEIGHT: 'Weighted Average',
      PROJECT_INDICATORS: 'Project\'s Indicators',
      PROJECT_INDICATOR_SHARE: 'Shared indicators',
      ADD_ITEM: 'Add Indicator',
      OPTION_CATEGORY: 'Option',
      PROJECTS: 'Projects',
      CROSS_INDICATOR: 'Cross Project Indicator',
      CALCULATE_DATA: 'Calculate',
      ADD_ACTIVITY: 'New Indicator',
      EDIT_ACTIVITY: 'Add from Existing Indicators',
      ADD: 'Add Indicator',
      LAYER_URL: 'Layers URL',
      EDIT_LAYERS: 'Enter Layer\'s URL',
      USE_COLORS: 'Map Colors settings',
      USE_LAYERS: 'Map layers settings',
      OUTPUT: 'Output',
      CROSS_PROJECT: {
        NAME: 'Name',
        DESCRIPTION: 'Description '
      },
      LINK_LOGFRAME: 'Link to the Logframe',
      NONE_AGGREGATE: 'None',
      UNIT_COST: 'Unit cost',
      INDICATOR_BASE: 'List of Indicators',
      SEARCH: 'Search an Indicator',
      SELECT_INDICATOR: 'Select an Indicator',
      SEARCH_LOCATION: 'Search a location',
      SEARCH_PERIOD: 'Search a period',
      PERFORMANCE_INDICATOR: 'Performance Indicators',
      CROSS_INDICATORS: 'Cross-cutting indicators',
      MAPPING: 'Mapping',
      CATEGORY: 'Disaggregation Categories',
      CROSS_DISAGGREGATION: 'Cross Disaggregation',
      PARALLEL_DISAGGREGATION: 'Parallel Disaggregation',
      DESCRIPTION_ONG: 'Details',
      POSSIBLEVALUE: 'Possible Values',
      LESS_THAN: 'Value must be less than or equal to {{valeur}}',
      GREAT_THAN: 'Value must be greater than or equal to {{valeur}}',
      EGAL_TO: 'Value must be equal to {{valeur}}',
      BEETWEEB: 'Value must be between {{valeur_min}} and {{valeur_max}}',
      ERROR_UPDATE: 'An error occurred while updating!',
      LEGEND: 'GIS Settings',
      LEGEND_COLOR: 'Map Colors Legend',
      ERROR_DECOUP_PLAN: {
        DATE_COLLECT: 'The collection date must belong to the period',
        TITLE: 'Error',
        VALUE: 'Please enter a value',
        DATE_RELEVE: 'Please enter the planned date',
        DATE_ALERT: 'Please enter the alert date',
        DATE_A: 'The alert date must be less than the planned date.',
        DATE_RELEVE_T: 'The planned date must be within the period and within the duration of the indicator',
        CATEGORY_VALUE: 'The sum of the disaggregated values is not equal to the aggregated value ',
        CATEGORY_VALUE_POSITIF: 'The disaggregated value must be greater or equal to 0',
        CATEGORY_VALUE_PARALLELE: 'Check that the value entered has been correctly disaggregated for each category',
        DENOMINATOR_ERROR: 'The value of the denominator must be different from 0'
      },
      ENTRY_DATA_HELP: 'Indicator results are entered manually',
      PROJECT: {
        VALUE: 'Value',
        VALUES: 'Values '
      },
      HELP: {
        TRANS: 'A common indicator is an indicator common to all activities (see details in the manual)'
      },
      COUNT_ALL: 'Counting',
      DATA_TABLE: {
        TEMPLATE: 'Data File',
        CALCULATION: 'Calculation',
        LOCALITIES: 'Geography',
        PERIODS: 'Period',
        QUERY: 'Query',
        CALCULATION_ITEM: 'Field '
      },
      DOCUMENTS: 'Documents',
      COMPOSITE: 'Data Source',
      SELECT: 'List',
      LOCATION: 'Location',
      CALMETHOD: 'Calculation method',
      CRITERIA: 'Criteria ',
      CODE: 'Code',
      INDICATOR_EDIT: 'Indicator details',
      KEY_KPI: 'Core',
      NAME: 'Name',
      NAME_FRENCH: 'Name (French)',
      CALCULATE_RATE: 'In the calculation of the % achievement {{lastLevel}}',
      NAME_ENG: 'Name (English)',
      DEF: 'Definition / Description',
      UNIT: 'Unit of measure',
      TYPE: 'Category',
      TYPE_LABEL: 'Type',
      CLASS: 'Type',
      KEY: 'Indicators (KPIs)',
      VAL_POSS: 'Possible values',
      FORMULA: {
        REQUIRED_ALL: 'All the indicators of the formula are mandatory',
        CLEAR: 'Clear',
        INVALID: 'Incorrect formula',
        NONEINDIC: 'No indicator in the formula '
      },
      BEGIN: 'Start date',
      END: 'End date',
      FREQUENCE: 'Frequency of Collection',
      NIV_GEO: 'Input geography level',
      _NIV_GEO: 'Geography',
      AGGREGA: 'Geography aggregation method',
      GROUP_CIBLE: 'Target Group',
      HYPOTHESE: 'Assumptions',
      MOY_VERIF: 'Means of verification',
      TECH_COLL: 'Collection technique',
      TARGET: 'Final Target (Project End)',
      REF: 'Baseline',
      TARGET_VAL: 'Targets',
      REAL_VAL: 'Actuals',
      VARIANCE: 'Variance',
      TRANS: 'Cross cutting Indicator',
      DISPLAY_TRANS: 'Only cross cutting Indicators',
      SIMPL: 'Simple',
      COMPO: 'Complex',
      CUMUL: 'Cumulative',
      COMPL: 'Complex',
      SUM: 'Sum',
      MOY: 'Average',
      MAXI: 'Maximum',
      MINI: 'Minimum',
      NONE: 'None',
      MOD_UN: 'Changing the unit will result in the loss of all records.\nDo you want to continue?',
      MOD_NG: 'Changing the geographic level will result in the loss of all records.\nDo you want to continue?',
      MOD_DATE: 'Changing dates could result in the loss of multiple records.\nDo you want to continue?',
      MOD_PER: 'Changing the periodicity could result in the loss of multiple records.\nDo you want to continue?',
      MOD_CLS: 'Changing the data source will result in loss of all records.\nDo you want to continue?',
      LABEL_VP: 'Label',
      POUND: 'Weight',
      PLAN: 'Indicator Planning',
      PLAN_: {
        TITLE: 'Setting Targets',
        VALUE: 'Target '
      },
      TRACK_: {
        ACTUAL_DATE: 'Collection Date',
        VALUE: 'Enter actual value',
        TITLE: 'Indicator Actual '
      },
      DATE_R: 'Planned Date ',
      VALUE: 'Value',
      BUDGET: 'Budget',
      COST: 'Cost',
      REL: 'Indicator Data Collection',
      RAPP: 'Indicator Report',
      PIC: 'Picture',
      IN_MON: 'Indicator Monitoring',
      MON: 'Monitor',
      DATA_FIN: 'Cost ',
      DATA_FIN_: 'Financial Data',
      FOLLOW_FIN: 'Track Budget/Cost',
      DISPLAY_CODE_LOC: 'Display geography codes',
      COMMENTS: 'Comments',
      RESPO: 'Responsible Entity',
      DISPLAY_PL: 'Display ',
      ACHEVE: '% Achieved',
      SOCIO_ECO: 'Socio-economic',
      SPATIAL: 'Spatial',
      GRAPHE_COMBINE: 'Mixed Graphs',
      CONFIRM_DELETE_RELEVE: 'Do you want to delete this data?',
      CONFIRM_DELETE_PLAN: 'Do you want to delete this planning?',
      CONFIRM_DELETE: 'Do you want to delete this indicator?',
      CONFIRM_DELETE_CROSS: 'Deleting a cross-cutting Indicator will erase all associated data',
      DATE_ALERT: 'Alert Date',
      ERRORS: {
        VP_LABEL: 'The Name is already assigned',
        VP_VALUE: 'The weight is already assigned '
      },
      GLOBAL: {
        TITLE: 'Indicator Baselines and Final Targets ',
        TITLE_: 'Target and actual Values ',
        LOCATION: 'Location',
        BEGIN: 'Start date',
        END: 'End date',
        TARGET: 'Final Target (Project End)',
        REF: 'Baseline',
        COMMENTS: 'Comments',
        ERROR: {
          REF_VALUE_DESEGREGATE: 'The sum of the disaggregated reference values is not equal to the reference value',
          TARGET_VALUE_DESEGREGATE: 'The sum of the disaggregated target values is not equal to the target value',
          DATE: 'The end date must be greater than the start date',
          Target_Value: 'Please enter the target!',
          REF_VALUE: 'Please enter the reference value!',
          DATE_D: 'Please enter the Start Date!',
          DATE_F: 'Please enter the End Date!'
        }
      },
      FROM_ENTRY_DATA: 'Direct Entry ',
      FROM_CALCULATE_DATA: 'Calculate from a formula ',
      FROM_DATABASE: 'Populate from a data file',
      OVERVIEW_MENU: {
        SETTINGS: 'Indicator Settings',
        BASELINE: 'Set Baselines',
        TARGET: 'Set Targets',
        ACTUAL: 'Track Actuals',
        REPORT: 'Report'
      },
      MENU: {
        EDIT: 'Edit',
        PLAN: 'Plan Targets',
        RELE: 'Track Indicator',
        FICHE: 'Indicator Reference Sheet',
        IMAGE: 'Image (300x400)',
        RIGHT: 'Set Permissions',
        GLOB_RELE: 'Baselines and Final targets',
        RAPPORT: 'Notes',
        SPATIAL: 'Map',
        LEGEND: 'Legend',
        WORKFLOW: 'Workflow ',
        CUSTOM_REPORT: 'Custom Reports'
      },
      RIGHT: {
        TITLE: 'Set user Permissions',
        BASELINE: 'Baselines & Final targets',
        TARGET: 'Plan Targets',
        ACTUAL: 'Track Indicator '
      },
      PLAN_TARGET: {
        TITLE: 'Indicator Targets '
      },
      TRACK: {
        TITLE: 'Indicator Tracking '
      },
      DASHBOARD: {
        TAB: 'Indicator Report',
        G: 'Total Project',
        Y: 'Year',
        P: 'Period',
        TIMESCALE: 'Timescale',
        DIFFERENCE: 'Difference',
        FINAL_TARGET: 'Project End Target',
        BASELINE: 'Baseline',
        TARGETS: 'Targets',
        ACTUALS: 'Actuals',
        RATE_TARGET: '% of Target',
        MAP: 'Map',
        TITLE2: '% of Targets by ',
        TITLE2_: '% of Targets by Projects',
        TITLE1_1: 'Monitoring Indicator – Achievements / Targets',
        TITLE1_2: 'Indicator Progress per year',
        LEGEND: '% of targets',
        TITLE: 'Indicator Report',
        PAGE1TITLE: 'Performance (Actuals vs Targets)',
        PAGE2TITLE: 'Indicator Progress',
        PAGE2SUBTITLE1: 'Change to Indicator',
        PAGE2SUBTITLE2: 'Indicator Progress for ',
        TITLE_1_3: 'Actuals vs Targets by ',
        TITLE_1_3_: 'Actuals vs Targets by Projects'
      },
      SHEET: {
        SHEET: 'sheet',
        TITLE: 'Indicator Reference Sheet',
        NAME: 'Name of the Indicator',
        DESCRP: 'Description',
        TYPE: 'Category',
        RAT_CL: 'Link to Log frame',
        U_MES: 'Unit of measure',
        PER_COL_SUI: 'Reporting frequency',
        NG: 'Geography Level',
        RESPO: 'Individual(s) Responsible',
        TECH: 'Collection technique',
        SRC: 'Data source',
        FORMULA: 'Calculation Formula',
        VAL_REF: 'Reference value',
        VAL: 'Value',
        EVO: 'Change to Indicator',
        FINAL_TARGET: 'Final target (Project End)',
        BASELINE: 'Baseline',
        TARGETS: 'Targets',
        ACTUALS: 'Actuals '
      },
      SPATIALS: {
        DATA: 'Data\'s Layers',
        LAYERS: 'Layers',
        TITLE: 'Layer Settings',
        LAYER: 'Layer',
        LABEL: 'Label',
        PERIODIC: 'Periodic',
        URL: 'URL',
        PROPERTY: 'Template',
        DATA_LAYER: 'Data Layer ',
        PIN: 'Marker',
        SERVICE: 'Service',
        SERVER_URL: 'Data source (Server Url) ',
        LAYERS_ID: 'Layer ID',
        ADD_PARAMS: 'Additional Parameters',
        AUTHORIZATION: 'Authentication',
        AUTHORIZATION_TOKEN: 'Token',
        DISPLAY_POPUP: 'Display information'
      },
      USER: 'Data entered by '
    },
    INDICATOR_VISUALISATION: {
      CATEGORIES: 'Category',
      DESEGREGATE_VALUE: 'Display Disaggregated Values',
      CIRCLE: 'Circle',
      DELTA: 'Default Layer',
      DATA: 'Data',
      LAYERS: 'Layers',
      LEGEND: 'Legend',
      FORMATTING: 'Formatting',
      BRIGHTNESS: 'Brightness',
      INDICATOR: 'Indicator',
      VALUE: 'Value',
      YEAR: 'Year',
      PERIOD: 'Period',
      TITLE: 'Spatial Visualization',
      SELECT_INDICATOR: 'Select an Indicator ',
      DISPLAY_DATA: 'Display data',
      SELECT_GEO: 'Select a geography level',
      SELECT_LAYER: 'Select Layers',
      COLLECTED_DATA: 'Collected Data',
      ERROR_INTERVAL: 'Error in the intervals'
    },
    LOGICAL_PLAN: {
      CROSS_CUTTING_KPIS: 'Display cross-cutting indicators',
      RATE_INDIC_HELP: 'By checking this option, the activities will be monitored only by means of the indicators in the Indicator module of DELTA.',
      ADD: 'Add',
      HAS_INDIC: 'Assign Indicator',
      LABEL: 'Name',
      LONG: 'length',
      ERROR_UPDATE_LONG: 'Can\'t update the length.',
      CONFIRM_DELETE_LEVEL: 'Do you want to delete this level?',
      CONFIRM_DELETE: 'Do you want to delete this data?',
      CODE: 'Code',
      DESCRP: 'Description',
      LABEL_C: 'Name',
      LABEL_L: 'Description',
      IMPORT: 'Import Log Frame',
      WEIGHT_AUTO: 'Automatic Weight',
      BUDGET: 'Budget',
      COST: 'Actual cost',
      SUM_WEIGHT: 'Σ Weight',
      BY: 'Based on',
      WEIGHT: 'Weight',
      RATE_INDIC: 'Activity progress measured by KPIs',
      ERROR_STRUC: 'An error has been detected in the file structure',
      ERROR_FORMAT: 'Please use a *.xsl! file',
      ERROR_CANADD: 'Items are used!',
      UPLOAD_LABEL: 'Level {{level}} ...',
      HELP: {
        CODE: 'Enter {{LIBELLE_NCL}} code',
        LABEL_C: 'Enter {{LIBELLE_NCL}}',
        LABEL_L: 'Description {{LIBELLE_NCL}}'
      },
      ERROR: {
        DELETE_ACTIVITY: 'Activity has data and cannot be deleted!',
        DELETE: 'Element has data and cannot be deleted!',
        DELETE_INDICATOR: 'At least one indicator is linked to the element!'
      }
    },
    FLAG: {
      LABEL: 'Color Legend',
      MIN_VALUE_INDICATOR: 'Indicator value greater or equal to',
      MIN: 'Min',
      MAX: 'Max',
      COLOR: 'Color',
      MIN_VALUE: 'Value greater than or equal to',
      ERROR: {
        LABEL: 'The label already exists',
        CHEVAL: 'The interval is overlapping with another one ',
        IN: 'The interval is included in another one',
        OUT: 'The interval includes another one '
      }
    },
    FRAME_RESULT: {
      DISPLAY_NO_CUMUL_DATA: 'Display non-cumulative values',
      TITLE_2: 'Results Framework (Current status)',
      TITLE_1: 'Results Framework (Annual progress)',
      FINAL_TARGET: 'Final Target',
      ACTUAL: 'Current',
      VALUE: 'value',
      DATE: 'Date',
      ALL_LOCATION: 'All locations',
      ALL_PROJECT: 'All projects',
      COMMENT: 'Comment',
      FORMAT: 'Standard Format',
      INDIC: 'Indicator',
      T_I: 'Indicator Type ',
      IMPACT: 'Impact',
      TARGET: 'Target',
      REF: 'Baseline',
      UNIT: 'Unit ',
      TREEVIEW: 'Tree View',
      LISTVIEW: 'Indicators Results Framework',
      ONLY_CROSS: 'Cross-cutting only',
      DISPLAY_LEVEL: 'Display Log frame Levels',
      DISPLAY: 'Display'
    },
    ACTIVITY: {
      UPLOAD_INDEX: 'Accounting software link',
      ENDING_ACTIVITY: 'This activity is completed',
      NAME_ACT: 'Description',
      LINK_PL: 'Logframe link',
      SETTINGS: 'Settings',
      YTD: 'Total',
      UNIT_: 'Unit',

      ONLY_CROSS: 'Cross',
      ONLY_PLAN: 'Planned',
      ONLY_FINANCIAL: 'Budget',
      COMPLETED_ACTIVITIES: 'Completed',
      ON_GOING_ACTIVITIES: 'Ongoing',
      NOT_CARRIED_OUT_ACTIVITIES: 'Not started',
      REVIEW_PLAN: 'Reviewed Plan',

      BUDGET_BREAKDOWN: 'Budget Breakdown',

      TREEVIEW: 'Treeview',
      DISPLAY_LEVEL: 'Display Levels',
      ENDING: 'Completed',
      DISPLAY: 'Display',
      ACTIVITIES: 'Activities',
      ADD: 'Add',
      DBL_PERIOD: 'Double click for periods',
      UPLOAD: {
        USE_BUDGET_BREAKDOWN: 'With budget breakdown',
        ACTIVITY: 'Activity',
        LOCALITY: 'Locality',
        DATE: 'Date',
        AMOUNT: 'Amount',
        CATEGORYBUDGET: 'Budget Category',
        FINANCING: 'Financing'
      },
      DISTRIBUTE: {
        CATEGORY: 'Category',
        FINANCE: 'Funding Source',
        AMOUNT: 'Amount',
        TITLE: 'Distribute amount',
        SUBTITLE: 'Amount to distribute',
        SELECT_CATEGORY: 'Select a category',
        SELECT_FINANCE: 'Select a funding source',
        ERROR_FINANCE: 'The total must equal the amount to distribute'
      },
      FROM: {
        FROM: 'From',
        DOCUMENT: 'Document Library',
        LOCAL: 'Local Drive',
        CLOUD: 'Cloud'
      },
      CROSS_ACTIVITY: 'Cross cutting Activity',
      DISPLAY_CROSS_ACTIVITY: 'Only cross cutting Activities',
      OUTPUT: 'Outputs',
      RATE: 'Rate',
      FUNDING_SOURCE: 'Funding Source',
      PROJECT: 'Project',
      AGENCIES: 'Implementing Agencies',
      RELATED_INDICATOR: 'Indicators',
      SEARCH: 'Search an Activity',
      URL: 'External Link',
      DOCUMENT: 'Document',
      DOCUMENTS: 'Documents',
      ERROR_DOCUMENT: 'Please, fill in all fields.',
      NAME: 'File Name',
      START: 'Start',
      END: 'End',
      UNIT: 'Unit of measure',
      TYPE: 'Category',
      DISPLAY_UNIT_COST: 'Display unit cost',
      UNIT_COST: 'Unit cost',
      CAL_AUTO: 'Automatic Calculation',
      BUDGET: 'Budget',
      COST_A: 'Actual Cost',
      RESPO: 'Entity Responsible',
      COMMENT: 'Comments',
      IMPUT: 'Inputs',
      TOTAL: 'Total',
      CONT_LOC: 'Follow contract by location',
      CONT_UNK: 'Only Contract',
      NUM: 'Number',
      OWN: 'Owner',
      OBJECT: 'Object',
      COST: 'Cost',
      SIGN: 'Signature',
      BEGIN_CONTRACT: 'Begin',
      END_CONTRACT: 'End',
      BEGIN_D: 'Begin Date',
      END_D: 'End Date',
      SELECT_ACT: 'Select a stakeholder',
      ACTOR_IMPL: 'Stakeholders',
      UPLOAD_FINANCE: 'Import Budget/Cost',
      UPLOAD_FINANCE_CATEGORY: 'Import Cost/Budget breakdown',
      DEFINITION: 'Details',
      NOTE: 'Notes',
      ROLE: 'Role',
      TOTAL_ALERT: 'Total must equal 100',
      STEP: 'Step',
      ERROR: {
        SOURCE: 'The percentage must be between 0 and 100 '
      },
      HELP: {
        CAL_AUTO: 'If checked, Budget and/or Actual Cost will be automatically calculated based on quantity and unit cost '
      },
      MENU: {
        PLAN: 'Plan Activity',
        EDIT: 'Edit',
        REAL: 'Track Activity',
        IMAGE: 'Image (300x400)',
        PERMISSIONS: 'Permissions',
        WORKFLOW: 'Workflow '
      },
      WORKFLOW: {
        TITLE: 'Workflow',
        LEVEL: 'Level',
        NAME: 'Name',
        COLOR: 'Color',
        USER: 'User',
        REAL: 'Track Activity',
        SELECT_USER: 'Select User '
      },
      PLAN: {
        PLANNING: 'Planning',
        TO_REVIEW: 'Revise',
        ERROR_PERIOD_YEAR: 'No period set for this Year',
        CONFIRM_DELETE: 'Do you want to delete this plan?',
        TITLE: 'Plan',
        REVIEW: 'Revised',
        INIT: 'Initial',
        REVIEW_: 'Revised Planning',
        INIT_: 'Initial Planning',
        GLOBAL: 'Global',
        DURATION: 'Planning',
        TYPE: 'Type',
        YEAR: 'Year',
        PERIOD: 'Period',
        PLANNED: 'Planned',
        LOCATION: 'Location',
        AMOUNT: 'Target',
        COST: 'Budget',
        BEGIN: 'Start',
        END: 'End',
        AMOUNT_PLANNED: 'Planned',
        COST_PLANNED: 'Budget',
        BEGIN_PLANNED: 'Planned Start',
        END_PLANNED: 'Planned End',
        ERROR_FINANCE: 'Percentages total must equal 100%',
        ERROR_SUP_QTE: 'Quantity must be greater than or equal to {{QTE_ERROR}}',
        ERROR_INF_QTE: 'The quantity must be less than or equal to {{QTE_ERROR}}',
        ERROR_SUP_BUDGET: 'The budget must be greater than or equal to {{BUDGET_ERROR}}',
        ERROR_INF_BUDGET: 'Budget must be less than or equal to {{BUDGET_ERROR}}',
        ERROR_PLAN_ANNUEL: 'Annual planning has not been defined',
        ERROR_DATE: 'The end date must be greater than the start date.',
        ERROR_QTE: 'The quantity must be positive',
        ERROR_BUDGET: 'The budget must be positive',
        ERROR_COST: 'The cost must be positive',
        ERROR_PERIOD: 'The period must be between {{BEGIN}} and {{END}}',
        HELP: {
          AMOUNT: 'Enter planned target',
          COST: 'Enter allocated budget '
        },
        SEARCH_LOCATION: 'Search a location '
      },

      REAL: {
        ACTUAL: 'Actual',
        TITLE: 'Track',
        LOCATION: 'Location',
        AMOUNT: 'Actual',
        COST: 'Cost',
        BEGIN: 'Start Date',
        END: 'End Date',
        AMOUNT_ACTUAL: 'Actual',
        COST_ACTUAL: 'Cost',
        BEGIN_ACTUAL: 'Actual Start',
        END_ACTUAL: 'Actual End',
        COMMENTS: 'Comments',
        HELP: {
          AMOUNT: 'Enter actual value',
          COST: 'Enter Cost '
        }
      },
      DASHBOARD: {
        REFERENCE: 'Baseline',
        FINAL: 'End Target',
        PROJECT: 'Project',
        YEAR: 'Year',
        HELP: {
          SHARE: 'Share Dashboard '
        },
        RATE_TARGET: '% of Target',
        RATE_BUDGET: '% of Budget',
        DISPLAY_COST: 'Display Cost/Budget',
        ALL_LOC: 'All Location',
        ANY_LOC: 'Any Location',
        BY_LOC: 'By Location',
        TITLE: 'Report',
        RATE_PHY: '% of Target',
        RATE_FINANC: '% of Budget',
        TITLE2: '% completion by location ',
        TITLE2_: '% completion by project ',
        TITLE1_1: 'Indicator Progress Summary',
        TITLE1_2: 'Indicator Progress per year ',
        TITLE_GRAPHE_SECOND_PERIOD: '% of Targets and Budgets reached by Location of ',
        TITLE_GRAPHE_SECOND_YEAR: '% of Targets and Budgets reached by Location - Year',
        TITLE_GRAPHE_SECOND_YEAR_PROJECT: '% of Targets and Budgets reached by Project - Year',
        TITLE_GRAPHE_SECOND: 'Activity Report ',
        TITLE_GRAPHE_SECOND_PROJECT: '% of Targets and Budgets reached by Project ',
        TITLE_GRAPHE_FIRST_YEAR: '% of Targets and Budgets reached (year {{year}}) Location : ',
        TITLE_GRAPHE_FIRST: '% of Targets and Budgets reached by Location ',
        TITLE_GRAPHE_FIRST_PROJECT: '% of Targets and Budgets reached by Project '
      },
      AWPB: {
        TITLE: 'Annual Workplan and Budget (AWPB)',
        TITLE_YEAR: 'Multi-annual Work program and Budget '
      }
    },
    CROSS_ACTIVITY: {
      CODE_ACTIVITY: 'Code',
      NAME_ACTIVITY: 'Name',
      DATE_BEGIN_ACTIVITY: 'Start',
      DATE_END_ACTIVITY: 'End',
      UNIT_COST_ACTIVITY: 'Unit Cost',
      CAL_AUTO: 'Automatic Calculation',
      COMMENTS_ACTIVITY: 'Comments'
    },
    PLAN_BUDGET_ACTIVITY: {
      ACTUAL_RATE: '% of Actual',
      BUDGET_RATE: '% of Budget',
      STATUS_GRAPH: 'Activities Status Graph',
      ACTUALS: 'Actual',
      TARGETS: 'Target',
      ACTIVITY_PROGRESS: '{{activity}} Progress'
    },
    GANTT: {
      TITLE: 'Activity Gantt Chart',
      LIMIT: 'Chronogram Limits',
      TYPE: 'Planning',
      START: 'Start',
      END: 'Finish',
      DISPLAY: 'Display top level bars',
      LEGEND: 'Legend',
      PLAN: 'Planned',
      ACTUAL: 'Actual',
      ASSIGN_TO: 'Select',
      ACTOR: 'Assigned To',
      GEO: 'Geography',
      ZOOM: 'Zooming',
      REAL: 'Activity Actual',
      ACTIVITY: 'Activity',
      ACTUAL_BEGIN: 'Start',
      ACTUAL_END: 'Completed',
      PLAN_BEGIN: 'Planned',
      PLAN_END: 'End',
      YEAR: 'Year',
      MONTH: 'Month',
      WEEK: 'Week',
      TIMELINE: 'Display Timeline',
      ON_GOING_ACTIVITY: 'On-going',
      DELAYED_ACTIVITY: 'Delayed',
      NOT_STARTED_ACTIVITY: 'Not started',
      COMPLETED_ACTIVITY: 'Completed'
    },
    CONTRACT: {
      LOC: 'Location',
      N_MAR: 'N° Contract',
      PROGRESS: 'Progress',
      STEP: 'Step '
    },
    PROCUREMENT: {
      CONFIRM_DELETE: 'Do you want to delete this procurement?',
      CODE: 'Code',
      LABEL: 'Name '
    },
    REPORT_CAT: {
      LABEL: 'Category',
      PERIOD: 'Periodicity',
      DELAI: 'Deadline',
      RECIPIENT: 'Principal recipient',
      FORMAT: 'Report Template',
      RESPO: 'Assigned to',
      SELECT: 'Upload',
      CHANGE: 'Change',
      REMOVE: 'Remove',
      CONFIRM_DELETE: 'Do you want to delete this report category?',
      PERSON_ALERT: 'Stakeholders to alert',
      HELP: {
        DELAI: 'Number of days',
        LABEL: 'Enter Document Category '
      }
    },
    DOCUMENT: {
      DELETE_FOLDER: 'Delete folder',
      DELETE_FILE: 'Delete file',
      SUCCESS_ADD_FOLDER: 'Folder added',
      DOCUMENT_DATA: 'Document data',
      COVER: 'Cover Page',
      VIEW: 'Views',
      DOWNLOAD: 'Downloads',
      NEWFOLDER: 'Create New Folder',
      NOT_VALID_EMAIL_OR_URL: 'Not a valid email or URL',
      CONTACT: 'Contact (email or website)',
      LANGUAGE: 'Language',
      ADD_INFO: 'Select a folder to upload a document',
      RIGHT_CLICK_INFO: 'Use right click to Add or Edit Item',
      DBL_CLICK_INFO: 'Double click to open Item',
      SEE_DETAILS: 'See Details',
      FILTERS: 'Filters',
      PAGING_SUMMERY: '{{current}} of {{total}} pages ({{count}} files)',
      FORMAT: 'Document Format',
      FORMATS: {
        TEXT: 'Text',
        IMAGE: 'Image',
        VIDEO: 'Video',
        AUDIO: 'Audio',
        PDF: 'PDF'
      },
      FILTER: {
        PROPERTY: 'Field',
        OPERAND: 'Condition',
        VALUE: 'Value'
      },
      ACTIONS: {
        ADD_FOLDER: 'New Folder',
        EDIT_FOLDER: 'Edit Folder',
        REFRESH: 'Refresh',
        ADD_FROM_FILE: 'Upload a document',
        ADD_FROM_LINK: 'Upload from a link',
        ADD_EDIT_DOC: 'Write a document'
      },
      FILE_NAME: 'File',
      REPORT: 'Content',
      INSERT_DELTA: 'Insert DELTA\'s item',
      CLS: 'Document Folder',
      MAIN: 'Document Description',
      ADD_FILE: 'New Folder',
      ADD_FILE_: 'Folder',
      ADD_DOC: 'Upload a document...',
      ADD_DOC_EXT: 'Upload from a link',
      ADD_DOC_RED: 'Write a document',
      CODE: 'Code',
      DESCRIP: 'Description',
      CONFIRM_DELETE_FOLDER: 'Are you sure to delete this folder and all its content?',
      CONFIRM_DELETE_THEME: 'Do you want to delete this theme?',
      CONFIRM_DELETE_TYPE: 'Do you want to delete this type?',
      CONFIRM_DELETE_PHYS: 'Do you want to delete this location?',
      CONFIRM_DELETE_FILE: 'Do you want to delete this document?',
      RECIPIENT: 'Document Sharing',
      RECIPIENT_TOOLTIP: 'Share Document with',
      REFERENCE: 'Reference',
      PUBLICATION: 'Published Date',
      PUBLIC: 'Display on portal',
      PUBLIC_HELP: 'If this option is selected, the document will be accessible on the public portal',
      TITLE: 'Title',
      TITLE_HELP: 'Enter Document Title',
      URL_HELP: 'Enter URL link to the document',
      AUTHOR: 'Author(s)',
      DOC_TYPE: 'Category',
      DOC_TYPES: 'Categories',
      THEME: 'Subject',
      THEMES: 'Subjects',
      PHYS_LOC: 'Location',
      SUMMARY: 'Summary',
      SUMMARY_HELP: 'Enter here a brief summary of the document',
      LABEL: 'Label',
      USERS: 'Users',
      UPLOAD: 'Upload a document',
      SELECT_DOC_TYPE: 'Select a document category',
      SELECT_PHYS_LOC: 'Select a location',
      SELECT_THEME: 'Select a subject '
    },
    COMMUNITIES: {
      CHATS: 'Converse',
      SUCCESS_ADD: 'Community added!',
      SUCCESS_UPDATE: 'Community updated!',
      SUCCESS_DELETE: 'Community deleted!',
      IMAGE: 'Picture',
      NAME: 'Name',
      DESCRIPTION: 'Description',
      MEMBERS: 'Members',
      EDIT_COMMUNITY: 'Edit Community',
      REQUIRED: 'Required!',
      DOUBLE: 'Name already assigned!'
    },
    DOCUMENT_CALENDAR: {
      NEW_DOC: 'Add a new Document',
      EXIST: 'Select from Document Library',
      DOCUMENT: 'Document',
      SELECT_FOLDER: 'Select a Folder to store the document '
    },
    PHOTOLIBRARY: {
      PHOTOS: 'Photos',
      VIDEOS: 'Videos',
      REF: 'Reference',
      TITLE: 'Title',
      DATE: 'Date',
      PUBLIC: 'Display on portal',
      LOCATION: 'Location',
      LAT: 'Latitude (GPS)',
      LONG: 'Longitude (GPS)',
      AUTHOR: 'Author',
      SUMMARY: 'Summary',
      URL_VIDEO: 'URL Video (YouTube)',
      ADD_DOC: 'Add Photo',
      ADD_DOC_: 'Photo',
      FOLDER: 'Folder',
      ADD_FILE_: 'Folder',
      ADD_FILE: 'New folder',
      ADD_VIDEO: 'Add Video',
      ADD_VIDEO_: 'Video',
      CONFIRM_DELETE_PHOTO: 'Do you want to delete this photo?',
      CONFIRM_DELETE_VIDEO: 'Do you want to delete this video?',
      TITLE_ERROR_DELETE: 'The folder is not empty'
    },
    G_U: {
      RIGHT_INFO: 'Specify the user profile according to the projects to which he has access',
      PASSWORD_CHANGED: 'The password has been changed',
      DISPLAY_DASHBOARD: 'Display dashboards',
      DISPLAY_MAP: 'Display Map',
      FUNCTION: 'Function',
      STANDARD_USER: 'Standard User',
      ADMIN_USER: 'Administrator',
      VIEW_USER: 'Viewer',
      COLLECT_USER: 'Data Collect',
      COLLECTER_USER: 'Data Collect',
      CUSTOM_USER: 'Customized Rights',
      NO_ACCESS: 'No Access',
      NO_ACCESS_PORTFOLIO: 'No access to projects',
      PROJECT: 'Project',
      DETAIL: 'User Details',
      PROFILE: 'User Profile',
      EMAIL: 'Email',
      NAME: 'Last Name',
      SUP: 'SuperUser',
      PRENOM: 'First Name',
      AGENCIES: 'Implementing Agency',
      MDP: 'Password',
      CONF: 'Confirm Password',
      ACTOR: 'Stakeholders',
      STAFF: 'Stakeholder',
      CHANGE_PASSWORD: 'Change password',
      NEW_PASSWORD: 'New password',
      SEARCH: 'Search by email and last name',
      SELECT_AGENCIES: 'Select an implementing agency',
      SELECT_STAFF: 'Select Stakeholder',
      EXPIRE_HELP: 'Password Expiry Date',
      EXPIRE: 'Expiry Date',
      ACTORLIST: 'Assign Stakeholders',
      CONFIRM_DELETE: 'Do you want to delete this user?',
      ERROR_EMAIL: 'The user already exists',
      ERROR_PSW: '8 characters minimum',
      ERROR_CONF_PSW: 'Passwords are not the same',
      VALIDATION_RIGHT: 'Data Validation',
      VALIDATION_RIGHT_SET: 'Set Data validation Permissions',
      VALIDATION_RIGHT_TITLE: 'Select users whose data are validated by {{users}}',
      PORTFOLIO_USER: 'Portfolio access',
      EDIT_PROFIL: 'Click for details',
      EDIT_PROFIL_USER: 'User account',
      ERROR_PSW_NOT_CONFORM: 'Password does not match',
      ERROR_PSW_NOT_EXIST: 'Not authorize to update',
      ERROR__NOT_ALLOW: 'Not authorize to update',
      SEX: 'Sex',
      MALE: 'Male',
      FEMALE: 'Female',
      UNLOCK: 'Unlock',
      LOCK: 'Lock',
      HELP: {
        EMAIL: 'Email is used for login '
      },
      FEEDBACK: 'Suggestions',
      FEEDBACK_ADD: 'Share your suggestions',
      FEEDBACK_DETAIL: "Feedback details"

    },
    GR_U: {
      HELP: {
        TITLE: 'Create Group and assign Geography and Log frame to group members',
        CODE: 'Enter Group Name',
        LABEL: 'Enter Group Description '
      },
      CODE: 'Name',
      LABEL: 'Description',
      CONFIRM_DELETE: 'Do you want to delete this group?',
      USER: 'Assign Users',
      LOCATION: 'Locations',
      LOCATION_ONLY: 'location only',
      LOCATION_ALL: 'Sub locations',
      C_ONLY: 'Only',
      C_ALL: 'Include sub items '
    },
    RIGHT: {
      HELP: {
        TITLE: 'Assign User Permissions and Privileges according to their profiles',
        LABEL: 'Enter profile name ',
        DESCRIPTION: 'Description'
      },
      USER: 'Users',
      ADMIN: 'Administrator',
      PRIVIL: 'Permissions',
      LABEL: 'Profile',
      DESCRIPTION: 'Description',
      CONFIRM_DELETE: 'Do you want to delete this profile?'
    },
    TYPACTIVITY: {
      LST_TYP: 'Types of Activity',
      ED_TYP: 'Details Type of indicator',
      COL_COLOR: 'Color',
      COLOR_BLUE: 'Blue',
      COLOR_CRYAN: 'Cyan',
      COLOR_GREEN: 'Green',
      COLOR_YELOW: 'Yellow',
      COLOR_ORANGE: 'Orange',
      COLOR_RED: 'Red',
      COLOR_PINK: 'Pink',
      COLOR_PURPLE: 'Purple',
      COLOR_GREY: 'Grey',
      COLOR_BLACK: 'Black',
      CONFIRM_DELETE: 'Do you want to remove this activity type?'
    },
    FORMULAR: {
      SURVEY_DELETE: "Crush the seizures",
      SURVEY_VERSION: "Version",
      IMPORT_SURVEY: "Survey solutions forms",
      IMPORT_INTERVIEW: "interview(s)",
      SURVEY_JETON: "Modify the token",
      IMPORTED_INTERVIEW: "Imported interview (s)",
      IMPORTE_DATA: "Import",
      SURVEY_JETON_LIB: "Survey Solutions token",
      SURVEY_SET_JETON: "Generate a token?",
      SURVEY_USER: "Username",
      SURVEY_PASSE: "Passwords",
      SURVEY_GENERER: "Generate",

      COLLECT_MODE_SURVEY: 'Import data from Survey Solutions',
      COLLECTORS: 'Collectors',
      LINK_GEO: 'Link to the geographic plan',
      ERRORVIDEVAL: 'Please enter option and value',
      ERRORVIDE: 'Please enter the option',
      ERROREXISTEVAL: 'Option or value already exists',
      ERROREXISTE: 'Option already exists',

      ARCHIVATE: 'Archive',
      KOBO_API_EDIT: 'KoBo Toolbox API Token',
      ADD: 'Add Form',
      ADD_FROM_KOBO: 'Import from KoBo Toolbox',
      KOBO_API: 'API Token',
      ERROR_KOBO_API: 'Incorrect API token',
      ALREADY_SAVE: 'Always save',
      HOW_TO_FIND_TOKEN: 'How do I find the API Token?',
      REFRESH: 'Refresh data',
      KOBO_FORM_LIST: 'List of projects',

      FORM_SAVED: 'Form Saved!',
      SUCCESS_IMPORT: 'Data has been imported!',
      FAIL_IMPORT: 'Importation failed',
      CHART_STRUCTURE: 'Settings',
      CHART_STRUCTURE_TITLE: 'Chart Settings',
      SURVEY: 'Survey',
      ERROR: {
        NAME_REQUIRED: 'Name is required',
        OPTION_REQUIRED: 'Option is required',
        UNKNOWN_ERROR: 'An error occurred while importing',
        KOBO_TOKEN_ERROR: 'The api token is not correct'
      },
      SAVE: 'Save',
      SAVE_MSG: 'Record saved',
      DOWNLOAD_QR_CODE: 'Download QR code',
      GENERATE_URL: 'Generate URL for external entry',
      COPIED_URL: 'Copied',
      COPY_URL: 'Copy',
      COLLECTIONS: 'COLLECTION FORMS',
      COLLECTION: 'COLLECTION FORMS',
      DISPLAY_IMAGE: 'Picture',
      LAYOUT: 'Layout',
      STRUCTURE: 'Fields',
      ADD_SECTION: 'New section',
      ADD_DATA: 'Add Data',
      COLOR: 'Color',
      SEARCH: 'Search a Form',
      SEARCH_ANALYZE: 'Search an analytic',
      TITLE: 'Data Collection - Design Forms',
      SELECT_TEMPLATE: 'Select a form',
      CONDITION: 'Condition',
      CONDITION_HELP: 'Set condition for the field',
      PRESET: 'Pre-set Options',
      HELP_OPTION: 'Enter an option',
      TITLE_I: 'Form Name',
      PRIVATE: 'Private',
      SINGLUAR: 'One Item ',
      MAIN: 'Unique',
      PLURAL: 'several Items',
      MULTILINE: 'Multi line',
      LIMIT: 'character Limit',
      FORMAT: 'Format',
      LIST_OPTION: 'LIST OPTIONS',
      MULTI_CHOISE: 'Allow multiple selection',
      CUSTOM_FIELD: 'List options',
      CUSTOM_DATA: 'Forms',
      REQUIRED: 'Required',
      REQUIRED_NO_FIELD: 'Please drag a field into this area',
      COLUMN: 'Column',
      CONFIRM_STRUCTURE: 'At least one sub form has been deleted.\nWill you continue?',
      ERROR_GLOBAL: 'Some required fields are not filled',
      USERS: 'Users',
      IMPORT_DATA: 'Import Data',
      COLLECT_DATA: 'Data Collection & Review',
      PIN: 'Marker',
      CHOISE_COLLECT_MODE: 'Select Data collection mode',
      COLLECT_MODE_INTERN: 'Display Data Collection Screen',
      COLLECT_MODE_MOBILE: 'Collect Data via Mobile Device',
      COLLECT_MODE_LINK: 'Send Link for External Data Entry',
      COLLECT_MODE_EXCEL: 'Import Data From Excel File',
      FIELD: {
        EXCEL: 'Excel',
        PPTX: 'PowerPoint',
        TEXT: 'Text',
        LIST: 'List',
        DATE: 'Date',
        NUMBER: 'Number',
        CHOISE: 'Choice',
        FILE: 'File',
        PICTURE: 'Picture',
        VIDEO: 'Video',
        AUDIO: 'Audio',
        LOCATION: 'GPS coordinates',
        FORMULAR: 'Sub Form ',
        PATH: 'Path',
        SCORE: 'Score',
        OPTION: 'Option',
        VALUE: 'Value',
        POLYGON: 'Polygon'
      },
      MENU: {
        STRUCTURE: 'Structure',
        RECORD: 'Record(s)',
        RIGHT: 'Permissions ',
        EDIT: 'Edit Form',
        COLLECT: 'Collect Data',
        REVIEW: 'Review Data',
        ANALYZE: 'Analyze & Report'
      },
      CONFIRM_DELETE: 'Do you want to delete this Template?',
      CONFIRM_RECORD_DELETE: 'Do you want to delete this item?',
      OWN: 'My Template(s)',
      ALL: 'All Templates',
      REQUEST: 'Analytics',
      REQUEST_TITLE: 'Title',
      TEMPLATE: 'Form',
      ANALYSE: {
        SAVE: 'Save',
        TABS: {
          TABLE_PIVOT: 'Pivot Table',
          TABLE_SIMPLE: 'Table',
          GRAPH: 'Graph',
          TABLE: 'Table',
          MAP: 'Map ',
          PIE_CHART: 'Pie Chart',
          MIXED_CHART: 'Mixed Charts'
        },
        TABLE: {
          COLUMNS: 'Columns '
        },
        MAP: {
          CATEGORY: 'Category',
          VALUE: 'Value',
          FIELD: 'Field',
          LEGEND: 'Legend '
        },
        CHART: {
          FIELD: 'Field',
          CATEGORY: 'Category',
          OPERATION: 'Aggregation',
          NONE: 'None',
          COUNT: 'Count',
          VALUE: 'Value',
          SUM: 'Sum',
          AVG: 'Average',
          MAX: 'Maximum',
          MIN: 'Minimum',
          VARIANCE: 'Variance',
          TYPE: 'Type '
        },
        TYPE_CHART: {
          AREACHART: 'Area Chart',
          BARCHART: 'Bar Chart',
          COLUMNCHART: 'Column Chart',
          LINECHART: 'Line Chart',
          PIECHART: 'Pie Chart'
        },
        LAYERS: {
          TITLE: 'Layers',
          NAME: 'Name',
          FIELD: 'Field',
          COLOR: 'Color'
        }
      }
    },
    ANALYSE: {

      BACKGROUND_COLOR_TITLE: 'Title background color',
      COLOR_TITLE: 'Title color',
      SUCCESS_ADD: 'Report has been added',
      FIX_VALUE: 'Fix Value',
      VARIABLE_VALUE: 'Variable Value',
      VARIABLE_NAME: 'Variable Name',
      VARIABLES: 'Variables',
      AS_PORTFOLIO: 'Portfolio',
      AS_PORTFOLIO_: 'Share with Portfolio',
      COLLECTED_DATA: 'Collected data',
      SERIES: 'Series',
      SEARCH: 'Search a report',
      SWITCH: 'Set variables in column',
      TYPE_REP: 'Type Representation',
      TYPE_1: 'Table',
      TYPE_2: 'Graph',
      TYPE_3: 'Data',
      DIM: 'Based on',
      DIM1: 'Rows',
      DIM2: 'Display data by',
      COLUMNS: 'Columns',
      FIELD: 'Field',
      INDICATOR: 'Indicator',
      DESCRIPTION: 'Description',
      SETTINGS: 'Settings',
      FILTERS: 'Filters',
      SELECT_ALL: 'Select All',
      UNSELECT_ALL: 'Unselect All',
      FIELDS: {
        DATE: {
          BEGIN_PLAN: 'Planned Start',
          END_PLAN: 'Planned End',
          BEGIN_ACTUAL: 'Actual Start',
          END_ACTUAL: 'Actual End',
          T: 'Completion'
        },
        PHYSIC: {
          P: 'Target',
          R: 'Actual',
          E: 'Variance',
          T: '% of Target '
        },
        PHYSIC_INDIC: {
          P: 'Target Value',
          R: 'Actual Value '
        },
        BUDGET: {
          P: 'Budget',
          R: 'Actual cost',
          E: 'Budget Variance',
          T: '% of Budget '
        }
      },
      MAIN: 'Parameters',
      PUBLIC: 'Public',
      AS_DASHBORD: 'Save in Dashboards',
      OPERATION: 'Operation',
      CONDITION: 'Filters',
      PG: 'Locations',
      TITLE: 'Report Title',
      DESCRIPTION_REPORT: 'Report Description',
      PERIODE: 'Period',
      FINANCING_SOURCE: 'Financing Source',
      CAT_BUDGET: 'Budget Category',
      RATE: 'Rate',
      RUBRIQUE: 'Field',
      SUBRUBRIQUE: 'Sub-field',
      VALEUR: 'Value',
      PARAMETER: 'Parameter',
      DEFAULT_VALUE: 'Default Value',
      ERROR_SYNTAXE: 'Syntax error',
      ERROR_COND_EXIST: 'Some conditions do not exist',
      ERROR_COND_NOT_EXIST: 'Not all conditions are used',
      LIST_COND: 'Add Filter',
      RUBOP: {
        EGAL: 'Equal',
        DIFF: 'Not Equal',
        SUPP: 'Greater than',
        SUPP_EGAL: 'Greater or Equal to',
        INF: 'Less than',
        INF_EGAL: 'Less than or equal to',
        BEGIN: 'Start with',
        NOT_BEGIN: 'Do not start with',
        END: 'Ends with',
        NOT_END: 'Do not finish with',
        CONTENT: 'Contains',
        NOT_CONTENT: 'does not contain',
        NULL: 'Is null',
        NOT_NULL: 'is not null '
      },
      RUB: {
        ACTEUR: 'Responsible Entity',
        LOCAL: 'Location Code',
        CAD_LOG: 'Code \'{{planlog}}\' ',
        YEAR: 'Year ',
        FINANCING_SOURCE: 'Financing Source Code',
        CAT_BUDGET: 'Budget Category'
      },
      OPS: {
        MIN: 'Minimum',
        MAX: 'Maximum',
        AVG: 'Average',
        SUM: 'Sum',
        NONE: 'None',
        COUNT: 'Count '
      },
      ENCH_COND: 'Chaining filters AND / OR',
      CONFIRM_DELETE: 'Do you want to delete this view?',
      TYPE: {
        TABLE: 'Table',
        DONUT: 'Pie Chart',
        COU_HISTO: 'Combined Chart',
        WIDGET: 'Widget '
      },
      SERIE: 'Series',
      FILL: 'Fill',
      TYPE_COU_HISTO: {
        LINE: 'Line Chart',
        BAR: 'Bar Chart '
      }
    },
    CUSTOM_DASHBOARD: {
      DISPLAY_MAIN_PAGE: 'Display on Main page',
      SEARCH: 'Search Dashboards',
      ACTIVITIES: 'Activities',
      INDICATORS: 'Indicators',
      FORMS: 'Forms',
      GLOBAL_TITLE: 'Dashboards',
      TITLE: 'Title',
      VIEWS: 'Select Analytics',
      VIEWS_OPTION: 'List of Queries',
      ANALYSE: 'Analysis',
      HEIGHT: 'Height',
      WIDTH: 'Width',
      CONFIRM_DELETE: 'Do you want to delete this dashboard?',
      DISPLAY: 'Display all Dashboards',
      OWN: 'My Dashboards',
      FAVORITE: 'My Favorite Dashboards',
      OTHER: 'Others '
    },
    AGENDA: {
      LIST: 'List',
      MONTH: 'Month',
      WEEK: 'Week',
      DAY: 'Day',
      TODAY: 'Today',
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      BEGIN: 'Begin',
      END: 'End',
      INFO: 'Task / Event',
      USER: 'Share with '
    },
    COMMENT: {
      NEW_COMMENTS: 'New Posts',
      SUCCESS_ADD: 'The comment has been added',
      COMMENT: 'Comments',
      SHARE: 'Share Comments',
      USER: 'Users',
      WRITE: 'Write comment',
      FILE: 'Team Chatroom',
      TO_COMMENT: 'Comment',
      CANCEL_TO_COMMENT: 'Cancel',
      DISPLAY_COMMENT: 'Display comments',
      HIDE_COMMENT: 'Hide comments',
      DISPLAY_MORE_COMMENT: 'Display more comments '
    },
    WORKFLOW: {
      MENU: {
        ACTIVITIES: 'Activities',
        INDICATOR: 'Indicator',
        FORM: 'Form',
        DOCUMENTS: 'Documents '
      },
      ACTIVITIES: {
        TRACK: 'Track'
      },
      VALIDATE: 'Validate Data',
      COMMENTS: 'Comments '
    },
    DELETEDITEMS: {
      OBJECT: 'Item',
      PROJECT: 'Project',
      DATE: 'Date',
      REFRESH: 'Restore '
    },
    AUDITS: {
      USERS: 'Users',
      FILTER: 'Filter',
      ACTIONS: 'Actions',
      DELETE: 'Delete',
      UPDATE: 'Update',
      CREATE: 'Create',
      TO_DATE: 'To',
      FROM_DATE: 'From',
      PAGING_SUMMERY: '{{current}} of {{total}} pages ({{count}} items)',
      ACTION: 'Action',
      ENTITY: 'Entity',
      DESCRIPTION: 'Description',
      USER: 'Utilisateur',
      DATE: 'Date',
      ENTITIES: {
        TYPE_MISSION: 'Mission Type',
        MISSION: 'Mission',
        DISBURSEMENT: 'Disbursement',
        AGREEMENT: 'Financing',
        AGENCIES: 'Agency',
        PROJET: 'Project',
        UNITES: 'Unit',
        ACTEUR: 'Stakeholder',
        FINANCEMENT: 'Funding Source',
        NIVEAUGEO: 'Level (Geographic)',
        CHAMPSPERSO: 'Custom Field',
        LOCALITE: 'Locality',
        NIVEAUCL: 'Level (Logic)',
        CADRELOGIQUE: 'Log Frame',
        TYPEACTIVITY: 'Activity Category',
        DERNIERNIVEAU: 'Activity',
        TYPEINDICATEUR: 'KPIs Category',
        INDICATEURSCATEGORIE: 'Disaggregation Category',
        INDICATEURSCATEGORIEOPTION: 'Option (Disaggregation Category)',
        INDICATEURS: 'Indicator',
        ANALYSE: 'Custom Report',
        DASHBOARD: 'Dashboard',
        TYPERAPPORT: 'Document Category',
        CALENDARRAPPORT: 'Report',
        CLASSEUR: 'Folder',
        RAPPORTS: 'Document',
        CLASSEURPHOTOTHEQUE: 'Folder (Media Library)',
        PHOTOTHEQUE: 'Media Library',
        FORMULAR: 'Form',
        RECORDS: 'Record (Form)',
        FORMLARREQUEST: 'Query (Form)',
        USER: 'User',
        GROUPUSER: 'Users Group',
        PROFILUSER: 'Users Profile',
        CATEGORYBUDGET: 'Budget Category',
        DISTRIBUTEAMOUNT: 'Ventilation ',
        GLOBAL_PLAN: 'Planning (Global)',
        ANNUAL_PLAN: 'Planning (Annual)',
        PERIODIC_PLAN: 'Planning (Period)',
        REALIZE: 'Activity Tracking'
      }
    },
    SECURITY: {
      ATTEMPTS: 'Successive failed attempts',
      TIMEOUT: 'Timeout'
    },
    MISSIONS: {
      TITLE_MISSION: 'Mission',
      SELECT_TYPE: 'Select a type of mission',
      LIEU_MISSION: 'Location',
      OBJECT_MISSION: 'Object',
      MEMBERS: 'Members',
      SELECT_MEMBERS: 'Select the members of the mission',
      MISSION: 'Mission',
      TYPE: 'Type',
      DATE_BEGIN: 'Start Date',
      DATE_END: 'End Date',
      RESPONSIBLE: 'Responsible',
      REPORT: 'Report',
      NO_REPORT: 'No associated document'
    },
    REQUEST: {
      DATE_COURRIER: 'Request date',
      COMMENTAIRE: 'Comments',
      DOCS_SUPPORT: 'Support document',
      STATUT: 'Request status',
      DATE_STATUT: 'Status date',
      DATE_ECHEANCE: 'Deadline',
      BUDGET: 'Budget',
      MONTANTDMD: 'Amount claimed (FCFA)',
      SITUATION_REQUETE: 'Situation Request',
      DATE: 'Date',
      RESPONSABLE: 'Responsible',
      ECHEANCE: 'Due date',
      PJ: 'Attachment',
      OBJET: 'Object of the request ',
      // OBJET: 'Subject of the request ',
      // MONTANTDMD: 'Amount requested',
      PERSCONTACTS: 'People to alert',
      ADDRQTE: 'Adding a request',
      ADDSUIVIRQTE: 'Add query tracking',
      HISTORQ: 'Tracking history'
    },
    RISK: {
      CRITICALITY: {
        TITLE: 'Criticality',
        HIGH: 'High',
        MEDIUM: 'Medium',
        WEAK: 'Low'
      },
      DELAY: 'Duration (days)',
      TYPE: 'Type',
      RISK: 'Risk',
      PROBLEM: 'Problem',
      NATURE_RISK: 'Nature',
      CURRENT_POSITION: 'Save current position',
      CURRENT_POSITION_MARK: 'Move marker',
      CURRENT_POSITION_MARK_: 'on the map to retrieve the position',
      DESCRIPTION_: 'Description of',
      DESCRIPTION: 'Description',
      IMPACT_FINANCE: 'Financial impact',
      IMPACT: 'Impact',
      RESOLVE_DATE_RISK: 'Resolution Date',
      IMPACT_DELAY_: 'Impact Delay',
      IMPACT_DELAY: 'Timeline impact',
      TITLE_RISK: 'Risk/Problem',
      DELAY_: 'Delay',
      PROBABILITY: 'Probability',
      DATE: 'Date',
      STATUS: 'Status',
      RECOMMANDATIONS: 'Recommendations',
      IMPACTS: {
        HIGH: 'High',
        MEDIUM: 'Medium',
        LOW: 'Low'
      },
      STATUS_LIST: {
        UNRESOLVED: 'Unresolved',
        IN_PROGRESS: 'In progress',
        RESOLVED: 'Resolved'
      }
    },
    PROBLEM: {
      PROBLEM: 'Problems encountered',
      DATE_PROBLEM: 'Date',
      NATURE_PROBLEM: 'Nature',
      DESCRIPTION: 'Description',
      IMPACT_DELAY_: 'Impact Delay',
      IMPACT_DELAY: 'Timeline impact',
      IMPACT_FINANCE: 'Financial impact',
      RESPONSIBLE: 'Reported by',
      RECOMMANDATIONS: 'Recommendations'
    },

    RECOMMENDATION: {
      DESCRIPTION: 'Recommendation',
      RESPONSIBLE: 'Responsible',
      DEADLINE: 'Deadline',
      STATUS: 'Status',
      STATUS_LIST: {
        EXECUTED: 'Executed',
        NOT_EXECUTED: 'Not Executed',
        IN_PROGRESS: 'In Progress'
      }
    },
    EXPORT_DATA: {
      TYPES: 'Data types',
      EXPORT: 'Data export',
      LOADED: 'Generated data',
      SHARE: 'Share with...'
    },
    FEEDBACK: {
      EMPTY_FEEDBACK: 'No suggestions',
      NOTE_LEVEL: 'What is your satisfaction level?',
      LAST_UPDATE_DATE: 'Last updated on {{date}}',
      SEARCH_FEEDBACK: 'Search by subject or message',
      SEND: 'Send',
      BACK: 'Back',
      STATUS_SEND: 'Sending status',
      FORM: {
        OBJECT_PLACEHOLDER: 'Subject',
        MESSAGE_PLACEHOLDER: 'Message',
        ERROR_SEND_FEEDBACK: 'Sending failed, please try again',
        SUCCESS_SEND_FEEDBACK: 'Your suggestion has been sent successfully'
      }
    }
  });

  // Appeler la fonction pour récupérer les traductions
  var CODE_LANG = (cov_22vidcdndn.s[2]++, 'en');
  var CODE_LANG_STORE = (cov_22vidcdndn.s[3]++, 'TRANSLATE_' + CODE_LANG);
  var CHECK_TRANSLATE = (cov_22vidcdndn.s[4]++, 'DELTA_TRANSLATE');

  var translated = (cov_22vidcdndn.s[5]++, false);
  // Translate not active use data origine
  cov_22vidcdndn.s[6]++;
  if ((cov_22vidcdndn.b[1][0]++, localStorage.getItem(CHECK_TRANSLATE)) && (cov_22vidcdndn.b[1][1]++, localStorage.getItem(CHECK_TRANSLATE) == 0)) {
    cov_22vidcdndn.b[0][0]++;
    cov_22vidcdndn.s[7]++;

    translated = true;
    cov_22vidcdndn.s[8]++;
    console.log('TRANSLATE ORIGNINALE ' + CODE_LANG);
    cov_22vidcdndn.s[9]++;
    fetchTranslations(data, false);
  } else {
    cov_22vidcdndn.b[0][1]++;
  }

  cov_22vidcdndn.s[10]++;
  if (!translated) {
    cov_22vidcdndn.b[2][0]++;
    cov_22vidcdndn.s[11]++;

    // Translate activated
    if ((cov_22vidcdndn.b[4][0]++, !localStorage.getItem(CODE_LANG_STORE)) || (cov_22vidcdndn.b[4][1]++, angular.isUndefined(localStorage.getItem(CODE_LANG_STORE))) || (cov_22vidcdndn.b[4][2]++, localStorage.getItem(CODE_LANG_STORE) == '')) {
      cov_22vidcdndn.b[3][0]++;


      var params = (cov_22vidcdndn.s[12]++, {
        action: "getTranslateDataByLang",
        li_bksb: "Connexion",
        lang_bksb: CODE_LANG,
        method: 1,
        t_bksb: 'bk' + new Date().getTime() + 'sb',
        n: window.location.search.substr(1),
        space: (cov_22vidcdndn.b[5][0]++, localStorage.getItem('DELTA_TOKEN')) || (cov_22vidcdndn.b[5][1]++, null),
        data: JSON.stringify(data)
      });
      var URL = (cov_22vidcdndn.s[13]++, "/app/data/web_server.php");
      // Convertir l'objet params en une chaîne JSON
      var CONTENT = (cov_22vidcdndn.s[14]++, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      });

      // Effectuer la requête POST
      cov_22vidcdndn.s[15]++;
      fetch(URL, CONTENT).then(function (response) {
        cov_22vidcdndn.f[1]++;
        cov_22vidcdndn.s[16]++;

        // console.log('response 1');
        // console.log(response);
        if (response.ok) {
          cov_22vidcdndn.b[6][0]++;
          cov_22vidcdndn.s[17]++;

          return response.json();
        } else {
          cov_22vidcdndn.b[6][1]++;
        }
        cov_22vidcdndn.s[18]++;
        console.log('Erreur HTTP : ' + response.status);
        cov_22vidcdndn.s[19]++;
        return { error_state: true };
      }).then(function (dataReponse) {
        cov_22vidcdndn.f[2]++;

        var TAILLE_ORIGINE = (cov_22vidcdndn.s[20]++, Object.keys(data).length);
        var TAILLE_NEW = (cov_22vidcdndn.s[21]++, Object.keys(dataReponse).length);
        cov_22vidcdndn.s[22]++;
        if ((cov_22vidcdndn.b[8][0]++, dataReponse) && (cov_22vidcdndn.b[8][1]++, dataReponse.COUNTRIES) && (cov_22vidcdndn.b[8][2]++, angular.isUndefined(dataReponse.error_state)) && (cov_22vidcdndn.b[8][3]++, TAILLE_NEW >= TAILLE_ORIGINE - 10)) {
          cov_22vidcdndn.b[7][0]++;
          cov_22vidcdndn.s[23]++;

          console.log('(200) - TRANSLATE OK  ' + CODE_LANG + ': (' + TAILLE_NEW + ' - vs - ' + TAILLE_ORIGINE + ')');
          cov_22vidcdndn.s[24]++;
          fetchTranslations(dataReponse, true);
        } else {
          cov_22vidcdndn.b[7][1]++;
          cov_22vidcdndn.s[25]++;

          console.log('(200 & ERROR)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
          cov_22vidcdndn.s[26]++;
          if ((cov_22vidcdndn.b[10][0]++, dataReponse.error_state) && (cov_22vidcdndn.b[10][1]++, dataReponse.edit_language) && (cov_22vidcdndn.b[10][2]++, dataReponse.edit_language == 0)) {
            cov_22vidcdndn.b[9][0]++;
            cov_22vidcdndn.s[27]++;

            console.log('edit_language: ' + dataReponse.edit_language);
            cov_22vidcdndn.s[28]++;
            localStorage.setItem(CHECK_TRANSLATE, dataReponse.edit_language);
          } else {
            cov_22vidcdndn.b[9][1]++;
          }
          cov_22vidcdndn.s[29]++;
          fetchTranslations(data, false);
        }
      }).catch(function (error) {
        cov_22vidcdndn.f[3]++;
        cov_22vidcdndn.s[30]++;

        console.log('(500)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
        cov_22vidcdndn.s[31]++;
        fetchTranslations(data, false);
      });
    } else {
      cov_22vidcdndn.b[3][1]++;
      cov_22vidcdndn.s[32]++;

      console.log('TRANSLATE LOCALSTORAGE ' + CODE_LANG);
      cov_22vidcdndn.s[33]++;
      fetchTranslations(JSON.parse(localStorage.getItem(CODE_LANG_STORE)), false);
    }
  } else {
    cov_22vidcdndn.b[2][1]++;
  }

  function fetchTranslations(dataLang, save) {
    cov_22vidcdndn.f[4]++;
    cov_22vidcdndn.s[34]++;

    $translateProvider.translations(CODE_LANG, dataLang);
    cov_22vidcdndn.s[35]++;
    if (save) {
      cov_22vidcdndn.b[11][0]++;
      cov_22vidcdndn.s[36]++;

      if (localStorage.getItem(CHECK_TRANSLATE)) {
        cov_22vidcdndn.b[12][0]++;
        cov_22vidcdndn.s[37]++;

        localStorage.removeItem(CHECK_TRANSLATE);
      } else {
        cov_22vidcdndn.b[12][1]++;
      }
      cov_22vidcdndn.s[38]++;
      if (localStorage.getItem(CODE_LANG_STORE)) {
        cov_22vidcdndn.b[13][0]++;
        cov_22vidcdndn.s[39]++;

        localStorage.removeItem(CODE_LANG_STORE);
      } else {
        cov_22vidcdndn.b[13][1]++;
      }
      cov_22vidcdndn.s[40]++;
      localStorage.setItem(CODE_LANG_STORE, JSON.stringify(dataLang));
    } else {
      cov_22vidcdndn.b[11][1]++;
    }
  };
});