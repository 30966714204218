'use strict';

var cov_f0icut6mf = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_fr.js',
      hash = '0d6d92c92eb4c4c5d7205e0d55574b64fbfc5fa9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_fr.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3009,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2919,
          column: 3
        }
      },
      '2': {
        start: {
          line: 2923,
          column: 20
        },
        end: {
          line: 2923,
          column: 24
        }
      },
      '3': {
        start: {
          line: 2924,
          column: 26
        },
        end: {
          line: 2924,
          column: 50
        }
      },
      '4': {
        start: {
          line: 2925,
          column: 26
        },
        end: {
          line: 2925,
          column: 43
        }
      },
      '5': {
        start: {
          line: 2927,
          column: 19
        },
        end: {
          line: 2927,
          column: 24
        }
      },
      '6': {
        start: {
          line: 2929,
          column: 2
        },
        end: {
          line: 2933,
          column: 3
        }
      },
      '7': {
        start: {
          line: 2930,
          column: 4
        },
        end: {
          line: 2930,
          column: 22
        }
      },
      '8': {
        start: {
          line: 2931,
          column: 4
        },
        end: {
          line: 2931,
          column: 52
        }
      },
      '9': {
        start: {
          line: 2932,
          column: 4
        },
        end: {
          line: 2932,
          column: 35
        }
      },
      '10': {
        start: {
          line: 2936,
          column: 2
        },
        end: {
          line: 2995,
          column: 3
        }
      },
      '11': {
        start: {
          line: 2938,
          column: 4
        },
        end: {
          line: 2994,
          column: 5
        }
      },
      '12': {
        start: {
          line: 2940,
          column: 21
        },
        end: {
          line: 2949,
          column: 7
        }
      },
      '13': {
        start: {
          line: 2950,
          column: 18
        },
        end: {
          line: 2950,
          column: 44
        }
      },
      '14': {
        start: {
          line: 2952,
          column: 22
        },
        end: {
          line: 2958,
          column: 7
        }
      },
      '15': {
        start: {
          line: 2961,
          column: 6
        },
        end: {
          line: 2989,
          column: 9
        }
      },
      '16': {
        start: {
          line: 2965,
          column: 8
        },
        end: {
          line: 2967,
          column: 9
        }
      },
      '17': {
        start: {
          line: 2966,
          column: 10
        },
        end: {
          line: 2966,
          column: 33
        }
      },
      '18': {
        start: {
          line: 2968,
          column: 8
        },
        end: {
          line: 2968,
          column: 56
        }
      },
      '19': {
        start: {
          line: 2969,
          column: 8
        },
        end: {
          line: 2969,
          column: 34
        }
      },
      '20': {
        start: {
          line: 2972,
          column: 31
        },
        end: {
          line: 2972,
          column: 55
        }
      },
      '21': {
        start: {
          line: 2973,
          column: 27
        },
        end: {
          line: 2973,
          column: 58
        }
      },
      '22': {
        start: {
          line: 2974,
          column: 8
        },
        end: {
          line: 2984,
          column: 9
        }
      },
      '23': {
        start: {
          line: 2975,
          column: 10
        },
        end: {
          line: 2975,
          column: 109
        }
      },
      '24': {
        start: {
          line: 2976,
          column: 10
        },
        end: {
          line: 2976,
          column: 47
        }
      },
      '25': {
        start: {
          line: 2978,
          column: 10
        },
        end: {
          line: 2978,
          column: 77
        }
      },
      '26': {
        start: {
          line: 2979,
          column: 10
        },
        end: {
          line: 2982,
          column: 11
        }
      },
      '27': {
        start: {
          line: 2980,
          column: 12
        },
        end: {
          line: 2980,
          column: 71
        }
      },
      '28': {
        start: {
          line: 2981,
          column: 12
        },
        end: {
          line: 2981,
          column: 77
        }
      },
      '29': {
        start: {
          line: 2983,
          column: 10
        },
        end: {
          line: 2983,
          column: 41
        }
      },
      '30': {
        start: {
          line: 2987,
          column: 8
        },
        end: {
          line: 2987,
          column: 66
        }
      },
      '31': {
        start: {
          line: 2988,
          column: 8
        },
        end: {
          line: 2988,
          column: 39
        }
      },
      '32': {
        start: {
          line: 2992,
          column: 6
        },
        end: {
          line: 2992,
          column: 56
        }
      },
      '33': {
        start: {
          line: 2993,
          column: 6
        },
        end: {
          line: 2993,
          column: 82
        }
      },
      '34': {
        start: {
          line: 2998,
          column: 4
        },
        end: {
          line: 2998,
          column: 57
        }
      },
      '35': {
        start: {
          line: 2999,
          column: 4
        },
        end: {
          line: 3007,
          column: 5
        }
      },
      '36': {
        start: {
          line: 3000,
          column: 6
        },
        end: {
          line: 3002,
          column: 7
        }
      },
      '37': {
        start: {
          line: 3001,
          column: 8
        },
        end: {
          line: 3001,
          column: 49
        }
      },
      '38': {
        start: {
          line: 3003,
          column: 6
        },
        end: {
          line: 3005,
          column: 7
        }
      },
      '39': {
        start: {
          line: 3004,
          column: 8
        },
        end: {
          line: 3004,
          column: 49
        }
      },
      '40': {
        start: {
          line: 3006,
          column: 6
        },
        end: {
          line: 3006,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 29
          },
          end: {
            line: 1,
            column: 30
          }
        },
        loc: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 3009,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 2962,
            column: 12
          },
          end: {
            line: 2962,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2962,
            column: 26
          },
          end: {
            line: 2970,
            column: 7
          }
        },
        line: 2962
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 2971,
            column: 12
          },
          end: {
            line: 2971,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2971,
            column: 29
          },
          end: {
            line: 2985,
            column: 7
          }
        },
        line: 2971
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 2986,
            column: 13
          },
          end: {
            line: 2986,
            column: 14
          }
        },
        loc: {
          start: {
            line: 2986,
            column: 24
          },
          end: {
            line: 2989,
            column: 7
          }
        },
        line: 2986
      },
      '4': {
        name: 'fetchTranslations',
        decl: {
          start: {
            line: 2997,
            column: 11
          },
          end: {
            line: 2997,
            column: 28
          }
        },
        loc: {
          start: {
            line: 2997,
            column: 45
          },
          end: {
            line: 3008,
            column: 3
          }
        },
        line: 2997
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2929,
            column: 2
          },
          end: {
            line: 2933,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2929,
            column: 2
          },
          end: {
            line: 2933,
            column: 3
          }
        }, {
          start: {
            line: 2929,
            column: 2
          },
          end: {
            line: 2933,
            column: 3
          }
        }],
        line: 2929
      },
      '1': {
        loc: {
          start: {
            line: 2929,
            column: 6
          },
          end: {
            line: 2929,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2929,
            column: 6
          },
          end: {
            line: 2929,
            column: 43
          }
        }, {
          start: {
            line: 2929,
            column: 48
          },
          end: {
            line: 2929,
            column: 90
          }
        }],
        line: 2929
      },
      '2': {
        loc: {
          start: {
            line: 2936,
            column: 2
          },
          end: {
            line: 2995,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2936,
            column: 2
          },
          end: {
            line: 2995,
            column: 3
          }
        }, {
          start: {
            line: 2936,
            column: 2
          },
          end: {
            line: 2995,
            column: 3
          }
        }],
        line: 2936
      },
      '3': {
        loc: {
          start: {
            line: 2938,
            column: 4
          },
          end: {
            line: 2994,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2938,
            column: 4
          },
          end: {
            line: 2994,
            column: 5
          }
        }, {
          start: {
            line: 2938,
            column: 4
          },
          end: {
            line: 2994,
            column: 5
          }
        }],
        line: 2938
      },
      '4': {
        loc: {
          start: {
            line: 2938,
            column: 8
          },
          end: {
            line: 2938,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2938,
            column: 8
          },
          end: {
            line: 2938,
            column: 46
          }
        }, {
          start: {
            line: 2938,
            column: 50
          },
          end: {
            line: 2938,
            column: 108
          }
        }, {
          start: {
            line: 2938,
            column: 113
          },
          end: {
            line: 2938,
            column: 154
          }
        }],
        line: 2938
      },
      '5': {
        loc: {
          start: {
            line: 2947,
            column: 15
          },
          end: {
            line: 2947,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2947,
            column: 15
          },
          end: {
            line: 2947,
            column: 50
          }
        }, {
          start: {
            line: 2947,
            column: 54
          },
          end: {
            line: 2947,
            column: 58
          }
        }],
        line: 2947
      },
      '6': {
        loc: {
          start: {
            line: 2965,
            column: 8
          },
          end: {
            line: 2967,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2965,
            column: 8
          },
          end: {
            line: 2967,
            column: 9
          }
        }, {
          start: {
            line: 2965,
            column: 8
          },
          end: {
            line: 2967,
            column: 9
          }
        }],
        line: 2965
      },
      '7': {
        loc: {
          start: {
            line: 2974,
            column: 8
          },
          end: {
            line: 2984,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2974,
            column: 8
          },
          end: {
            line: 2984,
            column: 9
          }
        }, {
          start: {
            line: 2974,
            column: 8
          },
          end: {
            line: 2984,
            column: 9
          }
        }],
        line: 2974
      },
      '8': {
        loc: {
          start: {
            line: 2974,
            column: 12
          },
          end: {
            line: 2974,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2974,
            column: 12
          },
          end: {
            line: 2974,
            column: 23
          }
        }, {
          start: {
            line: 2974,
            column: 27
          },
          end: {
            line: 2974,
            column: 48
          }
        }, {
          start: {
            line: 2974,
            column: 52
          },
          end: {
            line: 2974,
            column: 96
          }
        }, {
          start: {
            line: 2974,
            column: 101
          },
          end: {
            line: 2974,
            column: 134
          }
        }],
        line: 2974
      },
      '9': {
        loc: {
          start: {
            line: 2979,
            column: 10
          },
          end: {
            line: 2982,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2979,
            column: 10
          },
          end: {
            line: 2982,
            column: 11
          }
        }, {
          start: {
            line: 2979,
            column: 10
          },
          end: {
            line: 2982,
            column: 11
          }
        }],
        line: 2979
      },
      '10': {
        loc: {
          start: {
            line: 2979,
            column: 14
          },
          end: {
            line: 2979,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2979,
            column: 14
          },
          end: {
            line: 2979,
            column: 37
          }
        }, {
          start: {
            line: 2979,
            column: 41
          },
          end: {
            line: 2979,
            column: 66
          }
        }, {
          start: {
            line: 2979,
            column: 71
          },
          end: {
            line: 2979,
            column: 101
          }
        }],
        line: 2979
      },
      '11': {
        loc: {
          start: {
            line: 2999,
            column: 4
          },
          end: {
            line: 3007,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2999,
            column: 4
          },
          end: {
            line: 3007,
            column: 5
          }
        }, {
          start: {
            line: 2999,
            column: 4
          },
          end: {
            line: 3007,
            column: 5
          }
        }],
        line: 2999
      },
      '12': {
        loc: {
          start: {
            line: 3000,
            column: 6
          },
          end: {
            line: 3002,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3000,
            column: 6
          },
          end: {
            line: 3002,
            column: 7
          }
        }, {
          start: {
            line: 3000,
            column: 6
          },
          end: {
            line: 3002,
            column: 7
          }
        }],
        line: 3000
      },
      '13': {
        loc: {
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        }, {
          start: {
            line: 3003,
            column: 6
          },
          end: {
            line: 3005,
            column: 7
          }
        }],
        line: 3003
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0, 0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_f0icut6mf.s[0]++;
angular.module('app').config(function ($translateProvider) {
  cov_f0icut6mf.f[0]++;

  var data = (cov_f0icut6mf.s[1]++, {
    COUNTRIES: {
      AD: 'Andorre',
      AE: 'Émirats arabes unis',
      AF: 'Afghanistan',
      AG: 'Antigua-et-Barbuda',
      AI: 'Anguilla',
      AL: 'Albanie',
      AM: 'Arménie',
      AN: 'Antilles néerlandaises',
      AO: 'Angola',
      AQ: 'Antarctique',
      AR: 'Argentine',
      AS: 'Samoa américaines',
      AT: 'Autriche',
      AU: 'Australie',
      AW: 'Aruba',
      AX: 'Iles Åland',
      AZ: 'Azerbaïdjan',
      BA: 'Bosnie-Herzégovine',
      BB: 'Barbade',
      BD: 'Bangladesh',
      BE: 'Belgique',
      BF: 'Burkina Faso',
      BG: 'Bulgarie',
      BH: 'Bahreïn',
      BI: 'Burundi',
      BJ: 'Bénin',
      BM: 'Bermudes',
      BN: 'Brunéi Darussalam',
      BO: 'Bolivie',
      BR: 'Brésil',
      BS: 'Bahamas',
      BT: 'Bhoutan',
      BV: 'Ile Bouvet',
      BW: 'Botswana',
      BY: 'Bélarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Iles Cocos - Keeling',
      CD: 'République démocratique du Congo',
      CF: 'République centrafricaine',
      CG: 'Congo-Brazzaville',
      CH: 'Suisse',
      CI: 'Côte d’Ivoire',
      CK: 'Iles Cook',
      CL: 'Chili',
      CM: 'Cameroun',
      CN: 'Chine',
      CO: 'Colombie',
      CR: 'Costa Rica',
      CS: 'Serbie-et-Monténégro',
      CU: 'Cuba',
      CV: 'Cap-Vert',
      CX: 'Ile Christmas',
      CY: 'Chypre',
      CZ: 'République tchèque',
      DE: 'Allemagne',
      DJ: 'Djibouti',
      DK: 'Danemark',
      DM: 'Dominique',
      DO: 'République dominicaine',
      DZ: 'Algérie',
      EC: 'Équateur',
      EE: 'Estonie',
      EG: 'Égypte',
      EH: 'Sahara occidental',
      ER: 'Érythrée',
      ES: 'Espagne',
      ET: 'Éthiopie',
      FI: 'Finlande',
      FJ: 'Fidji',
      FK: 'Iles Malouines',
      FM: 'États fédérés de Micronésie',
      FO: 'Iles Féroé',
      FR: 'France',
      GA: 'Gabon',
      GB: 'Royaume-Uni',
      GD: 'Grenade',
      GE: 'Géorgie',
      GF: 'Guyane française',
      GG: 'Guernesey',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GL: 'Groenland',
      GM: 'Gambie',
      GN: 'Guinée',
      GP: 'Guadeloupe',
      GQ: 'Guinée équatoriale',
      GR: 'Grèce',
      GS: 'Géorgie du Sud et les îles Sandwich du Sud',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinée-Bissau',
      GY: 'Guyana',
      HK: 'R.A.S. chinoise de Hong Kong',
      HM: 'Iles Heard et MacDonald',
      HN: 'Honduras',
      HR: 'Croatie',
      HT: 'Haïti',
      HU: 'Hongrie',
      ID: 'Indonésie',
      IE: 'Irlande',
      IL: 'Israël',
      IM: 'Ile de Man',
      IN: 'Inde',
      IO: 'Territoire britannique de l\'océan Indien',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Islande',
      IT: 'Italie',
      JE: 'Jersey',
      JM: 'Jamaïque',
      JO: 'Jordanie',
      JP: 'Japon',
      KE: 'Kenya',
      KG: 'Kirghizistan',
      KH: 'Cambodge',
      KI: 'Kiribati',
      KM: 'Comores',
      KN: 'Saint-Kitts-et-Nevis',
      KP: 'Corée du Nord',
      KR: 'Corée du Sud',
      KW: 'Koweït',
      KY: 'Iles Caïmans',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Liban',
      LC: 'Sainte-Lucie',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Libéria',
      LS: 'Lesotho',
      LT: 'Lituanie',
      LU: 'Luxembourg',
      LV: 'Lettonie',
      LY: 'Libye',
      MA: 'Maroc',
      MC: 'Monaco',
      MD: 'Moldavie',
      MG: 'Madagascar',
      MH: 'Iles Marshall',
      MK: 'Macédoine',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolie',
      MO: 'R.A.S. chinoise de Macao',
      MP: 'Iles Mariannes du Nord',
      MQ: 'Martinique',
      MR: 'Mauritanie',
      MS: 'Montserrat',
      MT: 'Malte',
      MU: 'Maurice',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexique',
      MY: 'Malaisie',
      MZ: 'Mozambique',
      NA: 'Namibie',
      NC: 'Nouvelle-Calédonie',
      NE: 'Niger',
      NF: 'Ile Norfolk',
      NG: 'Nigéria',
      NI: 'Nicaragua',
      NL: 'Pays-Bas',
      NO: 'Norvège',
      NP: 'Népal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Nouvelle-Zélande',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Pérou',
      PF: 'Polynésie française',
      PG: 'Papouasie-Nouvelle-Guinée',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Pologne',
      PM: 'Saint-Pierre-et-Miquelon',
      PN: 'Pitcairn',
      PR: 'Porto Rico',
      PS: 'Territoire palestinien',
      PT: 'Portugal',
      PW: 'Palaos',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Roumanie',
      RU: 'Russie',
      RW: 'Rwanda',
      SA: 'Arabie saoudite',
      SB: 'Iles Salomon',
      SC: 'Seychelles',
      SD: 'Soudan',
      SE: 'Suède',
      SG: 'Singapour',
      SH: 'Sainte-Hélène',
      SI: 'Slovénie',
      SJ: 'Svalbard et Île Jan Mayen',
      SK: 'Slovaquie',
      SL: 'Sierra Leone',
      SM: 'Saint-Marin',
      SN: 'Sénégal',
      SO: 'Somalie',
      SR: 'Suriname',
      ST: 'Sao Tomé-et-Principe',
      SV: 'El Salvador',
      SY: 'Syrie',
      SZ: 'Swaziland',
      TC: 'Iles Turks et Caïques',
      TD: 'Tchad',
      TF: 'Terres australes françaises',
      TG: 'Togo',
      TH: 'Thaïlande',
      TJ: 'Tadjikistan',
      TK: 'Tokelau',
      TL: 'Timor oriental',
      TM: 'Turkménistan',
      TN: 'Tunisie',
      TO: 'Tonga',
      TR: 'Turquie',
      TT: 'Trinité-et-Tobago',
      TV: 'Tuvalu',
      TW: 'Taïwan',
      TZ: 'Tanzanie',
      UA: 'Ukraine',
      UG: 'Ouganda',
      UM: 'Iles Mineures Éloignées des États-Unis',
      US: 'Etats-Unis',
      UY: 'Uruguay',
      UZ: 'Ouzbékistan',
      VA: 'État de la Cité du Vatican',
      VC: 'Saint-Vincent-et-les Grenadines',
      VE: 'Venezuela',
      VG: 'Iles Vierges britanniques',
      VI: 'Iles Vierges des États-Unis',
      VN: 'Viêt Nam',
      VU: 'Vanuatu',
      WF: 'Wallis-et-Futuna',
      WS: 'Samoa',
      YE: 'Yémen',
      YT: 'Mayotte',
      ZA: 'Afrique du Sud',
      ZM: 'Zambie',
      ZW: 'Zimbabwe'
    },
    MESSAGE: {
      CONVERSATION: 'Conversation',
      WRITE_MSG: 'Saisir un message',
      AUCUN: 'Aucun message',
      TITLE: 'Mes Messages',
      READ_ALL: 'Lire tous les messages',
      INBOX: 'Boîte de réception',
      SEND_BOX: 'Messages Envoyés',
      DRAFT_BOX: 'Brouillons',
      TRASH_BOX: 'Corbeille',
      REFRESH: 'Actualiser',
      REPLY: 'Répondre',
      IMPORTANT: 'Importants',
      VIEW_MSG: 'Afficher le message',
      TO: 'À (Utilisateur DELTA)',
      TO_: 'Utilisateur DELTA',
      PIECEJ: 'Joindre des fichiers',
      TOEMAIL: 'À (Email Externe)',
      MAILEXTERNE: 'Email Externe',
      EMAILS: 'Emails',
      SUBJECT: 'Sujet',
      FROM: 'De',
      DRAFT: 'Brouillons',
      DISCARD: 'Supprimer',
      CMP_MSG: 'Nouveau Message',
      SEND: 'Envoyer',
      EMAIL_BOX: 'Boîte aux lettres',
      FOLDER: 'Dossier',
      REMOVE: 'Supprimer',

      RECHARGEMENT: 'Actualiser',
      MARKREAD: 'Marquer lu',
      MARKNOREAD: 'Marquer non lu',
      MARKIMPORTANT: 'Marquer important',
      MARKTRASH: 'Supprimer',

      ADDEMAIL: 'Ajouter un contact',

      SEND_OK: 'Message envoyé',
      SEND_SAVE: 'Message enregistré',
      SEND_NO: 'Erreur lors de l\'envoie du Message',
      SEND_NO_T: 'Veuillez réessayer',

      ADDUSER: 'Ajouter un utilisateur',
      SIGNATURE: 'Signatures',
      SIGNATURE_SEND: 'Terminer',
      SIGNATURE_ERROR: 'Erreur',
      SIGNATURE_CREATE: 'Créer une signature',
      SIGNATURE_ADD: 'Créer',
      SIGNATURE_TITLE: 'Titre de la signature',
      SIGNATURE_DESIGN: 'Désignation',
      SIGNATURE_ANNULER: 'Annuler',
      SIGNATURE_AJOUTER: 'Ajouter',
      SIGNATURE_MODIFIER: 'Modifier',
      SIGNATURE_LIST: 'Liste des signatures',
      SIGNATURE_DEFAULT: 'Sélectionner comme signature par default',
      SIGNATURE_NODEFAULT: 'Désélectionner comme signature par default',

      ERROR: {
        TO: 'Veuillez sélectionner le destinataire!',
        SUBJECT: 'Entrez le sujet',
        EM: 'Entrez un email'
      }
    },
    LOGIN: {
      PASSWORD_EXPIRED_S: 'Votre mot de passe expire dans {{days}} jours',
      PASSWORD_EXPIRED: 'Votre mot de passe expire dans 1 jour',
      PROFILE: 'Profil',
      LOGOUT: 'Déconnexion'
    },
    HELP: {
      LEGAL: 'Mentions légales',
      QUICK_START: 'Centre d\'Apprentissage',
      USER_MANUAL: 'Manuel Utilisateur',
      FAQ: 'FAQ',
      WEBINAIRS: 'Webinaires',
      SUPPORT: 'Contacter le support technique',
      LEGAL_NOTICE_LINK: 'https://delta-monitoring.com/service-level-agreement-fr/',
      USER_FEEDBACK: "Partager vos suggestions"
    },
    ALERT: {
      ACTIVATE: 'Activer l\'alerte sur les problèmes',
      SMS: 'SMS',
      EMAIL: 'Email',
      IND_TEXT: 'Alertes d\'indicateurs',
      DOCUMENT: 'Nouveaux documents',
      MORE: 'Voir alertes indicateurs & documents',
      ALL: 'Toutes les alertes',
      NBR_J_L: 'Envoyer l\'alerte',
      SEND: 'jour(s) avant la date d\'échéance',
      HELP: {
        TITLE: 'L\'alerte est configurée pour envoyer un message d\'alerte aux membres de l\'équipe pour leur rappeler de saisir des données concernant les activités et les indicateurs attribués.'
      }
    },
    TODO: {
      TITLE: 'Liste des tâches',
      LABEL: 'Libellé'
    },
    HOME: {
      WELCOME: 'Application Web collaborative pour le S&E',
      USER: 'Utilisateur',
      PASS: 'Mot de passe',
      LOGIN: 'Connexion',
      FOOTER: 'DELTA Monitoring',
      CONNECTED_PLURAL: 'Actuellement Connectés: {{user}}</b> Utilisateurs',
      CONNECTED_SINGLE: 'Actuellement Connecté: 1</b> Utilisateur'
    },
    HEADER: {
      LOGO: 'Logo',
      TITLE: 'Entête Rapport',
      FIRST_LINE: 'Première Ligne',
      SECOND_LINE: 'Deuxième Ligne',
      THIRD_LINE: 'Troisième Ligne',
      REPORT_TITLE: 'Titre Rapport'
    },
    COMMON: {
      ACTIVE_SUP: "Activer la suppression temporaire",
      ALERTE_UNIT: 'Sélectionner une unité de type %.',
      STEP: 'Étapes',
      STEPONE: 'Étape',
      VALUE: 'Valeur',
      ADDSTEP: 'Associer des étapes de réalisation',
      ADDSTEP_VIEW: 'Le type d’activité sélectionné indique que l’activité sera suivie comme un processus avec des étapes de réalisation',
      EXPORT_POWERBI: 'Exporter vers Power BI',
      LOADED_MSG: 'Veuillez patienter, traitement en cours…',
      EMAIL_UPLOADED: 'Vous avez importé {{_nb}} email(s)',
      UPLOAD_CSV: 'Import de fichier .CSV',
      UPLOAD_CSV_RECOMMENDATION: "Assurez-vous de nommer l'en-tête de chaque colonne du fichier .CSV",
      UPLOAD_CSV_RECOMMENDATION_HEADERS: 'Courriel (obligatoire), Nom et Numéro (le cas échéant)',
      DATE_MAJ: 'Date dernière mise à jour',
      SELECT_ALL: 'Sélectionner Tous',
      UNSELECT_ALL: 'Désélectionner Tous',
      FORMULAIRE_ERR_EXISTE: 'Le Formulaire existe déjà',
      ANALYSE_ERR_EXISTE: 'Titre déjà utilisé',
      ERR_MSG: 'Veuillez renseigner ce champ.',
      COL: 'Colonnes',
      COL_S: 'Sélectionner les colonnes',
      SV: 'Sauvegarder',
      SV_R: 'Sauvegarder le Rapport',
      UPLOAD_DOC: 'Pièce jointe',
      SELECT_OPTION_: 'Veuillez sélectionner les éléments',
      SELECT_OPTION: 'Veuillez sélectionner un élément',
      // SELECT_OPTION: 'Choisir une option',
      REQUIRED: 'Obligatoire',
      CHECKING: 'Vérfication ...',
      ERROR_AUTHORIZE_LOCATION: 'Vous n\'avez pas de droits sur cette localité',
      ONLINE: 'Connecté',
      OFFLINE: 'Déconnecté',
      ALREADY_EXIST: 'La variable existe déjà !',
      SEE_MORE: 'Voir Plus ...',
      UNAVAILABLE: 'Indisponible',
      SELECT_UNIT: 'Sélectionnez une unité',
      CONDITIONS: 'Filtres',
      FORMATTING: 'Formater',
      CHART: 'Graphe',
      OVERWRITE_DATA: 'Écraser les données existantes',
      SHARE: 'Partager',
      GOOD: 'Bon',
      BAD: 'Mauvais',
      MEDIUM: 'Moyen',
      PORTFOLIO_VISIBLE: 'Afficher dans le portefeuille',
      NO_DATA: 'Aucune donnée',
      HELP: 'Aide',
      TASK_DISPLAY_FORMAT: 'day dd month yyyy',
      DAY_MAJOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      WEEK_MINOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      FLAVOR: 'Favoris',
      CHECK_ALL: 'Sélectionner tous',
      UNCHECK_ALL: 'Désélectionner tous',
      PLANNING: 'Planifié',
      NOT_PLANNING: 'Non Planifié',
      UPDATE: 'Mise à jour',
      BY: 'par',
      CHANGE_PHOTO: 'Changer la photo',
      ENTER: 'Entrer',
      FILTER: 'Filtrer par: ',
      AGGREGATE: 'Aggréger',
      DESEGREGATE: 'Désagréger',
      TAKE_PICTURE: 'Prendre une photo',
      REPLY_TREE: 'Cliquer pour parcourir l’arborescence',
      VALIDATE_DATA: 'Valider les données',
      VALIDATED_DATA: 'Données validées',
      ALL_DATA: 'Toutes les données',
      VALIDATION: 'Validation',

      SATELLITE: 'Satellite',
      GRAYSCALE: 'Carte en niveaux de gris',
      CITIES: 'Villes',
      DAY: 'Jour',
      EXPORT_DATA: 'Exporter Données',
      IMPORT_DATA: 'Importer Données',
      DENIED_LOGIN: 'Allez à votre page de connexion',
      DENIED: 'Accès non autorisé',
      FULLSCREEN: 'Afficher en plein écran',
      EXIT_FULLSCREEN: 'Sortir du plein écran',
      FULL_GRAPH: 'Graphe Empli',
      WORKFLOW: 'Workflow',
      DATA: 'Données',
      GPS: {
        LONGITUDE: 'Longitude',
        LATITUDE: 'Latitude',
        ALTITUDE: 'Altitude'
      },
      RIGHT: {
        VALIDATION_SELECT: 'Valider Sélection',
        ONLYVALDATE: 'Uniquement les données validées',
        NONVALIDATED: 'Non validé',
        INVALIDATED: 'En cours de validation',
        VALIDATED: 'Validé',
        DELVALIDATED: 'A supprimer',
        SELECTVAL: 'Sélectionner un statut',
        VALIDATE: 'Validation',
        VALIDATE_STATUS: 'Statut Validation',
        VIEWING: 'Consultation',
        UPDATING: 'Mise à jour',
        DELETING: 'Suppression',
        PAGE: 'Page',
        EDIT_DELETE: 'Modification / Suppression',
        DATA: 'Données',
        METADATA: 'MétaDonnées'
      },
      STATUT: {
        NOSTART: 'Non démarré',
        PROGRESS: 'En cours',
        DONE: 'Achevé'
      },
      SAVE: 'Enregistrer',
      VIRGULE: ',',
      NEXT: 'Suivant',
      PREV: 'Précédent',
      ADD: 'Ajout',
      EDIT: 'Modifier',
      DELETE: 'Supprimer',
      PRINT: 'Imprimer',
      SELECT: 'Sélectionner',
      YEAR: 'Année',
      SEM: 'Semestre',
      QUATR: 'Quadrimestre',
      TRIM: 'Trimestre',
      BIM: 'Bimestre',
      MOIS: 'Mois',
      PERIOD: 'Période',
      YEAR_SHORT: 'A',
      SEM_SHORT: 'S',
      QUATR_SHORT: 'Q',
      TRIM_SHORT: 'T',
      BIM_SHORT: 'B',
      MOIS_SHORT: 'M',
      DISPLAY: 'Afficher',
      VALIDATE: 'OK',
      CANCEL: 'Annuler',
      CONTINUE: 'Continuer',
      CONFIRM: 'Confirmer la suppression',
      CONFIRM_RESTORE: 'Confirmer la restauration',
      YES: 'Oui',
      NO: 'Non',
      ERROR_DELETE: 'Impossible de supprimer!',
      ERROR_: 'Erreur',
      ERROR_UPDATE: 'Impossible de modifier!',
      ERROR_ADD: 'Impossible d\'ajouter!',
      ERROR_CODE: 'Le code est déjà attribué',
      TITLE_ERROR_ADD: 'Une erreur a été détectée lors de la mise à jour',
      TITLE_ERROR_DELETE: 'La donnée est utilisée',
      DATE_FORMAT: 'DD/MM/YYYY',
      DATE_FORMAT_COMPLET: 'DD/MM/YYYY HH:mm',
      DATE_FORMAT_MM_YYYY: 'MMMM YYYY',
      HISTORQ: 'Historique',
      CLOSE: 'Fermer',
      DATE_DP: 'Date Début Prévue',
      ACTUAL: 'Date Réelle',
      DATE_DR: 'Date Début Réelle',
      DATE_FP: 'Date Fin Prévue',
      DATE_FR: 'Date Fin Réelle',
      H_DEB: 'Heure Début',
      H_FIN: 'Heure Fin',
      LIEU: 'Lieu',
      MSG_ADD: 'Ajout effectué !',
      MSG_MODIF: 'Modification effectuée !',
      MSG_DEL: 'Suppression effectuée !',
      TBORD: 'Tableau de bord',
      YEAR_ALL: 'Toutes (*)',
      ALL: 'Tous (*)',
      ALL_E: 'Toutes (*)',
      NONE: 'Aucun',
      NONE_E: 'Aucune',
      SEARCH: 'Recherche',
      TYPECHART: 'Type de Graphe',
      LINECHART: 'Courbe',
      BARCHART: 'Histogramme',
      PIECHART: 'Secteur',
      POLARCHART: 'Radar',
      MIXED_CHART: 'Graphe Combiné',
      BEGIN: 'Début',
      END: 'Fin',
      LESS_THAN: 'Plus petit que',
      GREAT_THAN: 'Plus grand que',
      EGAL_TO: 'Égal à',
      LESS_THAN_E: 'Plus petite que',
      GREAT_THAN_E: 'Plus grande que',
      EGAL_TO_E: 'Égale à',
      OPEN: 'Ouvrir',
      DOWNLOAD: 'Télécharger',
      QUIT: 'Annuler',
      OPEN_DASHBOARD: 'Cliquer pour afficher le Tableau de bord',
      DESCRIPTION: 'Description',
      MORE: 'Voir plus...',
      MORE_DBL: 'Double clic pour détails ',
      DASHBOARD: 'Tableau de bord',
      ERROR: {
        NOMATCH: 'Aucun résultat ne correspond',
        REQUIRED: 'Obligatoire',
        DBL: 'Le code est déjà attribué',
        DBL_REF: 'La référence est déjà attribuée',
        DBL_: 'Déjà attribué',
        NONE_DATA: 'Aucune donnée sélectionnée',
        MIN_CAR: 'La longueur{{field}} minimale est {{value}}',
        MAX_CAR: 'La longueur{{field}} maximale est {{value}}',
        EMAIL: 'L\'email n\'est pas valide',
        CODE_ERROR: 'La longueur doit être égale à {{value}}',
        CODE_ERROR_COM: 'La longueur doit être égale à {{value}} et commencer par {{begin}}',
        DATE_RANGE: 'La date début doit être supérieure à la date fin',
        DATE: 'Date invalide',
        FINANCETOTAL: 'Le total doit être égal à 100%',
        INVALID_URL: 'L\'url n\'est pas valide'
      },
      EXPORT_WORD: 'Exporter vers Word',
      EXPORT_EXCEL: 'Exporter vers Excel',
      EXPORT_PDF: 'Exporter vers PDF',
      EXPORT_JSON: 'Exporter vers JSON',
      STREETMAP: 'Street Map',
      NEW_FEAUTURE: 'Présentation des nouvelles fonctionnalités',
      DATE_FORMAT_DISPLAY: 'Format des dates'
    },
    MENU_SHORT: {
      LAYERS: 'Fonds de Cartes',
      MONITORING_PREPARATION: 'Suivi des Préparations',
      MONITORING_REQUETES: 'Suivi des requêtes',
      MONITORING_CONDITIONALITIES: 'Suivi des Conditionnalités',
      RISKS_MANAGEMENT: 'Gestion des risques',
      PROBLEM_MANAGEMENT: 'Incidents',
      RISKS: 'Risques',
      PROBLEM: 'Problèmes',
      MISSION_MANAGEMENT: 'Gestion des missions',
      MISSION_TYPES: 'Types Mission',
      COMMUNITIES: 'Communautés',
      REPORT_PLAN: 'Planning Rapport',
      REPORT_TRACKING: 'Suivi Rapport',
      FUNDS: 'Suivi Décaissements ',
      ACTIVITY_PLAN_BUDGET: 'Plan & Budget',
      PROJECT_TIMELINE: 'Chronogramme',
      RISK_MANAGEMENT: 'Gestion des Risques',
      PROJECT_MGT: 'Gestion',
      ACTIVITY_REPORT: 'Rapports',
      CUSTOM_RIGHT: 'Droits spécifiques',
      HOME: 'Accueil',
      ALERT: 'Alertes',
      D_B: 'Paramètres',
      DO: 'Portefeuille',
      PROJECT: 'Projets',
      PROJECT_SINGLE: 'Projet',
      D_T: 'Découpage Temporel',
      D_P: 'Découpage Périodique',
      D_PL: 'Découpage Plan ',
      UN: 'Unités',
      AGENCIES: 'Agences',
      AC: 'Acteurs',
      FINANCING: 'Financements',
      S_F: 'Sources Financements',
      P_G: 'Plan Géographique',
      S_G: 'Structure Géographique',
      T_I: 'Types Indic.',
      C_I: 'Désagrégation Indicateurs',
      IN: 'Suivi Indicateurs',
      IN_T: 'Indicateurs',
      IN_P: 'Indicateurs Portefeuille',
      R_F: 'Cadre des Résultats',
      R_F_: 'Cadre des Résultats des Indicateurs',
      P_L: 'Personnaliser le libellé',
      P_LO: 'Plan logique',
      C_B: 'Cat. bud.',
      P_CL: 'Cadre Logique',
      P_CUSTOM: 'Modèles',
      P_CR: 'Chaine des Résultats',
      ST: 'Niveaux du Cadre logique ',
      ST_CL: 'Structure Cadre Logique',
      ST_CR: 'Structure Chaine des Résultats',
      P_B: 'Plan Budgétaire',
      P_M: 'Plan Marchés',
      FL: 'Flag',
      T_A: 'Type Activités',
      AGENDA: 'Agenda',
      FILE: 'Fil d\'actualités',
      ANALYTICS: 'TDB',
      PL: 'Suivi Activités',
      P_A: 'Suivi Activités',
      P_AN_PTBA: 'Plan Annuel (PTBA)',
      IMP_BUDG: 'Importation Budgets',
      G_F_PF: 'Générer le fichier pré formaté',
      I_B: 'Importer les budgets',
      P_I: 'Planification Indicateurs',
      S_E: 'Suivi d\'exécution',
      S_A: 'Suivi Activités',
      I_C: 'Importation Coûts',
      I_COUT: 'Importer les coûts',
      S_PTBA: 'Suivi ',
      S_PTBP: 'Performance annuelle',
      T_B_A: 'Tableau de bord Activités',
      C_GANTT: 'Chronogramme Projet',
      P_AC: 'Plan d\'action',
      R_P: 'Requêtes Perso.',
      MA: 'Marché',
      S_M: 'Suivi Marché',
      S_PPM: 'Suivi PPM',
      S_I: 'Suivi Indicateur',
      S_I_IOV: 'Suivi Indicateur (IOV)',
      S_I_G: 'Suivi Géographique Indicateurs',
      T_B_I: 'Tableau de bord Indicateur',
      SAP_VIS: 'Représentation Spatiale',
      SY: 'Synthèse',
      C_L: 'Cadre Log',
      S_C_L: 'Suivi du cadre logique',
      S_C: 'Suivi Contexte',
      G_R: 'Gestion des risques',
      P_M_R: 'Plan de mitigation des risques',
      F_P: 'Facteurs Positifs',
      E_T: 'Enseignements Tirés',
      B_D: 'Documents',
      B_D_: 'Apprentissage et Partage des Connaissances',
      DB_P: 'Bases de Données perso.',
      T_RA: 'Rapports Internes',
      CA_R: 'Suivi Rapports',
      C_D: 'Documents',
      PH: 'Médiathèque',
      AD: 'Utilisateurs',
      G_U: 'Utilisateurs',
      GR_U: 'Groupes',
      U_C: 'Utilisateurs Connectés',
      J_C: 'Journal des Connexions',
      R_I: 'Profils',
      FORMULAR_: 'Collecte',
      FORMULAR: 'Collecte',
      DESIGN_FORMS: 'Conception des formulaires',
      COLLECT_DATA: 'Collecte des données',
      ANALYZE_DATA: 'Analyse & Reporting',
      ANALYSE: 'Rapports Personnalisés',
      DASHBOARD: 'Tableaux de bord',
      DASHBOARD_: 'Tableaux de bord',
      ANALYTICS_GIS: 'Analyse Spatiale',
      AUDITS: 'Journal des accès',
      SECURITY: 'Sécurité',
      DELETED_ITEMS: 'Éléments supprimés',
      ADMIN: 'Admin'
    },

    MENU: {
      LAYERS: 'Fonds de Cartes',
      LAYER: 'Fond de Carte',
      RECOMMENDATIONS: 'Recommandations',
      RISKS_MANAGEMENT: 'Gestion des risques',
      PROBLEM_MANAGEMENT: 'Incidents',
      RISKS: 'Risques',
      PROBLEM: 'Problèmes',
      MISSION_MANAGEMENT: 'Gestion des missions',
      MISSION: 'Mission',
      MISSION_TYPES: 'Types Mission',
      FOLDER: 'Dossier',
      COMMUNITIES: 'Communautés',
      REPORT_PLAN: 'Planning Rapport',
      REPORT_TRACKING: 'Suivi Rapport',
      FUNDS: 'Suivi Décaissements ',
      SECTORS: 'Secteurs',
      PROJECT_MGT: 'Gestion',
      ACTIVITY_REPORT: 'Rapports',
      DASHBOARDS: 'Tableaux de bord',
      PROJECTS: 'Projets',
      HOME: 'Accueil',
      ALERT: 'Alertes',
      D_B: 'Paramètres',
      DO: 'Portefeuille',
      PROJECT: 'Projets',
      D_T: 'Découpage Temporel',
      D_P: 'Découpage Périodique',
      D_PL: 'Découpage Plan',
      UN: 'Unités',
      FINANCING: 'Financements',
      AGENCIES: 'Agences d\'exécution',
      AC: 'Acteurs',
      S_F: 'Sources de Financement',
      P_G: 'Plan Géographique',
      Plan_G: 'Plan géographique',
      S_G: 'Structure Géographique',
      STRUCT_G: 'Structure géographique',
      T_I: 'Types Indicateurs',
      C_I: 'Désagrégation Indicateurs',
      IN: 'Suivi Indicateurs',
      IN_T: 'Indicateurs',
      IN_P: 'Indicateurs Portefeuille',
      R_F: 'Cadre des Résultats',
      R_F_: 'Cadre des Résultats',
      P_L: 'Personnaliser le libellé',
      P_LO: 'Plan logique',
      C_B: 'Catégories budgétaires',
      P_CL: 'Cadre Logique',
      P_CUSTOM: 'Modèles Cadres Logique',
      P_CR: 'Chaîne des Résultats',
      ST: 'Niveaux du Cadre logique',
      ST_CL: 'Structure Cadre Logique',
      ST_CR: 'Structure Chaîne des Résultats',
      P_B: 'Plan Budgétaire',
      P_M: 'Plan Marchés',
      FL: 'Flag',
      T_A: 'Types Activités',
      AGENDA: 'Agenda',
      FEEDBACK: 'Suggestion',
      FILE: 'Fil d\'actualités',
      PL: 'Activités',
      P_A: 'Suivi Activités',
      P_AN_PTBA: 'Plan Annuel (PTBA)',
      IMP_BUDG: 'Importation Budgets',
      G_F_PF: 'Générer le fichier pré formaté',
      I_B: 'Importer les budgets',
      P_I: 'Planification Indicateurs',
      S_E: 'Suivi d\'exécution',
      S_A: 'Suivi Activités',
      I_C: 'Importation Coûts',
      I_COUT: 'Importer les coûts',
      S_PTBA: 'Suivi ',
      S_PTBP: 'Performance Annuelle',
      T_B_A: 'Tableau de bord Activités',
      C_GANTT: 'Chronogramme Projet',
      P_AC: 'Plan d\'action',
      R_P: 'Requêtes Perso.',
      MA: 'Marché',
      S_M: 'Suivi Marché',
      S_I: 'Suivi Indicateur',
      S_I_IOV: 'Suivi Indicateur (IOV)',
      S_I_G: 'Suivi Géographique Indicateurs',
      T_B_I: 'Tableau de bord Indicateur',
      SAP_VIS: 'Représentation Spatiale',
      SY: 'Synthèse',
      C_L: 'Cadre Logique',
      S_C_L: 'Suivi du cadre logique',
      S_C: 'Suivi Contexte',
      G_R: 'Gestion des risques',
      P_M_R: 'Plan de mitigation des risques',
      F_P: 'Facteurs Positifs',
      E_T: 'Enseignements Tirés',
      B_D: 'Documents',
      B_D_: 'Apprentissage et Partage des Connaissances',
      DB_P: 'Bases de Données perso. ',
      T_RA: 'Rapports Internes',
      CA_R: 'Suivi Rapports',
      C_D: 'Documents',
      PH: 'Médiathèque',
      AD: 'Utilisateurs',
      G_U: 'Gestion des utilisateurs',
      GR_U: 'Groupes Utilisateurs',
      U_C: 'Utilisateurs Connectés',
      J_C: 'Journal des Connexions',
      R_I: 'Profils Utilisateurs',
      FORMULAR_: 'Collecte des Données',
      FORMULAR: 'Collecte Données',
      ANALYTICS: 'Tableaux de bord',
      ANALYTICS_CUSTOM_REPORT: 'Rapports Personnalisés',
      ANALYTICS_CUSTOM_DASHBOARD: 'Tableaux de bord',
      ANALYTICS_GIS: 'Analyse Spatiale',
      DESIGN_FORMS: 'Conception des formulaires',
      COLLECT_DATA: 'Collecte des données',
      ANALYZE_DATA: 'Analyse & Reporting',
      ANALYSE: 'Rapports Personnalisés',
      DASHBOARD: 'Tableaux de bord',
      DASHBOARD_: 'Tableaux de bord',
      AUDITS: 'Journal des accès',
      SECURITY: 'Sécurité des accès',
      DATA: 'Formulaires',
      ADMIN: 'Administration'
    },

    LAYER: {
      NAME: 'Titre',
      URL: 'URL Carte',
      HELP: {
        NAME: 'Titre',
        URL: 'L\'url du fond de carte (https://...{x}{y}{z})'
      },
      ERROR: {
        URL: 'Url Invalide'
      }
    },
    PROGRAM: {
      EDIT: 'Éditer le portefeuille',
      EDIT_PROGRAM: 'Edition du portefeuille',
      EDIT_HEADERS: {
        DETAIL: 'Détails portefeuille',
        DESCRIPTION: 'Description portefeuille',
        ME: 'Paramétrage des Périodes et Jalons',
        PERIODS: 'Périodicité Suivi',
        MILESTONES: 'Jalons',
        OPTIONS: 'Options'
      },
      TITLE: 'Portefeuille',
      NAME: 'Libellé',
      DESCRIPTION: 'Description',
      HELP: {
        NAME: 'Entrez le libellé du Portefeuille',
        DESCRIPTION: 'Entrez une description'
      }
    },
    DEFAULT_LOGFRAME: {
      TITLE: 'Cadre logique Personnalisés',
      SUBTITLE: 'Cadre logique Personnalisé',
      LABEL: 'Désignation',
      LEVELS: 'Niveaux du cadre logique',
      SEARCH: 'Recherche par la désignation',
      HELP: {
        NEW_LEVEL: 'Ajouter un niveau',
        LABEL: 'Entrer le nom du modèle'
      }
    },
    SECTORS: {
      NAME: 'Désignation'
    },
    FINANCING: {
      EDIT_TITLE: 'Financement',
      SOURCE: 'Source Financement',
      AGREEMENT: 'Accord de financement #',
      TYPE: 'Type',
      TYPES: {
        GRANT: 'Subvention',
        LOAN: 'Prêt',
        DONATION: 'Don',
        BUDGET_SUPPORT: 'Aide budgétaire'
      }
    },
    PORTFOLIO: {
      GO_TO_PORTFOLIO: 'Voir les projets ...',
      EDIT_TITLE: 'Portefeuille',
      CLOSE: 'Retourner aux portefeuilles',
      OPEN: 'Gérer le portefeuille',
      CODE: 'Sigle',
      LABEL: 'Nom',
      TITLE: 'Liste des Portefeuilles',
      ADD: 'Ajouter un Portefeuille',
      ERROR: {
        DBL: 'Le sigle du Portefeuille est déjà attribué',
        DATE: 'La date fin doit être supérieure à la date début',
        DATE_BEGIN_SUP: 'La date début doit être supérieure à {{X}}',
        DATE_BEGIN_INF: 'La date début doit être inférieure à {{X}}',
        DATE_END_SUP: 'La date fin doit être supérieure à {{X}}',
        DATE_END_INF: 'La date fin doit être inférieure à {{X}}',
        DELETE: 'Le secteur est utilisé'
      }
    },
    PROJECT: {
      ACTIVE_SMS: 'Activer l’envoi par SMS des alertes',
      ACTIVE_SMS_WARNING: 'L’activation de cette option entraînera des coût suppléments.\n' + 'Veuillez contacter DELTAGIS pour l’activation effectif de l’option.\n',
      NEWS: 'Nouveautés',
      CUSTOM_FIELD_1: 'Libellé Champs 1',
      CUSTOM_FIELD_2: 'Libellé Champs 2',
      OPTION_ACTIVITIES: 'Options Gestion',
      OPTION_INDICATORS: 'Options Indicateurs',
      OPTION_OTHERS: 'Autres Options',
      CUSTOM_FIELD: 'Utiliser les champs personnalisés',
      INDEX_PERFORMANCE: 'Performance',
      CROSS_CUTTING_KPIS: 'Gérer les indicateurs de performance',
      CROSS_CUTTING_KPIS_LIST: 'Indicateurs de performance',
      PORTFOLIO_CROSS_CUTTING_KPIS: 'Isoler les indicateurs transversaux dans les projets',
      MISSIONS: 'Suivi des Missions',
      RISK: 'Gestion des Risques',
      FOLLOW_RISK: 'Suivi des Risques',
      FOLLOW_PERFORM: 'Suivi des Performances',
      TYPE: 'Type',
      TYPES: {
        PROJECT: 'Projet',
        PROGRAM: 'Programme',
        OTHER: 'Autre'
      },
      FILTERS: 'Filtres',
      CLOSE: 'Fermer',
      TO_FILTER: 'filtrer',
      CLEAR_FILTERS: 'Effacer',
      ACTOR: 'Gestionnaire',
      ACTORS: 'Gestionnaire',
      ACTOR_NONE: 'Aucun',
      STATUS_PROGRESS: 'État Avancement',
      STATUS_PROGRESS_: 'Avancement',
      DASHBOARDS: 'Tableaux de bord Projets',
      GO_TO_PROJECT: 'Aller au Projet',
      SUMMARY: 'Performance du Projet',
      SELECT_SECTORS: 'Secteurs',
      SECTORS: 'Secteurs',
      PROJECTS: 'Projets',
      PROJECTS_COL: 'Projet',
      EDIT: 'Éditer',
      GEO_MAP: 'Geo Rèf',
      SECTOR: 'Secteur',
      FINANCING: 'Financement',
      AMOUNT: 'Coût',
      ADD_: 'Ajout',
      FINANCIAL_DATA: 'Performance Financière',
      PAGING_SUMMERY: '{{current}} de {{total}} pages ({{count}} projets)',
      PAGING_ELEMENT_SUMMERY: '{{current}} de {{total}} pages ({{count}} éléments)',
      AGENCIES: 'Agences d\'exécution',
      SELECT_AGENCIES: 'Sélectionner les Agences',
      DISPLAY_DATA: 'Utiliser un portail',
      LOCATION: 'Coordonnées GPS ',
      ADD: {
        OPTION_1: 'Projet',
        OPTION_2: 'Portefeuille'
      },
      LINK: {
        TITLE: 'Lier les données suivantes aux portefeuilles',
        UNITS: 'Unités',
        FINANCIAL_SOURCES: 'Sources de financement',
        LOCALITIES: 'Localités',
        LOGICAL_FRAME: 'Plan Logique',
        FLAG: 'Flag',
        TYPE_ACTIVITIES: 'Types activités',
        TYPE_INDICATORS: 'Types Indicateurs',
        TYPE_REPORT: 'Rapports',
        FORM: 'Formulaires'
      },
      SELECT_PERIOD: 'Sélectionner une période',
      SEARCH_PROJECT: 'Rechercher par le code ou la description',
      FILTER: 'Filtrer par',
      MILESTONES: {
        REALIZE: 'Réalisé',
        TITLE: 'Dates importantes',
        MILESTONE: 'Jalon',
        PLANNED_DATE: 'Date Prévue',
        ACTUAL_DATE: 'Date Réelle',
        ACTUAL: 'Réelle',
        COMMENTS: 'Commentaires',
        PLANNED: 'Prévu'
      },
      DESCRIPTION: 'Description',
      GLOBAL_PLAN: 'Utiliser la planification globale',
      PERIODIC_FOLLOW: 'Périodicité Suivi',
      SETTING_FOLLOW: 'Paramètres Suivi',
      ACTIVITY_WEIGHT: 'Utiliser la pondération',
      ACTIVITY_WEIGHT_AN: 'Par Année',
      START_MONTH: 'Début Année',
      FISCAL_YEAR: 'Année',
      LOGFRAME: 'Cadre Logique',
      RESBAS_MONIT: 'Gestion Axée sur le Résultat',
      CUSTOM_LOGFRAME: 'Cadre Logique Personnalisé',
      ME_METHOD: 'Modèle Cadre Logique',
      ME_METHOD_SELECT: 'Sélectionner un modèle de Cadre Logique',
      FILTER_BY: 'Filtrer par',
      PRJ: 'Projets',
      DASHBOARD: 'Indicateurs Portefeuille',
      TITLE: 'Portefeuille',
      NEWP: 'Ajouter',
      FROM: 'de',
      TO: 'à',
      ADDRESS: 'Adresse',
      CONTACT: 'Personne à contacter (Point Focal)',
      PROCESS: 'En cours...',
      COMPLETE: 'Achèvement à',
      PROJECT: 'Projet',
      EDIT_PROJECT: 'Edition du Projet',
      INFO: 'Informations',
      CODE: 'Sigle',
      LABEL: 'Nom',
      CURRENCY: 'Monnaie',
      D_DEB: 'Date Début',
      D_FIN: 'Date Fin',
      D_DEB_: 'Début',
      D_FIN_: 'Fin',
      COOR: 'Coordonnées',
      LINK_GEO_PLAN: 'Utiliser les localités du Programme ',
      ADR: 'Ville',
      PAYS: 'Pays',
      TEL: 'Téléphone',
      FAX: 'Fax',
      MAIL: 'E-mail',
      OTHER: 'Détail',
      AGENC: 'Agence d\'exécution',
      DESCR: 'Description',
      CODE_ERR: 'Le code est attribué',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce projet?',
      SELECT: 'Sélectionner',
      CHANGE_PIC: 'Changer l\'image (100x100)',
      STATUT_BUDGET: 'Publier données financières',
      GOAL: 'Objectif global',
      TARGET: 'Bénéficiaires',
      AREA: 'Zone d’exécution',
      LOCATION_FIELD: 'Localisation géographique',
      SPECIFIC_OBJECTIVES: 'Objectifs spécifiques',
      IMPACT: 'Impact attendu',
      DATE_MID: 'Date revue mi-parcours',
      STATUS: 'Statut',
      COST: 'Coût',
      FINAN: 'Financement',
      MAP: 'Localisation des Projets',
      GLOBAL_HEADERS: {
        PROJECTS: 'Liste',
        MAP: 'Carte',
        INDICATOR: 'Indicateurs Portefeuille'
      },
      EDIT_HEADERS: {
        DETAIL: 'Détails',
        DESCRIPTION: 'Description',
        ME: 'Paramétrage des Périodes et Jalons',
        PERIODS: 'Périodicité Suivi',
        MILESTONES: 'Jalons',
        OPTIONS: 'Options'
      },
      STATUTS: {
        NOT_STARTED: 'Non Démarré',
        ACTIVE: 'En cours',
        SUSPENDED: 'Suspendu',
        COMPLETED: 'Achevé',
        ARCHIVED: 'Archivé'
      },
      TOOLTIP: {
        CURRENCY: '5 caractères maximum',
        STATUT_BUDGET: 'Si l\'option est cochée, les données financières seront divulguées dans le cadre des informations affichées sur le portail.'
      },
      ERROR: {
        CUSTOM_FIELD: 'Veuillez renseigner l\'un des champs',
        DELETE: 'Le projet contient des données et ne peut être supprimé !',
        DBL: 'Le code est déjà attribué',
        DATE: 'La date fin doit être supérieure à la date début',
        CURRENCY: 'La longueur de la monnaie doit être inférieure à 5 caractères',
        SAVING: 'Si vous avez modifié la date début de l\'année fiscale ou la périodicité de suivi, les planifications et réalisations périodiques déjà saisies seront supprimées.'
      },
      MAP_VIEW: {
        NBR_PROJECT: 'Nombre de projets',
        NBR: 'Nombre',
        INVESTEMENT: 'Investissements ( millions {{currency}})',
        MILLIONS: '(millions {{currency}})',
        AGENC: 'Agence d\'exécution',
        PROJECTS: 'Projets',
        BUDGET: 'Montant (millions {{currency}})',
        LOCATIONS: 'Localités',
        BACK: 'Retour',
        STATUS: 'Statut',
        SECTORS: 'Secteurs',
        FUNDING_SOURCE: 'Sources de Financement',
        SUB_LOCATIONS: 'Sous-Localités',
        TOTAL: 'Total'
      },
      DASHBOARD_VIEW: {
        FINANCING_PLAN: 'Plan de financement',
        DASHBOARD_TYPE: {
          PROJECTS: 'Tableau de bord',
          RISK: 'Risques',
          PROBLEM: 'Incidents',
          MISSION: 'Missions'
        },
        FINANCING_TYPE_: 'Type de financement',
        DESCRIPTION: 'Description',
        FINANCIAL_DATA: 'Performance financière',
        INDICATOR_PERFORMANCE: 'Indicateurs de Performance',
        INDICATOR_RESULT: 'Cadre des Résultats',
        EXCHANGE: 'Taux de change',
        TOOLTIP_SECTOR_PLURALS: '{{project}} projets totalisants {{amount}} (millions {{currency}})',
        TOOLTIP_SECTOR_SINGLE: '1 projet totalisant {{amount}} (millions {{currency}})',
        NUMBER_OF_PROJECT: 'Nombre de projets',
        DECIMAL_CURRENCY: 'millions {{currency}} ',
        FINANCING: 'Financement',
        CURRENCY: 'Monnaie',
        PROJECT: 'Projets',
        AMOUNT: 'Montant',
        DISBURSED: 'Décaissé',
        PROJECT_BY_SECTOR: 'Répartition par Secteur',
        INVESTING_BY_FUNDING_SOURCE: 'Investissement par Source de Financement',
        PROJECT_BY_FUNDING_SOURCE: 'Répartition par Source de financement',
        SOLD: 'Disponible',
        RESULTS_FRAMEWORK_STANDARD: 'Cadre des résultats des indicateurs (Statut actuel)',
        RESULTS_FRAMEWORK_MULTI_YEAR: ' Cadre des Résultats des Indicateurs (Évolution annuelle)',
        RESULTS_FRAMEWORK: 'Cadre des Résultats',
        TOTAL_COST: 'Coût total du Projet',
        // DISBURSED_RATE: 'Taux de décaissement',
        DISBURSED_RATE: 'Évolution du Taux de décaissement global',
        TOTAL_DISBURSED_BY_FUNDING: 'Total décaissé par source de financement',
        FILTER: 'Filtre',
        FINANCING_TYPE: 'Source de financement',
        AGENCIES: 'Agences d\'exécution',
        STATUS: 'Statut',
        SECTORS: 'Secteur',
        LOCATIONS: 'Localisation (Plan Géo)',
        LOCATION: 'Localisation'
      }
    },
    BTN: {
      ADD: 'Ajouter',
      MOD: 'Modifier',
      DEL: 'Supprimer',
      CAN: 'Annuler',
      PRI: 'Imprimer',
      VAL: 'OK',
      NEX: 'Page Suivante',
      PRE: 'Page Précédente'
    },
    DEVISE: {
      TITLE: 'Monnaies',
      CODE: 'Code',
      LABEL: 'Désignation'
    },
    FUNDS: {
      AVAILABLE: 'Disponible',
      MAPPING: 'Importer',
      USE_SELECTED_FINANCING: 'Utiliser le financement sélectionné',
      USE_CURRENT_DATE: 'Utiliser la date d\'aujourd\'hui',
      PLEASE_SELECT_COLUMN: 'Veuillez sélectionner une colonne',
      REFERENCE: '#',
      REFERENCE_FIELD: 'No Décaissement',
      LABEL: 'Décaissement ',
      EDIT_TITLE: 'Décaissement ',
      DATE_FUNDS: 'Date',
      AMOUNT: 'Montant',
      DEVISE: 'Monnaie',
      RATE: 'Taux',
      AMOUNT_RATE: 'Montant (Monnaie financement) ',
      HELP: {
        REFERENCE: 'No ou Réf du décaissement ',
        LABEL: 'Nature ou objet du décaissement',
        DATE_FUNDS: 'Date du décaissement ',
        AMOUNT: 'Montant décaissé ',
        DEVISE: 'Monnaie du montant décaissé ',
        RATE: 'Taux de change par rapport à la monnaie du financement ',
        AMOUNT_RATE: 'Montant (Monnaie financement)'
      },
      FINANCING: 'Financement',
      DISBURSED: 'Décaissé',
      BAR_CHART: 'Progression du Taux de décaissement par année',
      PIE_CHART: 'Volume des décaissements par année'
    },
    FN_UNIT: {
      HELP: {
        COD: 'Entez le code de l\'unité 10 caractères',
        LAB: 'Description de l\'unité'
      },
      ERROR_CODE: 'La longueur du code ne doit pas excéder 10 caractères',
      SEARCH: 'Rechercher les unités par code ou description',
      LST_TI: 'Liste des unités',
      ED_TI: 'Détails de l\'unité',
      COD: 'Code',
      LAB: 'Description',
      TYP: 'Type',
      FOR: 'Format',
      CB_N: 'Numérique',
      CB_P: '%',
      CB_B: 'Booléen',
      CB_T: 'Texte',
      CB_D: 'Date',
      NEWU: 'Nouvelle unité',
      COLU: 'Unité',
      CONFIRM_DELETE: 'Voulez-vous supprimer cette unité?',
      DECIMAL: 'Nombre de chiffres après la virgule'
    },
    CATEGORIE_BUDGET: {
      HELP: {
        COD: 'Entrez le code de l\'unité (10 caractères)',
        LAB: 'Libellé de la catégorie'
      },
      CODE: 'Code',
      CATEGORIE_BUDGET: 'Catégorie Budgétaire',
      TITLE: 'Catégories Budgétaires',
      LABEL: 'Libellé',
      CONFIRM_DELETE: 'Voulez-vous supprimer cette catégorie?'
    },
    AGENCIES: {
      TITLE: 'Agences d\'exécution',
      NAME: 'Nom',
      SHORT_NAME: 'Nom Court',
      ADDRESS: 'Adresse',
      CITY: 'Ville',
      COUNTRY: 'Pays',
      TEL: 'Téléphone',
      FAX: 'Fax',
      EMAIL: 'Email',
      URL: 'Site Web',
      HELP: {
        NAME: 'Le nom de l\'agence',
        SHORT_NAME: 'Le nom court de l\'agence',
        ADDRESS: 'L\'adresse de l\'agence',
        CITY: 'La ville de l\'agence',
        COUNTRY: 'Pays',
        TEL: 'Numéro de Téléphone',
        FAX: 'Fax de l\'agence',
        EMAIL: 'Email de l\'agence',
        URL: 'Site web de l\'agence'
      },
      SEARCH: 'Rechercher par le nom, l\'adresse, la ville ou le pays',
      ERROR: {
        DBL_NAME: 'Le nom est déjà attribué'
      }
    },
    FN_ACTORS: {
      HELP: {
        COD: 'Code, ID, Acronyme',
        NOM: 'Nom de l\'acteur',
        PRE: 'Nom de l\'acteur',
        FON: 'Rôle / Responsabilité attribué à l\'acteur',
        ORGANIZATION: 'Organisation à laquelle appartient l\'acteur',
        GRO: 'Groupe de l\'acteur',
        EMAIL: 'Email de l\'acteur'
      },
      PERIOD_RAPPORT: 'Période',
      EDITION_RAPPORT: 'Date Edition',
      SEARCH: 'Rechercher les acteurs par Code, Nom, Prénoms',
      CATEGORY: 'Catégorie',
      CATEGORY_TEAM: 'Équipe Projet',
      CATEGORY_STAKE: 'Acteurs',
      LST_AC: 'Équipe Projet & Acteurs',
      ED_AC: 'Fiche Acteur',
      COD: 'Code',
      NOM: 'Nom',
      PRE: 'Nom',
      FON: 'Rôle / Responsabilité',
      IMG: 'Image',
      ORGANIZATION: 'Organisation',
      GRO: 'Groupe',
      GRO_: 'Groupe',
      SELECT_GRO: 'Sélectionner un groupe',
      NEWA: 'Nouvelle Acteur',
      LAB_AC: 'Activités',
      LAB_LSTAC: 'Activités',
      LAB_MI: 'Missions',
      LAB_LSTMI: 'Missions',
      LAB_RE: 'Réunions',
      LAB_LSTRE: 'Réunions',
      LAB_RA: 'Rapports',
      LAB_LSTRA: 'Rapports',
      LAB_TYPRA: 'Type Rapport',
      FILT: 'Filtre',
      ETAV: 'État d\'avancement',
      RESNIVO: 'Niveau de Responsabilité',
      CHECK_A1: 'En cours',
      CHECK_A2: 'Achevé',
      CHECK_A3: 'En retard',
      CHECK_A4: 'En avance',
      CHECK_A5: 'Global',
      CHECK_A6: 'Local',
      CONFIRM_DELETE: 'Voulez-vous supprimer cet acteur?'
    },
    FN_SFIN: {
      APPROVE_DATE: 'Date Approbation',
      CLOSE_DATE: 'Date Clôture',
      SEARCH: 'Rechercher par Code ou par Nom ',
      NAME: 'Nom',
      NAME_CONTACT: 'Nom',
      TEL_CONTACT: 'Téléphone',
      EMAIL_CONTACT: ' Email',

      NAME_CONTACT_LONG: 'Contact (Nom)',
      TEL_CONTACT_LONG: 'Contact (Téléphone)',
      EMAIL_CONTACT_LONG: 'Contact (Email)',

      CONTACT: 'Personne à contacter',
      FINANCE_AMOUNT: 'Montant du Financement',
      AMOUNT: 'Montant',
      CURRENCY: 'Monnaie',
      EXCHANGE_RATE: 'Taux',
      LST_FIN: 'Sources de financement',
      ED_FIN: 'Détails source',
      CONFIRM_DELETE: 'Voulez-vous supprimer cette source?',
      EQUIV: 'Equiv. {{CURRENCY}}',
      ERROR_DATE: 'La date d\'approbation doit être inférieure à la date de clôture'
    },
    FN_TYPINDIC: {
      LST_TYP: 'Liste des types d\'indicateurs',
      ED_TYP: 'Détails type d\'indicateur',
      COL_COLOR: 'Couleur',
      COLOR_BLUE: 'Bleu',
      COLOR_CRYAN: 'Cyan',
      COLOR_GREEN: 'Vert',
      COLOR_YELOW: 'Jaune',
      COLOR_ORANGE: 'Orange',
      COLOR_RED: 'Rouge',
      COLOR_PINK: 'Rose',
      COLOR_PURPLE: 'Violet',
      COLOR_GREY: 'Gris',
      COLOR_BLACK: 'Noir',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce type d\'indicateur?'
    },
    DASHBOARD: {
      ATTENTE: 'En attente',
      VALIDER: 'Validé',
      PROGRESS_REPORT: 'Rapport d’avancement',
      ACTIVITIES_TAB: {
        PHYSIC: '% Réalisation',
        FINANCIAL: '% Budget',
        DISBURSED: '% Décaissé',
        TITLE: 'Taux de réalisation',
        COMPLETED_ACTIVITIES: 'Terminé',
        NOT_CARRIED_OUT_ACTIVITIES: 'Non réalisé',
        ON_GOING_ACTIVITIES: 'En cours',
        STATUS: 'Statut'
      },
      LOCATION: 'Niveau Géographique',
      ACTIVITY_COUNT: 'Nombre Activités: {{value}}',
      PROJECT_ACTIVITY: '{{value}} des activités du Projet',
      ACTIVITY_PROGRESS_LABEL: '{{percent}}% des activités ({{count}}) ont un % de réalisation compris entre {{min}}% et {{max}}%',
      INDICATOR_PROGRESS: 'Évolution de l\'indicateur',
      SPATIAL_VIEW: 'Représentation Spatiale',
      INDICATOR_PROGRESS_COMPARED: 'Progression de l’indicateur par rapport à la valeur cible finale (Fin de Projet) ',
      INDICATOR: 'Tableau de bord Indicateur',
      ACTIVITY: 'Tableau de bord {{activity}}',
      BOOKMARKED: 'Indicateurs Favoris',
      REFERENCE: 'Référence',
      FINAL: 'Fin Projet',
      END_TARGET: 'Valeur Cible Final',
      ACTUAL_VALUE: 'Valeur Actuelle',
      END_VALUE: 'Fin Projet',
      MAP: 'Carte',
      TITLE: 'Tableau de bord {{code}}',
      TITLE_PORTFOLIO: 'Tableau de bord',
      V_R: 'Valeur Référence',
      V_C: 'Valeur Cible',
      FROM: 'de',
      TO: 'à',
      PROCESS: 'En cours...',
      COMPLETE: 'Achèvement à',
      SIT_INDIC: 'Situation des indicateurs',
      REP_ACTIVITE: 'Performance du Projet',
      PHY: 'Taux physique',
      FIN: 'Taux Budgétaire ',
      GRAPHE_PERF: 'Graphe de Performance',
      SUIV_PTBA: '{{planannuel}} (Évolution des taux d\'exécution)',
      DASHBOARD: 'Tableau de bord',
      RESUME_PROJECT: 'Présentation',
      EVOLUTION_TABLE: 'Évolution de l’Indicateur ',
      REALIZE: '% réalisé Fin Projet',
      YEAR: 'Année',
      PERIOD: 'Période',
      INDICATORS: 'Indicateurs',
      ACTIVITIES: 'Activités',
      MSG_DBCLK: 'Double-clic pour plus de détails'
    },
    TIME_DIVISION: {
      PERIOD: 'Période',
      PTBA: 'PTBA en cours',
      PLAN_AN: 'Plan Annuel',
      LEVEL: 'Niveau',
      LEVEL_1: 'Global',
      LEVEL_2: 'Année',
      LEVEL_3: 'Période',
      ALLOW_TOTAL_GLOBAL: 'Permettre le dépassement du global',
      ALLOW_TOTAL_ANNUAL: 'Permettre le dépassement de l’année',
      START_LEVEL: 'Niveau Début Planification',
      GLOBAL: 'Globale',
      ANNUAL: 'Annuelle',
      PERIODIC: 'Période',
      HELP: {
        LEVEL_1: 'Le plan global permet de définir des objectifs et des budgets pour toute la durée du projet',
        LEVEL_2: 'Le plan global est décomposé en plans annuels pour chaque année du projet.',
        LEVEL_3: 'Chaque plan annuel est décomposé en plans périodiques selon les périodes définies précédemment'
      },
      BEGIN: 'Début',
      END: 'Fin',
      LABEL: 'Libellé'
    },
    GEOGRAPHIC_PLAN: {
      IMPORT_SHP: {
        INFO: 'Veuillez sélectionner la propriété qui contient le nom des localités',
        PROPERTY: 'Propriété'
      },
      REFRESH_GEO_CODING_FILE: 'Mettre à jour le Géoréférencement à partir d\'un fichier (geojson/shapefile)',
      REFRESH_GEO_CODING: 'Mettre à jour le Géoréférencement dépuis le serveur',
      COUNTRY: 'Pays',
      ADD__: 'Ajouter une localité',
      ADD: 'Ajouter sous-localité',
      SUM: 'Somme',
      MOY: 'Moyenne',
      MAXI: 'Maximum',
      MINI: 'Minimum',
      AGGREGA: 'Agrégation',
      GENERATE: 'Importer depuis le Serveur Géographique',
      LEVEL: 'Niveau',
      CHAMPPERSO: 'Champs Personnalisés',
      LABEL: 'Libellé',
      CONFIRM_DELETE: 'Voulez-vous supprimer le champ?',
      LONG: 'Longueur',
      ERROR_UPDATE_LONG: 'Impossible de modifier la longueur.',
      CONFIRM_DELETE_LEVEL: 'Voulez-vous supprimer ce niveau?',
      CONFIRM_DELETE_LOCALTION: 'Voulez-vous supprimer cette localité?',
      CODE: 'Code',
      DESCRP: 'Description',
      IMPORT: 'Importer depuis un Fichier Excel',
      IMPORT_SHAPEFILE: 'Importer depuis un fichier shapefile ou geojson',
      ERROR_STRUC: 'Une erreur a été détectée dans la structure du fichier',
      ERROR_FORMAT: 'Veuillez utiliser un fichier *.xsl!',
      ERROR_CANADD: 'Des localités sont utilisées!',
      UPLOAD_LABEL: 'Niveau {{level}}...',
      EXPORT: 'Exporter vers Excel'
    },
    INDICATOR_CAT: {
      TITLE_EDIT: 'MODIFIER LES CATEGORIES',
      TITLE: 'CATEGORIES DE DESAGREGATION',
      CODE: 'Code',
      LABEL: 'Libellé',
      AGGREGATE: 'Agrégation',
      OPTIONS: 'Options',
      OPTION: 'Option'
    },
    INDICATOR: {
      VALUE_TO_DISPLAY: 'Propriété à afficher',
      PORTFOLIOS: 'Portefeuilles',
      DATA_REFRESH: 'Actualiser les valeurs',
      FIXED_DENOMINATOR: 'Dénominateur fixe',
      FROM_CALCULATE_DATA_NUM_DEMO: 'Numérateur/Dénominateur',
      NUMERATOR: 'Numérateur',
      DENOMINATOR: 'Dénominateur',
      FROM_CALCULATE_DATA_NUM_DEMO_HELP: '',
      WEIGHT: 'Poids',
      MOY_WEIGHT: 'Moyenne Pondérée',
      PROJECT_INDICATORS: 'Indicateurs des projets',
      PROJECT_INDICATOR_SHARE: 'Indicateurs partagés',
      ADD_ITEM: 'Ajout Indicateur',
      OPTION_CATEGORY: 'Option',
      PROJECTS: 'Projets',
      CROSS_INDICATOR: 'Indicateur Transversal',
      CALCULATE_DATA: 'Calculer',
      ADD_ACTIVITY: 'Nouvel Indicateur',
      EDIT_ACTIVITY: 'Ajouter depuis la liste Indicateurs',
      ADD: 'Ajouter un indicateur',
      LAYER_URL: 'URL des couches',
      EDIT_LAYERS: 'Entrez l\'URL des couches',
      USE_COLORS: 'Paramètres des couleurs',
      USE_LAYERS: 'Paramètres des couches',
      OUTPUT: 'Sortant',
      CROSS_PROJECT: {
        NAME: 'Nom',
        DESCRIPTION: 'Description'
      },
      LINK_LOGFRAME: 'Liaison au Cadre Logique',
      NONE_AGGREGATE: 'Aucun',
      UNIT_COST: 'Cout Unitaire',
      INDICATOR_BASE: 'Liste des indicateurs',
      SEARCH: 'Rechercher un indicateur',
      SELECT_INDICATOR: 'Choisir un indicateur',
      SEARCH_LOCATION: 'Rechercher une localité',
      SEARCH_PERIOD: 'Rechercher une période',
      PERFORMANCE_INDICATOR: 'Indicateurs de performance',
      CROSS_INDICATORS: 'Indicateurs transversaux',
      MAPPING: 'Association',
      CATEGORY: 'Catégories de désagrégation',
      CROSS_DISAGGREGATION: 'Désagrégation Croisée',
      PARALLEL_DISAGGREGATION: 'Désagrégation Parallèle',
      DESCRIPTION_ONG: 'Détails',
      POSSIBLEVALUE: 'Valeurs Possibles',
      LESS_THAN: 'La valeur doit être inférieure ou égale à {{valeur}}',
      GREAT_THAN: 'La valeur doit être supérieure ou égale à {{valeur}}',
      EGAL_TO: 'La valeur doit être égale à {{valeur}}',
      BEETWEEB: 'La valeur doit être comprise entre {{valeur_min}} et {{valeur_max}}',

      ERROR_UPDATE: 'Une erreur s\'est produite lors de la mise à jour!',
      LEGEND: 'Paramètres SIG',
      LEGEND_COLOR: 'Légende des couleurs',
      ERROR_DECOUP_PLAN: {
        DATE_COLLECT: 'La date de collecte doit appartenir à la période',
        TITLE: 'Erreur',
        VALUE: 'Veuillez entrer une valeur',
        DATE_RELEVE: 'Veuillez entrer la date prévue',
        DATE_ALERT: 'Veuillez entrer la date d\'alerte',
        DATE_RELEVE_T: 'La date prévue doit être dans la période et dans la durée de l\'indicateur',
        DATE_A: 'La date d\'alerte doit être inferieure a la date prevue',
        CATEGORY_VALUE: 'La somme des valeurs désagrégées n\'est pas égale à la valeur agrégée',
        CATEGORY_VALUE_POSITIF: 'La valeur désagrégée doit être supérieure ou égale à 0',
        CATEGORY_VALUE_PARALLELE: 'Vérifier que la valeur saisie a été correctement désagrégée pour chaque catégorie',
        DENOMINATOR_ERROR: 'La valeur du dénominateur doit être differente de 0'
      },
      ENTRY_DATA_HELP: 'Les données sur l’indicateur sont saisies manuellement',
      PROJECT: {
        VALUE: 'Valeur',
        VALUES: 'Valeurs'
      },
      HELP: {
        TRANS: 'Un indicateur commun est un indicateur commun à toutes les activités (voir les détails dans le manuel).'
      },
      COUNT_ALL: 'Comptage',
      DATA_TABLE: {
        TEMPLATE: 'Formulaire',
        CALCULATION: 'Calcul',
        LOCALITIES: 'Colonne des Localités',
        PERIODS: 'Colonne des périodes',
        QUERY: 'Requête',
        CALCULATION_ITEM: 'Champ'
      },
      DOCUMENTS: 'Documents',
      COMPOSITE: 'Source des Données',
      SELECT: 'Liste',
      LOCATION: 'Localité',
      CALMETHOD: 'Méthode de calcul',
      CRITERIA: 'Critères ',
      CODE: 'Code',
      INDICATOR_EDIT: 'Edition Indicateur',
      KEY_KPI: 'Indicateur clé',
      NAME: 'Nom',
      NAME_FRENCH: 'Nom (Français)',
      CALCULATE_RATE: 'Entre dans le calcul du % réalisation {{lastLevel}}',
      NAME_ENG: 'Nom (Anglais)',
      DEF: 'Définition / Description',
      UNIT: 'Unité',
      TYPE: 'Type',
      CLASS: 'Classe',
      KEY: 'Indicateurs',
      VAL_POSS: 'Valeurs Possibles',
      FORMULA: {
        REQUIRED_ALL: 'Tous les indicateurs de la formule sont obligatoires ',
        CLEAR: 'Effacer',
        INVALID: 'Formule Incorrecte',
        NONEINDIC: 'Aucun indicateur dans la formule'
      },
      BEGIN: 'Date Début',
      END: 'Date Fin',
      FREQUENCE: 'Fréquence',
      NIV_GEO: 'Niveau Géographique',
      _NIV_GEO: 'Niveau Géographique',
      AGGREGA: 'Agrégation',
      GROUP_CIBLE: 'Groupe Cible',
      HYPOTHESE: 'Hypothèse',
      MOY_VERIF: 'Moyen de vérification',
      TECH_COLL: 'Technique de collecte',
      TARGET: 'Valeur Cible (Finale)',
      REF: 'Valeur de Référence',
      TARGET_VAL: 'Valeur Cible',
      REAL_VAL: 'Valeur Relevée',
      VARIANCE: 'Écart',
      TRANS: 'Indicateur Transversal',
      DISPLAY_TRANS: 'Uniquement les Indicateurs transversaux',
      SIMPL: 'Simple',
      COMPO: 'Complexe',
      CUMUL: 'Cumulatif',
      COMPL: 'Complexe',
      SUM: 'Somme',
      MOY: 'Moyenne',
      MAXI: 'Maximum',
      MINI: 'Minimum',
      NONE: 'Aucun',
      MOD_UN: 'La modification de l\'unité entraînera la perte de tous les relevés.\nVoulez-vous continuer?',
      MOD_NG: 'La modification du niveau géographique entraînera la perte de tous les relevés.\nVoulez-vous continuer?',
      MOD_DATE: 'La modification des dates pourrait entraîner la perte de plusieurs relevés.\nVoulez-vous continuer?',
      MOD_PER: 'La modification de la périodicité pourrait entraîner la perte de plusieurs relevés.\nVoulez-vous continuer?',
      MOD_CLS: 'La modification de la source des données entraînera la perte de tous les relevés.\nVoulez-vous continuer?',
      LABEL_VP: 'Libellé',
      POUND: 'Pondération',
      PLAN: 'Planification Indicateur',
      PLAN_: {
        TITLE: 'Valeurs Cibles',
        VALUE: 'Valeur Cible'
      },
      TRACK_: {
        ACTUAL_DATE: 'Date de relevé',
        VALUE: 'Valeur',
        TITLE: 'Valeur Relevée '
      },
      DATE_R: 'Date prévue',
      VALUE: 'Valeur',
      BUDGET: 'Budget',
      COST: 'Coût',
      REL: 'Relevé Indicateur',
      RAPP: 'Rapport Indicateur',
      PIC: 'Image',
      IN_MON: 'Suivi Indicator',
      MON: 'Suivi',
      DATA_FIN_: 'Données Financières',
      DATA_FIN: 'Données Financières',
      FOLLOW_FIN: 'Suivi financier',
      DISPLAY_CODE_LOC: 'Afficher le code des localités',
      COMMENTS: 'Commentaires',
      RESPO: 'Responsable',
      DISPLAY_PL: 'Afficher ',
      ACHEVE: '% Réalisé',
      SOCIO_ECO: 'Socio-économique',
      SPATIAL: 'Spatial',
      GRAPHE_COMBINE: 'Graphe Combiné',
      CONFIRM_DELETE_RELEVE: 'Voulez-vous supprimer ce relevé?',
      CONFIRM_DELETE_PLAN: 'Voulez-vous supprimer cette planification?',
      CONFIRM_DELETE: 'Voulez-vous supprimer cet indicateur?',
      CONFIRM_DELETE_CROSS: 'La suppression de l\'indicateur transversal supprimera toutes les données liées à l’indicateur',
      DATE_ALERT: 'Date Alerte',
      ERRORS: {
        VP_LABEL: 'Le libellé est déjà attribué',
        VP_VALUE: 'La pondération est déjà attribuée'
      },
      GLOBAL: {
        TITLE: 'Valeurs Référence et Cible finale Indicateur',
        TITLE_: 'Valeurs Cible et Réalisée',
        LOCATION: 'Localité',
        BEGIN: 'Date Début',
        END: 'Date Fin',
        TARGET: 'Cible finale (Fin Projet)',
        REF: 'Valeur de référence',
        COMMENTS: 'Commentaires',
        ERROR: {
          REF_VALUE_DESEGREGATE: 'La somme des valeurs de réference désagrégées n\'est pas égale à la valeur de réference',
          TARGET_VALUE_DESEGREGATE: 'La somme des valeurs cible désagrégées n\'est pas égale à la valeur cible',
          DATE: 'La date fin doit être supérieure à la date début',
          Target_Value: 'Veuillez entrer la cible!',
          REF_VALUE: 'Veuillez renseigner la valeur de référence!',
          DATE_D: 'Veuillez renseigner la Date Début!',
          DATE_F: 'Veuillez renseigner la Date Fin!'
        }
      },
      FROM_ENTRY_DATA: 'Saisie manuelle ',
      FROM_CALCULATE_DATA: 'Calcul automatique (Formule)',
      FROM_DATABASE: 'Calcul automatique (Fichier)',
      OVERVIEW_MENU: {
        SETTINGS: 'Paramètres Indicateur',
        BASELINE: 'Valeurs Initiales',
        TARGET: 'Valeurs Cibles',
        ACTUAL: 'Suivi',
        REPORT: 'Rapports'
      },
      MENU: {
        EDIT: 'Éditer',
        PLAN: 'Valeurs cibles',
        RELE: 'Valeurs relevées',
        FICHE: 'Voir la Fiche',
        IMAGE: 'Image (300x400)',
        RIGHT: 'Droits',
        GLOB_RELE: 'Valeurs initiales et cible finales',
        RAPPORT: 'Notes',
        SPATIAL: 'Carte',
        LEGEND: 'Légende',
        WORKFLOW: 'Workflow',
        CUSTOM_REPORT: 'Rapports Personnalisés'
      },
      RIGHT: {
        TITLE: 'Droits Indicateur',
        BASELINE: 'Valeurs Initiales et Cible Finales',
        TARGET: 'Valeurs Cibles',
        ACTUAL: 'Valeurs Relevées'
      },
      PLAN_TARGET: {
        TITLE: 'Valeurs relevées'
      },
      TRACK: {
        TITLE: 'Suivi des indicateurs'
      },
      DASHBOARD: {
        TAB: 'Suivi Indicateur',
        G: 'Global',
        Y: 'Année',
        P: 'Période',
        TIMESCALE: 'Échelle de temps',
        DIFFERENCE: 'Différence',
        FINAL_TARGET: 'Valeur cible globale',
        BASELINE: 'Valeur Reference',
        TARGETS: 'Cibles',
        ACTUALS: 'Relevés',
        RATE_TARGET: '%',
        MAP: 'Carte',
        TITLE2: 'Taux de réalisation par ',
        TITLE2_: 'Taux de réalisation par projets',
        TITLE1_1: 'Suivi Indicateur – Réalisations / Cibles',
        TITLE1_2: 'Graphe d’évolution de l’indicateur par année',
        LEGEND: 'Taux de réalisation',
        TITLE: 'Tableau de Bord Indicateur',
        PAGE1TITLE: 'Valeurs Relevées / Valeurs Cibles ',
        PAGE2TITLE: 'Évolution de l\'indicateur',
        PAGE2SUBTITLE1: 'Évolution de l’Indicateur',
        PAGE2SUBTITLE2: 'Graphe d’Évolution de l’Indicateur pour ',
        TITLE_1_3: 'Valeurs Relevées / Valeurs cibles par ',
        TITLE_1_3_: 'Valeurs Relevées / Valeurs cibles par projet'
      },
      SHEET: {
        SHEET: 'Fiche',
        TITLE: 'Fiche de l\'indicateur',
        NAME: 'Nom de l’indicateur',
        DESCRP: 'Description',
        TYPE: 'Type',
        RAT_CL: 'Rattachement Cadre logique',
        U_MES: 'Unité de mesure',
        PER_COL_SUI: 'Périodicité de collecte et de suivi',
        NG: 'Niveau Géographique',
        RESPO: 'Responsable(s) de Collecte',
        TECH: 'Technique de collecte',
        SRC: 'Source de vérification',
        FORMULA: 'Formule de calcul',
        VAL_REF: 'Valeur de référence',
        VAL: 'Valeur',
        EVO: 'Évolution de l’Indicateur',
        FINAL_TARGET: 'Valeur cible globale',
        BASELINE: 'Valeur Reference',
        TARGETS: 'Cibles',
        ACTUALS: 'Relevés'
      },
      SPATIALS: {
        DATA: 'Couches de données',
        LAYERS: 'Couches',
        TITLE: 'Paramètres Couche',
        LAYER: 'Couche',
        LABEL: 'Libellé',
        PERIODIC: 'Périodique',
        URL: 'URL',
        PROPERTY: 'Format',
        DATA_LAYER: 'Couche de données',
        PIN: 'Marqueur',
        SERVICE: 'Service',
        SERVER_URL: 'Source Données (Url Serveur)',
        LAYERS_ID: 'Identifiant de la couche',
        ADD_PARAMS: 'Paramètres additionnels',
        AUTHORIZATION: 'Authentification',
        AUTHORIZATION_TOKEN: 'Jeton',
        DISPLAY_POPUP: 'Afficher des informations'
      },
      USER: 'Utilisateur'
    },
    INDICATOR_VISUALISATION: {
      CATEGORIES: 'Catégorie',
      DESEGREGATE_VALUE: 'Afficher les valeurs désagrégées',
      CIRCLE: 'Cercle',
      DELTA: 'Couche par défaut',
      DATA: 'Données',
      LAYERS: 'Couches',
      LEGEND: 'Légende',
      FORMATTING: 'Format',
      BRIGHTNESS: 'Luminosité',
      INDICATOR: 'Indicateur',
      VALUE: 'Valeur',
      YEAR: 'Année',
      PERIOD: 'Période',
      TITLE: 'Représentation spatiale',
      SELECT_INDICATOR: 'Sélectionner un Indicateur',
      DISPLAY_DATA: 'Afficher les données',
      SELECT_GEO: 'Sélectionner un niveau géographique',
      SELECT_LAYER: 'Couches',
      COLLECTED_DATA: 'Données Collectées',
      ERROR_INTERVAL: 'Une erreur a été détectée dans les intervalles'
    },
    LOGICAL_PLAN: {
      CROSS_CUTTING_KPIS: 'Afficher les indicateurs transversaux',
      RATE_INDIC_HELP: 'En cochant cette option, les activités seront suivies uniquement au moyen des indicateurs dans le module Indicateur de DELTA',
      ADD: 'Ajouter',
      HAS_INDIC: 'Associer un indicateur',
      LABEL: 'Libellé',
      LONG: 'Longueur',
      ERROR_UPDATE_LONG: 'Impossible de modifier la longueur.',
      CONFIRM_DELETE_LEVEL: 'Voulez-vous supprimer ce niveau?',
      CONFIRM_DELETE: 'Voulez-vous supprimer cette donnée?',
      CODE: 'Code',
      DESCRP: 'Description',
      LABEL_C: 'Nom',
      LABEL_L: 'Description',
      IMPORT: 'Importer Cadre Logique',
      WEIGHT_AUTO: 'Poids Automatique',
      BUDGET: 'Budget',
      COST: 'Coût Réel',
      SUM_WEIGHT: '∑ Poids',
      BY: 'Déterminé par',
      WEIGHT: 'Poids',
      RATE_INDIC: 'Taux des indicateurs',
      ERROR_STRUC: 'Une erreur a été détectée dans la structure du fichier',
      ERROR_FORMAT: 'Veuillez utiliser un fichier *.xsl!',
      ERROR_CANADD: 'Des éléments sont utilisés!',
      UPLOAD_LABEL: 'Niveau {{level}}...',
      HELP: {
        CODE: 'Entrer le code de \'{{LIBELLE_NCL}}\'',
        LABEL_C: 'Entrer \'{{LIBELLE_NCL}}\'',
        LABEL_L: 'Description {{LIBELLE_NCL}}'
      },
      ERROR: {
        DELETE_ACTIVITY: 'L’activité contient des données et ne peut être supprimée !',
        DELETE: 'La composante contient des données et ne peut être supprimée !',
        DELETE_INDICATOR: 'Au moins un indicateur est lié à la composante!'
      }
    },
    FLAG: {
      LABEL: 'Légende couleur',
      MIN_VALUE_INDICATOR: 'Valeur Indicateur supérieure ou égale à',
      MIN: 'Min',
      MAX: 'Max',
      COLOR: 'Couleur',
      MIN_VALUE: 'Valeur supérieure ou égale à',
      ERROR: {
        LABEL: 'Le libellé existe déjà.',
        CHEVAL: 'L\'intervalle est à cheval sur un autre',
        IN: 'L\'intervalle est inclus dans un autre',
        OUT: 'L\'intervalle inclus un autre'
      }
    },
    FRAME_RESULT: {
      DISPLAY_NO_CUMUL_DATA: 'Afficher les valeurs non cumulatives',
      TITLE_2: 'Cadre des résultats (Statut actuel)',
      TITLE_1: ' Cadre des Résultats (Évolution annuelle)',
      FINAL_TARGET: 'Valeur Cible Finale',
      OPEN_CHART: 'Afficher le Graphe',
      DETAILS: 'Afficher le Detail',
      ACTUAL: 'Valeur Actuelle',
      VALUE: 'Valeur',
      DATE: 'Date',
      ALL_LOCATION: 'Toutes les localités',
      ALL_PROJECT: 'Tous les projets',
      COMMENT: 'Commentaire',
      FORMAT: 'Format Standard',
      INDIC: 'Indicateur',
      T_I: 'Type Indicateurs',
      IMPACT: 'Impact',
      TARGET: 'Cible',
      REF: 'Référence',
      UNIT: 'Unité',
      TREEVIEW: 'Arborescence',
      LISTVIEW: 'Cadre des Résultats',
      ONLY_CROSS: 'Transversales uniquement',
      DISPLAY_LEVEL: 'Afficher les Niveaux du Cadre logique',
      DISPLAY: 'Afficher'
    },
    ACTIVITY: {
      UPLOAD_INDEX: 'Lien Logiciel comptable',
      ENDING_ACTIVITY: 'Cette activité est terminée',
      NAME_ACT: 'Description',
      LINK_PL: 'Liaison Cadre logique',
      SETTINGS: 'Paramètres',
      YTD: 'Total',
      UNIT_: 'Unité',
      ONLY_CROSS: 'Transversal',
      ONLY_PLAN: 'Planifié',
      ONLY_FINANCIAL: 'Budget',
      COMPLETED_ACTIVITIES: 'Achevé',
      ON_GOING_ACTIVITIES: 'En cours',
      NOT_CARRIED_OUT_ACTIVITIES: 'Non commencé',
      REVIEW_PLAN: 'Plan révisé',

      BUDGET_BREAKDOWN: 'Désagrégation budgétaire',
      TREEVIEW: 'Arborescence',
      DISPLAY_LEVEL: 'Afficher Niveaux Cadre logique',
      ENDING: 'Terminée',
      DISPLAY: 'Affichage',
      ACTIVITIES: 'Activités',
      ADD: 'Ajout',
      DBL_PERIOD: 'Double cliquez pour les périodes',
      UPLOAD: {
        USE_BUDGET_BREAKDOWN: 'Avec la ventilation budgétaire',
        ACTIVITY: 'Activité',
        LOCALITY: 'Localité',
        DATE: 'Date',
        AMOUNT: 'Montant',
        CATEGORYBUDGET: 'Catégorie Budgétaire',
        FINANCING: 'Financement'
      },
      DISTRIBUTE: {
        CATEGORY: 'Catégorie',
        FINANCE: 'Financement',
        AMOUNT: 'Montant',
        TITLE: 'Ventilation du montant',
        SUBTITLE: 'Montant à ventiler',
        SELECT_CATEGORY: 'Sélectionner une catégorie',
        SELECT_FINANCE: 'Sélectionner une source de financement',
        ERROR_FINANCE: 'Le total doit être égal au montant à ventiler'
      },
      FROM: {
        FROM: 'A partir',
        DOCUMENT: 'Base Documentaire',
        LOCAL: 'Disque dur local',
        CLOUD: 'Cloud'
      },
      CROSS_ACTIVITY: 'Activités Transversales',
      DISPLAY_CROSS_ACTIVITY: 'Uniquement Activités Transversales',
      OUTPUT: 'Sortant',
      RATE: 'Pourcentage',
      FUNDING_SOURCE: 'Source de financement',
      PROJECT: 'Projet',
      AGENCIES: 'Agence',
      RELATED_INDICATOR: 'Indicateurs Liés',
      SEARCH: 'Rechercher une activité',
      URL: 'Lien Externe',
      DOCUMENT: 'Document',
      DOCUMENTS: 'Documents',
      ERROR_DOCUMENT: 'Veuillez renseigner tous les champs.',

      NAME: 'Nom du fichier',
      START: 'Date Début',
      END: 'Date Fin',
      UNIT: 'Unité',
      TYPE: 'Type',
      DISPLAY_UNIT_COST: 'Afficher le coût unitaire',
      UNIT_COST: 'Coût Unitaire',
      CAL_AUTO: 'Calcul Automatique',
      BUDGET: 'Budget',
      COST_A: 'Coût Réel',
      RESPO: 'Responsable',
      COMMENT: 'Commentaires',
      IMPUT: 'Intrants',
      TOTAL: 'Total',
      CONT_LOC: 'Suivre les marchés par localités',
      CONT_UNK: 'Marché Unique',
      NUM: 'Numéro',
      OWN: 'Titulaire',
      OBJECT: 'Objet',
      COST: 'Montant',
      SIGN: 'Signature',
      BEGIN_CONTRACT: 'Date Début',
      END_CONTRACT: 'Date Fin',
      BEGIN_D: 'Date Début',
      END_D: 'Date Fin',
      SELECT_ACT: 'Sélectionner un acteur',
      ACTOR_IMPL: 'Acteurs Impliqués',
      UPLOAD_FINANCE: 'Importer Budget/Coût',
      UPLOAD_FINANCE_CATEGORY: 'Importer Ventilation Coût/Budget',
      DEFINITION: 'Détail',
      NOTE: 'Notes',
      ROLE: 'Rôle',
      TOTAL_ALERT: 'Le total doit être égal à 100',
      STEP: 'Étape',
      ERROR: {
        SOURCE: 'Le pourcentage doit être compris entre 0 et 100'
      },
      HELP: {
        CAL_AUTO: 'Le budget et/ou le Coût réel de l\'activité seront calculés automatiquement sur la base de la quantité et du coût unitaire'
      },
      MENU: {
        PLAN: 'Planifier',
        EDIT: 'Modifier',
        REAL: 'Relever',
        IMAGE: 'Image (300x400)',
        PERMISSIONS: 'Autorisations',
        WORKFLOW: 'Workflow'
      },
      WORKFLOW: {
        TITLE: 'Workflow',
        LEVEL: 'Niveau',
        NAME: 'Libellé',
        COLOR: 'Couleur',
        USER: 'Utilisateur',
        REAL: 'Réalisation',
        SELECT_USER: 'Sélectionner un utilisateur'
      },
      PLAN: {
        PLANNING: 'Planifiée',
        ERROR_PERIOD_YEAR: 'Aucune période n\'est définie pour l\'année!',
        CONFIRM_DELETE: 'Voulez-vous supprimer la planification?',
        TITLE: 'Planifier',
        REVIEW: 'Révisée',
        TO_REVIEW: 'Réviser',
        INIT: 'Initiale',
        REVIEW_: 'Planning Révisé',
        INIT_: 'Planning Initial',
        GLOBAL: 'Global',
        DURATION: 'Planification',
        TYPE: 'Type',
        YEAR: 'Année',
        PERIOD: 'Période',
        PLANNED: 'Prévu',
        LOCATION: 'Localité',
        AMOUNT: 'Prévu',
        COST: 'Budget',
        BEGIN: 'Début',
        END: 'Fin',
        AMOUNT_PLANNED: 'Prévu',
        COST_PLANNED: 'Budget',
        BEGIN_PLANNED: 'Début Planifié',
        END_PLANNED: 'Fin Planifiée',
        ERROR_FINANCE: 'La somme des pourcentages doit être égale à 100%',
        ERROR_SUP_QTE: 'La quantité doit être supérieure ou égale à {{QTE_ERROR}}',
        ERROR_INF_QTE: 'La quantité doit être inférieure ou égale à {{QTE_ERROR}}',
        ERROR_SUP_BUDGET: 'Le budget doit être supérieur ou égal à {{BUDGET_ERROR}}',
        ERROR_INF_BUDGET: 'Le budget doit être inférieur ou égal à {{BUDGET_ERROR}}',
        ERROR_PLAN_ANNUEL: 'La planification annuelle n\'a pas été définie',
        ERROR_DATE: 'La date fin doit être supérieure à la date début.',
        ERROR_QTE: 'La quantité doit être positive',
        ERROR_BUDGET: 'Le budget doit être positif',
        ERROR_COST: 'Le cout doit être positif',
        ERROR_PERIOD: 'La période doit être comprise entre {{BEGIN}} et {{END}}',
        HELP: {
          AMOUNT: 'Saisir la quantité prévue ou objectif',
          COST: 'Saisir le budget alloué'
        },
        SEARCH_LOCATION: 'Rechercher une localité'
      },
      REAL: {
        ACTUAL: 'Réaliser',
        TITLE: 'Suivre',
        LOCATION: 'Localité',
        AMOUNT: 'Réalisé',
        COST: 'Cout',
        BEGIN: 'Date Début',
        END: 'Date Fin',
        AMOUNT_ACTUAL: 'Réalisé',
        COST_ACTUAL: 'Coût',
        BEGIN_ACTUAL: 'Début Réel',
        END_ACTUAL: 'Fin Réelle',
        COMMENTS: 'Commentaires',
        HELP: {
          AMOUNT: 'Saisir la valeur réelle',
          COST: 'Saisir le cout réel'
        }
      },
      DASHBOARD: {
        REFERENCE: 'Référence',
        FINAL: 'Cible finale',
        PROJECT: 'Projet',
        YEAR: 'Année',
        HELP: {
          SHARE: 'Partager le rapport'
        },
        RATE_TARGET: '% Physique',
        RATE_BUDGET: '% Budget',
        DISPLAY_COST: 'Afficher les couts',
        ALL_LOC: 'Toutes les localités',
        ANY_LOC: 'Une localité',
        BY_LOC: 'Par Localité',
        TITLE: 'Rapport',
        RATE_PHY: 'Taux physique',
        RATE_FINANC: 'Taux budgetaire',
        TITLE2: '% réalisé par localité',
        TITLE2_: '% réalisé par projet',
        TITLE1_1: 'Évolution de l\'indicateur (Global)',
        TITLE1_2: 'Évolution annuelle de l\'indicateur',
        TITLE_GRAPHE_SECOND_PERIOD: 'Graphe des Taux de réalisation par Localité sur',
        TITLE_GRAPHE_SECOND_YEAR: 'Graphe des Taux de réalisation par Localité de l\'année ',
        TITLE_GRAPHE_SECOND_YEAR_PROJECT: 'Graphe des Taux de réalisation par Projet de l\'année ',
        TITLE_GRAPHE_SECOND: 'Rapport d\'activité',
        TITLE_GRAPHE_SECOND_PROJECT: 'Statut de l\'activité par Projet',
        TITLE_GRAPHE_FIRST_YEAR: 'Graphe des Taux de réalisation ({{year}}) – Localité : ',
        TITLE_GRAPHE_FIRST: 'Graphe des Taux de réalisation par Localité',
        TITLE_GRAPHE_FIRST_PROJECT: 'Graphe des Taux de réalisation par Projet'
      },
      AWPB: {
        TITLE: 'Suivi Plan de Travail et Budget Annuel (PTBA)',
        TITLE_YEAR: 'Programme de travail et budget Multi-annuel'
      }
    },
    CROSS_ACTIVITY: {
      CODE_ACTIVITY: 'Code',
      NAME_ACTIVITY: 'Libellé',
      DATE_BEGIN_ACTIVITY: 'Date Début',
      DATE_END_ACTIVITY: 'Date Fin',
      UNIT_COST_ACTIVITY: 'Cout Unitaire',
      CAL_AUTO: 'Calcul Automatique',
      COMMENTS_ACTIVITY: 'Commentaires'
    },
    PLAN_BUDGET_ACTIVITY: {
      ACTUAL_RATE: '% Réalisé',
      BUDGET_RATE: '% Budget',
      STATUS_GRAPH: 'Graphe Suivi d’exécution',
      ACTUALS: 'Réalisé',
      TARGETS: 'Prévu',
      ACTIVITY_PROGRESS: 'Taux de réalisation {{activity}}'
    },
    GANTT: {
      TITLE: 'Chronogramme d’activité',
      LIMIT: 'Limite Période',
      TYPE: 'Type Planning',
      START: 'Démarré',
      END: 'Fini',
      DISPLAY: 'Afficher niveaux supérieurs',
      LEGEND: 'Légende',
      PLAN: 'Planning',
      ACTUAL: 'Réel',
      ASSIGN_TO: 'Sélectionner',
      ACTOR: 'Acteurs',
      GEO: 'Plan Géographique',
      ZOOM: 'Échelle de temps',
      REAL: 'Valeurs Réelles',
      ACTIVITY: 'Activité',
      ACTUAL_BEGIN: 'Démarré',
      ACTUAL_END: 'Fini',
      PLAN_BEGIN: 'Prévu',
      PLAN_END: 'Fin',
      YEAR: 'Année',
      MONTH: 'Mois',
      WEEK: 'Semaine',
      TIMELINE: 'Chronologie',
      ON_GOING_ACTIVITY: 'En cours',
      DELAYED_ACTIVITY: 'En retard',
      NOT_STARTED_ACTIVITY: 'Non démarré',
      COMPLETED_ACTIVITY: 'Achevé'
    },
    CONTRACT: {
      LOC: 'Localité',
      N_MAR: 'N° Contrat',
      PROGRESS: 'En cours',
      STEP: 'Étape'
    },
    PROCUREMENT: {
      CONFIRM_DELETE: 'Voulez-vous supprimer le marché?',
      CODE: 'Code',
      LABEL: 'Libellé'
    },
    REPORT_CAT: {
      LABEL: 'Catégorie',
      PERIOD: 'Périodicité',
      DELAI: 'Délai de production',
      RECIPIENT: 'Destinataire',
      FORMAT: 'Modèle Rapport',
      RESPO: 'Responsable',
      SELECT: 'Importer',
      CHANGE: 'Modifier',
      REMOVE: 'Supprimer',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce type de rapport?',
      PERSON_ALERT: 'Acteurs à informer',
      HELP: {
        DELAI: 'Nombre de jours',
        LABEL: 'Entrer la Catégorie de document'
      }
    },
    DOCUMENT: {
      DELETE_FOLDER: 'Supprimer le dossier',
      DELETE_FILE: 'Supprimer le fichier',
      SUCCESS_ADD_FOLDER: 'Dossier ajouté',
      DOCUMENT_DATA: 'Données du document',
      COVER: 'Page de couverture',
      VIEW: 'Vues',
      DOWNLOAD: 'Téléchargements',
      NEWFOLDER: 'Créer un nouveau dossier',
      NOT_VALID_EMAIL_OR_URL: 'Le contact n\'est pas un site ou un email valide',
      CONTACT: 'Contact (email ou site web)',
      LANGUAGE: 'Langue',
      ADD_INFO: 'Sélectionner un dossier pour télécharger un document',
      RIGHT_CLICK_INFO: 'Utiliser le clic droit pour ajouter ou éditer un élément',
      DBL_CLICK_INFO: 'Double clic pour ouvrir un élément',
      SEE_DETAILS: 'Détails',
      FILTERS: 'Filtres',
      PAGING_SUMMERY: '{{current}} de {{total}} pages ({{count}} fichiers)',
      FORMAT: 'Format Document',
      FORMATS: {
        TEXT: 'Texte',
        IMAGE: 'Image',
        VIDEO: 'Vidéo',
        AUDIO: 'Audio',
        PDF: 'PDF'
      },
      FILTER: {
        PROPERTY: 'Champ',
        OPERAND: 'Condition',
        VALUE: 'Valeur'
      },
      ACTIONS: {
        ADD_FOLDER: 'Nouveau Dossier',
        EDIT_FOLDER: 'Modifier le Dossier',
        REFRESH: 'Actualiser',
        ADD_FROM_FILE: 'Ajouter depuis un fichier',
        ADD_FROM_LINK: 'Ajouter depuis un lien',
        ADD_EDIT_DOC: 'Rédiger un document'
      },
      FILE_NAME: 'Fichier',
      REPORT: 'Rapport',
      INSERT_DELTA: 'Insérer un élément de DELTA',
      CLS: 'Classeur',
      MAIN: 'Description',
      ADD_FILE: 'Nouveau Dossier',
      ADD_FILE_: 'Dossier',
      ADD_DOC: 'Ajouter un Document depuis un fichier',
      ADD_DOC_EXT: 'Ajouter depuis un lien',
      ADD_DOC_RED: 'Rédiger un document',
      CODE: 'Code',
      DESCRIP: 'Description',
      CONFIRM_DELETE_FOLDER: 'Êtes-vous sûr de vouloir supprimer ce dossier et tout ce qu’il contient ?',
      CONFIRM_DELETE_THEME: 'Voulez-vous supprimer le theme?',
      CONFIRM_DELETE_TYPE: 'Voulez-vous supprimer le type?',
      CONFIRM_DELETE_PHYS: 'Voulez-vous supprimer l\'emplacement physique?',
      CONFIRM_DELETE_FILE: 'Voulez-vous supprimer ce document?',
      RECIPIENT: 'Partage',
      RECIPIENT_TOOLTIP: 'Partager le Document avec',
      REFERENCE: 'Référence',
      PUBLICATION: 'Date Publication',
      PUBLIC: 'Afficher sur le portail',
      PUBLIC_HELP: 'Si cette option est sélectionnée, le document sera accessible sur le portail public.',
      TITLE: 'Titre',
      TITLE_HELP: 'Entrer le titre du document',
      URL_HELP: 'Entrer le lien vers document',
      AUTHOR: 'Auteur(s)',
      DOC_TYPE: 'Type document',
      DOC_TYPES: 'Types document',
      THEME: 'Thème',
      THEMES: 'Thèmes',
      PHYS_LOC: 'Emplacement physique',
      SUMMARY: 'Résumé',
      SUMMARY_HELP: 'Entrer un bref résumé du document',
      LABEL: 'Libellé',
      USERS: 'Utilisateurs',
      UPLOAD: 'Téléverser un document',
      SELECT_DOC_TYPE: 'Sélectionner un Type document',
      SELECT_PHYS_LOC: 'Sélectionner un emplacement physique',
      SELECT_THEME: 'Sélectionner un thème'
    },
    COMMUNITIES: {
      CHATS: 'Converser',
      SUCCESS_ADD: 'La communauté à été ajoutée',
      SUCCESS_UPDATE: 'La communauté à été modifiée',
      SUCCESS_DELETE: 'La communauté à été supprimée',
      IMAGE: 'Image',
      NAME: 'Libellé',
      DESCRIPTION: 'Description',
      MEMBERS: 'Membres',
      EDIT_COMMUNITY: 'Edition Communauté',
      REQUIRED: 'Obligatoire !',
      DOUBLE: 'Le libellé est déjà attribué !'
    },
    DOCUMENT_CALENDAR: {
      NEW_DOC: 'Ajouter un nouveau Document',
      EXIST: 'Sélectionner dans la Bibliothèque',
      DOCUMENT: 'Document',
      SELECT_FOLDER: 'Sélectionner le dossier pour stocker le document'
    },
    PHOTOLIBRARY: {
      PHOTOS: 'Photos',
      VIDEOS: 'Vidéos',
      REF: 'Référence',
      TITLE: 'Titre',
      DATE: 'Date',
      PUBLIC: 'Afficher sur le portail',
      LOCATION: 'Localité',
      LAT: 'Latitude (GPS)',
      LONG: 'Longitude (GPS)',
      AUTHOR: 'Auteur',
      SUMMARY: 'Résumé',
      URL_VIDEO: 'Vidéo URL (YouTube)',
      ADD_DOC: 'Ajouter une Photo',
      ADD_DOC_: 'Photo',
      FOLDER: 'Classeur',
      ADD_FILE_: 'Dossier',
      ADD_FILE: 'Nouveau Dossier',
      ADD_VIDEO: 'Ajouter une Vidéo',
      ADD_VIDEO_: 'Vidéo',
      CONFIRM_DELETE_PHOTO: 'Voulez-vous supprimer cette photo?',
      CONFIRM_DELETE_VIDEO: 'Voulez-vous supprimer cette vidéo?',
      TITLE_ERROR_DELETE: 'Le dossier n\'est pas vide'
    },
    G_U: {
      RIGHT_INFO: 'Préciser le profil de l\'utilisateur suivant les projets auxquels il a accèss',
      PASSWORD_CHANGED: 'Le mot de passe a été changé',
      DISPLAY_DASHBOARD: 'Tableau de bord',
      DISPLAY_MAP: 'Carte',
      FUNCTION: 'Fonction',
      STANDARD_USER: 'Utilisateur Standard',
      ADMIN_USER: 'Administrateur',
      VIEW_USER: 'Invité',
      COLLECT_USER: 'Collecte Données',
      COLLECTER_USER: 'Collecte Données',
      CUSTOM_USER: 'Droits Spécifiques',
      NO_ACCESS: 'Pas d\'accès',
      NO_ACCESS_PORTFOLIO: 'Pas d\'accès aux projets',
      PROJECT: 'Projet',
      DETAIL: 'Détails Utilisateur',
      PROFILE: 'Profil Utilisateur',
      EMAIL: 'Email',
      NAME: 'Nom',
      SUP: 'Superviseur',
      PRENOM: 'Prénom(s)',
      AGENCIES: 'Agence',
      MDP: 'Mot de passe',
      CONF: 'Confirmation',
      ACTOR: 'Acteurs',
      STAFF: 'Acteur',
      CHANGE_PASSWORD: 'Changer le mot de passe',
      NEW_PASSWORD: 'Nouveau mot de passe',
      SEARCH: 'Rechercher par l\'email et le nom',
      SELECT_AGENCIES: 'Sélectionner une agence',
      SELECT_STAFF: 'Sélectionner un acteur',
      EXPIRE_HELP: 'Date d’expiration du mot de passe',
      EXPIRE: 'Date Expiration',
      ACTORLIST: 'Assigner des acteurs',
      CONFIRM_DELETE: 'Voulez-vous supprimer cet utilisateur?',
      ERROR_EMAIL: 'L\'utilisateur existe déjà',
      ERROR_PSW: '8 caractères minimum',
      ERROR_CONF_PSW: 'Les mots de passe ne sont pas identiques',
      VALIDATION_RIGHT: 'Droits de Validation',
      VALIDATION_RIGHT_SET: 'Droits de Validation',
      VALIDATION_RIGHT_TITLE: 'Sélectionner les utilisateurs dont les données sont validées par {{users}}',
      PORTFOLIO_USER: 'Accès Portefeuille',
      EDIT_PROFIL: 'Cliquer pour détails',
      EDIT_PROFIL_USER: 'Compte Utilisateur',
      ERROR_PSW_NOT_CONFORM: 'Le mot de passe ne correspond pas',
      ERROR_PSW_NOT_EXIST: 'Vous n\'êtes pas autorisé à modifier le profil',
      ERROR__NOT_ALLOW: 'Vous n\'êtes pas autorisé à modifier le profil',
      SEX: 'Sexe',
      MALE: 'Masculin',
      FEMALE: 'Féminin',
      UNLOCK: 'Déverrouiller',
      LOCK: 'Verrouiller',
      HELP: {
        EMAIL: 'L’email est utilisé pour se connecter'
      },
      FEEDBACK: 'Suggestions',
      FEEDBACK_ADD: 'Partager vos suggestions',
      FEEDBACK_DETAIL: "Détails de la suggestion"
    },
    GR_U: {
      HELP: {
        TITLE: 'Créer des groups et assigner des localités et éléments du plan logique aux membres des groupes',
        CODE: 'Enter Group Name',
        LABEL: 'Enter Group Description'
      },
      CODE: 'Nom',
      LABEL: 'Description',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce groupe?',
      USER: 'Assigner des utilisateurs',
      LOCATION: 'Localités',
      LOCATION_ONLY: 'Localité seule',
      LOCATION_ALL: 'Sous-localités',
      C_ONLY: 'Élément Seul',
      C_ALL: 'Y compris sous-éléments '
    },
    RIGHT: {
      HELP: {
        TITLE: 'Assigner les droits aux utilisateurs selon les profils',
        LABEL: 'Entrer le nom du profil',
        DESCRIPTION: 'Description'
      },
      USER: 'Utilisateurs',
      ADMIN: 'Administrateur',
      PRIVIL: 'Droits',
      LABEL: 'Profil',
      DESCRIPTION: 'Description',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce profil?'
    },
    TYPACTIVITY: {
      LST_TYP: 'Types d\'activités',
      ED_TYP: 'Détails Type d\'indicateur',
      COL_COLOR: 'Couleur',
      COLOR_BLUE: 'Bleu',
      COLOR_CRYAN: 'Cyan',
      COLOR_GREEN: 'Vert',
      COLOR_YELOW: 'Jaune',
      COLOR_ORANGE: 'Orange',
      COLOR_RED: 'Rouge',
      COLOR_PINK: 'Rose',
      COLOR_PURPLE: 'Violet',
      COLOR_GREY: 'Gris',
      COLOR_BLACK: 'Noir',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce type?'
    },
    FORMULAR: {
      SURVEY_DELETE: "Écraser les saisies",
      SURVEY_VERSION: "Version",
      IMPORT_SURVEY: "Formulaires Survey Solutions",
      IMPORT_INTERVIEW: "Interview(s)",
      SURVEY_JETON: "Modifier le jeton",
      IMPORTED_INTERVIEW: "Interview(s) importé(s)",
      IMPORTE_DATA: "Importer",
      SURVEY_JETON_LIB: "Jeton Survey Solutions",
      SURVEY_SET_JETON: "Générer un jeton ?",
      SURVEY_USER: "Nom d'utilisateur",
      SURVEY_PASSE: "Mots de passe",
      SURVEY_GENERER: "Générer",

      COLLECT_MODE_SURVEY: 'Importer des Données depuis Survey Solutions',
      COLLECTORS: 'Collecteurs',
      LINK_GEO: 'Lier au plan géographique',
      ERRORVIDEVAL: 'Veuillez saisir l\'option et la valeur',
      ERRORVIDE: 'Veuillez saisir l\'option',
      ERROREXISTEVAL: 'L\'option ou la valeur existe déjà',
      ERROREXISTE: 'L\'option existe déjà',
      ARCHIVATE: 'Archiver',
      KOBO_API_EDIT: 'Jeton API KoBo Toolbox',
      ADD: 'Ajouter Formulaire',
      ADD_FROM_KOBO: 'Importer depuis KoBo Toolbox',
      KOBO_API: 'Jeton API',
      ERROR_KOBO_API: 'Jeton API incorrect',
      ALREADY_SAVE: 'Toujours enregistrer',
      HOW_TO_FIND_TOKEN: 'Comment trouver le Jeton API ? ',
      REFRESH: 'Actualiser les données',
      KOBO_FORM_LIST: 'Liste des projets',
      FORM_SAVED: 'Formulaire enregistré !',
      SUCCESS_IMPORT: 'Les données ont été importées !',
      FAIL_IMPORT: 'L\'importation a échouée',
      CHART_STRUCTURE: 'Paramètres',
      CHART_STRUCTURE_TITLE: 'Paramètres Graphe',
      SURVEY: 'Sondage',
      ERROR: {
        NAME_REQUIRED: 'Le nom est obligatoire',
        OPTION_REQUIRED: 'Veuillez renseigner les options',
        UNKNOWN_ERROR: 'Une erreur s\'est produite lors de l\'importation',
        KOBO_TOKEN_ERROR: 'Le jeton api n\'est pas correct'
      },
      SAVE: 'Enregistrer',
      SAVE_MSG: 'Enregistrement ajouté',
      DOWNLOAD_QR_CODE: 'Télécharger le code QR',
      GENERATE_URL: 'Générer une URL pour saisie externe',
      COPIED_URL: 'Copié',
      COPY_URL: 'Copier',
      COLLECTIONS: 'FORMULAIRES DE COLLECTE',
      COLLECTION: 'Formulaire de collecte',
      // DISPLAY_IMAGE: 'Afficher l\'image',
      DISPLAY_IMAGE: 'Image',
      LAYOUT: 'Disposition',
      STRUCTURE: 'Champs',
      ADD_SECTION: 'Nouvelle section',
      ADD_DATA: 'Ajouter une donnée',
      COLOR: 'Couleur',
      SEARCH: 'Rechercher un formulaire',
      SEARCH_ANALYZE: 'Rechercher une analyse',
      TITLE: 'Collecte des données - Conception des formulaires',
      SELECT_TEMPLATE: 'Choisir un formulaire',
      CONDITION: 'Condition',
      CONDITION_HELP: 'Définir la condition du champ',
      PRESET: 'Options Prédéfinies',
      HELP_OPTION: 'Saisir une option',
      TITLE_I: 'Nom du formulaire',
      PRIVATE: 'Confidentiel',
      SINGLUAR: 'Un seul',
      MAIN: 'Unique',
      PLURAL: 'Plusieurs',
      MULTILINE: 'Multi ligne',
      LIMIT: 'Limite des caractères',
      FORMAT: 'Format',
      LIST_OPTION: 'LISTE DES OPTIONS',
      MULTI_CHOISE: 'Autoriser la sélection multiple',
      CUSTOM_FIELD: 'Liste Options',
      CUSTOM_DATA: 'Formulaires',
      REQUIRED: 'Obligatoire',
      REQUIRED_NO_FIELD: 'Veuillez glisser un champ dans cette zone',
      COLUMN: 'Colonne',
      CONFIRM_STRUCTURE: 'Au moins un sous formulaire a été supprimé.\nVoulez-vous continuer?',
      ERROR_GLOBAL: 'Certains champs obligatoires ne sont pas renseignés',
      USERS: 'Utilisateurs',
      IMPORT_DATA: 'Importer les données',
      COLLECT_DATA: 'Collecte des données',
      PIN: 'Marqueur',
      CHOISE_COLLECT_MODE: 'Sélectionnez le mode de collecte des données',
      COLLECT_MODE_INTERN: 'Afficher l\'écran de Collecte des Données',
      COLLECT_MODE_MOBILE: 'Collecter depuis un Smartphone',
      COLLECT_MODE_LINK: 'Envoyer un lien pour la saisie de Données externes',
      COLLECT_MODE_EXCEL: 'Importer des Données depuis un Fichier Excel',
      FIELD: {
        EXCEL: 'Excel',
        PPTX: 'PowerPoint',
        TEXT: 'Texte',
        LIST: 'Liste',
        DATE: 'Date',
        NUMBER: 'Numérique',
        CHOISE: 'Choix',
        FILE: 'Fichier',
        PICTURE: 'Image',
        VIDEO: 'Vidéo',
        AUDIO: 'Audio',
        LOCATION: 'Coordonnées GPS ',
        FORMULAR: 'Sous Formulaire',
        PATH: 'Chemin',
        SCORE: 'Score',
        OPTION: 'Option',
        VALUE: 'Valeur',
        POLYGON: 'Polygone'
      },
      MENU: {
        STRUCTURE: 'Structure',
        RECORD: 'Enregistrement(s)',
        RIGHT: 'Droits',
        EDIT: 'Éditer le Formulaire',
        COLLECT: 'Collecter les données',
        REVIEW: 'Réviser les données',
        ANALYZE: 'Analyse & Rapports'
      },
      CONFIRM_DELETE: 'Voulez-vous supprimer ce formulaire?',
      CONFIRM_RECORD_DELETE: 'Voulez-vous supprimer cet élément?',
      OWN: 'Mes formulaires',
      ALL: 'Tous les formulaires',
      REQUEST: 'Analyse',
      REQUEST_TITLE: 'Titre',
      TEMPLATE: 'Formulaire',
      ANALYSE: {
        SAVE: 'Enregistrer',
        TABS: {
          TABLE_PIVOT: 'Tableau Croisé Dynamique',
          TABLE_SIMPLE: 'Tableau',
          GRAPH: 'Graphe',
          TABLE: 'Table',
          MAP: 'Carte',
          PIE_CHART: 'Secteur',
          MIXED_CHART: 'Graphe Combiné'
        },
        TABLE: {
          COLUMNS: 'Colonnes'
        },
        MAP: {
          CATEGORY: 'Catégorie',
          VALUE: 'Valeur',
          FIELD: 'Champ',
          LEGEND: 'Légende'
        },
        CHART: {
          FIELD: 'Champ',
          CATEGORY: 'Catégorie',
          OPERATION: 'Agrégation',
          NONE: 'Aucune',
          COUNT: 'Nombre d\'occurrences',
          VALUE: 'Valeur',
          SUM: 'Somme',
          AVG: 'Moyenne',
          MAX: 'Maximum',
          MIN: 'Minimum',
          VARIANCE: 'Variance',
          TYPE: 'Type'
        },
        TYPE_CHART: {
          AREACHART: 'Aire',
          BARCHART: 'Barre',
          COLUMNCHART: 'Histogramme',
          LINECHART: 'Courbe',
          PIECHART: 'Secteur'
        },
        LAYERS: {
          TITLE: 'Couches',
          NAME: 'Nom',
          FIELD: 'Champ',
          COLOR: 'Couleur'
        }
      }
    },
    ANALYSE: {
      BACKGROUND_COLOR_TITLE: 'Couleur de fond du titre',
      COLOR_TITLE: 'Couleur du titre',
      SUCCESS_ADD: 'Le rapport à été ajouté',
      FIX_VALUE: 'Valeur Fixe',
      VARIABLE_VALUE: 'Valeur Variable',
      VARIABLE_NAME: 'Nom Variable',
      VARIABLES: 'Variables',
      AS_PORTFOLIO: 'Portefeuille',
      AS_PORTFOLIO_: 'Partager avec le Portefeuille',
      COLLECTED_DATA: 'Données collectées',
      SERIES: 'Séries',
      SEARCH: 'Rechercher un rapport',
      SWITCH: 'Mettre les variables en colonne',
      TYPE_REP: 'Type Représentation',
      TYPE_1: 'Tableau',
      TYPE_2: 'Graphe',
      TYPE_3: 'Donnée',
      DIM: 'Axe',
      DIM1: 'Lignes du Tableau',
      DIM2: 'Afficher les données par',
      COLUMNS: 'Colonnes',
      FIELD: 'Champ',
      INDICATOR: 'Indicateur',
      DESCRIPTION: 'Description',
      SETTINGS: 'Paramètres',
      FILTERS: 'Filtres',
      SELECT_ALL: 'Sélectionner Tous',
      UNSELECT_ALL: 'Désélectionner Tous',
      FIELDS: {
        DATE: {
          BEGIN_PLAN: 'Début Planifié',
          END_PLAN: 'Fin Planifiée',
          BEGIN_ACTUAL: 'Début Réel',
          END_ACTUAL: 'Fin Réelle',
          T: 'Achèvement'
        },
        PHYSIC: {
          P: 'Prévu',
          R: 'Réalisé',
          E: 'Écart ',
          T: '% physique'
        },
        PHYSIC_INDIC: {
          P: 'Valeur Cible',
          R: 'Valeur Relevée'
        },
        BUDGET: {
          P: 'Budget',
          R: 'Consommé',
          E: 'Disponible',
          T: '% Budget'
        }
      },
      MAIN: 'Paramètres',
      PUBLIC: 'Public',
      AS_DASHBORD: 'Tableau de bord',
      OPERATION: 'Opération',
      CONDITION: 'Filtres',
      PG: 'Plan Géographique',
      TITLE: 'Titre du rapport',
      DESCRIPTION_REPORT: 'Description Rapport',
      PERIODE: 'Période',
      FINANCING_SOURCE: 'Source de financement',
      CAT_BUDGET: 'Catégorie Budgétaire',
      RATE: 'Taux',
      RUBRIQUE: 'Champ',
      SUBRUBRIQUE: 'Sous-Champ',
      VALEUR: 'Valeur',
      PARAMETER: 'Paramètre',
      DEFAULT_VALUE: 'Valeur par défaut',
      ERROR_SYNTAXE: 'Erreur de syntaxe',
      ERROR_COND_EXIST: 'Certaines conditions n\'existent pas',
      ERROR_COND_NOT_EXIST: 'Toutes les conditions ne sont pas utilisées',
      LIST_COND: 'Ajout Filtre',
      RUBOP: {
        EGAL: 'Égal à',
        DIFF: 'Différent de',
        SUPP: 'Supérieur à',
        SUPP_EGAL: 'Supérieur ou égal à',
        INF: 'Inférieur à',
        INF_EGAL: 'Inférieur ou égal à',
        BEGIN: 'Commence par',
        NOT_BEGIN: 'Ne commence pas par',
        END: 'Finit par',
        NOT_END: 'Ne finit pas par',
        CONTENT: 'Contient',
        NOT_CONTENT: 'Ne contient pas',
        NULL: 'Est null',
        NOT_NULL: 'N\'est pas null'
      },
      RUB: {
        ACTEUR: 'Code Acteur',
        LOCAL: 'Code Localité',
        CAD_LOG: 'Code \'{{planlog}}\'',
        YEAR: 'Année',
        FINANCING_SOURCE: 'Code Source de financement',
        CAT_BUDGET: 'Catégorie Budgétaire'
      },
      OPS: {
        MIN: 'Minimum',
        MAX: 'Maximum',
        AVG: 'Moyenne',
        SUM: 'Somme',
        NONE: 'Aucune',
        COUNT: 'Compter'
      },
      ENCH_COND: 'Enchainement des conditions ET/OU ',
      CONFIRM_DELETE: 'Voulez-vous supprimer cette vue?',
      TYPE: {
        TABLE: 'Tableau',
        DONUT: 'Graphe Secteur',
        COU_HISTO: 'Graphe combiné',
        WIDGET: 'Widget'
      },
      SERIE: 'Séries',
      FILL: 'Remplir',
      TYPE_COU_HISTO: {
        LINE: 'Courbe',
        BAR: 'Histogramme'
      }
    },
    CUSTOM_DASHBOARD: {
      DISPLAY_MAIN_PAGE: 'Afficher sur la page principale',
      SEARCH: 'Rechercher Tableaux de Bord',
      ACTIVITIES: 'Activités',
      INDICATORS: 'Indicateurs',
      FORMS: 'Formulaires',
      GLOBAL_TITLE: 'Tableaux de bord',
      TITLE: 'Titre',
      VIEWS: 'Sélectionner les Analyses',
      VIEWS_OPTION: 'Liste des vues',
      ANALYSE: 'Analyse',
      HEIGHT: 'Hauteur',
      WIDTH: 'Largeur',
      CONFIRM_DELETE: 'Voulez-vous supprimer ce tableau de bord?',
      DISPLAY: 'Afficher tous les tableaux de bord',
      OWN: 'Mes Tableaux de bord',
      FAVORITE: 'Mes Tableaux de bord Favoris',
      OTHER: 'Autres'
    },
    AGENDA: {
      LIST: 'Liste',
      MONTH: 'Mois',
      WEEK: 'Semaine',
      DAY: 'Jour',
      TODAY: 'Aujourd\'hui',
      TITLE: 'Titre',
      DESCRIPTION: 'Description',
      BEGIN: 'Début',
      END: 'Fin',
      INFO: 'Taches / Évènement',
      USER: 'Partager avec...'
    },
    COMMENT: {
      NEW_COMMENTS: 'Nouveaux Messages',
      SUCCESS_ADD: 'Le commentaire à été ajouté',
      COMMENT: 'Commentaires',
      SHARE: 'Partager les Commentaires',
      USER: 'Utilisateurs',
      WRITE: 'Rédiger un commentaire',
      FILE: 'Fil d\'actualités',
      TO_COMMENT: 'Commenter',
      CANCEL_TO_COMMENT: 'Annuler',
      DISPLAY_COMMENT: 'Afficher les commentaires',
      HIDE_COMMENT: 'Masquer les commentaires',
      DISPLAY_MORE_COMMENT: 'Afficher plus de commentaires'
    },
    WORKFLOW: {
      MENU: {
        ACTIVITIES: 'Activités',
        INDICATOR: 'Indicateurs',
        FORM: 'Formulaires',
        DOCUMENTS: 'Documents'
      },
      ACTIVITIES: {
        TRACK: 'Suivi'
      },
      VALIDATE: 'Valider les données',
      COMMENTS: 'Commentaires'
    },
    DELETEDITEMS: {
      OBJECT: 'Élément',
      PROJECT: 'Projet',
      DATE: 'Date',
      REFRESH: 'Restaurer '
    },
    AUDITS: {
      USERS: 'Utilisateurs',
      FILTER: 'Filtrer',
      ACTIONS: 'Actions',
      DELETE: 'Suppression',
      UPDATE: 'Modification',
      CREATE: 'Création',
      TO_DATE: 'À',
      FROM_DATE: 'De',
      PAGING_SUMMERY: '{{current}} de {{total}} pages ({{count}} éléments)',
      ACTION: 'Action',
      ENTITY: 'Entité',
      DESCRIPTION: 'Description',
      USER: 'User',
      DATE: 'Date',
      ENTITIES: {
        TYPE_MISSION: 'Type Mission',
        MISSION: 'Mission',
        DISBURSEMENT: 'Décaissements',
        AGREEMENT: 'Financement',
        AGENCIES: 'Agence',
        PROJET: 'Projet',
        UNITES: 'Unité',
        ACTEUR: 'Acteur',
        FINANCEMENT: 'Source de financement',
        NIVEAUGEO: 'Niveau Géographique',
        CHAMPSPERSO: 'Champ Perso',
        LOCALITE: 'Localité',
        NIVEAUCL: 'Niveau Logique',
        CADRELOGIQUE: 'Cadre Logique',
        TYPEACTIVITY: 'Type Activité',
        DERNIERNIVEAU: 'Activité',
        TYPEINDICATEUR: 'Type Indicateur',
        INDICATEURSCATEGORIE: 'Catégorie de désagrégation',
        INDICATEURSCATEGORIEOPTION: 'Option (Catégorie de désagrégation)',
        INDICATEURS: 'Indicateur',
        ANALYSE: 'Rapport Perso.',
        DASHBOARD: 'Tableau de bord',
        TYPERAPPORT: 'Type de Rapport',
        CALENDARRAPPORT: 'Rapport',
        CLASSEUR: 'Classeur',
        RAPPORTS: 'Document',
        CLASSEURPHOTOTHEQUE: 'Classeur (Médiathèque)',
        PHOTOTHEQUE: 'Médiathèque',
        FORMULAR: 'Formulaire',
        RECORDS: 'Enregistrement (Formulaire)',
        FORMLARREQUEST: 'Requête',
        USER: 'Utilisateur',
        GROUPUSER: 'Groupe Utilisateurs',
        PROFILUSER: 'Profil Utilisateurs',
        CATEGORYBUDGET: 'Catégorie Budgétaire',
        DISTRIBUTEAMOUNT: 'Ventilation ',
        GLOBAL_PLAN: 'Planification (Global)',
        ANNUAL_PLAN: 'Planification  (Annuelle)',
        PERIODIC_PLAN: 'Planification (Périodique)',
        REALIZE: 'Réalisation Activité'
      }
    },
    SECURITY: {
      ATTEMPTS: 'Tentatives d’échec successives',
      TIMEOUT: 'Temps d\'inactivité'
    },
    MISSIONS: {
      TITLE_MISSION: 'Mission',
      SELECT_TYPE: 'Selectionnez un type de mission',
      LIEU_MISSION: 'Lieu',
      OBJECT_MISSION: 'Objet',
      MEMBERS: 'Membres',
      SELECT_MEMBERS: 'Selectionnez les membres de la mission',
      MISSION: 'Mission',
      TYPE: 'Type',
      DATE_BEGIN: 'Date début',
      DATE_END: 'Date fin',
      RESPONSIBLE: 'Responsable',
      REPORT: 'Rapport',

      NO_REPORT: 'Aucun document associé'
    },
    REQUEST: {
      DATE_COURRIER: 'Date de la requête',
      COMMENTAIRE: 'Commentaires',
      DOCS_SUPPORT: 'Aide-mémoire',
      STATUT: 'Statut de la requête',
      DATE_STATUT: 'Date statut',
      DATE_ECHEANCE: 'Échéance',
      BUDGET: 'Budget',
      MONTANTDMD: 'Montant demandé (FCFA)',
      SITUATION_REQUETE: 'Situation Requête',
      DATE: 'Date',
      RESPONSABLE: 'Responsable',
      ECHEANCE: 'Échéance',
      PJ: 'Pièce jointe',
      OBJET: 'Objet de la requête ',
      // MONTANTDMD: 'Montant demandé',
      PERSCONTACTS: 'Personnes à alerter',
      ADDRQTE: 'Ajout de requête',
      ADDSUIVIRQTE: 'Ajout de suivi de requête',
      HISTORQ: 'Historique de suivi'
    },
    RISK: {
      CRITICALITY: {
        TITLE: 'Criticité',
        HIGH: 'Elevé',
        MEDIUM: 'Moyen',
        WEAK: 'Faible'
      },
      DELAY: 'Durée (Jours)',
      TYPE: 'Type',
      RISK: 'Risque',
      PROBLEM: 'Problème',
      CURRENT_POSITION: 'Récupérer la position actuelle',
      CURRENT_POSITION_MARK: 'Déplacer le marqueur',
      CURRENT_POSITION_MARK_: 'sur la carte pour récupérer la position',
      NATURE_RISK: 'Nature',
      DESCRIPTION: 'Description',
      DESCRIPTION_: 'Description du',
      TITLE_RISK: 'Risque/Problème',
      IMPACT_FINANCE: 'Impact financier',
      RESOLVE_DATE_RISK: 'Date de résolution',
      IMPACT_DELAY: 'Impact Délai',

      FINANCE: 'Financier',
      DELAY_: 'Délai',

      IMPACT: 'Impact',

      PROBABILITY: 'Probabilité',
      DATE: 'Date',
      STATUS: 'Statut',
      RECOMMANDATIONS: 'Recommandations',
      // élevé, moyen et faible
      IMPACTS: {
        HIGH: 'Elevé',
        MEDIUM: 'Moyen',
        LOW: 'Faible'
      },
      STATUS_LIST: {
        UNRESOLVED: 'Non résolu',
        IN_PROGRESS: 'En cours',
        RESOLVED: 'Résolu'

      }
    },
    PROBLEM: {
      PROBLEM: 'Problèmes rencontrés',
      DATE_PROBLEM: 'Date',
      NATURE_PROBLEM: 'Nature',
      DESCRIPTION: 'Description',
      IMPACT_DELAY: 'Impact Délai',
      IMPACT_FINANCE: 'Impact financier',
      RESPONSIBLE: 'Rapporté par',
      RECOMMANDATIONS: 'Recommandations'
    },

    RECOMMENDATION: {
      DESCRIPTION: 'Recommandation',
      RESPONSIBLE: 'Responsables',
      DEADLINE: 'Échéance',
      STATUS: 'Statut',
      STATUS_LIST: {
        EXECUTED: 'Réalisée',
        NOT_EXECUTED: 'Non exécutée',
        IN_PROGRESS: 'En cours'
      }

    },
    EXPORT_DATA: {
      TYPES: 'Types de données',
      EXPORT: 'Exportation de données',
      LOADED: 'Donnée(s) Générée(s)',
      SHARE: 'Partager avec...'
    },
    FEEDBACK: {
      EMPTY_FEEDBACK: 'Aucune suggestion',
      NOTE_LEVEL: 'Quel est votre niveau de stisfaction ?',
      LAST_UPDATE_DATE: 'Mise à jour le {{date}}',
      SEARCH_FEEDBACK: 'Rechercher par objet ou message',
      SEND: 'Envoyer',
      BACK: 'Retour',
      STATUS_SEND: "Statut d'envoi",
      FORM: {
        OBJECT_PLACEHOLDER: 'Objet',
        MESSAGE_PLACEHOLDER: 'Message',
        ERROR_SEND_FEEDBACK: "L'envoi a échoué, veuillez réessayer",
        SUCCESS_SEND_FEEDBACK: "Votre suggestion a été envoyée avec succès"
      }
    }
  });

  // Appeler la fonction pour récupérer les traductions
  var CODE_LANG = (cov_f0icut6mf.s[2]++, 'fr');
  var CODE_LANG_STORE = (cov_f0icut6mf.s[3]++, 'TRANSLATE_' + CODE_LANG);
  var CHECK_TRANSLATE = (cov_f0icut6mf.s[4]++, 'DELTA_TRANSLATE');

  var translated = (cov_f0icut6mf.s[5]++, false);
  // Translate not active use data origine
  cov_f0icut6mf.s[6]++;
  if ((cov_f0icut6mf.b[1][0]++, localStorage.getItem(CHECK_TRANSLATE)) && (cov_f0icut6mf.b[1][1]++, localStorage.getItem(CHECK_TRANSLATE) == 0)) {
    cov_f0icut6mf.b[0][0]++;
    cov_f0icut6mf.s[7]++;

    translated = true;
    cov_f0icut6mf.s[8]++;
    console.log('TRANSLATE ORIGNINALE ' + CODE_LANG);
    cov_f0icut6mf.s[9]++;
    fetchTranslations(data, false);
  } else {
    cov_f0icut6mf.b[0][1]++;
  }

  cov_f0icut6mf.s[10]++;
  if (!translated) {
    cov_f0icut6mf.b[2][0]++;
    cov_f0icut6mf.s[11]++;

    // Translate activated
    if ((cov_f0icut6mf.b[4][0]++, !localStorage.getItem(CODE_LANG_STORE)) || (cov_f0icut6mf.b[4][1]++, angular.isUndefined(localStorage.getItem(CODE_LANG_STORE))) || (cov_f0icut6mf.b[4][2]++, localStorage.getItem(CODE_LANG_STORE) == '')) {
      cov_f0icut6mf.b[3][0]++;


      var params = (cov_f0icut6mf.s[12]++, {
        action: "getTranslateDataByLang",
        li_bksb: "Connexion",
        lang_bksb: CODE_LANG,
        method: 1,
        t_bksb: 'bk' + new Date().getTime() + 'sb',
        n: window.location.search.substr(1),
        space: (cov_f0icut6mf.b[5][0]++, localStorage.getItem('DELTA_TOKEN')) || (cov_f0icut6mf.b[5][1]++, null),
        data: JSON.stringify(data)
      });
      var URL = (cov_f0icut6mf.s[13]++, "/app/data/web_server.php");
      // Convertir l'objet params en une chaîne JSON
      var CONTENT = (cov_f0icut6mf.s[14]++, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      });

      // Effectuer la requête POST
      cov_f0icut6mf.s[15]++;
      fetch(URL, CONTENT).then(function (response) {
        cov_f0icut6mf.f[1]++;
        cov_f0icut6mf.s[16]++;

        // console.log('response 1');
        // console.log(response);
        if (response.ok) {
          cov_f0icut6mf.b[6][0]++;
          cov_f0icut6mf.s[17]++;

          return response.json();
        } else {
          cov_f0icut6mf.b[6][1]++;
        }
        cov_f0icut6mf.s[18]++;
        console.log('Erreur HTTP : ' + response.status);
        cov_f0icut6mf.s[19]++;
        return { error_state: true };
      }).then(function (dataReponse) {
        cov_f0icut6mf.f[2]++;

        var TAILLE_ORIGINE = (cov_f0icut6mf.s[20]++, Object.keys(data).length);
        var TAILLE_NEW = (cov_f0icut6mf.s[21]++, Object.keys(dataReponse).length);
        cov_f0icut6mf.s[22]++;
        if ((cov_f0icut6mf.b[8][0]++, dataReponse) && (cov_f0icut6mf.b[8][1]++, dataReponse.COUNTRIES) && (cov_f0icut6mf.b[8][2]++, angular.isUndefined(dataReponse.error_state)) && (cov_f0icut6mf.b[8][3]++, TAILLE_NEW >= TAILLE_ORIGINE - 10)) {
          cov_f0icut6mf.b[7][0]++;
          cov_f0icut6mf.s[23]++;

          console.log('(200) - TRANSLATE OK  ' + CODE_LANG + ': (' + TAILLE_NEW + ' - vs - ' + TAILLE_ORIGINE + ')');
          cov_f0icut6mf.s[24]++;
          fetchTranslations(dataReponse, true);
        } else {
          cov_f0icut6mf.b[7][1]++;
          cov_f0icut6mf.s[25]++;

          console.log('(200 & ERROR)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
          cov_f0icut6mf.s[26]++;
          if ((cov_f0icut6mf.b[10][0]++, dataReponse.error_state) && (cov_f0icut6mf.b[10][1]++, dataReponse.edit_language) && (cov_f0icut6mf.b[10][2]++, dataReponse.edit_language == 0)) {
            cov_f0icut6mf.b[9][0]++;
            cov_f0icut6mf.s[27]++;

            console.log('edit_language: ' + dataReponse.edit_language);
            cov_f0icut6mf.s[28]++;
            localStorage.setItem(CHECK_TRANSLATE, dataReponse.edit_language);
          } else {
            cov_f0icut6mf.b[9][1]++;
          }
          cov_f0icut6mf.s[29]++;
          fetchTranslations(data, false);
        }
      }).catch(function (error) {
        cov_f0icut6mf.f[3]++;
        cov_f0icut6mf.s[30]++;

        console.log('(500)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
        cov_f0icut6mf.s[31]++;
        fetchTranslations(data, false);
      });
    } else {
      cov_f0icut6mf.b[3][1]++;
      cov_f0icut6mf.s[32]++;

      console.log('TRANSLATE LOCALSTORAGE ' + CODE_LANG);
      cov_f0icut6mf.s[33]++;
      fetchTranslations(JSON.parse(localStorage.getItem(CODE_LANG_STORE)), false);
    }
  } else {
    cov_f0icut6mf.b[2][1]++;
  }

  function fetchTranslations(dataLang, save) {
    cov_f0icut6mf.f[4]++;
    cov_f0icut6mf.s[34]++;

    $translateProvider.translations(CODE_LANG, dataLang);
    cov_f0icut6mf.s[35]++;
    if (save) {
      cov_f0icut6mf.b[11][0]++;
      cov_f0icut6mf.s[36]++;

      if (localStorage.getItem(CHECK_TRANSLATE)) {
        cov_f0icut6mf.b[12][0]++;
        cov_f0icut6mf.s[37]++;

        localStorage.removeItem(CHECK_TRANSLATE);
      } else {
        cov_f0icut6mf.b[12][1]++;
      }
      cov_f0icut6mf.s[38]++;
      if (localStorage.getItem(CODE_LANG_STORE)) {
        cov_f0icut6mf.b[13][0]++;
        cov_f0icut6mf.s[39]++;

        localStorage.removeItem(CODE_LANG_STORE);
      } else {
        cov_f0icut6mf.b[13][1]++;
      }
      cov_f0icut6mf.s[40]++;
      localStorage.setItem(CODE_LANG_STORE, JSON.stringify(dataLang));
    } else {
      cov_f0icut6mf.b[11][1]++;
    }
  };
});