'use strict';

var cov_ybm8pdibn = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_es.js',
      hash = 'a855eb660c420f3577b3c528e9184f33232989e3',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_es.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3004,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2914,
          column: 3
        }
      },
      '2': {
        start: {
          line: 2918,
          column: 20
        },
        end: {
          line: 2918,
          column: 24
        }
      },
      '3': {
        start: {
          line: 2919,
          column: 26
        },
        end: {
          line: 2919,
          column: 50
        }
      },
      '4': {
        start: {
          line: 2920,
          column: 26
        },
        end: {
          line: 2920,
          column: 43
        }
      },
      '5': {
        start: {
          line: 2922,
          column: 19
        },
        end: {
          line: 2922,
          column: 24
        }
      },
      '6': {
        start: {
          line: 2924,
          column: 2
        },
        end: {
          line: 2928,
          column: 3
        }
      },
      '7': {
        start: {
          line: 2925,
          column: 4
        },
        end: {
          line: 2925,
          column: 22
        }
      },
      '8': {
        start: {
          line: 2926,
          column: 4
        },
        end: {
          line: 2926,
          column: 52
        }
      },
      '9': {
        start: {
          line: 2927,
          column: 4
        },
        end: {
          line: 2927,
          column: 35
        }
      },
      '10': {
        start: {
          line: 2931,
          column: 2
        },
        end: {
          line: 2990,
          column: 3
        }
      },
      '11': {
        start: {
          line: 2933,
          column: 4
        },
        end: {
          line: 2989,
          column: 5
        }
      },
      '12': {
        start: {
          line: 2935,
          column: 21
        },
        end: {
          line: 2944,
          column: 7
        }
      },
      '13': {
        start: {
          line: 2945,
          column: 18
        },
        end: {
          line: 2945,
          column: 44
        }
      },
      '14': {
        start: {
          line: 2947,
          column: 22
        },
        end: {
          line: 2953,
          column: 7
        }
      },
      '15': {
        start: {
          line: 2956,
          column: 6
        },
        end: {
          line: 2984,
          column: 9
        }
      },
      '16': {
        start: {
          line: 2960,
          column: 8
        },
        end: {
          line: 2962,
          column: 9
        }
      },
      '17': {
        start: {
          line: 2961,
          column: 10
        },
        end: {
          line: 2961,
          column: 33
        }
      },
      '18': {
        start: {
          line: 2963,
          column: 8
        },
        end: {
          line: 2963,
          column: 56
        }
      },
      '19': {
        start: {
          line: 2964,
          column: 8
        },
        end: {
          line: 2964,
          column: 34
        }
      },
      '20': {
        start: {
          line: 2967,
          column: 31
        },
        end: {
          line: 2967,
          column: 55
        }
      },
      '21': {
        start: {
          line: 2968,
          column: 27
        },
        end: {
          line: 2968,
          column: 58
        }
      },
      '22': {
        start: {
          line: 2969,
          column: 8
        },
        end: {
          line: 2979,
          column: 9
        }
      },
      '23': {
        start: {
          line: 2970,
          column: 10
        },
        end: {
          line: 2970,
          column: 109
        }
      },
      '24': {
        start: {
          line: 2971,
          column: 10
        },
        end: {
          line: 2971,
          column: 47
        }
      },
      '25': {
        start: {
          line: 2973,
          column: 10
        },
        end: {
          line: 2973,
          column: 77
        }
      },
      '26': {
        start: {
          line: 2974,
          column: 10
        },
        end: {
          line: 2977,
          column: 11
        }
      },
      '27': {
        start: {
          line: 2975,
          column: 12
        },
        end: {
          line: 2975,
          column: 71
        }
      },
      '28': {
        start: {
          line: 2976,
          column: 12
        },
        end: {
          line: 2976,
          column: 77
        }
      },
      '29': {
        start: {
          line: 2978,
          column: 10
        },
        end: {
          line: 2978,
          column: 41
        }
      },
      '30': {
        start: {
          line: 2982,
          column: 8
        },
        end: {
          line: 2982,
          column: 66
        }
      },
      '31': {
        start: {
          line: 2983,
          column: 8
        },
        end: {
          line: 2983,
          column: 39
        }
      },
      '32': {
        start: {
          line: 2987,
          column: 6
        },
        end: {
          line: 2987,
          column: 56
        }
      },
      '33': {
        start: {
          line: 2988,
          column: 6
        },
        end: {
          line: 2988,
          column: 82
        }
      },
      '34': {
        start: {
          line: 2993,
          column: 4
        },
        end: {
          line: 2993,
          column: 57
        }
      },
      '35': {
        start: {
          line: 2994,
          column: 4
        },
        end: {
          line: 3002,
          column: 5
        }
      },
      '36': {
        start: {
          line: 2995,
          column: 6
        },
        end: {
          line: 2997,
          column: 7
        }
      },
      '37': {
        start: {
          line: 2996,
          column: 8
        },
        end: {
          line: 2996,
          column: 49
        }
      },
      '38': {
        start: {
          line: 2998,
          column: 6
        },
        end: {
          line: 3000,
          column: 7
        }
      },
      '39': {
        start: {
          line: 2999,
          column: 8
        },
        end: {
          line: 2999,
          column: 49
        }
      },
      '40': {
        start: {
          line: 3001,
          column: 6
        },
        end: {
          line: 3001,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 29
          },
          end: {
            line: 1,
            column: 30
          }
        },
        loc: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 3004,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 2957,
            column: 12
          },
          end: {
            line: 2957,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2957,
            column: 26
          },
          end: {
            line: 2965,
            column: 7
          }
        },
        line: 2957
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 2966,
            column: 12
          },
          end: {
            line: 2966,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2966,
            column: 29
          },
          end: {
            line: 2980,
            column: 7
          }
        },
        line: 2966
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 2981,
            column: 13
          },
          end: {
            line: 2981,
            column: 14
          }
        },
        loc: {
          start: {
            line: 2981,
            column: 24
          },
          end: {
            line: 2984,
            column: 7
          }
        },
        line: 2981
      },
      '4': {
        name: 'fetchTranslations',
        decl: {
          start: {
            line: 2992,
            column: 11
          },
          end: {
            line: 2992,
            column: 28
          }
        },
        loc: {
          start: {
            line: 2992,
            column: 45
          },
          end: {
            line: 3003,
            column: 3
          }
        },
        line: 2992
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2924,
            column: 2
          },
          end: {
            line: 2928,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2924,
            column: 2
          },
          end: {
            line: 2928,
            column: 3
          }
        }, {
          start: {
            line: 2924,
            column: 2
          },
          end: {
            line: 2928,
            column: 3
          }
        }],
        line: 2924
      },
      '1': {
        loc: {
          start: {
            line: 2924,
            column: 6
          },
          end: {
            line: 2924,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2924,
            column: 6
          },
          end: {
            line: 2924,
            column: 43
          }
        }, {
          start: {
            line: 2924,
            column: 48
          },
          end: {
            line: 2924,
            column: 90
          }
        }],
        line: 2924
      },
      '2': {
        loc: {
          start: {
            line: 2931,
            column: 2
          },
          end: {
            line: 2990,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2931,
            column: 2
          },
          end: {
            line: 2990,
            column: 3
          }
        }, {
          start: {
            line: 2931,
            column: 2
          },
          end: {
            line: 2990,
            column: 3
          }
        }],
        line: 2931
      },
      '3': {
        loc: {
          start: {
            line: 2933,
            column: 4
          },
          end: {
            line: 2989,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2933,
            column: 4
          },
          end: {
            line: 2989,
            column: 5
          }
        }, {
          start: {
            line: 2933,
            column: 4
          },
          end: {
            line: 2989,
            column: 5
          }
        }],
        line: 2933
      },
      '4': {
        loc: {
          start: {
            line: 2933,
            column: 8
          },
          end: {
            line: 2933,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2933,
            column: 8
          },
          end: {
            line: 2933,
            column: 46
          }
        }, {
          start: {
            line: 2933,
            column: 50
          },
          end: {
            line: 2933,
            column: 108
          }
        }, {
          start: {
            line: 2933,
            column: 113
          },
          end: {
            line: 2933,
            column: 154
          }
        }],
        line: 2933
      },
      '5': {
        loc: {
          start: {
            line: 2942,
            column: 15
          },
          end: {
            line: 2942,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2942,
            column: 15
          },
          end: {
            line: 2942,
            column: 50
          }
        }, {
          start: {
            line: 2942,
            column: 54
          },
          end: {
            line: 2942,
            column: 58
          }
        }],
        line: 2942
      },
      '6': {
        loc: {
          start: {
            line: 2960,
            column: 8
          },
          end: {
            line: 2962,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2960,
            column: 8
          },
          end: {
            line: 2962,
            column: 9
          }
        }, {
          start: {
            line: 2960,
            column: 8
          },
          end: {
            line: 2962,
            column: 9
          }
        }],
        line: 2960
      },
      '7': {
        loc: {
          start: {
            line: 2969,
            column: 8
          },
          end: {
            line: 2979,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2969,
            column: 8
          },
          end: {
            line: 2979,
            column: 9
          }
        }, {
          start: {
            line: 2969,
            column: 8
          },
          end: {
            line: 2979,
            column: 9
          }
        }],
        line: 2969
      },
      '8': {
        loc: {
          start: {
            line: 2969,
            column: 12
          },
          end: {
            line: 2969,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2969,
            column: 12
          },
          end: {
            line: 2969,
            column: 23
          }
        }, {
          start: {
            line: 2969,
            column: 27
          },
          end: {
            line: 2969,
            column: 48
          }
        }, {
          start: {
            line: 2969,
            column: 52
          },
          end: {
            line: 2969,
            column: 96
          }
        }, {
          start: {
            line: 2969,
            column: 101
          },
          end: {
            line: 2969,
            column: 134
          }
        }],
        line: 2969
      },
      '9': {
        loc: {
          start: {
            line: 2974,
            column: 10
          },
          end: {
            line: 2977,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2974,
            column: 10
          },
          end: {
            line: 2977,
            column: 11
          }
        }, {
          start: {
            line: 2974,
            column: 10
          },
          end: {
            line: 2977,
            column: 11
          }
        }],
        line: 2974
      },
      '10': {
        loc: {
          start: {
            line: 2974,
            column: 14
          },
          end: {
            line: 2974,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2974,
            column: 14
          },
          end: {
            line: 2974,
            column: 37
          }
        }, {
          start: {
            line: 2974,
            column: 41
          },
          end: {
            line: 2974,
            column: 66
          }
        }, {
          start: {
            line: 2974,
            column: 71
          },
          end: {
            line: 2974,
            column: 101
          }
        }],
        line: 2974
      },
      '11': {
        loc: {
          start: {
            line: 2994,
            column: 4
          },
          end: {
            line: 3002,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2994,
            column: 4
          },
          end: {
            line: 3002,
            column: 5
          }
        }, {
          start: {
            line: 2994,
            column: 4
          },
          end: {
            line: 3002,
            column: 5
          }
        }],
        line: 2994
      },
      '12': {
        loc: {
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        }, {
          start: {
            line: 2995,
            column: 6
          },
          end: {
            line: 2997,
            column: 7
          }
        }],
        line: 2995
      },
      '13': {
        loc: {
          start: {
            line: 2998,
            column: 6
          },
          end: {
            line: 3000,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2998,
            column: 6
          },
          end: {
            line: 3000,
            column: 7
          }
        }, {
          start: {
            line: 2998,
            column: 6
          },
          end: {
            line: 3000,
            column: 7
          }
        }],
        line: 2998
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0, 0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ybm8pdibn.s[0]++;
angular.module('app').config(function ($translateProvider) {
  cov_ybm8pdibn.f[0]++;

  var data = (cov_ybm8pdibn.s[1]++, {

    COUNTRIES: {
      AD: 'Andorra',
      AE: 'Emiratos Árabes Unidos',
      AF: 'Afganistán',
      AG: 'Antigua y Barbuda',
      AI: 'Anguila',
      AL: 'Albania',
      AM: 'Armenia',
      AN: 'Antillas Holandesas',
      AO: 'Angola',
      AQ: 'Antártida',
      AR: 'Argentina',
      AS: 'Samoa Americana',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Islas Aland',
      AZ: 'Azerbaiyán',
      BA: 'Bosnia y Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Bélgica',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrein',
      BI: 'Burundi',
      BJ: 'Benin',
      BM: 'Islas Bermudas',
      BN: 'Brunei Darussalam',
      BO: 'Bolivia',
      BR: 'Brasil',
      BS: 'Bahamas',
      BT: 'Bután',
      BV: 'Isla Bouvet',
      BW: 'Botswana',
      BY: 'Bielorrusia',
      BZ: 'Belice',
      CA: 'Canadá',
      CC: 'Islas Cocos (Keeling)',
      CD: 'República Democrática del Congo',
      CF: 'República Centroafricana',
      CG: 'Congo',
      CH: 'Suiza',
      CI: 'Costa de Marfil',
      CK: 'Islas Cook',
      CL: 'Chile',
      CM: 'Camerún',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CS: 'Serbia y Montenegro',
      CU: 'Cuba',
      CV: 'Cabo Verde',
      CX: 'Isla de Navidad',
      CY: 'Chipre',
      CZ: 'Republica Checa',
      DE: 'Alemania',
      DJ: 'Yibuti',
      DK: 'Dinamarca',
      DM: 'Dominica',
      DO: 'República Dominicana',
      DZ: 'Argelia',
      EC: 'Ecuador',
      EE: 'Estonia',
      EG: 'Egipto',
      EH: 'Sahara Occidental',
      ER: 'Eritrea',
      ES: 'España',
      ET: 'Etiopía',
      FI: 'Finlandia',
      FJ: 'Fiyi',
      FK: 'Islas Malvinas',
      FM: 'Estados Federados de Micronesia',
      FO: 'Islas Faroe',
      FR: 'Francia',
      GA: 'Gabón',
      GB: 'Reino Unido',
      GD: 'Granada',
      GE: 'Georgia',
      GF: 'Guayana Francesa',
      GG: 'Guernsey',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GL: 'Groenlandia',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadalupe',
      GQ: 'Guinea Ecuatorial',
      GR: 'Grecia',
      GS: 'Islas Georgias del Sur y Sándwich del Sur',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bisáu',
      GY: 'Guayana',
      HK: 'Hong Kong',
      HM: 'Islas Heard y Mcdonald',
      HN: 'Honduras',
      HR: 'Croacia',
      HT: 'Haití',
      HU: 'Hungría',
      ID: 'Indonesia',
      IE: 'Irlanda',
      IL: 'Israel',
      IM: 'Isla de Man',
      IN: 'India',
      IO: 'Territorio Británico del Océano Índico',
      IQ: 'Irak',
      IR: 'República Islámica de Irán',
      IS: 'Islandia',
      IT: 'Italia',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordán',
      JP: 'Japón',
      KE: 'Kenia',
      KG: 'Kirguistán',
      KH: 'Camboya',
      KI: 'Kiribati',
      KM: 'Comoras',
      KN: 'San Cristóbal y Nieves',
      KP: 'Corea del Norte',
      KR: 'Corea del Sur',
      KW: 'Kuwait',
      KY: 'Islas Caimán',
      KZ: 'Kazajstán',
      LA: 'República Democrática Popular de Lao',
      LB: 'Líbano',
      LC: 'Santa Lucía',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesoto',
      LT: 'Lituania',
      LU: 'Luxemburgo',
      LV: 'Letonia',
      LY: 'Libia',
      MA: 'Marruecos',
      MC: 'Mónaco',
      MD: 'República de Moldavia',
      MG: 'Madagascar',
      MH: 'Islas Marshall',
      MK: 'Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macao',
      MP: 'Islas Marianas del Norte',
      MQ: 'Martinica',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauricio',
      MV: 'Maldivas',
      MW: 'Malawi',
      MX: 'México',
      MY: 'Malasia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'Nueva Caledonia',
      NE: 'Níger',
      NF: 'Isla Norfolk',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Países Bajos',
      NO: 'Noruega',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Nueva Zelanda',
      OM: 'Omán',
      PA: 'Panamá',
      PE: 'Perú',
      PF: 'Polinesia Francesa',
      PG: 'Papúa Nueva Guinea',
      PH: 'Filipinas',
      PK: 'Pakistán',
      PL: 'Polonia',
      PM: 'San Pedro y Miquelón',
      PN: 'Pitcairn',
      PR: 'Puerto Rico',
      PS: 'Territorio Palestino',
      PT: 'Portugal',
      PW: 'Palaos',
      PY: 'Paraguay',
      QA: 'Katar',
      RE: 'Reunión',
      RO: 'Rumania',
      RU: 'Federación Rusa',
      RW: 'Ruanda',
      SA: 'Arabia Saudita',
      SB: 'Islas Salomón',
      SC: 'Seychelles',
      SD: 'Sudán',
      SE: 'Suecia',
      SG: 'Singapur',
      SH: 'Santa elena',
      SI: 'Eslovenia',
      SJ: 'Svalbard y Jan Mayen',
      SK: 'Eslovaquia',
      SL: 'Sierra Leona',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      ST: 'Santo Tomé y Príncipe',
      SV: 'El Salvador',
      SY: 'Siria',
      SZ: 'Suazilandia',
      TC: 'Islas Turcas y Caicos',
      TD: 'Chad',
      TF: 'Territorios Franceses del Sur',
      TG: 'Togo',
      TH: 'Tailandia',
      TJ: 'Tayikistán',
      TK: 'Tokelau',
      TL: 'Timor Oriental',
      TM: 'Turkmenistán',
      TN: 'Túnez',
      TO: 'Tonga',
      TR: 'Turquía',
      TT: 'Trinidad y Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ucrania',
      UG: 'Uganda',
      UM: 'Islas Ultramarinas Menores de los Estados Unidos',
      US: 'Estados Unidos',
      UY: 'Uruguay',
      UZ: 'Uzbekistán',
      VA: 'Santa Sede',
      VC: 'San Vicente y las Granadinas',
      VE: 'Venezuela',
      VG: 'Islas Vírgenes Británicas',
      VI: 'Islas Vírgenes de los Estados Unidos',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis y Futuna',
      WS: 'Samoa',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'Sudáfrica',
      ZM: 'Zambia',
      ZW: 'Zimbabue'
    },
    MESSAGE: {
      CONVERSATION: 'Conversación',
      WRITE_MSG: 'Introduce un mensaje',
      AUCUN: 'Sin mensaje',
      TITLE: 'Mis Mensajes',
      READ_ALL: 'Leer todos los mensajes',
      INBOX: 'Bandeja de entrada',
      SEND_BOX: 'Correo Enviado',
      DRAFT_BOX: 'Borradores',
      TRASH_BOX: 'Papelera',
      REFRESH: 'Actualizar',
      REPLY: 'Responder',
      IMPORTANT: 'Importante',
      VIEW_MSG: 'Ver Mensaje',
      TO: 'Para (Usuario DELTA)',
      TO_: 'Usuario DELTA',
      PIECEJ: 'Adjuntar archivos',
      EMAILS: 'Correos electrónicos',
      TOEMAIL: 'Para (correo electrónico externo)',
      MAILEXTERNE: 'Correo electrónico externo',
      SUBJECT: 'Asunto',
      FROM: 'De',
      DRAFT: 'Borrador',
      DISCARD: 'Descartar',
      CMP_MSG: 'Redactar Mensaje',
      SEND: 'Enviar',
      EMAIL_BOX: 'Buzón',
      FOLDER: 'Carpeta',
      REMOVE: 'Borrar',

      RECHARGEMENT: 'Actualizar',
      MARKREAD: 'Marcar como leído',
      MARKNOREAD: 'Marcar como no leido',
      MARKIMPORTANT: 'Marcar importante',
      MARKTRASH: 'Borrar',

      ADDEMAIL: 'Añade un contacto',

      SEND_OK: 'Mensaje enviado',
      SEND_SAVE: 'Mensaje grabado',
      SEND_NO: 'Error al enviar mensaje',
      SEND_NO_T: 'Intentar otra vez',

      ADDUSER: 'Agregar usuario',
      SIGNATURE: 'Firmas',
      SIGNATURE_SEND: 'Para terminar',
      SIGNATURE_ERROR: 'Error',
      SIGNATURE_CREATE: 'Crear una firma',
      SIGNATURE_ADD: 'Crear',
      SIGNATURE_TITLE: 'Título de la firma',
      SIGNATURE_DESIGN: 'Designacion',
      SIGNATURE_ANNULER: 'Anular',
      SIGNATURE_AJOUTER: 'Para agregar',
      SIGNATURE_MODIFIER: 'Editar',
      SIGNATURE_LIST: 'Lista de firmas',
      SIGNATURE_DEFAULT: 'Seleccionar como firma predeterminada',
      SIGNATURE_NODEFAULT: 'Deseleccionar como firma predeterminada',

      ERROR: {
        TO: '¡Selecciona un destinatario!',
        SUBJECT: 'Ingresar Asunto',
        EM: 'Ingrese correo electrónico'
      }
    },
    LOGIN: {
      PASSWORD_EXPIRED_S: 'Su contraseña caduca en {{days}} días',
      PASSWORD_EXPIRED: 'Su contraseña caducará en 1 día',
      PROFILE: 'Perfil',
      LOGOUT: 'Desconectar'
    },
    HELP: {
      LEGAL: 'Información jurídica',
      QUICK_START: 'Ayuda',
      USER_MANUAL: 'Manual de usuario',
      FAQ: 'Preguntas Más Frecuentes',
      WEBINAIRS: 'Seminarios web',
      SUPPORT: 'Contactar al Soporte Técnico',
      LEGAL_NOTICE_LINK: 'https://delta-monitoring.com/service-level-agreement/',
      USER_FEEDBACK: "Comentarios de Usuario"
    },
    ALERT: {
      ACTIVATE: 'Activar alerta de problema',
      SMS: 'SMS',
      EMAIL: 'Email',
      IND_TEXT: 'Alertas de indicadores',
      DOCUMENT: 'Nuevos documentos',
      MORE: 'Ver todas las alertas',
      ALL: 'Todas las alertas',
      NBR_J_L: 'Enviar alerta',
      SEND: 'día(s) antes de la fecha de vencimiento',
      HELP: {
        TITLE: 'La alerta está configurada para enviar un mensaje de alerta a los miembros del equipo para recordarles que ingresen datos sobre las actividades y métricas asignadas.'
      }
    },
    TODO: {
      TITLE: 'Lista de Actividades',
      LABEL: 'Etiqueta'
    },
    HOME: {
      WELCOME: 'Aplicación web colaborativa para M&E',
      USER: 'Nombre de Usuario',
      PASS: 'Contraseña',
      LOGIN: 'Entrar',
      FOOTER: 'Monitoreo DELTA',
      CONNECTED_PLURAL: 'Actualmente conectados: {{user}}</b> Usuarios',
      CONNECTED_SINGLE: 'Actualmente conectado: 1</b> Usuario'
    },
    HEADER: {
      LOGO: 'Logo',
      TITLE: 'Encabezado del informe',
      FIRST_LINE: 'Primera línea',
      SECOND_LINE: 'Segunda línea',
      THIRD_LINE: 'Tercera línea',
      REPORT_TITLE: 'Título del informe'
    },
    COMMON: {
      ACTIVE_SUP: "Activar la eliminación temporal",
      ALERTE_UNIT: 'Seleccione una unidad de Tipo %.',
      STEPONE: 'Escenario',
      STEP: 'Pasos',
      VALUE: 'Valor',
      ADDSTEP: 'Definir etapas de finalización.',
      ADDSTEP_VIEW: 'El tipo de actividad seleccionada indica que la actividad se seguirá como un proceso con etapas de realización',
      EXPORT_POWERBI: 'Exportar a Power BI',
      LOADED_MSG: 'Espere, tratamiento en progreso ...',
      EMAIL_UPLOADED: 'Ha importado {{_nb}} correos electrónicos',
      UPLOAD_CSV: 'Importar desde archivo .CSV',
      UPLOAD_CSV_RECOMMENDATION: "Asegúrese de nombrar el encabezado de cada columna en el archivo .CSV",
      UPLOAD_CSV_RECOMMENDATION_HEADERS: 'Correo electrónico (obligatorio), Nombre y Número (si corresponde).',
      DATE_MAJ: 'Última Fecha de Actualización',
      SELECT_ALL: 'Seleccionar todo',
      UNSELECT_ALL: 'Dejar de seleccionar todo',
      ANALYSE_ERR_EXISTE: 'Título ya usado',
      FORMULAIRE_ERR_EXISTE: 'El formulario ya existe',
      ERR_MSG: 'Por favor complete este campo.',
      COL: 'Columnas',
      COL_S: 'Seleccionar Columnas',
      SV: 'Guardar',
      SV_R: 'Guardar Informe',
      UPLOAD_DOC: 'Adjunto',
      SELECT_OPTION_: 'Seleccionar una opción',
      SELECT_OPTION: 'Seleccionar una opción',
      REQUIRED: 'Obligatorio',
      CHECKING: 'Comprobación ...',
      ERROR_AUTHORIZE_LOCATION: 'No tienes derechos sobre esta localidad',
      FOLDER: 'Carpeta',
      ONLINE: 'Conectado',
      OFFLINE: 'Deconectado',
      ALREADY_EXIST: '¡La variable ya existe!',
      SEE_MORE: 'Ver más ...',
      UNAVAILABLE: 'No disponible',
      SELECT_UNIT: 'Seleccionar una Unidad',
      CONDITIONS: 'Filtros',
      FORMATTING: 'Aplicar Formato',
      CHART: 'Gráfico',
      OVERWRITE_DATA: 'Sobrescribir datos',
      SHARE: 'Compartir',
      GOOD: 'Bueno',
      BAD: 'Malo',
      MEDIUM: 'Medio',
      PORTFOLIO_VISIBLE: 'Visible en el Portafolio',
      NO_DATA: 'Sin datos',
      HELP: 'Ayuda',
      TASK_DISPLAY_FORMAT: 'day dd month yyyy',
      DAY_MAJOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      WEEK_MINOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      FLAVOR: 'favoritos',
      CHECK_ALL: 'Marcar todo',
      UNCHECK_ALL: 'Desmarcar todo',
      PLANNING: 'Programado',
      NOT_PLANNING: 'No programado',
      UPDATE: 'Actualizar',
      BY: 'por',
      CHANGE_PHOTO: 'Cambiar foto',
      ENTER: 'Entrar',
      FILTER: 'Filtrar por',
      AGGREGATE: 'Agregar',
      DESEGREGATE: 'Desagregar',
      TAKE_PICTURE: 'Toma una foto',
      REPLY_TREE: 'Haz clic para navegar por la vista de árbol',
      VALIDATE_DATA: 'Validar datos',
      VALIDATED_DATA: 'Datos validados',
      ALL_DATA: 'Todos los datos',
      VALIDATION: 'Validación',

      SATELLITE: 'Satélite',
      GRAYSCALE: 'Escala de grises',
      CITIES: 'Ciudades',
      DAY: 'Día',
      EXPORT_DATA: 'Exportar datos',
      IMPORT_DATA: 'Importar datos',
      DENIED_LOGIN: 'Ve a tu página de inicio de sesión',
      DENIED: 'Acceso denegado',
      FULLSCREEN: 'Ver en pantalla completa',
      EXIT_FULLSCREEN: 'Salir de pantalla completa',
      FULL_GRAPH: 'Llenar',
      WORKFLOW: 'Flujo de trabajo',
      DATA: 'Datos',
      GPS: {
        LONGITUDE: 'Longitud',
        LATITUDE: 'Latitud',
        ALTITUDE: 'Altitud'
      },
      RIGHT: {
        VALIDATION_SELECT: 'Validar seleccionada',
        ONLYVALDATE: 'Solo datos validados',
        NONVALIDATED: 'No validado',
        INVALIDATED: 'En curso de validación',
        VALIDATED: 'Válido',
        DELVALIDATED: 'A eliminar',
        SELECTVAL: 'Selección de un estatuto',
        VALIDATE: 'Validación',
        VALIDATE_STATUS: 'Estado de validación',
        VIEWING: 'Ver',
        UPDATING: 'Editar',
        DELETING: 'Eliminar',
        PAGE: 'Función',
        EDIT_DELETE: 'Editar/Eliminar',
        DATA: 'Datos',
        METADATA: 'Metadatos'
      },
      STATUT: {
        NOSTART: 'No comenzado',
        PROGRESS: 'En progreso',
        DONE: 'Finalizado'
      },
      SAVE: 'Guardar',
      VIRGULE: '.',
      NEXT: 'Siguiente',
      PREV: 'Anterior',
      ADD: 'Añadir',
      EDIT: 'Editar',
      DELETE: 'Eliminar',
      PRINT: 'Impresión',
      SELECT: 'Seleccionar',
      YEAR: 'Año',
      SEM: 'Semestre',
      QUATR: 'Cuatrimestre',
      TRIM: 'Trimestre',
      BIM: 'Bimestre',
      MOIS: 'Mes',
      PERIOD: 'Período',
      YEAR_SHORT: 'A',
      SEM_SHORT: 'S',
      QUATR_SHORT: 'C',
      TRIM_SHORT: 'T',
      BIM_SHORT: 'B',
      MOIS_SHORT: 'M',
      DISPLAY: 'Mostrar',
      VALIDATE: 'OK',
      CANCEL: 'Cancelar',
      CONTINUE: 'Continuar',
      CONFIRM: 'Confirmar eliminación',
      CONFIRM_RESTORE: 'Confirmar restauración',
      YES: 'Si',
      NO: 'No',
      ERROR_DELETE: '¡No se puede eliminar!',
      ERROR_: 'Error',
      ERROR_UPDATE: '¡No se puede actualizar!',
      ERROR_ADD: '¡No se puede agregar!',
      ERROR_CODE: 'El código ya está usado',
      TITLE_ERROR_ADD: 'Se detectó un error durante la actualización',
      TITLE_ERROR_DELETE: 'Los datos tienen dependencias',
      DATE_FORMAT: 'DD/MM/YYYY',
      DATE_FORMAT_COMPLET: 'DD/MM/YYYY HH:mm',
      DATE_FORMAT_MM_YYYY: 'MMMM YYYY',
      HISTORQ: 'Reciente',
      CLOSE: 'Cerrar',
      DATE_DP: 'Fecha Inicial',
      ACTUAL: 'Fecha Actual',
      DATE_DR: 'Fecha Inicial',
      DATE_FP: 'Fecha Final',
      DATE_FR: 'Fecha Final',
      H_DEB: 'Hora de Inicio',
      H_FIN: 'Hora de Finalización',
      LIEU: 'Ubicación',
      MSG_ADD: '¡Registro agregado!',
      MSG_MODIF: '¡Actualización completada!',
      MSG_DEL: '¡Eliminación completada!',
      TBORD: 'Tablero',
      YEAR_ALL: 'Todo (*)',
      ALL: 'Todos (*)',
      ALL_E: 'Todas (*)',
      NONE: 'Ninguno',
      NONE_E: 'Ninguna',
      SEARCH: 'Buscar',
      TYPECHART: 'Tipo de Gráfico',
      LINECHART: 'Gráfico de Línea',
      BARCHART: 'Gráfico de Barras',
      PIECHART: 'Gráfico de Anillos',
      POLARCHART: 'Gráfico Polar',
      MIXED_CHART: 'Gráfico Mixto',
      BEGIN: 'Comenzar',
      END: 'Finalizar',
      LESS_THAN: 'Menor que',
      GREAT_THAN: 'Mayor que',
      EGAL_TO: 'Igual a',
      LESS_THAN_E: 'Menor que',
      GREAT_THAN_E: 'Mayor que',
      EGAL_TO_E: 'Igual a',
      OPEN: 'Abrir',
      DOWNLOAD: 'Descargar',
      QUIT: 'Cancelar',
      OPEN_DASHBOARD: 'Haz clic para mostrar el Tablero',
      DESCRIPTION: 'Descripción',
      MORE: 'Más...',
      MORE_DBL: 'Haz doble clic para mostrar los detalles',
      DASHBOARD: 'Tablero',
      ERROR: {
        NOMATCH: 'No hay resultados',
        REQUIRED: 'Obligatorio',
        DBL: 'El código ya está asignado',
        DBL_REF: 'La referencia ya está asignada',
        DBL_LAB: 'La etiqueta ya está asignada',
        DBL_: 'Está asignada',
        NONE_DATA: 'No hay datos seleccionados.',
        MIN_CAR: 'la longitud mínima de {{field}} es {{value}}',
        MAX_CAR: 'la longitud máxima de {{field}} es {{value}}',
        EMAIL: 'El correo electrónico no es valido',
        CODE_ERROR: 'La longitud debe ser igual a {{value}}',
        CODE_ERROR_COM: 'La longitud debe ser igual a {{value}} y comenzar con {{begin}}',
        DATE_RANGE: 'La fecha de inicio debe ser mayor que la fecha de finalización',
        DATE: 'Fecha invalida',
        FINANCETOTAL: 'El total debe ser igual al 100%.',
        INVALID_URL: 'La URL no es válida'
      },
      EXPORT_WORD: 'Exportar a Word',
      EXPORT_EXCEL: 'Exportar a Excel',
      EXPORT_PDF: 'Exportar a PDF',
      EXPORT_JSON: 'Exportar a JSON',
      STREETMAP: 'Mapa de Carretera',
      NEW_FEAUTURE: 'Presentación de nuevas funciones.',
      DATE_FORMAT_DISPLAY: 'Formato de fechas'
    },
    MENU_SHORT: {
      LAYERS: 'Fondo de mapa',
      LAYER: 'Fondo de mapa',
      RISKS_MANAGEMENT: 'Gestión de riesgos',
      PROBLEM_MANAGEMENT: 'Problemas',
      RISKS: 'Riesgos',
      PROBLEM: 'Problemas',
      MISSION_MANAGEMENT: 'Gestión de misiones',
      MISSION_TYPES: 'Tipos de misión',
      COMMUNITIES: 'Comunidades',
      REPORT_PLAN: 'Planificación de Informes',
      REPORT_TRACKING: 'Seguimiento de Informes',
      FUNDS: 'Desembolso de fondos',
      ACTIVITY_PLAN_BUDGET: 'Planificar y Presupuesto',
      PROJECT_TIMELINE: 'Cronograma del proyecto',
      RISK_MANAGEMENT: 'Gestión de riesgos',
      PROJECT_MGT: 'Gestión',
      ACTIVITY_REPORT: 'Informes',
      CUSTOM_RIGHT: 'Derechos personalizados',
      HOME: 'Inicio',
      ALERT: 'Alertas',
      D_B: 'Configuraciones',
      DO: 'Portafolio de proyectos',
      PROJECT: 'Proyectos',
      PROJECT_SINGLE: 'Proyecto',
      D_T: 'División de tiempo',
      D_P: 'Período de Informes',
      D_PL: 'Planificación de 3 Niveles',
      UN: 'Unidades',
      AGENCIES: 'Agencias',
      AC: 'Interesados',
      FINANCING: 'Financiación',
      S_F: 'Fuentes de Financiamiento',
      P_G: 'Localizaciones',
      S_G: 'División Geográfica',
      T_I: 'Categ. de KPI',
      C_I: 'Desagregación de KPI',
      IN: 'Indicadores (KPI)',
      IN_T: 'Indicadores (KPI)',
      IN_P: 'KPI de proyectos cruzados',
      R_F: 'Marco de resultados de KPI',
      R_F_: 'Marco de resultados',
      P_L: 'Personalizar Etiqueta',
      P_LO: 'Registrar marco',
      C_B: 'Categ. de Presupuesto',
      P_CL: 'Marco lógico',
      P_CUSTOM: 'Plantillas',
      P_CR: 'Cadena de Resultados',
      ST: 'Plantilla de marco de registro',
      ST_CL: 'Estructura del marco lógico',
      ST_CR: 'Estructura de Cadena de Resultados',
      P_B: 'Plan Presupuestario',
      P_M: 'Plan de adquisiciones',
      FL: 'Bandera',
      T_A: 'Categ. de Actividad',
      AGENDA: 'Agenda',
      FILE: 'Sala de chat',
      ANALYTICS: 'Tableros',
      PL: 'Actividades',
      P_A: 'Monitoreo de Actividad',
      P_AN_PTBA: 'Plan anual (AWPB)',
      IMP_BUDG: 'Presupuesto de importación',
      G_F_PF: 'Generar archivo preformateado',
      I_B: 'Importar presupuestos',
      P_I: 'Indicadores de Planificación',
      S_E: 'Monitoreo',
      S_A: 'Monitoreo de {{level}}',
      I_C: 'Costos de importación',
      I_COUT: 'Importación de costos',
      S_PTBA: 'Monitoreo',
      S_PTBP: 'Rendimiento Anual ',
      T_B_A: 'Panel de Actividades',
      C_GANTT: 'Cronograma del Proyecto',
      P_AC: 'Plan de Acción',
      R_P: 'Consultas personalizadas',
      MA: 'Contratos',
      S_M: 'Monitor de contratos',
      S_PPM: 'Monitoreo de PP',
      S_I: 'Monitoreo de indicadores',
      S_I_IOV: 'Indicadores',
      S_I_G: 'Monitoreo geográfico de indicadores',
      T_B_I: 'Tablero de indicadores',
      SAP_VIS: 'Visualización espacial',
      SY: 'Resumen',
      C_L: 'Marco de Registros',
      S_C_L: 'Monitoreo del Marco de Registros',
      S_C: 'Contexto',
      G_R: 'Gestión de riesgos',
      P_M_R: 'Planificación de mitigación de riesgos',
      F_P: 'Factores Positivos',
      E_T: 'Lecciones Aprendidas',
      B_D: 'Aprendizaje',
      B_D_: 'Intercambio de conocimientos y aprendizaje',
      DB_P: 'Bases de datos personalizadas',
      T_RA: 'Informes del Personal',
      CA_R: 'Seguimiento de Informes',
      C_D: 'Documentos',
      PH: 'Mediateca',
      AD: 'Usuarios',
      G_U: 'Usuarios',
      GR_U: 'Grupos',
      U_C: 'Usuarios Conectados',
      J_C: 'Diario de Conexiones',
      R_I: 'Perfiles',
      FORMULAR_: 'Recopilación de Datos',
      FORMULAR: 'Recopilación de Datos',
      DESIGN_FORMS: 'Formas de diseño',
      COLLECT_DATA: 'Recopilación de Datos',
      ANALYZE_DATA: 'Análisis e Informes',
      ANALYSE: 'Informes Personalizados',
      DASHBOARD: 'Tableros',
      DASHBOARD_: 'Tableros',
      ANALYTICS_GIS: 'Análisis Espacial',
      AUDITS: 'Pista de Auditoría',
      SECURITY: 'Seguridad',
      DELETED_ITEMS: 'Elementos Eliminados',
      ADMIN: 'Administración'
    },
    MENU: {
      LAYERS: 'Fondo de mapa',
      LAYER: 'Fondo de mapa',
      RECOMMENDATIONS: 'Recomendación',
      RISKS_MANAGEMENT: 'Gestión de riesgos',
      PROBLEM_MANAGEMENT: 'Problemas',
      RISKS: 'Riesgos',
      PROBLEM: 'Problemas',
      MISSION_MANAGEMENT: 'Gestión de misiones',
      MISSION: 'Misione',
      MISSION_TYPES: 'Tipos de misión',
      COMMUNITIES: 'Comunidades',
      REPORT_PLAN: 'Planificación de Informes',
      REPORT_TRACKING: 'Seguimiento de Informes',
      FUNDS: 'Desembolso de fondos',
      SECTORS: 'Sectores',
      PROJECT_MGT: 'Gestión',
      ACTIVITY_REPORT: 'Informes de proyectos',
      DASHBOARDS: 'Tableros',
      PROJECTS: 'Proyectos',
      HOME: 'Inicio',
      ALERT: 'Alertas',
      D_B: 'Configuraciones',
      DO: 'Portafolio de Proyectos',
      PROJECT: 'Proyectos',
      D_T: 'División de Tiempo',
      D_P: 'Período de Informe',
      D_PL: 'Planificación de 3 niveles',
      UN: 'Unidades de medida',
      FINANCING: 'Financiación',
      AGENCIES: 'Agencias de Ejecución',
      AC: 'Partes Interesadas',
      S_F: 'Fuentes de financiamiento',
      P_G: 'Localizaciones',
      Plan_G: 'Localizaciones',
      S_G: 'Estructura Geográfica',
      STRUCT_G: 'Estructura geográfica',
      T_I: 'Categorías de KPI',
      C_I: 'Desagregación de KPI',
      IN: 'Indicadores (KPI)',
      IN_T: 'Indicadores (KPI)',
      IN_P: 'KPI de Proyectos Cruzados',
      R_F: 'Marco de resultados de KPI',
      R_F_: 'Marco de resultados de indicadores',
      P_L: 'Personalizar Etiqueta',
      P_LO: 'Marco de Registro',
      C_B: 'Categorías Presupuestarias',
      P_CL: 'Marco de Registro',
      P_CUSTOM: 'Plantillas de Marco de Registro',
      P_CR: 'Cadena de Resultados',
      ST: 'Plantilla de Marco de Registro',
      ST_CL: 'Estructura del Marco Lógico',
      ST_CR: 'Estructura de Cadena de Resultados',
      P_B: 'Plan Presupuestario',
      P_M: 'Plan de Adquisiciones',
      FL: 'Bandera',
      T_A: 'Categorías de Actividades',
      AGENDA: 'Agenda',
      FEEDBACK: 'Retroalimentación',
      FILE: 'Noticias',
      PL: 'Actividades',
      P_A: 'Monitoreo de Actividades',
      P_AN_PTBA: 'Plan Anual (AWPB)',
      IMP_BUDG: 'Importación de Presupuesto',
      G_F_PF: 'Generar archivo preformateado',
      I_B: 'Importar presupuestos',
      P_I: 'Indicadores del plan',
      S_E: 'Monitoreo',
      S_A: 'Monitoreando {{level}}',
      I_C: 'Importación de Costos ',
      I_COUT: 'Importar Costos',
      S_PTBA: 'Monitoreo',
      S_PTBP: 'Rendimiento Anual del Proyecto',
      T_B_A: 'Talero de Actividades',
      C_GANTT: 'Cronograma del proyecto',
      P_AC: 'Plan de Acción',
      R_P: 'Consultas personalizadas',
      MA: 'Contratos',
      S_M: 'Monitoreo de Contratos',
      S_I: 'Monitoreo de indicadores',
      S_I_IOV: 'Indicadores',
      S_I_G: 'Monitoreo geográfico de Indicadores',
      T_B_I: 'Tablero de Indicadores',
      SAP_VIS: 'Visualización Espacial',
      SY: 'Resumen',
      C_L: 'Marco de registro',
      S_C_L: 'Supervisión del marco lógico',
      S_C: 'Contexto',
      G_R: 'Gestión de riesgos',
      P_M_R: 'Plan de mitigación de riesgos',
      F_P: 'Factores positivos',
      E_T: 'Lecciones Aprendidas',
      B_D: 'Aprendizaje',
      B_D_: 'Intercambio de conocimientos y aprendizaje',
      DB_P: 'Bases de Datos Personalizadas',
      T_RA: 'Informes del Personal',
      CA_R: 'Seguimiento de Informes',
      C_D: 'Documentos',
      PH: 'Mediateca',
      AD: 'Usuarios',
      G_U: 'Gestión de usuarios',
      GR_U: 'Grupos de Usuarios',
      U_C: 'Usuarios Conectados',
      J_C: 'Diario de Conexiones',
      R_I: 'Perfiles de Usuario',
      FORMULAR_: 'Recopilación de Datos',
      FORMULAR: 'Recopilación de Datos',
      ANALYTICS: 'Tableros',
      ANALYTICS_CUSTOM_REPORT: 'Informes Personalizados',
      ANALYTICS_CUSTOM_DASHBOARD: 'Tableros',
      ANALYTICS_GIS: 'Análisis Espacial',
      DESIGN_FORMS: 'Diseño de Formularios',
      COLLECT_DATA: 'Recopilación de Datos',
      ANALYZE_DATA: 'Análisis e Informes',
      ANALYSE: 'Informes Personalizados',
      DASHBOARD: 'Tableros',
      DASHBOARD_: 'Tableros',
      AUDITS: 'Pista de auditoría',
      SECURITY: 'Ajustes de Seguridad',
      DATA: 'Formularios',
      ADMIN: 'Administración'
    },
    LAYER: {
      NAME: 'título',
      URL: 'Url',
      HELP: {
        NAME: 'título',
        URL: 'La URL del fondo de mapa (https://...{x}{y}{z})'
      },
      ERROR: {
        URL: 'URL inválida'
      }
    },
    PROGRAM: {
      EDIT: 'Editar Portafolio',
      EDIT_PROGRAM: 'Edición del Portafolio',
      EDIT_HEADERS: {
        DETAIL: 'Detalles del Portafolio',
        DESCRIPTION: 'Descripción del Portafolio',
        ME: 'Establecimiento de períodos e hitos',
        PERIODS: 'Parametrización de períodos',
        MILESTONES: 'Hitos',
        OPTIONS: 'Opciones'
      },
      TITLE: 'Portafolio',
      NAME: 'Nombre',
      DESCRIPTION: 'Descripción',
      HELP: {
        NAME: 'Ingresar el Nombre del Portafolio',
        DESCRIPTION: 'Ingresar la Descripción del Portafolio'
      }
    },
    DEFAULT_LOGFRAME: {
      TITLE: 'Plantillas de Marco de Registro',
      SUBTITLE: 'Plantilla de Marco de Registro',
      LABEL: 'Nombre',
      LEVELS: 'Nivel de Marco de Registro',
      SEARCH: 'Buscar por nombre',
      HELP: {
        NEW_LEVEL: 'Agregar nuevo nivel',
        LABEL: 'Ingresar el nombre del Marco de Registro'
      }
    },
    SECTORS: {
      NAME: 'Nombre'
    },
    FINANCING: {
      EDIT_TITLE: 'Financiamiento',
      SOURCE: 'Fuente de financiamiento',
      AGREEMENT: 'Acuerdo de financiamiento #',
      TYPE: 'Escribe',
      TYPES: {
        GRANT: 'Conceder',
        LOAN: 'Préstamo',
        DONATION: 'Donación',
        BUDGET_SUPPORT: 'Apoyo presupuestario'
      }
    },
    PORTFOLIO: {
      GO_TO_PORTFOLIO: 'ver proyectos ...',
      EDIT_TITLE: 'Portafolio',
      CLOSE: 'Volver a portafolios',
      OPEN: 'Administrar Portafolio',
      CODE: 'Acrónimo',
      LABEL: 'Nombre ',
      TITLE: 'Portafolios',
      ADD: 'Agregar Portafolio',
      ERROR: {
        DBL: 'El acrónimo de la cartera ya está asignado',
        DATE: 'La fecha de finalización debe ser mayor que la fecha de inicio',
        DATE_BEGIN_SUP: 'La fecha de inicio debe ser mayor que {{X}}',
        DATE_BEGIN_INF: 'La fecha de inicio debe ser menor que {{X}}',
        DATE_END_SUP: 'La fecha de finalización debe ser mayor que {{X}}',
        DATE_END_INF: 'La fecha de finalización debe ser menor que {{X}}',
        DELETE: 'Este sector se está utilizando actualmente'
      }
    },
    PROJECT: {
      ACTIVE_SMS: 'Activar envío de alertas SMS',
      ACTIVE_SMS_WARNING: 'La activación de esta opción incurrirá en costos adicionales.\n' + 'Póngase en contacto con DELTAGIS para la activación efectiva de la opción.',
      NEWS: 'Noticias',

      CUSTOM_FIELD_1: 'Caption Fields 1',
      CUSTOM_FIELD_2: 'Caption Fields 2',
      OPTION_ACTIVITIES: 'Opciones de gestión',
      OPTION_INDICATORS: 'Indicadores de opciones',
      OPTION_OTHERS: 'Otras opciones',
      CUSTOM_FIELD: 'Usar campos personalizados',
      INDEX_PERFORMANCE: 'Rendimiento',
      CROSS_CUTTING_KPIS: 'Indicadores clave de desempeño transversales',
      CROSS_CUTTING_KPIS_LIST: 'Indicadores clave de desempeño transversales',
      PORTFOLIO_CROSS_CUTTING_KPIS: 'Aislar los indicadores transversales de la cartera',
      MISSIONS: 'Seguimiento de la misión',
      RISK: 'Gestión de riesgos',
      FOLLOW_RISK: 'monitoreo de riesgos',
      FOLLOW_PERFORM: 'Supervisión del rendimiento',
      TYPE: 'Tipo',
      TYPES: {
        PROJECT: 'Proyecto',
        PROGRAM: 'Programa',
        OTHER: 'Otro'
      },
      FILTERS: 'Filtros',
      CLOSE: 'Cerrar',
      TO_FILTER: 'filtrar',
      CLEAR_FILTERS: 'borrar',
      ACTOR: 'Encargado',
      ACTORS: 'Encargado',
      ACTOR_NONE: 'Ninguno',
      STATUS_PROGRESS: 'Progreso',
      STATUS_PROGRESS_: 'Progreso',
      DASHBOARDS: 'Salpicadero Proyecto',
      GO_TO_PROJECT: 'Ir al proyecto',
      SUMMARY: 'Desempeño del proyecto',
      SELECT_SECTORS: 'Sectores',
      SECTORS: 'Sectores',
      PROJECTS: 'Proyectos',
      PROJECTS_COL: 'Proyecto',
      EDIT: 'Editar',
      GEO_MAP: 'Geo Mapeado',
      SECTOR: 'Sector',
      FINANCING: 'Fuente de Financiamiento',
      AMOUNT: 'Cantidad',
      ADD_: 'Agregar',
      FINANCIAL_DATA: 'Datos Financieros',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} proyectos)',
      PAGING_ELEMENT_SUMMERY: '{{current}} de {{total}} páginas ({{count}} elementos)',
      AGENCIES: 'Agencias Ejecutoras',
      SELECT_AGENCIES: 'Seleccionar Agencias Ejecutoras',
      DISPLAY_DATA: 'Utilizar el portal para mostrar datos',
      LOCATION: 'Coordenadas GPS',
      ADD: {
        OPTION_1: 'Proyecto',
        OPTION_2: 'Portafolio'
      },
      LINK: {
        TITLE: 'Vincular los siguientes datos a los portafolios',
        UNITS: 'Unidades',
        FINANCIAL_SOURCES: 'Fuentes de Financiamiento',
        LOCALITIES: 'Ubicaciones',
        LOGICAL_FRAME: 'Marco de Registro',
        FLAG: 'Bandera',
        TYPE_ACTIVITIES: 'Categorías de actividad',
        TYPE_INDICATORS: 'Categorías de KPI',
        TYPE_REPORT: 'Informes',
        FORM: 'Formularios'
      },
      SELECT_PERIOD: 'Seleccionar un período',
      SEARCH_PROJECT: 'Buscar por código o etiqueta',
      FILTER: 'Filtrar por:',
      MILESTONES: {
        REALIZE: 'Completado',
        TITLE: 'Hitos',
        MILESTONE: 'Hito',
        PLANNED_DATE: 'Fecha Planificada',
        ACTUAL_DATE: 'Fecha Actual',
        ACTUAL: 'Real',
        COMMENTS: 'Comentarios',
        PLANNED: 'Planificado'
      },
      DESCRIPTION: 'Descripción',
      GLOBAL_PLAN: 'Usar planificación global',
      PERIODIC_FOLLOW: 'Frecuencia de Informes',
      SETTING_FOLLOW: 'Configuraciones de Monitoreo',
      ACTIVITY_WEIGHT: 'Usar Ponderación',
      ACTIVITY_WEIGHT_AN: 'Por año',
      START_MONTH: 'Inicio del año fiscal',
      FISCAL_YEAR: 'Año fiscal',
      LOGFRAME: 'Marco de Registro',
      RESBAS_MONIT: 'Monitoreo Basado en Resultados',
      CUSTOM_LOGFRAME: 'Marco de Registro Personalizado',
      ME_METHOD: 'Plantillas de Marco de Registro',
      ME_METHOD_SELECT: 'Seleccionar plantilla de Marco de Registro del proyecto',
      FILTER_BY: 'Filtrar por',
      PRJ: 'Portafolio',
      DASHBOARD: 'KPI de Proyectos Cruzados',
      TITLE: 'Portafolio',
      NEWP: 'Agregar',
      FROM: 'desde',
      TO: 'hasta',
      ADDRESS: 'Dirección',
      CONTACT: 'Persona de contacto (punto focal)',
      PROCESS: 'Progreso...',
      COMPLETE: 'Terminación con',
      PROJECT: 'Proyecto',
      EDIT_PROJECT: 'Editar Proyecto',
      INFO: 'Información',
      CODE: 'Acrónimo del Proyecto',
      LABEL: 'Nombre del proyecto',
      CURRENCY: 'Moneda',
      D_DEB: 'Fecha de Inicio',
      D_FIN: 'Fecha final',
      D_DEB_: 'Inicio',
      D_FIN_: 'Final',
      COOR: 'Información del Contacto',
      LINK_GEO_PLAN: 'Utilizar las ubicaciones del Programa',
      ADR: 'Ciudad',
      PAYS: 'País',
      TEL: 'Teléfono',
      FAX: 'Fax',
      MAIL: 'Correo Electrónico',
      OTHER: 'Detalles',
      AGENC: 'Agencia Ejecutora',
      DESCR: 'Descripción',
      CODE_ERR: 'Código no disponible',
      CONFIRM_DELETE: '¿Quieres eliminar este proyecto?',
      SELECT: 'Seleccionar',
      CHANGE_PIC: 'Cambiar Imagen (100x100)',
      STATUT_BUDGET: 'Publicar datos financieros',
      GOAL: 'Objetivo general',
      TARGET: 'Beneficiarios',
      AREA: 'Área de ejecución',
      LOCATION_FIELD: 'Localización geográfica',
      SPECIFIC_OBJECTIVES: 'Objetivos específicos',
      IMPACT: 'Impacto esperado',
      DATE_MID: 'Revisión Intermedia',
      STATUS: 'Estado',
      COST: 'Costo Total del Proyecto',
      FINAN: 'Financiación',
      MAP: 'Ubicación de Proyectos',
      GLOBAL_HEADERS: {
        PROJECTS: 'Lista',
        MAP: 'Mapa',
        INDICATOR: 'KPI de proyectos cruzados'
      },
      EDIT_HEADERS: {
        DETAIL: 'Detalles',
        DESCRIPTION: 'Descripción',
        ME: 'Establecimiento de períodos e hitos',
        PERIODS: 'Parametrización de períodos',
        MILESTONES: 'Hitos',
        OPTIONS: 'Opciones'
      },
      STATUTS: {
        NOT_STARTED: 'No Comenzado',
        ACTIVE: 'Activo',
        SUSPENDED: 'Suspendido',
        COMPLETED: 'Terminado',
        ARCHIVED: 'Archivado'
      },
      TOOLTIP: {
        CURRENCY: '5 caracteres máximo',
        STATUT_BUDGET: 'Si esta opción está marcada, los datos financieros se revelarán como parte de la información que se muestra en el portal.'
      },
      ERROR: {
        CUSTOM_FIELD: 'Por favor, complete uno de los campos',
        DELETE: '¡El proyecto tiene datos y no se puede eliminar!',
        DBL: 'El acrónimo ya está asignado',
        DATE: 'La fecha de finalización debe ser mayor que la fecha de inicio',
        CURRENCY: 'La longitud de la moneda debe tener menos de 5 caracteres.',
        SAVING: 'Si ha actualizado la fecha de inicio del año fiscal o la frecuencia de los informes, se eliminarán todos los datos relacionados (planificados y reales).'
      },
      MAP_VIEW: {
        NBR_PROJECT: 'Numero de proyectos',
        NBR: 'Numero',
        INVESTEMENT: 'Inversiones ({{currency}} millones)',
        MILLIONS: '({{currency}} millones)',
        AGENC: 'Agencia Ejecutora',
        PROJECTS: 'Proyectos',
        BUDGET: 'Monto ({{currency}} millones)',
        LOCATIONS: 'Localidades',
        BACK: 'Espalda',
        STATUS: 'Estado',
        SECTORS: 'Sectores',
        FUNDING_SOURCE: 'Fuentes de Financiamiento',
        SUB_LOCATIONS: 'Sublocalidades',
        TOTAL: 'Total'
      },
      DASHBOARD_VIEW: {
        FINANCING_PLAN: 'Plan de financiación',
        DASHBOARD_TYPE: {
          PROJECTS: 'Panel',
          RISK: 'Riesgos',
          PROBLEM: 'Incidentes',
          MISSION: 'Misiones'
        },
        FINANCING_TYPE_: 'Tipo de financiación',
        DESCRIPTION: 'Descripción',
        FINANCIAL_DATA: 'Rendimiento financiero',
        INDICATOR_PERFORMANCE: 'Indicadores de rendimiento',
        INDICATOR_RESULT: 'Marco de resultados',
        EXCHANGE: 'Tipo de cambio',
        TOOLTIP_SECTOR_PLURALS: '{{project}} proyectos que totalizan {{amount}} ({{currency}} millones)',
        TOOLTIP_SECTOR_SINGLE: '1 proyecto que totaliza {{amount}} ({{currency}} millones)',
        NUMBER_OF_PROJECT: 'Numero de proyectos',
        DECIMAL_CURRENCY: '{{currency}} millones',
        FINANCING: 'Financiación',
        CURRENCY: 'Moneda',
        PROJECT: 'Proyectos',
        AMOUNT: 'Cantidad',
        DISBURSED: 'Desembolsado',
        PROJECT_BY_SECTOR: 'Desglose por Sector',
        INVESTING_BY_FUNDING_SOURCE: 'Inversión por fuentes de Financiamiento',
        PROJECT_BY_FUNDING_SOURCE: 'Desglose por fuentes de Financiamiento',

        SOLD: 'Disponible',
        RESULTS_FRAMEWORK_STANDARD: 'Marco de resultados de indicadores (estado actual)',
        RESULTS_FRAMEWORK_MULTI_YEAR: 'Marco de resultados de indicadores (avance anual)',
        RESULTS_FRAMEWORK: 'Marco de Resultados',
        TOTAL_COST: 'Costo total del Proyecto',
        DISBURSED_RATE: 'Evolución de la tasa global de desembolso',
        TOTAL_DISBURSED_BY_FUNDING: 'Total desembolsado por fuente de financiamiento',
        FILTER: 'Filtrar',
        FINANCING_TYPE: 'Fuente de financiamiento',
        AGENCIES: 'Agencias Ejecutoras',
        STATUS: 'Estado',
        SECTORS: 'Sector',
        LOCATIONS: 'Ubicaciones',
        LOCATION: 'Ubicacione'
      }
    },
    BTN: {
      ADD: 'Agregar',
      MOD: 'Editar',
      DEL: 'Eliminar',
      CAN: 'Cancelar',
      PRI: 'Impresión',
      VAL: 'Ok',
      NEX: 'Página Siguiente',
      PRE: 'Página Anterior'
    },
    DEVISE: {
      TITLE: 'Moneda',
      CODE: 'Código',
      LABEL: 'Nombre'
    },
    FUNDS: {
      AVAILABLE: 'Disponible',
      MAPPING: 'Importar Datos',
      USE_SELECTED_FINANCING: 'Utilizar Financiación Seleccionada',
      USE_CURRENT_DATE: 'Usar la fecha de hoy',
      PLEASE_SELECT_COLUMN: 'Seleccionar una columna',
      REFERENCE: '#',
      REFERENCE_FIELD: 'Desembolso #',
      LABEL: 'Desembolso',
      EDIT_TITLE: 'Desembolso',
      DATE_FUNDS: 'Fecha',
      AMOUNT: 'Cantidad',
      DEVISE: 'Moneda',
      RATE: 'Velocidad',
      AMOUNT_RATE: 'Monto * Tarifa',
      HELP: {
        REFERENCE: '# o Ref. del Desembolso',
        LABEL: 'Naturaleza o propósito del desembolso',
        DATE_FUNDS: 'Fecha de desembolso',
        AMOUNT: 'Monto desembolsado',
        DEVISE: 'Moneda del monto desembolsado',
        RATE: 'Tipo de cambio frente a la moneda de financiación',
        AMOUNT_RATE: 'Importe (moneda de financiación)'
      },
      FINANCING: 'Financiación',
      DISBURSED: 'Desembolsado',
      BAR_CHART: 'TASA DE DESEMBOLSO POR AÑO',
      PIE_CHART: 'TOTAL DESEMBOLSADO POR AÑO'
    },
    FN_UNIT: {
      HELP: {
        COD: 'Ingresar el código de la unidad (10 caracteres)',
        LAB: 'Nombre de la unidad'
      },
      ERROR_CODE: 'La longitud del código no debe exceder los 10 caracteres',
      SEARCH: 'Buscar unidades por código o nombre',
      LST_TI: 'Unidades de medida (actividades e indicadores)',
      ED_TI: 'Detalles de la unidad',
      COD: 'Código',
      LAB: 'Etiqueta',
      TYP: 'Tipo',
      FOR: 'Formato',
      CB_N: 'Numérico',
      CB_P: '%',
      CB_B: 'Booleano',
      CB_T: 'Texto',
      CB_D: 'Fecha',
      NEWU: 'Crear nueva unidad',
      COLU: 'Unidad',
      CONFIRM_DELETE: '¿Quieres eliminar esta unidad?',
      DECIMAL: 'Número de dígitos después del punto decimal'
    },
    CATEGORIE_BUDGET: {
      HELP: {
        COD: 'Ingresar el código de la unidad (10 caracteres)',
        LAB: 'Nombre de la categoría'
      },
      CODE: 'Código',
      CATEGORIE_BUDGET: 'Categoría de presupuesto',
      TITLE: 'Categorías de presupuesto',
      LABEL: 'Nombre',
      CONFIRM_DELETE: '¿Quieres eliminar esta categoría?'
    },
    AGENCIES: {
      TITLE: 'Agencias Ejecutoras',
      NAME: 'Nombre',
      SHORT_NAME: 'Nombre corto',
      ADDRESS: 'Dirección',
      CITY: 'Ciudad',
      COUNTRY: 'País',
      TEL: 'Teléfono',
      FAX: 'Fax',
      EMAIL: 'Correo Electrónico',
      URL: 'Sitio web',
      HELP: {
        NAME: 'Ingresar el nombre de la agencia',
        SHORT_NAME: 'Ingresar el nombre corto de la agencia',
        ADDRESS: 'Ingresar la dirección de la agencia',
        CITY: 'Ingresar Ciudad',
        COUNTRY: 'País',
        TEL: 'Ingresar Teléfono',
        FAX: 'Ingresar Fax',
        EMAIL: 'Ingresar Correo Electrónico',
        URL: 'Ingresar sitio web'
      },
      SEARCH: 'Buscar por nombre, dirección, ciudad o país',
      ERROR: {
        DBL_NAME: 'El nombre ya está asignado'
      }
    },
    FN_ACTORS: {
      HELP: {
        COD: 'Código, Identificación o Acrónimo',
        NOM: 'Nombre del interesado',
        PRE: 'Nombre del interesado',
        FON: 'Rol/Responsabilidad asignada al grupo de interés',
        ORGANIZATION: 'Organización a la que pertenece el grupo de interés.',
        GRO: 'Grupo del interesado',
        EMAIL: 'Correo electrónico del interesado.'
      },
      PERIOD_RAPPORT: 'Período',
      EDITION_RAPPORT: 'Fecha de edición',
      SEARCH: 'Buscar por código, nombre',
      CATEGORY: 'Categoría',
      CATEGORY_TEAM: 'El personal del Proyecto',
      CATEGORY_STAKE: 'Interesados',
      LST_AC: 'Interesados',
      ED_AC: 'Miembro del equipo',
      COD: 'Código',
      NOM: 'Nombre',
      PRE: 'Nombre',
      FON: 'Rol o responsabilidad',
      IMG: 'Imagen',
      ORGANIZATION: 'Organización',
      GRO: 'Grupo',
      GRO_: 'Grupo',
      SELECT_GRO: 'Seleccionar un grupo',
      NEWA: 'Crear nuevo interesado',
      LAB_AC: 'Ocupaciones',
      LAB_LSTAC: 'Ocupaciones',
      LAB_MI: 'Misiones',
      LAB_LSTMI: 'Misiones',
      LAB_RE: 'Reunión',
      LAB_LSTRE: 'Reunión',
      LAB_RA: 'Informes',
      LAB_LSTRA: 'Informes',
      LAB_TYPRA: 'Tipo de Informe',
      FILT: 'Filtrar',
      ETAV: 'Estado de actividad',
      RESNIVO: 'Nivel de Responsabilidad',
      CHECK_A1: 'En progreso',
      CHECK_A2: 'Completado',
      CHECK_A3: 'Después de lo previsto',
      CHECK_A4: 'Antes de lo previsto',
      CHECK_A5: 'Global',
      CHECK_A6: 'Local',
      CONFIRM_DELETE: '¿Quieres eliminar este actor?'
    },
    FN_SFIN: {
      APPROVE_DATE: 'Fecha de aprobación',
      CLOSE_DATE: 'Fecha de cierre',
      SEARCH: 'Buscar por código o nombre',
      NAME: 'Nombre',
      NAME_CONTACT: 'Nombre',
      TEL_CONTACT: 'Teléfono',
      EMAIL_CONTACT: 'Dirección de correo electrónico',

      NAME_CONTACT_LONG: 'Contacto (Nombre)',
      TEL_CONTACT_LONG: 'Contacto (teléfono)',
      EMAIL_CONTACT_LONG: 'Contacto (correo electrónico)',

      CONTACT: 'Persona de contacto',
      FINANCE_AMOUNT: 'Monto de financiamiento',
      AMOUNT: 'Monto',
      CURRENCY: 'Moneda',
      EXCHANGE_RATE: 'Tipo de cambio',
      LST_FIN: 'Fuentes de financiamiento',
      ED_FIN: 'Detalles de la fuente de financiamiento',
      CONFIRM_DELETE: '¿Quieres eliminar esta fuente?',
      EQUIV: 'Equiv. {{CURRENCY}}',
      ERROR_DATE: 'La fecha de aprobación debe ser menor que la fecha de cierre.'
    },
    FN_TYPINDIC: {
      LST_TYP: 'Lista de tipos de indicadores',
      ED_TYP: 'Detalles tipo de indicador',
      COL_COLOR: 'Color',
      COLOR_BLUE: 'Azul',
      COLOR_CRYAN: 'Cian',
      COLOR_GREEN: 'Verde',
      COLOR_YELOW: 'Amarillo',
      COLOR_ORANGE: 'Naranja',
      COLOR_RED: 'Rojo',
      COLOR_PINK: 'Rosado',
      COLOR_PURPLE: 'Púrpura',
      COLOR_GREY: 'Gris',
      COLOR_BLACK: 'Negro',
      CONFIRM_DELETE: '¿Quieres eliminar este tipo de indicador?'
    },
    DASHBOARD: {
      PROGRESS_REPORT: 'Reporte de progreso',
      ACTIVITIES_TAB: {
        PHYSIC: '% Físico',
        FINANCIAL: '% Presupuesto',
        DISBURSED: '% Desembolsado',
        TITLE: 'Progreso de la Actividad',
        COMPLETED_ACTIVITIES: 'Terminado',
        NOT_CARRIED_OUT_ACTIVITIES: 'No realizado',
        ON_GOING_ACTIVITIES: 'En marcha',
        STATUS: 'Estado'
      },
      LOCATION: 'Ubicación',
      ACTIVITY_COUNT: 'Número de actividades: {{value}}',
      PROJECT_ACTIVITY: '{{value}} de las actividades del proyecto',
      ACTIVITY_PROGRESS_LABEL: 'El {{percent}}% de las actividades ({{count}}) tienen una tasa de finalización entre el {{min}}% y el {{max}}%',
      INDICATOR_PROGRESS: 'Progreso del Indicador',
      SPATIAL_VIEW: 'Vista Espacial',
      INDICATOR_PROGRESS_COMPARED: 'Progreso del indicador en comparación con el proyecto final (fin del proyecto)',
      INDICATOR: 'Panel de indicadores',
      ACTIVITY: 'Panel de  {{activity}}',
      BOOKMARKED: 'Indicador Marcado',
      REFERENCE: 'Referencia',
      FINAL: 'Fin del Proyecto',
      END_TARGET: 'Objetivo Final',
      ACTUAL_VALUE: 'Real',
      END_VALUE: 'Fin del proyecto',
      MAP: 'Mapa',
      TITLE: 'Panel del {{code}}',
      TITLE_PORTFOLIO: 'Panel',
      V_R: 'Valor de Referencia',
      V_C: 'Valor objetivo',
      FROM: 'desde',
      TO: 'hasta',
      PROCESS: 'Progreso...',
      COMPLETE: 'Completado',
      SIT_INDIC: 'Estado de los Indicadores',
      REP_ACTIVITE: 'Rendimiento del proyecto',
      PHY: '% de objetivo',
      FIN: '% del presupuesto',
      GRAPHE_PERF: 'Gráfico de Rendimiento',
      SUIV_PTBA: 'Rendimiento Anual del Proyecto',
      DASHBOARD: 'Tablero',
      RESUME_PROJECT: 'Resumen del Proyecto',
      EVOLUTION_TABLE: 'Progreso del iIndicador',
      REALIZE: '% Logrado para Finalizar el Proyecto',
      YEAR: 'Año',
      PERIOD: 'Período',
      INDICATORS: 'Indicadores',
      ACTIVITIES: 'Ocupaciones',
      MSG_DBCLK: 'Doble clic para más detalles'
    },
    TIME_DIVISION: {
      PERIOD: 'Período',
      PTBA: 'AWPB actual',
      PLAN_AN: 'Plan Anual',
      LEVEL: 'Nivel',
      LEVEL_1: 'Global',
      LEVEL_2: 'Anual',
      LEVEL_3: 'Período',
      ALLOW_TOTAL_GLOBAL: 'Permitir que el total anual exceda el total',
      ALLOW_TOTAL_ANNUAL: 'Permitir que el período total exceda el anual',
      START_LEVEL: 'Comenzar Nivel de Planificación',
      GLOBAL: 'Global',
      ANNUAL: 'Anual',
      PERIODIC: 'Período',
      HELP: {
        LEVEL_1: 'El plan global permite establecer objetivos y presupuestos para toda la duración del proyecto.',
        LEVEL_2: 'El plan global se desglosa en planes anuales para cada año del Proyecto.',
        LEVEL_3: 'Cada plan anual se desglosa en planes periódicos según los periodos establecidos previamente'
      },
      BEGIN: 'Comenzar',
      END: 'Finalizar',
      LABEL: 'Etiqueta'
    },
    GEOGRAPHIC_PLAN: {

      IMPORT_SHP: {
        INFO: 'Por favor, seleccione la propiedad que contiene el nombre de los lugares',
        PROPERTY: 'Propiedad'
      },
      REFRESH_GEO_CODING_FILE: 'Actualizar Geocodificación desde un archivo (geojson/shapefile)',
      REFRESH_GEO_CODING: 'Actualizar Referencia Geográfica',
      COUNTRY: 'País',
      ADD__: 'Agregar Ubicación',
      ADD: 'Agregar Ubicación Secundaria',
      SUM: 'Suma',
      MOY: 'Promedio',
      MAXI: 'Máximo',
      MINI: 'Mínimo',
      AGGREGA: 'Agregación',
      GENERATE: 'Importar desde el Servidor Geográfico',
      LEVEL: 'Nivel',
      CHAMPPERSO: 'Campos de datos definidos por el usuario',
      LABEL: 'Etiqueta',
      CONFIRM_DELETE: '¿Quieres borrar este campo?',
      LONG: 'Longitud',
      ERROR_UPDATE_LONG: 'No se puede actualizar la longitud.',
      CONFIRM_DELETE_LEVEL: '¿Quieres eliminar este nivel?',
      CONFIRM_DELETE_LOCALTION: '¿Quieres eliminar esta ubicación?',
      CODE: 'Código',
      DESCRP: 'Descripción',
      IMPORT: 'Importar desde archivo de Excel',
      IMPORT_SHAPEFILE: 'Importar desde un archivo shapefile o geojson',
      ERROR_STRUC: 'Se ha detectado un error en la estructura de archivos.',
      ERROR_FORMAT: '¡Debes usar un archivo *.xsl!',
      ERROR_CANADD: '¡Ubicaciones usadas!',
      UPLOAD_LABEL: 'Nivel {{level}} ...',
      EXPORT: 'Exportar a Excel'
    },
    INDICATOR_CAT: {
      TITLE_EDIT: 'EDITAR CATEGORÍAS',
      TITLE: 'Categorías de Desagregación',
      CODE: 'Código',
      LABEL: 'Nombre',
      AGGREGATE: 'Agregación',
      OPTIONS: 'Opciones',
      OPTION: 'Opción'
    },
    INDICATOR: {
      VALUE_TO_DISPLAY: 'Propiedad a mostrar',
      PORTFOLIOS: 'Portafolios',
      DATA_REFRESH: 'Actualizar valores',
      FIXED_DENOMINATOR: 'Denominador fijo',
      FROM_CALCULATE_DATA_NUM_DEMO: 'numerador / denominador',
      NUMERATOR: 'Numerador',
      DENOMINATOR: 'Denominador',
      FROM_CALCULATE_DATA_NUM_DEMO_HELP: '',
      WEIGHT: 'Peso',
      MOY_WEIGHT: 'Promedio ponderado',
      PROJECT_INDICATORS: 'Indicadores de proyectos',
      PROJECT_INDICATOR_SHARE: 'Indicadores compartidos',
      ADD_ITEM: 'Agregar indicador',
      OPTION_CATEGORY: 'opción',
      PROJECTS: 'Proyectos',
      CROSS_INDICATOR: 'Indicador de Proyectos Cruzados',
      CALCULATE_DATA: 'Calcular',
      ADD_ACTIVITY: 'Nuevo indicador',
      EDIT_ACTIVITY: 'Agregar desde Indicadores Existentes',
      ADD: 'Agregar Indicador',
      LAYER_URL: 'URL de capas',
      EDIT_LAYERS: 'Ingresar URL de la Capa',
      USE_COLORS: 'Configuración de colores del mapa',
      USE_LAYERS: 'Configuración de capas de mapa',
      OUTPUT: 'Salida',
      CROSS_PROJECT: {
        NAME: 'Nombre',
        DESCRIPTION: 'Descripción'
      },
      LINK_LOGFRAME: 'Enlazar al Marco Lógico',
      NONE_AGGREGATE: 'Ninguno',
      UNIT_COST: 'Costo Unitario',
      INDICATOR_BASE: 'Lista de Indicadores',
      SEARCH: 'Buscar un Indicador',
      SELECT_INDICATOR: 'Seleccionar un Indicador',
      SEARCH_LOCATION: 'Buscar una ubicación',
      SEARCH_PERIOD: 'Buscar un período',
      PERFORMANCE_INDICATOR: 'Indicadores de desempeño',
      CROSS_INDICATORS: 'Indicadores transversales',
      MAPPING: 'Mapeo',
      CATEGORY: 'Categorías de Desagregación',
      CROSS_DISAGGREGATION: 'Desagregación Cruzada',
      PARALLEL_DISAGGREGATION: 'Desagregación Paralela',
      DESCRIPTION_ONG: 'Detalles',
      POSSIBLEVALUE: 'Valores Posibles',
      LESS_THAN: 'El valor debe ser menor o igual que {{valeur}}',
      GREAT_THAN: 'El valor debe ser mayor o igual que {{valeur}}',
      EGAL_TO: 'El valor debe ser igual a {{valeur}}',
      BEETWEEB: 'El valor debe estar entre {{valeur_min}} y {{valeur_max}}',
      ERROR_UPDATE: '¡Se produjo un error durante la actualización!',
      LEGEND: 'Configuración SIG',
      LEGEND_COLOR: 'Colores de leyenda (mapa)',
      ERROR_DECOUP_PLAN: {
        DATE_COLLECT: 'La fecha de recogida debe pertenecer al período',
        TITLE: 'Error',
        VALUE: 'Por favor, ingresa un valor',
        DATE_RELEVE: 'Por favor, ingresa la fecha planificada',
        DATE_ALERT: 'Por favor, ingresa la fecha de la alerta',
        DATE_A: 'La fecha de alerta debe ser menor que la fecha planificada.',
        DATE_RELEVE_T: 'La fecha planificada debe estar dentro del período y dentro de la duración del indicador',
        CATEGORY_VALUE: 'La suma de los valores desagregados no es igual al valor agregado',
        CATEGORY_VALUE_POSITIF: 'El valor desagregado debe ser mayor o igual a 0',
        CATEGORY_VALUE_PARALLELE: 'Verifica que el valor ingresado se haya desagregado correctamente para cada categoría',
        DENOMINATOR_ERROR: 'El valor del denominador debe ser diferente de 0'
      },
      ENTRY_DATA_HELP: 'Los resultados del indicador son ingresados manualmente',
      PROJECT: {
        VALUE: 'Valor',
        VALUES: 'Valores'
      },
      HELP: {
        TRANS: 'Un indicador común es un indicador común a todas las actividades (ver detalles en el manual)'
      },
      COUNT_ALL: 'Contando',
      DATA_TABLE: {
        TEMPLATE: 'Archivo de datos',
        CALCULATION: 'Cálculo',
        LOCALITIES: 'Geografía',
        PERIODS: 'Período',
        QUERY: 'Consulta',
        CALCULATION_ITEM: 'Campo'
      },
      DOCUMENTS: 'Documentos',
      COMPOSITE: 'Fuente de Datos',
      SELECT: 'Lista',
      LOCATION: 'Ubicación',
      CALMETHOD: 'Método de cálculo',
      CRITERIA: 'Criterios',
      CODE: 'Código',
      INDICATOR_EDIT: 'Detalles del indicador',
      KEY_KPI: 'Núcleo',
      NAME: 'Nombre',
      NAME_FRENCH: 'Nombre (Francés)',
      CALCULATE_RATE: 'En el cálculo del logro % {{lastLevel}}',
      NAME_ENG: 'Nombre (Inglés)',
      DEF: 'Definición/Descripción',
      UNIT: 'Unidad de medida',
      TYPE: 'Categoría',
      CLASS: 'Tipo',
      KEY: 'Indicadores (KPI)',
      VAL_POSS: 'Valores Posibles',
      FORMULA: {
        REQUIRED_ALL: 'Todos los indicadores de la fórmula son obligatorios',
        CLEAR: 'Borrar',
        INVALID: 'Fórmula Incorrecta',
        NONEINDIC: 'Sin indicador en la fórmula'
      },
      BEGIN: 'Fecha de Inicio',
      END: 'Fecha de Finalización',
      FREQUENCE: 'Frecuencia de Recolección',
      NIV_GEO: 'Nivel Geográfico de Entrada',
      _NIV_GEO: 'Geografía',
      AGGREGA: 'Método de agregación geográfica',
      GROUP_CIBLE: 'Grupo Objetivo',
      HYPOTHESE: 'Supuestos',
      MOY_VERIF: 'Medios de verificación',
      TECH_COLL: 'Técnica de recolección',
      TARGET: 'Objetivo final (fin del proyecto)',
      REF: 'Valor de referencia',
      TARGET_VAL: 'Objetivos',
      REAL_VAL: 'Actuales',
      VARIANCE: 'Diferencia',
      TRANS: 'Indicador de Corte Transversal',
      DISPLAY_TRANS: 'Solo indicadores de corte transversal',
      SIMPL: 'Simple',
      COMPO: 'Complejo',
      CUMUL: 'Acumulativo',
      COMPL: 'Complejo',
      SUM: 'Suma',
      MOY: 'Promedio',
      MAXI: 'Máximo',
      MINI: 'Mínimo',
      NONE: 'Ninguno',
      MOD_UN: 'Cambiar la unidad resultará en la pérdida de todos los registros._x000D_\n¿Quieres continuar?',
      MOD_NG: 'Cambiar el nivel geográfico resultará en la pérdida de todos los registros.\n¿Quieres continuar?',
      MOD_DATE: 'Cambiar las fechas podría resultar en la pérdida de varios registros.\n¿Quieres continuar?',
      MOD_PER: 'Cambiar la periodicidad podría resultar en la pérdida de varios registros.\n¿Quieres continuar?',
      MOD_CLS: 'Cambiar la fuente de datos resultará en la pérdida de todos los registros.\n¿Quieres continuar?',
      LABEL_VP: 'Etiqueta',
      POUND: 'Ponderación',
      PLAN: 'Planificación de Indicadores',
      PLAN_: {
        TITLE: 'Establecer Objetivos',
        VALUE: 'Objetivo'
      },
      TRACK_: {
        ACTUAL_DATE: 'Fecha actual',
        VALUE: 'Ingresar un valor real',
        TITLE: 'Indicador Actual'
      },
      DATE_R: 'Cita Planificada',
      VALUE: 'Valor',
      BUDGET: 'Presupuesto',
      COST: 'Costo',
      REL: 'Recopilación de Datos de Indicadores',
      RAPP: 'Informe Indicador',
      PIC: 'Imagen',
      IN_MON: 'Monitoreo de indicadores',
      MON: 'Monitor',
      DATA_FIN: 'Datos Financieros',
      DATA_FIN_: 'Datos Financieros',
      FOLLOW_FIN: 'Seguimiento del Presupuesto/Costo',
      DISPLAY_CODE_LOC: 'Mostrar códigos geográficos',
      COMMENTS: 'Comentarios',
      RESPO: 'Entidad Responsable',
      DISPLAY_PL: 'Monitor',
      ACHEVE: '% Logrado',
      SOCIO_ECO: 'Socioeconómico',
      SPATIAL: 'Espacial',
      GRAPHE_COMBINE: 'Gráficos Mixtos',
      CONFIRM_DELETE_RELEVE: '¿Quieres borrar estos datos?',
      CONFIRM_DELETE_PLAN: '¿Quieres eliminar esta planificación?',
      CONFIRM_DELETE: '¿Quieres eliminar este indicador?',
      CONFIRM_DELETE_CROSS: 'Eliminar el indicador eliminará los datos de los proyectos',
      DATE_ALERT: 'Fecha de Alerta',
      ERRORS: {
        VP_LABEL: 'El nombre ya fue asignado',
        VP_VALUE: 'La ponderación ya fue asignada'
      },
      GLOBAL: {
        TITLE: 'Indicadores de Referencia y Objetivos Finales',
        TITLE_: 'Valores Objetivo y Reales',
        LOCATION: 'Ubicación',
        BEGIN: 'Fecha de inicio',
        END: 'Fecha de Finalización',
        TARGET: 'Objetivo Final (fin del proyecto)',
        REF: 'Valor de referencia',
        COMMENTS: 'Comentarios',
        ERROR: {
          REF_VALUE_DESEGREGATE: 'La suma de los valores de referencia desagregados no es igual al valor de referencia',
          TARGET_VALUE_DESEGREGATE: 'La suma de los valores objetivo desagregados no es igual al valor objetivo',
          DATE: 'La fecha de finalización debe ser mayor que la fecha de inicio.',
          Target_Value: 'Por favor, ingresa el objetivo',
          REF_VALUE: 'Por favor, ingresa el valor de referencia',
          DATE_D: 'Por favor, ingresa la fecha de inicio',
          DATE_F: 'Por favor, ingresa la fecha de finalización'
        }
      },
      FROM_ENTRY_DATA: 'Entrada directa',
      FROM_CALCULATE_DATA: 'Calcular a partir de una fórmula',
      FROM_DATABASE: 'Completar desde un archivo de datos',
      OVERVIEW_MENU: {
        SETTINGS: 'Configuración del indicador',
        BASELINE: 'Establecer Valores de Referencia',
        TARGET: 'Establecer objetivos',
        ACTUAL: 'Seguimento de Datos Reales',
        REPORT: 'Informes'
      },
      MENU: {
        EDIT: 'Editar',
        PLAN: 'Valores objetivo',
        RELE: 'Valores registrados',
        FICHE: 'Hoja de referencia del indicador',
        IMAGE: 'Imagen (300x400)',
        RIGHT: 'Establecer permisos',
        GLOB_RELE: 'Valores Objetivo Iniciales y Finales',
        RAPPORT: 'Nota',
        SPATIAL: 'Mapa',
        LEGEND: 'Leyenda',
        WORKFLOW: 'Flujo de trabajo',
        CUSTOM_REPORT: 'Informes Personalizados'
      },
      RIGHT: {
        TITLE: 'Establecer permisos de usuario',
        BASELINE: 'Valores de referencia y objetivos finales',
        TARGET: 'Valores objetivo',
        ACTUAL: 'Valores registrados'
      },
      PLAN_TARGET: {
        TITLE: 'Metas del indicador'
      },
      TRACK: {
        TITLE: 'Seguimiento del indicador'
      },
      DASHBOARD: {
        TAB: 'Informe de indicador',
        G: 'Proyecto total',
        Y: 'Año',
        P: 'Período',
        TIMESCALE: 'Escala de tiempo',
        DIFFERENCE: 'Diferencia',
        FINAL_TARGET: 'Objetivo final del proyecto',
        BASELINE: 'Valor de Referencia',
        TARGETS: 'Objetivos',
        ACTUALS: 'Actuales',
        RATE_TARGET: '% de objetivo',
        MAP: 'Mapa',
        TITLE2: '% de objetivos por',
        TITLE2_: '% de objetivos por proyectos',
        TITLE1_1: 'Indicador de Seguimiento – Logros / Metas',
        TITLE1_2: 'Progreso del indicador por año',
        LEGEND: '% de objetivos',
        TITLE: 'Informe de indicador',
        PAGE1TITLE: 'Rendimiento (datos reales frente a objetivos)',
        PAGE2TITLE: 'Progreso del indicador',
        PAGE2SUBTITLE1: 'Cambiar a indicador',
        PAGE2SUBTITLE2: 'Progreso del Indicador para',
        TITLE_1_3: 'Datos reales frente a objetivos por',
        TITLE_1_3_: 'Datos reales frente a objetivos por proyectos'
      },
      SHEET: {
        SHEET: 'Hoja',
        TITLE: 'Hoja de referencia del indicador',
        NAME: 'Nombre del indicador',
        DESCRP: 'Descripción',
        TYPE: 'Categoría',
        RAT_CL: 'Enlace al Marco de Registro',
        U_MES: 'Unidad de medida',
        PER_COL_SUI: 'Frecuencia de informes',
        NG: 'Nivel Geográfico',
        RESPO: 'Individuo(s) responsable',
        TECH: 'Técnica de recolección',
        SRC: 'Fuente de datos',
        FORMULA: 'Fórmula de Cálculo',
        VAL_REF: 'Valor de referencia',
        VAL: 'Valor',
        EVO: 'Cambiar a Indicador',
        FINAL_TARGET: 'Objetivo final (Fin del proyecto)',
        BASELINE: 'Valor de Referencia',
        TARGETS: 'Objetivos',
        ACTUALS: 'Actuales'
      },
      SPATIALS: {
        DATA: 'Capas de Datos',
        LAYERS: 'Capas',
        TITLE: 'Configuración de capa',
        LAYER: 'Capa',
        LABEL: 'Etiqueta',
        PERIODIC: 'Periódico',
        URL: 'URL',
        PROPERTY: 'Modelo',
        DATA_LAYER: 'Capa de Datos',
        PIN: 'Marcador',
        SERVICE: 'Servicio',
        SERVER_URL: 'Fuente de datos (url del servidor)',
        LAYERS_ID: 'ID de capa',
        ADD_PARAMS: 'Parámetros Adicionales',
        AUTHORIZATION: 'Autenticación',
        AUTHORIZATION_TOKEN: 'Ficha',
        DISPLAY_POPUP: 'Mostrar información'
      },
      USER: 'Datos ingresados por'
    },
    INDICATOR_VISUALISATION: {
      CATEGORIES: 'Categoría',
      DESEGREGATE_VALUE: 'Mostrar valores desagregados',
      CIRCLE: 'Circulo',
      DELTA: 'Capa Predeterminada',
      DATA: 'Datos',
      LAYERS: 'Capas',
      LEGEND: 'Leyenda',
      FORMATTING: 'Formateo',
      BRIGHTNESS: 'Brillo',
      INDICATOR: 'Indicador',
      VALUE: 'Valor',
      YEAR: 'Año',
      PERIOD: 'Período',
      TITLE: 'Visión Espacial',
      SELECT_INDICATOR: 'Seleccionar un Indicador',
      DISPLAY_DATA: 'Mostrar datos',
      SELECT_GEO: 'Seleccionar un nivel geográfico',
      SELECT_LAYER: 'Seleccionar Capas',
      COLLECTED_DATA: 'Información Recolectada',
      ERROR_INTERVAL: 'Error en los intervalos'
    },
    LOGICAL_PLAN: {
      CROSS_CUTTING_KPIS: 'Mostrar indicadores transversales',
      RATE_INDIC_HELP: 'Al marcar esta opción, las actividades serán monitoreadas solo por medio de los indicadores en el módulo de Indicadores de DELTA.',
      ADD: 'Agregar',
      HAS_INDIC: 'Asignar indicador',
      LABEL: 'Nombre',
      LONG: 'longitud',
      ERROR_UPDATE_LONG: 'No se puede actualizar la longitud.',
      CONFIRM_DELETE_LEVEL: '¿Quieres eliminar este nivel?',
      CONFIRM_DELETE: '¿Quieres borrar estos datos?',
      CODE: 'Código',
      DESCRP: 'Descripción',
      LABEL_C: 'Nombre',
      LABEL_L: 'Descripción',
      IMPORT: 'Importar Marco de Registro',
      WEIGHT_AUTO: 'Peso Automático',
      BUDGET: 'Presupuesto',
      COST: 'Costo real',
      SUM_WEIGHT: 'Σ Peso',
      BY: 'Basado en',
      WEIGHT: 'Peso',
      RATE_INDIC: 'Progreso de la actividad medido por KPI',
      ERROR_STRUC: 'Se ha detectado un error en la estructura de archivos.',
      ERROR_FORMAT: '¡Por favor, utiliza un archivo *.xsl!',
      ERROR_CANADD: '¡Se utilizan elementos!',
      UPLOAD_LABEL: 'Nivel {{level}} ...',
      HELP: {
        CODE: 'Ingresa el código {{LIBELLE_NCL}}',
        LABEL_C: 'Ingresa {{LIBELLE_NCL}}',
        LABEL_L: 'Descripción {{LIBELLE_NCL}}'
      },
      ERROR: {
        DELETE_ACTIVITY: '¡La actividad tiene datos y no se puede eliminar!',
        DELETE: '¡El elemento tiene datos y no se puede eliminar!',
        DELETE_INDICATOR: '¡Al menos un indicador está vinculado al elemento!'
      }
    },
    FLAG: {
      LABEL: 'Leyenda de color',
      MIN_VALUE_INDICATOR: 'Indicador de valor mayor o igual que',
      MIN: 'Mín.',
      MAX: 'Max.',
      COLOR: 'Color',
      MIN_VALUE: 'Valor mayor o igual que',
      ERROR: {
        LABEL: 'La etiqueta ya existe',
        CHEVAL: 'El intervalo se superpone con otro',
        IN: 'El intervalo está incluido en otro',
        OUT: 'El intervalo incluye otro'
      }
    },
    FRAME_RESULT: {

      DISPLAY_NO_CUMUL_DATA: 'Mostrar valores no acumulativos',
      TITLE_2: 'Marco de resultados (estado actual)',
      TITLE_1: 'Marco de resultados (avance anual)',
      FINAL_TARGET: 'Valor Objetivo Final',
      OPEN_CHART: 'Mostrar gráfico',
      DETAILS: 'Mostrar los detalles',
      ACTUAL: 'Actual',
      VALUE: 'Valor',
      DATE: 'Fecha',
      ALL_LOCATION: 'todas las localizaciones',
      ALL_PROJECT: 'Todos los proyectos',
      COMMENT: 'Comentario',
      FORMAT: 'Formato Estándar',
      INDIC: 'Indicador',
      T_I: 'Filtrar',
      IMPACT: 'Impacto',
      TARGET: 'Objetivo',
      REF: 'Referencia',
      UNIT: 'Unidad',
      TREEVIEW: 'Vista de Árbol',
      LISTVIEW: 'Marco de Resultados de Indicadores',
      ONLY_CROSS: 'Solo transversal',
      DISPLAY_LEVEL: 'Niveles de Visualización',
      DISPLAY: 'Monitor'
    },
    ACTIVITY: {
      UPLOAD_INDEX: 'Enlace de software de contabilidad',
      ENDING_ACTIVITY: 'Esta actividad ha terminado',
      NAME_ACT: 'Descripción',
      LINK_PL: 'Enlace de marco lógico',
      SETTINGS: 'Configuración',
      YTD: 'Total',
      UNIT_: 'Unidad',

      ONLY_CROSS: 'Cruz',
      ONLY_PLAN: 'Planificado',
      ONLY_FINANCIAL: 'Presupuesto',
      COMPLETED_ACTIVITIES: 'Completado',
      ON_GOING_ACTIVITIES: 'En curso',
      NOT_CARRIED_OUT_ACTIVITIES: 'No iniciado',
      REVIEW_PLAN: 'Plan revisado',
      BUDGET_BREAKDOWN: 'Desglose del Presupuesto',
      TREEVIEW: 'Vista de Árbol',
      DISPLAY_LEVEL: 'Niveles de Visualización',
      ENDING: 'Terminado',
      DISPLAY: 'Mostrar',
      ACTIVITIES: 'Ocupaciones',
      ADD: 'Agregar',
      DBL_PERIOD: 'Doble clic para períodos',
      UPLOAD: {
        USE_BUDGET_BREAKDOWN: 'Con desglose presupuestario',
        ACTIVITY: 'Actividad',
        LOCALITY: 'Ubicación',
        DATE: 'Fecha',
        AMOUNT: 'Cantidad',
        CATEGORYBUDGET: 'Categoría de Presupuesto',
        FINANCING: 'Financiación'
      },
      DISTRIBUTE: {
        CATEGORY: 'Categoría',
        FINANCE: 'Fuente de Financiamiento',
        AMOUNT: 'Cantidad',
        TITLE: 'Distribuir cantidad',
        SUBTITLE: 'Cantidad a distribuir',
        SELECT_CATEGORY: 'Seleccionar una categoría',
        SELECT_FINANCE: 'Seleccionar una fuente de financiamiento',
        ERROR_FINANCE: 'El total debe ser igual a la cantidad a distribuir'
      },
      FROM: {
        FROM: 'Desde',
        DOCUMENT: 'Biblioteca de documentos',
        LOCAL: 'Unidad local',
        CLOUD: 'Nube'
      },
      CROSS_ACTIVITY: 'Actividad Transversal',
      DISPLAY_CROSS_ACTIVITY: 'Solo Actividades Transversales',
      OUTPUT: 'Salidas',
      RATE: 'Porcentaje',
      FUNDING_SOURCE: 'Fuente de Financiamiento',
      PROJECT: 'Proyecto',
      AGENCIES: 'Agencias Ejecutoras',
      RELATED_INDICATOR: 'Indicadores',
      SEARCH: 'Buscar una actividad',
      URL: 'Enlace Externo',
      DOCUMENT: 'Documento',
      DOCUMENTS: 'Documentos',
      ERROR_DOCUMENT: 'Por favor, rellena todos los campos.',
      NAME: 'Nombre del Archivo',
      START: 'Inicio',
      END: 'Final',
      UNIT: 'Unidad de medida',
      TYPE: 'Categoría',
      DISPLAY_UNIT_COST: 'Mostrar costo unitario',
      UNIT_COST: 'Costo unitario',
      CAL_AUTO: 'Cálculo automático',
      BUDGET: 'Presupuesto',
      COST_A: 'Costo real',
      RESPO: 'Entidad responsable',
      COMMENT: 'Comentarios',
      IMPUT: 'Entradas',
      TOTAL: 'Total',
      CONT_LOC: 'Seguir el contrato por ubicación',
      CONT_UNK: 'Solo Contrato',
      NUM: 'Número',
      OWN: 'Propietario',
      OBJECT: 'Objeto',
      COST: 'Costo',
      SIGN: 'Firma',
      BEGIN_CONTRACT: 'Comienzo',
      END_CONTRACT: 'Final',
      BEGIN_D: 'Fecha de inicio',
      END_D: 'Fecha de finalización',
      SELECT_ACT: 'Seleccionar una parte interesada',
      ACTOR_IMPL: 'Partes Interesadas',
      UPLOAD_FINANCE: 'Importación de Presupuesto/Costo',
      UPLOAD_FINANCE_CATEGORY: 'Importación del Desglose de Costo/Presupuesto',
      DEFINITION: 'Detalles',
      NOTE: 'Notas',
      ROLE: 'Papel',
      TOTAL_ALERT: 'El total debe ser igual a 100',
      STEP: 'Escenario',
      ERROR: {
        SOURCE: 'El porcentaje debe estar entre 0 y 100'
      },
      HELP: {
        CAL_AUTO: 'Si está marcado, el presupuesto y/o el costo real se calcularán automáticamente en función de la cantidad y el costo unitario'
      },
      MENU: {
        PLAN: 'Planificar Actividad',
        EDIT: 'Editar',
        REAL: 'Seguimiento de actividad',
        IMAGE: 'Imagen (300x400)',
        PERMISSIONS: 'Permisos',
        WORKFLOW: 'Flujo de Trabajo'
      },
      WORKFLOW: {
        TITLE: 'Flujo de Trabajo',
        LEVEL: 'Nivel',
        NAME: 'Nombre',
        COLOR: 'Color',
        USER: 'Usuario',
        REAL: 'Seguimiento de actividad',
        SELECT_USER: 'Seleccionar Usuario'
      },
      PLAN: {
        PLANNING: 'Planificado',
        TO_REVIEW: 'Revisar',
        ERROR_PERIOD_YEAR: 'No hay período establecido para este año',
        CONFIRM_DELETE: '¿Quieres eliminar este plan?',
        TITLE: 'Plan',
        REVIEW: 'Revisado',
        INIT: 'Inicial',
        REVIEW_: 'Planificación revisada',
        INIT_: 'Planificación inicial',
        GLOBAL: 'Global',
        DURATION: 'Planificación',
        TYPE: 'Tipo',
        YEAR: 'Año',
        PERIOD: 'Período',
        PLANNED: 'Planificado',
        LOCATION: 'Ubicación',
        AMOUNT: 'Objetivo',
        COST: 'Presupuesto',
        BEGIN: 'Inicio',
        END: 'Final',
        AMOUNT_PLANNED: 'Planificado',
        COST_PLANNED: 'Presupuesto',
        BEGIN_PLANNED: 'Inicioplanificado',
        END_PLANNED: 'Finalplanificado',
        ERROR_FINANCE: 'Los porcentajes totales deben ser iguales al 100%',
        ERROR_SUP_QTE: 'La cantidad debe ser mayor o igual a {{QTE_ERROR}}',
        ERROR_INF_QTE: 'La cantidad debe ser menor o igual a {{QTE_ERROR}}',
        ERROR_SUP_BUDGET: 'El presupuesto debe ser mayor o igual a {{BUDGET_ERROR}}',
        ERROR_INF_BUDGET: 'El presupuesto debe ser menor o igual a {{BUDGET_ERROR}}',
        ERROR_PLAN_ANNUEL: 'No se ha definido la planificación anual',
        ERROR_DATE: 'La fecha de finalización debe ser mayor que la fecha de inicio.',
        ERROR_QTE: 'La cantidad debe ser positiva',
        ERROR_BUDGET: 'El presupuesto debe ser positivo',
        ERROR_COST: 'El costo debe ser positivo',
        ERROR_PERIOD: 'El período debe estar entre {{BEGIN}} y {{END}}',
        HELP: {
          AMOUNT: 'Ingresa el objetivo planificado',
          COST: 'Ingresa el presupuesto asignado'
        },
        SEARCH_LOCATION: 'Buscar una ubicación'
      },

      REAL: {
        ACTUAL: 'Actual',
        TITLE: 'Rastrear',
        LOCATION: 'Ubicación',
        AMOUNT: 'Actual',
        COST: 'Costo',
        BEGIN: 'Fecha de inicio',
        END: 'Fecha final',
        AMOUNT_ACTUAL: 'Actual',
        COST_ACTUAL: 'Costo',
        BEGIN_ACTUAL: 'Inicioreal',
        END_ACTUAL: 'Finalreal',
        COMMENTS: 'Comentarios',
        HELP: {
          AMOUNT: 'Ingrese el valor real',
          COST: 'Ingresar Costo'
        }
      },
      DASHBOARD: {
        REFERENCE: 'Valor de Referencia',
        FINAL: 'Objetivo final',
        PROJECT: 'Proyecto',
        YEAR: 'Año',
        HELP: {
          SHARE: 'Compartir panel'
        },
        RATE_TARGET: '% del Objetivo',
        RATE_BUDGET: '% del Presupuesto',
        DISPLAY_COST: 'Mostrar Costo/Presupuesto',
        ALL_LOC: 'Todas las Ubicaciones',
        ANY_LOC: 'Cualquier Ubicación',
        BY_LOC: 'Por Ubicación',
        TITLE: 'Informe',
        RATE_PHY: '% del Objetivo',
        RATE_FINANC: '% del Presupuesto',
        TITLE2: '% de finalización por ubicación',
        TITLE2_: '% de finalización por proyecto',
        TITLE1_1: 'Resumen del Indicador de Progreso',
        TITLE1_2: 'Indicador de progreso por año',
        TITLE_GRAPHE_SECOND_PERIOD: '% de Objetivos y Presupuestos alcanzados por Ubicación de',
        TITLE_GRAPHE_SECOND_YEAR: '% de Objetivos y Presupuestos alcanzados por Ubicación - año',
        TITLE_GRAPHE_SECOND_YEAR_PROJECT: '% de Metas y Presupuestos alcanzados por Proyecto - año',
        TITLE_GRAPHE_SECOND: 'Informe de Actividades',
        TITLE_GRAPHE_SECOND_PROJECT: '% de Metas y Presupuestos alcanzados por Proyecto',
        TITLE_GRAPHE_FIRST_YEAR: 'Porcentaje de Objetivos y Presupuestos alcanzados (año {{year}}) Ubicación:',
        TITLE_GRAPHE_FIRST: '% de Objetivos y Presupuestos alcanzados por Ubicación',
        TITLE_GRAPHE_FIRST_PROJECT: '% de Metas y Presupuestos alcanzados por Proyecto'
      },
      AWPB: {
        TITLE: 'Plan de Trabajo y Presupuesto anual (AWPB)',
        TITLE_YEAR: 'Programa de Trabajo y Presupuesto Plurianuales'
      }
    },
    CROSS_ACTIVITY: {
      CODE_ACTIVITY: 'Código',
      NAME_ACTIVITY: 'Nombre',
      DATE_BEGIN_ACTIVITY: 'Inicio',
      DATE_END_ACTIVITY: 'Final',
      UNIT_COST_ACTIVITY: 'Costo Unitario',
      CAL_AUTO: 'Cálculo Automático',
      COMMENTS_ACTIVITY: 'Comentarios'
    },
    PLAN_BUDGET_ACTIVITY: {
      ACTUAL_RATE: '% de Actual',
      BUDGET_RATE: '% del Presupuesto',
      STATUS_GRAPH: 'Gráfico del Estado de las Actividades',
      ACTUALS: 'Actual',
      TARGETS: 'Objetivo',
      ACTIVITY_PROGRESS: 'Progreso de \'{{activity}}\''
    },
    GANTT: {
      TITLE: 'Diagrama de Gantt de actividad',
      LIMIT: 'Límites del cronograma',
      TYPE: 'Planificación',
      START: 'Inicio',
      END: 'Final',
      DISPLAY: 'Mostrar barras de nivel superioras',
      LEGEND: 'Leyenda',
      PLAN: 'Planificado',
      ACTUAL: 'Actual',
      ASSIGN_TO: 'Seleccionar',
      ACTOR: 'Asignado A',
      GEO: 'Geografía',
      ZOOM: 'Zoom',
      REAL: 'Actividad Actual',
      ACTIVITY: 'Actividad',
      ACTUAL_BEGIN: 'Inicio',
      ACTUAL_END: 'Final',
      PLAN_BEGIN: 'Planificado',
      PLAN_END: 'Final',
      YEAR: 'Año',
      MONTH: 'Mes',
      WEEK: 'Semana',
      TIMELINE: 'Mostrar línea de tiempo',
      ON_GOING_ACTIVITY: 'En marcha',
      DELAYED_ACTIVITY: 'Retrasadas',
      NOT_STARTED_ACTIVITY: 'No iniciadas',
      COMPLETED_ACTIVITY: 'Completadas'
    },
    CONTRACT: {
      LOC: 'Ubicación',
      N_MAR: 'No. de Contrato',
      PROGRESS: 'Progreso',
      STEP: 'Paso'
    },
    PROCUREMENT: {
      CONFIRM_DELETE: '¿Quieres eliminar esta adquisición?',
      CODE: 'Código',
      LABEL: 'Nombre'
    },
    REPORT_CAT: {
      LABEL: 'Categoría',
      PERIOD: 'Periodicidad',
      DELAI: 'Fecha límite',
      RECIPIENT: 'Destinatario principal',
      FORMAT: 'Plantilla de Informe',
      RESPO: 'Asignado a',
      SELECT: 'Importar Plantilla',
      CHANGE: 'Cambiar',
      REMOVE: 'Eliminar',
      CONFIRM_DELETE: '¿Quieres eliminar esta categoría de informe?',
      PERSON_ALERT: 'Partes interesadas para alertar',
      HELP: {
        DELAI: 'Número de días',
        LABEL: 'Ingresa la Categoría del Documento'
      }
    },
    DOCUMENT: {
      DELETE_FOLDER: 'Eliminar carpeta',
      DELETE_FILE: 'Eliminar archivo',
      SUCCESS_ADD_FOLDER: 'Carpeta agregada',
      DOCUMENT_DATA: 'Datos del documento',
      COVER: 'Portada',
      VIEW: 'Vistas',
      NEWFOLDER: 'Crear nueva carpeta',
      DOWNLOAD: 'Descargas',
      NOT_VALID_EMAIL_OR_URL: 'No es un correo electrónico o URL válido',
      CONTACT: 'Contacto (correo electrónico o sitio web)',
      LANGUAGE: 'Idioma',
      ADD_INFO: 'Seleccionar una carpeta para cargar un documento',
      RIGHT_CLICK_INFO: 'Usar el botón derecho para agregar o editar un elemento',
      DBL_CLICK_INFO: 'Haz doble clic para abrir el elemento',
      SEE_DETAILS: 'Ver detalles',
      FILTERS: 'Filtros',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} archivos)',
      FORMAT: 'Formato de documento',
      FORMATS: {
        TEXT: 'Texto',
        IMAGE: 'Imagen',
        VIDEO: 'Vídeo',
        AUDIO: 'Audio',
        PDF: 'PDF'
      },
      FILTER: {
        PROPERTY: 'Campo',
        OPERAND: 'Condición',
        VALUE: 'Valor'
      },
      ACTIONS: {
        ADD_FOLDER: 'Nueva carpeta',
        EDIT_FOLDER: 'Editar carpeta',
        REFRESH: 'Actualizar',
        ADD_FROM_FILE: 'Subir un documento',
        ADD_FROM_LINK: 'Subir desde un enlace',
        ADD_EDIT_DOC: 'Escribir un documento'
      },
      FILE_NAME: 'Archivo',
      REPORT: 'Contenido',
      INSERT_DELTA: 'Insertar elemento de DELTA',
      CLS: 'Carpeta de documentos',
      MAIN: 'Descripción del documento',
      ADD_FILE: 'Nueva carpeta',
      ADD_FILE_: 'Carpeta',
      ADD_DOC: 'Cargar un documento ...',
      ADD_DOC_EXT: 'Subir desde un enlace',
      ADD_DOC_RED: 'Escribir un documento',
      CODE: 'Código',
      DESCRIP: 'Descripción',
      CONFIRM_DELETE_FOLDER: '¿Está seguro de eliminar esta carpeta y todo su contenido?',
      CONFIRM_DELETE_THEME: '¿Quieres eliminar este tema?',
      CONFIRM_DELETE_TYPE: '¿Quieres eliminar este tipo?',
      CONFIRM_DELETE_PHYS: '¿Quieres eliminar esta ubicación?',
      CONFIRM_DELETE_FILE: '¿Quieres eliminar este documento?',
      RECIPIENT: 'Compartir documentos',
      RECIPIENT_TOOLTIP: 'Compartir documento con',
      REFERENCE: 'Referencia',
      PUBLICATION: 'Fecha de Publicación',
      PUBLIC: 'Mostrar en el portal',
      PUBLIC_HELP: 'Si se seleccionas esta opción, el documento será accesible en el portal público',
      TITLE: 'Título',
      TITLE_HELP: 'Ingresa el título del documento',
      URL_HELP: 'Ingresa el enlace URL al documento',
      AUTHOR: 'Autor(es)',
      DOC_TYPE: 'Categoría',
      DOC_TYPES: 'Categorías',
      THEME: 'Asunto',
      THEMES: 'Asuntos',
      PHYS_LOC: 'Ubicación',
      SUMMARY: 'Resumen',
      SUMMARY_HELP: 'Ingresa aquí un breve resumen del documento',
      LABEL: 'Etiqueta',
      USERS: 'Usuarios',
      UPLOAD: 'Subir un documento',
      SELECT_DOC_TYPE: 'Seleccionar una categoría de documento',
      SELECT_PHYS_LOC: 'Seleccionar una ubicación',
      SELECT_THEME: 'Seleccionar un tema'
    },
    COMMUNITIES: {
      CHATS: 'Conversar',
      SUCCESS_ADD: 'La comunidad ha sido agregada',
      SUCCESS_UPDATE: 'La comunidad ha sido cambiada',
      SUCCESS_DELETE: 'La comunidad ha sido eliminada',
      IMAGE: 'Imagen',
      NAME: 'Nombre',
      DESCRIPTION: 'Descripción',
      MEMBERS: 'Miembros',
      EDIT_COMMUNITY: 'Editar Comunidad',
      REQUIRED: '¡Necesario!',
      DOUBLE: '¡Nombre ya asignado!'
    },
    DOCUMENT_CALENDAR: {
      NEW_DOC: 'Agregar un Documento Nuevo',
      EXIST: 'Seleccionar de la Biblioteca de Documentos',
      DOCUMENT: 'Documento',
      SELECT_FOLDER: 'Seleccionar una Carpeta para almacenar el documento'
    },
    PHOTOLIBRARY: {
      PHOTOS: 'Imágenes',
      VIDEOS: 'Vídeos',
      REF: 'Referencia',
      TITLE: 'Título',
      DATE: 'Fecha',
      PUBLIC: 'Mostrar en el portal',
      LOCATION: 'Ubicación',
      LAT: 'Latitud (GPS)',
      LONG: 'Longitud (GPS)',
      AUTHOR: 'Autor',
      SUMMARY: 'Resumen',
      URL_VIDEO: 'URL del Video (YouTube)',
      ADD_DOC: 'Agregar Foto',
      ADD_DOC_: 'Foto',
      FOLDER: 'Carpeta',
      ADD_FILE_: 'Carpeta',
      ADD_FILE: 'Nueva carpeta',
      ADD_VIDEO: 'Añadir video',
      ADD_VIDEO_: 'Video',
      CONFIRM_DELETE_PHOTO: '¿Quieres borrar esta foto?',
      CONFIRM_DELETE_VIDEO: '¿Quieres borrar este video?',
      TITLE_ERROR_DELETE: 'La carpeta no está vacía'
    },
    G_U: {
      RIGHT_INFO: 'Especificar el perfil de usuario según los proyectos a los que tiene acceso',
      PASSWORD_CHANGED: 'La contraseña ha sido cambiada',
      DISPLAY_DASHBOARD: 'Mostrar panel',
      DISPLAY_MAP: 'Mostrar mapa',
      FUNCTION: 'Función',
      STANDARD_USER: 'Usuario estándar',
      ADMIN_USER: 'Administrador',
      VIEW_USER: 'Espectador',
      COLLECT_USER: 'Recopilación de Datos',
      COLLECTER_USER: 'Recopilación de Datos',
      CUSTOM_USER: 'Derechos Personalizados',
      NO_ACCESS: 'Sin Acceso',
      NO_ACCESS_PORTFOLIO: 'Sin Acceso a proyectos',
      PROJECT: 'Proyecto',
      DETAIL: 'Detalles de Usuario',
      PROFILE: 'Perfil del Usuario',
      EMAIL: 'Correo Electrónico',
      NAME: 'Apellido',
      SUP: 'Superusuario',
      PRENOM: 'Nombre de pila',
      AGENCIES: 'Agencia Ejecutora',
      MDP: 'Contraseña',
      CONF: 'Confirmar contraseña',
      ACTOR: 'Partes Interesadas',
      STAFF: 'Parte Interesada',
      CHANGE_PASSWORD: 'Cambia la contraseña',
      NEW_PASSWORD: 'Nueva contraseña',
      SEARCH: 'Buscar por correo electrónico y apellido',
      SELECT_AGENCIES: 'Seleccionar una agencia implementadora',
      SELECT_STAFF: 'Seleccionar una parte interesada',
      EXPIRE_HELP: 'Fecha de caducidad de la contraseña',
      EXPIRE: 'Fecha de caducidad',
      ACTORLIST: 'Asignar partes interesadas',
      CONFIRM_DELETE: '¿Quieres eliminar este usuario?',
      ERROR_EMAIL: 'El usuario ya existe',
      ERROR_PSW: '8 caracteres como mínimo',
      ERROR_CONF_PSW: 'Las contraseñas no son similares',
      VALIDATION_RIGHT: 'Derechos de validación',
      VALIDATION_RIGHT_SET: 'Establecer permisos de validación de datos',
      VALIDATION_RIGHT_TITLE: 'Seleccionar usuarios cuyos datos sean validados por {{users}}',
      PORTFOLIO_USER: 'Acceso al Portafolio',
      EDIT_PROFIL: 'Haz clic para más detalles',
      EDIT_PROFIL_USER: 'Cuenta de usuario',
      ERROR_PSW_NOT_CONFORM: 'Las contraseñas no coinciden',
      ERROR_PSW_NOT_EXIST: 'No autorizado para actualizar',
      ERROR__NOT_ALLOW: 'No autorizado para actualizar',
      SEX: 'Sexo',
      MALE: 'Masculino',
      FEMALE: 'Femenino',
      UNLOCK: 'Desbloquear',
      LOCK: 'Bloquear',
      HELP: {
        EMAIL: 'El correo electrónico se utiliza para iniciar sesión'
      },
      FEEDBACK: 'Tus sugerencia',
      FEEDBACK_ADD: 'Comparte tus sugerencias',
      FEEDBACK_DETAIL: "Detalles de la sugerencia"

    },
    GR_U: {
      HELP: {
        TITLE: 'Crear grupo y asignar geografía y marco de registro a los miembros del grupo',
        CODE: 'Ingresar Nombre del Grupo',
        LABEL: 'Ingresar la Descripción del Grupo'
      },
      CODE: 'Nombre',
      LABEL: 'Descripción',
      CONFIRM_DELETE: '¿Quieres eliminar este grupo?',
      USER: 'Asignar Usuarios',
      LOCATION: 'Ubicaciones',
      LOCATION_ONLY: 'Solo ubicación',
      LOCATION_ALL: 'Ubicaciones secundarias',
      C_ONLY: 'Solo',
      C_ALL: 'Incluir elementos secundarios'
    },
    RIGHT: {
      HELP: {
        TITLE: 'Asignar permisos y privilegios de usuario de acuerdo con sus perfiles',
        LABEL: 'Ingresar el nombre de perfil',
        DESCRIPTION: 'Descripción'
      },
      USER: 'Usuarios',
      ADMIN: 'Administrador',
      PRIVIL: 'Permisos',
      LABEL: 'Perfil',
      DESCRIPTION: 'Descripción',
      CONFIRM_DELETE: '¿Quieres eliminar este perfil?'
    },
    TYPACTIVITY: {
      LST_TYP: 'Tipos de actividad',
      ED_TYP: 'Detalles Tipo de Indicador',
      COL_COLOR: 'Color',
      COLOR_BLUE: 'Azul',
      COLOR_CRYAN: 'Cian',
      COLOR_GREEN: 'Verde',
      COLOR_YELOW: 'Amarillo',
      COLOR_ORANGE: 'Naranja',
      COLOR_RED: 'Rojo',
      COLOR_PINK: 'Rosado',
      COLOR_PURPLE: 'Púrpura',
      COLOR_GREY: 'Gris',
      COLOR_BLACK: 'Negro',
      CONFIRM_DELETE: '¿Quieres eliminar este tipo de actividad?'
    },
    FORMULAR: {
      SURVEY_DELETE: "Aplastar las convulsiones",
      SURVEY_VERSION: "Versión",
      IMPORT_SURVEY: "Formularios de soluciones de encuestas",
      IMPORT_INTERVIEW: "Entrevista (s)",
      SURVEY_JETON: "Modificar el token",
      IMPORTED_INTERVIEW: "Entrevistas importadas",
      IMPORTE_DATA: "Importadora",
      SURVEY_JETON_LIB: "Soluciones de encuesta de token",
      SURVEY_SET_JETON: "Generar un token?",
      SURVEY_USER: "nombre del usuario",
      SURVEY_PASSE: "Contraseña",
      SURVEY_GENERER: "Generar",

      COLLECT_MODE_SURVEY: 'Importar datos de las soluciones de encuestas',
      COLLECTORS: 'Coleccionistas',
      LINK_GEO: 'Enlace al plano geográfico',
      ERRORVIDEVAL: 'Por favor ingrese la opción y el valor',
      ERRORVIDE: 'Por favor ingrese la opción',
      ERROREXISTEVAL: 'La opción o el valor ya existe',
      ERROREXISTE: 'La opción ya existe',

      ARCHIVATE: 'Archivo',
      KOBO_API_EDIT: 'Token API de Kobotools',
      ADD_FROM_KOBO: 'Agregar desde Kobotool',
      KOBO_API: 'API Token',
      ERROR_KOBO_API: 'Token API incorrecto',
      ALREADY_SAVE: 'Guardar siempre',
      HOW_TO_FIND_TOKEN: '¿Cómo encuentro el token de API?',
      ACTUALIZAR: 'Actualizar datos',
      KOBO_FORM_LIST: 'Lista de proyectos',
      FORM_SAVED: '¡Formulario guardado!',
      SUCCESS_IMPORT: '¡Se han importado los datos!',
      FAIL_IMPORT: 'Error de importación',
      CHART_STRUCTURE: 'Configuraciones',
      CHART_STRUCTURE_TITLE: 'Configuración de Gráfica',
      SURVEY: 'Encuesta',
      ERROR: {
        NAME_REQUIRED: 'Se requiere un nombre',
        OPTION_REQUIRED: 'Se requiere una opinión',
        UNKNOWN_ERROR: 'Ocurrió un error al importar',
        KOBO_TOKEN_ERROR: 'El token api no es correcto'
      },
      SAVE: 'Guardar',
      SAVE_MSG: 'Registro guardado',
      DOWNLOAD_QR_CODE: 'Descargar código QR',
      GENERATE_URL: 'Generar URL para entrada externa',
      COPIED_URL: 'Copiado',
      COPY_URL: 'Copiar',
      COLLECTION: 'FORMAS DE RECOGIDA',
      COLLECTIONS: 'FORMAS DE RECOGIDA',
      DISPLAY_IMAGE: 'Mostrar imagen',
      ADD: 'Agregar formulario',
      LAYOUT: 'Diseño',
      STRUCTURE: 'Campos',
      ADD_SECTION: 'Nueva sección',
      ADD_DATA: 'Agregar datos',
      COLOR: 'Color',
      SEARCH: 'Buscar un formulario',
      SEARCH_ANALYZE: 'Buscar una analítica',
      TITLE: 'Recopilación de datos: Diseño de Formularios',
      SELECT_TEMPLATE: 'Seleccionar un formulario',
      CONDITION: 'Condición',
      CONDITION_HELP: 'Establecer condición para el campo',
      PRESET: 'Opciones preestablecidas',
      HELP_OPTION: 'Ingresar una opción',
      TITLE_I: 'Nombre del formulario',
      PRIVATE: 'Privado',
      SINGLUAR: 'Un elemento',
      MAIN: 'Único',
      PLURAL: 'Varios Elementos',
      MULTILINE: 'Multilínea',
      LIMIT: 'Límite de caracteres',
      FORMAT: 'Formato',
      LIST_OPTION: 'OPCIONES DE LISTA',
      MULTI_CHOISE: 'Permitir selecciones múltiples',
      CUSTOM_FIELD: 'Opciones de lista',
      CUSTOM_DATA: 'Formularios',
      REQUIRED: 'Necesario',
      REQUIRED_NO_FIELD: 'Arrastre un campo a esta zona',
      COLUMN: 'Columna',
      CONFIRM_STRUCTURE: 'Se ha eliminado al menos un subformulario._x000D_\n¿Continuarás? ',
      ERROR_GLOBAL: 'Algunos campos obligatorios no están llenos',
      USERS: 'Usuarios',
      IMPORT_DATA: 'Importar Datos',
      COLLECT_DATA: 'Recopilación y Revisión de datos',
      PIN: 'Marcador',
      CHOISE_COLLECT_MODE: 'Seleccionar el Modo de Recolección de Datos',
      COLLECT_MODE_INTERN: 'Mostrar Pantalla de Recolección de Datos',
      COLLECT_MODE_MOBILE: 'Recopilar Datos a través de un Dispositivo Móvil',
      COLLECT_MODE_LINK: 'Enviar un Enlace para Entradas de Datos Externas',
      COLLECT_MODE_EXCEL: 'Importar Datos desde un Archivo de Excel',
      FIELD: {
        EXCEL: 'Excel',
        PPTX: 'PowerPoint',
        TEXT: 'Texto',
        LIST: 'Lista',
        DATE: 'Fecha',
        NUMBER: 'Número',
        CHOISE: 'Elección',
        FILE: 'Expediente',
        PICTURE: 'Imagen',
        VIDEO: 'Vídeo',
        AUDIO: 'Audio',
        LOCATION: 'Coordenadas GPS',
        FORMULAR: 'Sub formulario',
        PATH: 'Camino',
        SCORE: 'Puntuación',
        OPTION: 'Opción',
        VALUE: 'Valor',
        POLYGON: 'Polígono'
      },
      MENU: {
        STRUCTURE: 'Estructura',
        RECORD: 'Registro(s)',
        RIGHT: 'Permisos',
        EDIT: 'Editar Formularios',
        COLLECT: 'Recolectar Datos',
        REVIEW: 'Revisar Datos',
        ANALYZE: 'Análisis e Informes'
      },
      CONFIRM_DELETE: '¿Quieres eliminar esta plantilla?',
      CONFIRM_RECORD_DELETE: '¿Quieres eliminar este artículo?',
      OWN: 'Mis Plantillas',
      ALL: 'Todas las plantillas',
      REQUEST: 'Analíticas',
      REQUEST_TITLE: 'Título',
      TEMPLATE: 'Formar',
      ANALYSE: {
        SAVE: 'Guardar',
        TABS: {
          TABLE_PIVOT: 'Tabla Dinámica',
          TABLE_SIMPLE: 'Tabla',
          GRAPH: 'Grafico',
          TABLE: 'Tabla',
          MAP: 'Mapa',
          PIE_CHART: 'Gráfico Circular',
          MIXED_CHART: 'Gráficos Mixtos'
        },
        TABLE: {
          COLUMNS: 'Columnas'
        },
        MAP: {
          CATEGORY: 'Categoría',
          VALUE: 'Valor',
          FIELD: 'Campo',
          LEGEND: 'Leyenda'
        },
        CHART: {
          FIELD: 'Campo',
          CATEGORY: 'Categoría',
          OPERATION: 'Agregación',
          NONE: 'Ninguna',
          COUNT: 'Contar',
          VALUE: 'Valor',
          SUM: 'Suma',
          AVG: 'Promedio',
          MAX: 'Máximo',
          MIN: 'Mínimo',
          VARIANCE: 'Diferencia',
          TYPE: 'Tipo'
        },
        TYPE_CHART: {
          AREACHART: 'Gráfico de Área',
          BARCHART: 'Gráfico de Barras',
          COLUMNCHART: 'Gráfico de Columnas',
          LINECHART: 'Gráfico de Línea',
          PIECHART: 'Gráfico Circular'
        },
        LAYERS: {
          TITLE: 'Capas',
          NAME: 'Nombre',
          FIELD: 'Campo',
          COLOR: 'Color'
        }
      }
    },
    ANALYSE: {
      BACKGROUND_COLOR_TITLE: 'Color de fondo del título',
      COLOR_TITLE: 'Color del título',
      SUCCESS_ADD: 'Se ha agregado el informe',
      FIX_VALUE: 'Valor fijo',
      VARIABLE_VALUE: 'Valor Variable',
      VARIABLE_NAME: 'Nombre Variable',
      VARIABLES: 'Variables',
      AS_PORTFOLIO: 'Portafolio',
      AS_PORTFOLIO_: 'Compartir con billetera',
      COLLECTED_DATA: 'Información Recolectada',
      SERIES: 'Serie',
      SEARCH: 'Buscar un informe',
      SWITCH: 'Establecer variables en columna',
      TYPE_REP: 'Representación de tipos',
      TYPE_1: 'Tabla',
      TYPE_2: 'Grafico',
      TYPE_3: 'Datos',
      DIM: 'Basado en',
      DIM1: 'Filas',
      DIM2: 'Mostrar datos por',
      COLUMNS: 'Columnas',
      FIELD: 'Campo',
      INDICATOR: 'Indicador',
      DESCRIPTION: 'Descripción',
      SETTINGS: 'Configuraciones',
      FILTERS: 'Filtros',
      SELECT_ALL: 'Seleccionar todo',
      UNSELECT_ALL: 'Dejar de seleccionar todo',
      FIELDS: {
        DATE: {
          BEGIN_PLAN: 'Inicio planificado',
          END_PLAN: 'Fin planificado',
          BEGIN_ACTUAL: 'Inicio Real',
          END_ACTUAL: 'Fin Real',
          T: 'Competición'
        },
        PHYSIC: {
          P: 'Objetivo',
          R: 'Real',
          E: 'Diferencia',
          T: '% de Objetivo'
        },
        PHYSIC_INDIC: {
          P: 'Valor Objetivo',
          R: 'Valor Actual'
        },
        BUDGET: {
          P: 'Presupuesto',
          R: 'Costo real',
          E: 'Variación Presupuestaria',
          T: '% del Presupuesto'
        }
      },
      MAIN: 'Parámetros',
      PUBLIC: 'Público',
      AS_DASHBORD: 'Guardar en Paneles',
      OPERATION: 'Operación',
      CONDITION: 'Filtros',
      PG: 'Ubicaciones',
      TITLE: 'Título del informe',
      DESCRIPTION_REPORT: 'Descripción del Informe',
      PERIODE: 'Período',
      FINANCING_SOURCE: 'Fuente de Financiamiento',
      CAT_BUDGET: 'Categoría de Presupuesto',
      RATE: 'Velocidad',
      RUBRIQUE: 'Campo',
      SUBRUBRIQUE: 'Subcampo',
      VALEUR: 'Valor',
      PARAMETER: 'Parámetro',
      DEFAULT_VALUE: 'Valor por defecto',
      ERROR_SYNTAXE: 'Error de sintaxis',
      ERROR_COND_EXIST: 'Algunas condiciones no existen',
      ERROR_COND_NOT_EXIST: 'No se utilizan todas las condiciones',
      LIST_COND: 'Agregar Filtro',
      RUBOP: {
        EGAL: 'Igual',
        DIFF: 'No es igual',
        SUPP: 'Mas grande que',
        SUPP_EGAL: 'Más grande o Igual que',
        INF: 'Menor que',
        INF_EGAL: 'Menor o igual que',
        BEGIN: 'Comenzar con',
        NOT_BEGIN: 'No comenzar con',
        END: 'Terminar con',
        NOT_END: 'No terminar con',
        CONTENT: 'Contiene',
        NOT_CONTENT: 'no contiene',
        NULL: 'Es nulo',
        NOT_NULL: 'No es nulo'
      },
      RUB: {
        ACTEUR: 'Entidad responsable',
        LOCAL: 'Código de localización',
        CAD_LOG: 'Código \'{{planlog}}\'',
        YEAR: 'Año',
        FINANCING_SOURCE: 'Código Fuente de Financiación',
        CAT_BUDGET: 'Categoría de Presupuesto'
      },
      OPS: {
        MIN: 'Mínimo',
        MAX: 'Máximo',
        AVG: 'Promedio',
        SUM: 'Suma',
        NONE: 'Ninguno',
        COUNT: 'Contar'
      },
      ENCH_COND: 'Encadenamiento de filtros Y/O',
      CONFIRM_DELETE: '¿Quieres eliminar esta vista?',
      TYPE: {
        TABLE: 'Mesa',
        DONUT: 'Gráfico Circular',
        COU_HISTO: 'Gráfico Combinado',
        WIDGET: 'Widget'
      },
      SERIE: 'Serie',
      FILL: 'Llenar',
      TYPE_COU_HISTO: {
        LINE: 'Gráfico de Líneas',
        BAR: 'Gráfico de Barras'
      }
    },
    CUSTOM_DASHBOARD: {
      DISPLAY_MAIN_PAGE: 'Mostrar en la página principal',
      SEARCH: 'Buscar en Paneles',
      ACTIVITIES: 'Actividades',
      INDICATORS: 'Indicadores',
      FORMS: 'Formularios',
      GLOBAL_TITLE: 'Cuadros de Mando',
      TITLE: 'Título',
      VIEWS: 'Seleccionar Analíticas',
      VIEWS_OPTION: 'Lista de Consultas',
      ANALYSE: 'Análisis',
      HEIGHT: 'Altura',
      WIDTH: 'Anchura',
      CONFIRM_DELETE: '¿Quieres eliminar este panel?',
      DISPLAY: 'Mostrar todos los paneles',
      OWN: 'Mis paneles',
      FAVORITE: 'Mis paneles favoritos',
      OTHER: 'Otros'
    },
    AGENDA: {
      LIST: 'Listado',
      MONTH: 'Mes',
      WEEK: 'Semana',
      DAY: 'Día',
      TODAY: 'Hoy',
      TITLE: 'Título',
      DESCRIPTION: 'Descripción',
      BEGIN: 'Inicio',
      END: 'Final',
      INFO: 'Tarea / Evento',
      USER: 'Compartir con'
    },
    COMMENT: {
      NEW_COMMENTS: 'Publicaciones nuevas',
      SUCCESS_ADD: 'El comentario ha sido agregado',
      COMMENT: 'Comentarios',
      SHARE: 'Compartir comentarios',
      USER: 'Usuarios',
      WRITE: 'Escribir comentario',
      FILE: 'Noticias del equipo',
      TO_COMMENT: 'Comentario',
      CANCEL_TO_COMMENT: 'Cancelar',
      DISPLAY_COMMENT: 'Mostrar comentarios',
      HIDE_COMMENT: 'Ocultar comentarios',
      DISPLAY_MORE_COMMENT: 'Mostrar más comentarios'
    },
    WORKFLOW: {
      MENU: {
        ACTIVITIES: 'Actividades',
        INDICATOR: 'Indicadores',
        FORM: 'Formularios',
        DOCUMENTS: 'Documentos'
      },
      ACTIVITIES: {
        TRACK: 'Rastrear'
      },
      VALIDATE: 'Validar Datos',
      COMMENTS: 'Comentarios'
    },
    DELETEDITEMS: {
      OBJECT: 'Articulo',
      PROJECT: 'Proyecto',
      DATE: 'Fecha',
      REFRESH: 'Restaurar'
    },
    AUDITS: {
      USERS: 'Usuarios',
      FILTER: 'Filtrar',
      ACTIONS: 'Acciónes',
      DELETE: 'Eliminar',
      UPDATE: 'Actualizar',
      CREATE: 'Crear',
      TO_DATE: 'Para',
      FROM_DATE: 'De',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} elementos)',
      ACTION: 'Acción',
      ENTITY: 'Entidad',
      DESCRIPTION: 'Descripción',
      USER: 'Usuario',
      DATE: 'Fecha',
      ENTITIES: {
        TYPE_MISSION: 'Tipo de misión',
        MISSION: 'misión',
        DISBURSEMENT: 'Desembolso',
        AGREEMENT: 'Financiación',
        AGENCIES: 'Agencia',
        PROJET: 'Proyecto',
        UNITES: 'Unidad',
        ACTEUR: 'Interesado',
        FINANCEMENT: 'Fuente de Financiamiento',
        NIVEAUGEO: 'Nivel (geográfico)',
        CHAMPSPERSO: 'Campo Personalizado',
        LOCALITE: 'Localidad',
        NIVEAUCL: 'Nivel (lógica)',
        CADRELOGIQUE: 'Marco de Registro',
        TYPEACTIVITY: 'Categoría de Actividad',
        DERNIERNIVEAU: 'Actividad',
        TYPEINDICATEUR: 'Categoría de KPI',
        INDICATEURSCATEGORIE: 'Categoría de Desagregación',
        INDICATEURSCATEGORIEOPTION: 'Opción (Categoría de Desagregación)',
        INDICATEURS: 'Indicador',
        ANALYSE: 'Informe Personalizado',
        DASHBOARD: 'Tablero',
        TYPERAPPORT: 'Categoría de Documento',
        CALENDARRAPPORT: 'Informe',
        CLASSEUR: 'Carpeta',
        RAPPORTS: 'Documento',
        CLASSEURPHOTOTHEQUE: 'Carpeta (Biblioteca de Medios)',
        PHOTOTHEQUE: 'Mediateca',
        FORMULAR: 'Formulario',
        RECORDS: 'Registro (formulario)',
        FORMLARREQUEST: 'Consulta (formulario)',
        USER: 'Usuario',
        GROUPUSER: 'Grupo de Usuarios',
        PROFILUSER: 'Perfil de Usuarios',
        CATEGORYBUDGET: 'Categoría de Presupuesto',
        DISTRIBUTEAMOUNT: 'Ventilación',
        GLOBAL_PLAN: 'Planificación  (Global)',
        ANNUAL_PLAN: 'Planificación (Anual)',
        PERIODIC_PLAN: 'Planificación (Período)',
        REALIZE: 'Seguimiento de Actividades'
      }
    },
    SECURITY: {
      ATTEMPTS: 'Intentos fallidos sucesivos',
      TIMEOUT: 'Tiempo Máximo'
    },
    MISSIONS: {
      TITLE_MISSION: 'Misión',
      SELECT_TYPE: 'Selecciona un tipo de misión',
      LIEU_MISSION: 'Localización',
      OBJECT_MISSION: 'Objeto',
      MEMBERS: 'Miembros',
      SELECT_MEMBERS: 'Seleccionar los miembros de la misión',
      MISSION: 'Misión',
      TYPE: 'Escribe',
      DATE_BEGIN: 'fecha de inicio',
      DATE_END: 'Fecha final',
      RESPONSIBLE: 'Responsable',
      REPORT: 'Relación',
      NO_REPORT: 'Sin documento asociado'
    },
    REQUEST: {
      DATE_COURRIER: 'Fecha de la solicitud',
      COMMENTAIRE: 'Comentarios',
      DOCS_SUPPORT: 'Memorándum de ayuda',
      STATUT: 'Estado de la solicitud',
      DATE_STATUT: 'Fecha de estado',
      DATE_ECHEANCE: 'Fecha límite',
      BUDGET: 'Presupuesto',
      MONTANTDMD: 'Monto solicitado (FCFA)',
      SITUATION_REQUETE: 'Situación de la solicitud',
      DATE: 'Fecha',
      RESPONSABLE: 'Responsable',
      ECHEANCE: 'Fecha límite',
      PJ: 'Adjunto',
      OBJET: 'Objeto de la solicitud',
      // MONTANTDMD: 'Monto solicitado',
      PERSCONTACTS: 'Personas a alertar',
      ADDRQTE: 'Agregar solicitud',
      ADDSUIVIRQTE: 'Agregar seguimiento de solicitud',
      HISTORQ: 'Historial de seguimiento'
    },
    RISK: {
      CRITICALITY: {
        TITLE: 'Criticidad',
        HIGH: 'Alto',
        MEDIUM: 'Medio',
        WEAK: 'Débil'
      },
      DELAY: 'Duración (Días)',
      TYPE: 'Escribe',
      RISK: 'Riesgo',
      PROBLEM: 'Problema',
      CURRENT_POSITION: 'Guardar posición actual',
      CURRENT_POSITION_MARK: 'Mover marcador',
      CURRENT_POSITION_MARK_: 'en el mapa para recuperar la posición',
      NATURE_RISK: 'Naturaleza',
      DESCRIPTION_: 'Descripción of',
      DESCRIPTION: 'Descripción',
      IMPACT_FINANCE: 'Impacto financiero',
      IMPACT_DELAY: 'Retraso de impacto',
      RESOLVE_DATE_RISK: 'Fecha de Resolución',
      TITLE_RISK: 'Riesgo/Problema',
      FINANCE: 'Financiero',
      DELAY_: 'Retraso',

      IMPACT: 'Impacto',

      PROBABILITY: 'Probabilidad',
      DATE: 'Con fecha de',
      STATUS: 'Estado',
      RECOMMANDATIONS: 'Recomendaciones',
      IMPACTS: {
        HIGH: 'Alto',
        MEDIUM: 'Medio',
        LOW: 'Bajo'
      },
      STATUS_LIST: {
        UNRESOLVED: 'Irresoluto',
        IN_PROGRESS: 'En progreso',
        RESOLVED: 'Resuelto'

      }
    },
    PROBLEM: {
      PROBLEM: 'Problemas encontrados',
      DATE_PROBLEM: 'Fecha',
      NATURE_PROBLEM: 'Naturaleza',
      DESCRIPTION: 'Descripción',
      IMPACT_DELAY: 'Retraso de impacto',
      IMPACT_FINANCE: 'Impacto financiero',
      RESPONSIBLE: 'Reportado por',
      RECOMMANDATIONS: 'Recomendaciones'
    },

    RECOMMENDATION: {
      DESCRIPTION: 'Recomendación',
      RESPONSIBLE: 'Responsables',
      DEADLINE: 'Plazo',
      STATUS: 'Estado',
      STATUS_LIST: {
        EXECUTED: 'Logrado',
        NOT_EXECUTED: 'Sin ejecutar',
        IN_PROGRESS: 'En curso'
      }

    },
    EXPORT_DATA: {
      TYPES: 'Tipos de datos',
      EXPORT: 'Exportación de datos',
      LOADED: 'Datos generados',
      SHARE: 'Compartir con...'
    },
    FEEDBACK: {
      EMPTY_FEEDBACK: 'No hay sugerencias',
      NOTE_LEVEL: '¿Cuál es tu nivel de satisfacción?',
      LAST_UPDATE_DATE: 'Última actualización el {{date}}',
      SEARCH_FEEDBACK: 'Buscar por asunto o mensaje',
      SEND: 'Enviar',
      BACK: 'Volver',
      STATUS_SEND: 'Estado de envío',
      FORM: {
        OBJECT_PLACEHOLDER: 'Asunto',
        MESSAGE_PLACEHOLDER: 'Mensaje',
        ERROR_SEND_FEEDBACK: 'Error al enviar, por favor inténtalo de nuevo',
        SUCCESS_SEND_FEEDBACK: 'Tu sugerencia se ha enviado correctamente'
      }
    }
  });

  // Appeler la fonction pour récupérer les traductions
  var CODE_LANG = (cov_ybm8pdibn.s[2]++, 'es');
  var CODE_LANG_STORE = (cov_ybm8pdibn.s[3]++, 'TRANSLATE_' + CODE_LANG);
  var CHECK_TRANSLATE = (cov_ybm8pdibn.s[4]++, 'DELTA_TRANSLATE');

  var translated = (cov_ybm8pdibn.s[5]++, false);
  // Translate not active use data origine
  cov_ybm8pdibn.s[6]++;
  if ((cov_ybm8pdibn.b[1][0]++, localStorage.getItem(CHECK_TRANSLATE)) && (cov_ybm8pdibn.b[1][1]++, localStorage.getItem(CHECK_TRANSLATE) == 0)) {
    cov_ybm8pdibn.b[0][0]++;
    cov_ybm8pdibn.s[7]++;

    translated = true;
    cov_ybm8pdibn.s[8]++;
    console.log('TRANSLATE ORIGNINALE ' + CODE_LANG);
    cov_ybm8pdibn.s[9]++;
    fetchTranslations(data, false);
  } else {
    cov_ybm8pdibn.b[0][1]++;
  }

  cov_ybm8pdibn.s[10]++;
  if (!translated) {
    cov_ybm8pdibn.b[2][0]++;
    cov_ybm8pdibn.s[11]++;

    // Translate activated
    if ((cov_ybm8pdibn.b[4][0]++, !localStorage.getItem(CODE_LANG_STORE)) || (cov_ybm8pdibn.b[4][1]++, angular.isUndefined(localStorage.getItem(CODE_LANG_STORE))) || (cov_ybm8pdibn.b[4][2]++, localStorage.getItem(CODE_LANG_STORE) == '')) {
      cov_ybm8pdibn.b[3][0]++;


      var params = (cov_ybm8pdibn.s[12]++, {
        action: "getTranslateDataByLang",
        li_bksb: "Connexion",
        lang_bksb: CODE_LANG,
        method: 1,
        t_bksb: 'bk' + new Date().getTime() + 'sb',
        n: window.location.search.substr(1),
        space: (cov_ybm8pdibn.b[5][0]++, localStorage.getItem('DELTA_TOKEN')) || (cov_ybm8pdibn.b[5][1]++, null),
        data: JSON.stringify(data)
      });
      var URL = (cov_ybm8pdibn.s[13]++, "/app/data/web_server.php");
      // Convertir l'objet params en une chaîne JSON
      var CONTENT = (cov_ybm8pdibn.s[14]++, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      });

      // Effectuer la requête POST
      cov_ybm8pdibn.s[15]++;
      fetch(URL, CONTENT).then(function (response) {
        cov_ybm8pdibn.f[1]++;
        cov_ybm8pdibn.s[16]++;

        // console.log('response 1');
        // console.log(response);
        if (response.ok) {
          cov_ybm8pdibn.b[6][0]++;
          cov_ybm8pdibn.s[17]++;

          return response.json();
        } else {
          cov_ybm8pdibn.b[6][1]++;
        }
        cov_ybm8pdibn.s[18]++;
        console.log('Erreur HTTP : ' + response.status);
        cov_ybm8pdibn.s[19]++;
        return { error_state: true };
      }).then(function (dataReponse) {
        cov_ybm8pdibn.f[2]++;

        var TAILLE_ORIGINE = (cov_ybm8pdibn.s[20]++, Object.keys(data).length);
        var TAILLE_NEW = (cov_ybm8pdibn.s[21]++, Object.keys(dataReponse).length);
        cov_ybm8pdibn.s[22]++;
        if ((cov_ybm8pdibn.b[8][0]++, dataReponse) && (cov_ybm8pdibn.b[8][1]++, dataReponse.COUNTRIES) && (cov_ybm8pdibn.b[8][2]++, angular.isUndefined(dataReponse.error_state)) && (cov_ybm8pdibn.b[8][3]++, TAILLE_NEW >= TAILLE_ORIGINE - 10)) {
          cov_ybm8pdibn.b[7][0]++;
          cov_ybm8pdibn.s[23]++;

          console.log('(200) - TRANSLATE OK  ' + CODE_LANG + ': (' + TAILLE_NEW + ' - vs - ' + TAILLE_ORIGINE + ')');
          cov_ybm8pdibn.s[24]++;
          fetchTranslations(dataReponse, true);
        } else {
          cov_ybm8pdibn.b[7][1]++;
          cov_ybm8pdibn.s[25]++;

          console.log('(200 & ERROR)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
          cov_ybm8pdibn.s[26]++;
          if ((cov_ybm8pdibn.b[10][0]++, dataReponse.error_state) && (cov_ybm8pdibn.b[10][1]++, dataReponse.edit_language) && (cov_ybm8pdibn.b[10][2]++, dataReponse.edit_language == 0)) {
            cov_ybm8pdibn.b[9][0]++;
            cov_ybm8pdibn.s[27]++;

            console.log('edit_language: ' + dataReponse.edit_language);
            cov_ybm8pdibn.s[28]++;
            localStorage.setItem(CHECK_TRANSLATE, dataReponse.edit_language);
          } else {
            cov_ybm8pdibn.b[9][1]++;
          }
          cov_ybm8pdibn.s[29]++;
          fetchTranslations(data, false);
        }
      }).catch(function (error) {
        cov_ybm8pdibn.f[3]++;
        cov_ybm8pdibn.s[30]++;

        console.log('(500)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
        cov_ybm8pdibn.s[31]++;
        fetchTranslations(data, false);
      });
    } else {
      cov_ybm8pdibn.b[3][1]++;
      cov_ybm8pdibn.s[32]++;

      console.log('TRANSLATE LOCALSTORAGE ' + CODE_LANG);
      cov_ybm8pdibn.s[33]++;
      fetchTranslations(JSON.parse(localStorage.getItem(CODE_LANG_STORE)), false);
    }
  } else {
    cov_ybm8pdibn.b[2][1]++;
  }

  function fetchTranslations(dataLang, save) {
    cov_ybm8pdibn.f[4]++;
    cov_ybm8pdibn.s[34]++;

    $translateProvider.translations(CODE_LANG, dataLang);
    cov_ybm8pdibn.s[35]++;
    if (save) {
      cov_ybm8pdibn.b[11][0]++;
      cov_ybm8pdibn.s[36]++;

      if (localStorage.getItem(CHECK_TRANSLATE)) {
        cov_ybm8pdibn.b[12][0]++;
        cov_ybm8pdibn.s[37]++;

        localStorage.removeItem(CHECK_TRANSLATE);
      } else {
        cov_ybm8pdibn.b[12][1]++;
      }
      cov_ybm8pdibn.s[38]++;
      if (localStorage.getItem(CODE_LANG_STORE)) {
        cov_ybm8pdibn.b[13][0]++;
        cov_ybm8pdibn.s[39]++;

        localStorage.removeItem(CODE_LANG_STORE);
      } else {
        cov_ybm8pdibn.b[13][1]++;
      }
      cov_ybm8pdibn.s[40]++;
      localStorage.setItem(CODE_LANG_STORE, JSON.stringify(dataLang));
    } else {
      cov_ybm8pdibn.b[11][1]++;
    }
  };
});