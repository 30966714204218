'use strict';

var cov_26hbl2eucb = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_pt.js',
      hash = 'c6e90905ec57c55dbb91a12bf9af62e7ce76ae13',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/constants/translations_pt.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3005,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2915,
          column: 3
        }
      },
      '2': {
        start: {
          line: 2919,
          column: 20
        },
        end: {
          line: 2919,
          column: 24
        }
      },
      '3': {
        start: {
          line: 2920,
          column: 26
        },
        end: {
          line: 2920,
          column: 50
        }
      },
      '4': {
        start: {
          line: 2921,
          column: 26
        },
        end: {
          line: 2921,
          column: 43
        }
      },
      '5': {
        start: {
          line: 2923,
          column: 19
        },
        end: {
          line: 2923,
          column: 24
        }
      },
      '6': {
        start: {
          line: 2925,
          column: 2
        },
        end: {
          line: 2929,
          column: 3
        }
      },
      '7': {
        start: {
          line: 2926,
          column: 4
        },
        end: {
          line: 2926,
          column: 22
        }
      },
      '8': {
        start: {
          line: 2927,
          column: 4
        },
        end: {
          line: 2927,
          column: 52
        }
      },
      '9': {
        start: {
          line: 2928,
          column: 4
        },
        end: {
          line: 2928,
          column: 35
        }
      },
      '10': {
        start: {
          line: 2932,
          column: 2
        },
        end: {
          line: 2991,
          column: 3
        }
      },
      '11': {
        start: {
          line: 2934,
          column: 4
        },
        end: {
          line: 2990,
          column: 5
        }
      },
      '12': {
        start: {
          line: 2936,
          column: 21
        },
        end: {
          line: 2945,
          column: 7
        }
      },
      '13': {
        start: {
          line: 2946,
          column: 18
        },
        end: {
          line: 2946,
          column: 44
        }
      },
      '14': {
        start: {
          line: 2948,
          column: 22
        },
        end: {
          line: 2954,
          column: 7
        }
      },
      '15': {
        start: {
          line: 2957,
          column: 6
        },
        end: {
          line: 2985,
          column: 9
        }
      },
      '16': {
        start: {
          line: 2961,
          column: 8
        },
        end: {
          line: 2963,
          column: 9
        }
      },
      '17': {
        start: {
          line: 2962,
          column: 10
        },
        end: {
          line: 2962,
          column: 33
        }
      },
      '18': {
        start: {
          line: 2964,
          column: 8
        },
        end: {
          line: 2964,
          column: 56
        }
      },
      '19': {
        start: {
          line: 2965,
          column: 8
        },
        end: {
          line: 2965,
          column: 34
        }
      },
      '20': {
        start: {
          line: 2968,
          column: 31
        },
        end: {
          line: 2968,
          column: 55
        }
      },
      '21': {
        start: {
          line: 2969,
          column: 27
        },
        end: {
          line: 2969,
          column: 58
        }
      },
      '22': {
        start: {
          line: 2970,
          column: 8
        },
        end: {
          line: 2980,
          column: 9
        }
      },
      '23': {
        start: {
          line: 2971,
          column: 10
        },
        end: {
          line: 2971,
          column: 109
        }
      },
      '24': {
        start: {
          line: 2972,
          column: 10
        },
        end: {
          line: 2972,
          column: 47
        }
      },
      '25': {
        start: {
          line: 2974,
          column: 10
        },
        end: {
          line: 2974,
          column: 77
        }
      },
      '26': {
        start: {
          line: 2975,
          column: 10
        },
        end: {
          line: 2978,
          column: 11
        }
      },
      '27': {
        start: {
          line: 2976,
          column: 12
        },
        end: {
          line: 2976,
          column: 71
        }
      },
      '28': {
        start: {
          line: 2977,
          column: 12
        },
        end: {
          line: 2977,
          column: 77
        }
      },
      '29': {
        start: {
          line: 2979,
          column: 10
        },
        end: {
          line: 2979,
          column: 41
        }
      },
      '30': {
        start: {
          line: 2983,
          column: 8
        },
        end: {
          line: 2983,
          column: 66
        }
      },
      '31': {
        start: {
          line: 2984,
          column: 8
        },
        end: {
          line: 2984,
          column: 39
        }
      },
      '32': {
        start: {
          line: 2988,
          column: 6
        },
        end: {
          line: 2988,
          column: 56
        }
      },
      '33': {
        start: {
          line: 2989,
          column: 6
        },
        end: {
          line: 2989,
          column: 82
        }
      },
      '34': {
        start: {
          line: 2994,
          column: 4
        },
        end: {
          line: 2994,
          column: 57
        }
      },
      '35': {
        start: {
          line: 2995,
          column: 4
        },
        end: {
          line: 3003,
          column: 5
        }
      },
      '36': {
        start: {
          line: 2996,
          column: 6
        },
        end: {
          line: 2998,
          column: 7
        }
      },
      '37': {
        start: {
          line: 2997,
          column: 8
        },
        end: {
          line: 2997,
          column: 49
        }
      },
      '38': {
        start: {
          line: 2999,
          column: 6
        },
        end: {
          line: 3001,
          column: 7
        }
      },
      '39': {
        start: {
          line: 3000,
          column: 8
        },
        end: {
          line: 3000,
          column: 49
        }
      },
      '40': {
        start: {
          line: 3002,
          column: 6
        },
        end: {
          line: 3002,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 29
          },
          end: {
            line: 1,
            column: 30
          }
        },
        loc: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 3005,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 2958,
            column: 12
          },
          end: {
            line: 2958,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2958,
            column: 26
          },
          end: {
            line: 2966,
            column: 7
          }
        },
        line: 2958
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 2967,
            column: 12
          },
          end: {
            line: 2967,
            column: 13
          }
        },
        loc: {
          start: {
            line: 2967,
            column: 29
          },
          end: {
            line: 2981,
            column: 7
          }
        },
        line: 2967
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 2982,
            column: 13
          },
          end: {
            line: 2982,
            column: 14
          }
        },
        loc: {
          start: {
            line: 2982,
            column: 24
          },
          end: {
            line: 2985,
            column: 7
          }
        },
        line: 2982
      },
      '4': {
        name: 'fetchTranslations',
        decl: {
          start: {
            line: 2993,
            column: 11
          },
          end: {
            line: 2993,
            column: 28
          }
        },
        loc: {
          start: {
            line: 2993,
            column: 45
          },
          end: {
            line: 3004,
            column: 3
          }
        },
        line: 2993
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2925,
            column: 2
          },
          end: {
            line: 2929,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2925,
            column: 2
          },
          end: {
            line: 2929,
            column: 3
          }
        }, {
          start: {
            line: 2925,
            column: 2
          },
          end: {
            line: 2929,
            column: 3
          }
        }],
        line: 2925
      },
      '1': {
        loc: {
          start: {
            line: 2925,
            column: 6
          },
          end: {
            line: 2925,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2925,
            column: 6
          },
          end: {
            line: 2925,
            column: 43
          }
        }, {
          start: {
            line: 2925,
            column: 48
          },
          end: {
            line: 2925,
            column: 90
          }
        }],
        line: 2925
      },
      '2': {
        loc: {
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2991,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2991,
            column: 3
          }
        }, {
          start: {
            line: 2932,
            column: 2
          },
          end: {
            line: 2991,
            column: 3
          }
        }],
        line: 2932
      },
      '3': {
        loc: {
          start: {
            line: 2934,
            column: 4
          },
          end: {
            line: 2990,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2934,
            column: 4
          },
          end: {
            line: 2990,
            column: 5
          }
        }, {
          start: {
            line: 2934,
            column: 4
          },
          end: {
            line: 2990,
            column: 5
          }
        }],
        line: 2934
      },
      '4': {
        loc: {
          start: {
            line: 2934,
            column: 8
          },
          end: {
            line: 2934,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2934,
            column: 8
          },
          end: {
            line: 2934,
            column: 46
          }
        }, {
          start: {
            line: 2934,
            column: 50
          },
          end: {
            line: 2934,
            column: 108
          }
        }, {
          start: {
            line: 2934,
            column: 113
          },
          end: {
            line: 2934,
            column: 154
          }
        }],
        line: 2934
      },
      '5': {
        loc: {
          start: {
            line: 2943,
            column: 15
          },
          end: {
            line: 2943,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2943,
            column: 15
          },
          end: {
            line: 2943,
            column: 50
          }
        }, {
          start: {
            line: 2943,
            column: 54
          },
          end: {
            line: 2943,
            column: 58
          }
        }],
        line: 2943
      },
      '6': {
        loc: {
          start: {
            line: 2961,
            column: 8
          },
          end: {
            line: 2963,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2961,
            column: 8
          },
          end: {
            line: 2963,
            column: 9
          }
        }, {
          start: {
            line: 2961,
            column: 8
          },
          end: {
            line: 2963,
            column: 9
          }
        }],
        line: 2961
      },
      '7': {
        loc: {
          start: {
            line: 2970,
            column: 8
          },
          end: {
            line: 2980,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2970,
            column: 8
          },
          end: {
            line: 2980,
            column: 9
          }
        }, {
          start: {
            line: 2970,
            column: 8
          },
          end: {
            line: 2980,
            column: 9
          }
        }],
        line: 2970
      },
      '8': {
        loc: {
          start: {
            line: 2970,
            column: 12
          },
          end: {
            line: 2970,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2970,
            column: 12
          },
          end: {
            line: 2970,
            column: 23
          }
        }, {
          start: {
            line: 2970,
            column: 27
          },
          end: {
            line: 2970,
            column: 48
          }
        }, {
          start: {
            line: 2970,
            column: 52
          },
          end: {
            line: 2970,
            column: 96
          }
        }, {
          start: {
            line: 2970,
            column: 101
          },
          end: {
            line: 2970,
            column: 134
          }
        }],
        line: 2970
      },
      '9': {
        loc: {
          start: {
            line: 2975,
            column: 10
          },
          end: {
            line: 2978,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2975,
            column: 10
          },
          end: {
            line: 2978,
            column: 11
          }
        }, {
          start: {
            line: 2975,
            column: 10
          },
          end: {
            line: 2978,
            column: 11
          }
        }],
        line: 2975
      },
      '10': {
        loc: {
          start: {
            line: 2975,
            column: 14
          },
          end: {
            line: 2975,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 2975,
            column: 14
          },
          end: {
            line: 2975,
            column: 37
          }
        }, {
          start: {
            line: 2975,
            column: 41
          },
          end: {
            line: 2975,
            column: 66
          }
        }, {
          start: {
            line: 2975,
            column: 71
          },
          end: {
            line: 2975,
            column: 101
          }
        }],
        line: 2975
      },
      '11': {
        loc: {
          start: {
            line: 2995,
            column: 4
          },
          end: {
            line: 3003,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2995,
            column: 4
          },
          end: {
            line: 3003,
            column: 5
          }
        }, {
          start: {
            line: 2995,
            column: 4
          },
          end: {
            line: 3003,
            column: 5
          }
        }],
        line: 2995
      },
      '12': {
        loc: {
          start: {
            line: 2996,
            column: 6
          },
          end: {
            line: 2998,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2996,
            column: 6
          },
          end: {
            line: 2998,
            column: 7
          }
        }, {
          start: {
            line: 2996,
            column: 6
          },
          end: {
            line: 2998,
            column: 7
          }
        }],
        line: 2996
      },
      '13': {
        loc: {
          start: {
            line: 2999,
            column: 6
          },
          end: {
            line: 3001,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2999,
            column: 6
          },
          end: {
            line: 3001,
            column: 7
          }
        }, {
          start: {
            line: 2999,
            column: 6
          },
          end: {
            line: 3001,
            column: 7
          }
        }],
        line: 2999
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0, 0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_26hbl2eucb.s[0]++;
angular.module('app').config(function ($translateProvider) {
  cov_26hbl2eucb.f[0]++;

  var data = (cov_26hbl2eucb.s[1]++, {
    COUNTRIES: {
      AD: 'Andorra',
      AE: 'Emirados Árabes Unidos',
      AF: 'Afeganistão',
      AG: 'Antigua e Barbuda',
      AI: 'Anguilla',
      AL: 'Albânia',
      AM: 'Armênia',
      AN: 'Antilhas Holandesas',
      AO: 'Angola',
      AQ: 'Antártica',
      AR: 'Argentina',
      AS: 'Samoa Americana',
      AT: 'Áustria',
      AU: 'Austrália',
      AW: 'Aruba',
      AX: 'Ilhas Aland',
      AZ: 'Azerbaijão',
      BA: 'Bósnia e Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Bélgica',
      BF: 'Burkina Faso',
      BG: 'Bulgária',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BM: 'Bermudas',
      BN: 'Brunei Darussalam',
      BO: 'Bolívia',
      BR: 'Brasil',
      BS: 'Bahamas',
      BT: 'Butão',
      BV: 'Ilha Bouvet',
      BW: 'Botswana',
      BY: 'Bielo-Rússia',
      BZ: 'Belize',
      CA: 'Canadá',
      CC: 'Ilhas Cocos (Keeling)',
      CD: 'República Democrática do Congo',
      CF: 'República Centro-Africana',
      CG: 'Congo',
      CH: 'Suíça',
      CI: 'Costa do Marfim',
      CK: 'Ilhas Cook',
      CL: 'Chile',
      CM: 'Camarões',
      CN: 'China',
      CO: 'Colômbia',
      CR: 'Costa Rica',
      CS: 'Sérvia e Montenegro',
      CU: 'Cuba',
      CV: 'Cabo Verde',
      CX: 'Ilha do Natal',
      CY: 'Chipre',
      CZ: 'República Checa',
      DE: 'Alemanha',
      DJ: 'Djibouti',
      DK: 'Dinamarca',
      DM: 'Dominica',
      DO: 'República Dominicana',
      DZ: 'Argélia',
      EC: 'Equador',
      EE: 'Estônia',
      EG: 'Egito',
      EH: 'Saara Ocidental',
      ER: 'Eritreia',
      ES: 'Espanha',
      ET: 'Etiópia',
      FI: 'Finlândia',
      FJ: 'Fiji',
      FK: 'Ilhas Falkland (Malvinas)',
      FM: 'Estados Federados da Micronésia',
      FO: 'ilhas Faroe',
      FR: 'França',
      GA: 'Gabão',
      GB: 'Reino Unido',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'Guiana Francesa',
      GG: 'Guernsey',
      GH: 'Gana',
      GI: 'Gibraltar',
      GL: 'Groenlândia',
      GM: 'Gâmbia',
      GN: 'Guiné',
      GP: 'Guadalupe',
      GQ: 'Guiné Equatorial',
      GR: 'Grécia',
      GS: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-bissau',
      GY: 'Guiana',
      HK: 'Hong Kong',
      HM: 'Ilha Heard e Ilhas Mcdonald',
      HN: 'Honduras',
      HR: 'Croácia',
      HT: 'Haiti',
      HU: 'Hungria',
      ID: 'Indonésia',
      IE: 'Irlanda',
      IL: 'Israel',
      IM: 'Ilha de Man',
      IN: 'Índia',
      IO: 'Território Britânico do Oceano Índico',
      IQ: 'Iraque',
      IR: 'Irã (Republic Islâmica do Irã',
      IS: 'Islândia',
      IT: 'Itália',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordânia',
      JP: 'Japão',
      KE: 'Quênia',
      KG: 'Quirguistão',
      KH: 'Camboja',
      KI: 'Kiribati',
      KM: 'Comores',
      KN: 'São Cristóvão e Nevis',
      KP: 'República Popular Democrática da Coréia',
      KR: 'Republica da Coréia',
      KW: 'Kuwait',
      KY: 'Ilhas Cayman',
      KZ: 'Cazaquistão',
      LA: 'República Democrática Popular do Laos',
      LB: 'Líbano',
      LC: 'Santa Lúcia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Libéria',
      LS: 'Lesoto',
      LT: 'Lituânia',
      LU: 'Luxemburgo',
      LV: 'Letônia',
      LY: 'Jamahiriya Árabe da Líbia',
      MA: 'Marrocos',
      MC: 'Mônaco',
      MD: 'República da Moldávia',
      MG: 'Madagáscar',
      MH: 'Ilhas Marshall',
      MK: 'Antiga República Iugoslava da Macedônia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongólia',
      MO: 'Macau',
      MP: 'Ilhas Marianas do Norte',
      MQ: 'Martinica',
      MR: 'Mauritânia',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Maurício',
      MV: 'Maldivas',
      MW: 'Malawi',
      MX: 'México',
      MY: 'Malásia',
      MZ: 'Moçambique',
      NA: 'Namíbia',
      NC: 'Nova Caledônia',
      NE: 'Níger',
      NF: 'Ilha Norfolk',
      NG: 'Nigéria',
      NI: 'Nicarágua',
      NL: 'Países Baixos',
      NO: 'Noruega',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Nova Zelândia',
      OM: 'Omã',
      PA: 'Panamá',
      PE: 'Peru',
      PF: 'Polinésia Francesa',
      PG: 'Papua Nova Guiné',
      PH: 'Filipinas',
      PK: 'Paquistão',
      PL: 'Polônia',
      PM: 'São Pedro e Miquelão',
      PN: 'Pitcairn',
      PR: 'Porto Rico',
      PS: 'Território Palestino, Ocupado',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguai',
      QA: 'Catar',
      RE: 'Reunião',
      RO: 'Romênia',
      RU: 'Federação Russa',
      RW: 'Ruanda',
      SA: 'Arábia Saudita',
      SB: 'Ilhas Salomão',
      SC: 'Seychelles',
      SD: 'Sudão',
      SE: 'Suécia',
      SG: 'Cingapura',
      SH: 'Santa Helena',
      SI: 'Eslovênia',
      SJ: 'Svalbard e Jan Mayen',
      SK: 'Eslováquia',
      SL: 'Serra Leoa',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somália',
      SR: 'Suriname',
      ST: 'São Tomé e Príncipe',
      SV: 'El Salvador',
      SY: 'República Árabe da Síria',
      SZ: 'Suazilândia',
      TC: 'Ilhas Turcas e Caicos',
      TD: 'Chade',
      TF: 'Territórios Franceses do Sul',
      TG: 'Togo',
      TH: 'Tailândia',
      TJ: 'Tajiquistão',
      TK: 'Toquelau',
      TL: 'Timor-Leste',
      TM: 'Turcomenistão',
      TN: 'Tunísia',
      TO: 'Tonga',
      TR: 'Turquia',
      TT: 'Trindade e Tobago',
      TV: 'Tuvalu',
      TW: 'Província China do Taiwan',
      TZ: 'República Unida da Tanzânia',
      UA: 'Ucrânia',
      UG: 'Uganda',
      UM: 'Ilhas Menores Distantes dos Estados Unidos',
      US: 'Estados Unidos',
      UY: 'Uruguai',
      UZ: 'Uzbequistão',
      VA: 'Santa Sé (Estado da Cidade do Vaticano)',
      VC: 'São Vicente e Granadinas',
      VE: 'Venezuela',
      VG: 'Ilhas Virgens Britânicas',
      VI: 'Ilhas Virgens, EUA',
      VN: 'Viet Nam',
      VU: 'Vanuatu',
      WF: 'Wallis e Futuna',
      WS: 'Samoa',
      YE: 'Iémen',
      YT: 'Mayotte',
      ZA: 'África do Sul',
      ZM: 'Zâmbia',
      ZW: 'Zimbábue'
    },
    MESSAGE: {
      CONVERSATION: 'Conversação',
      WRITE_MSG: 'Digite uma mensagem',
      AUCUN: 'Nenhuma mensagem',
      TITLE: 'Minhas Mensagens',
      READ_ALL: 'Leia todas as mensagens',
      INBOX: 'Caixa de entrada',
      SEND_BOX: 'Correspondência Enviada',
      DRAFT_BOX: 'Rascunhos',
      TRASH_BOX: 'Lixo',
      REFRESH: 'Atualizar',
      REPLY: 'Resposta',
      IMPORTANT: 'Importante',
      VIEW_MSG: 'Ver Mensagem',
      TO: 'Para (Usuário DELTA)',
      TO_: 'Usuário DELTA',
      PIECEJ: 'Anexando arquivos',
      EMAILS: 'E-mails',
      TOEMAIL: 'Para (E-mail externo)',
      MAILEXTERNE: 'E-mail externo',
      SUBJECT: 'Assunto',
      FROM: 'De',
      DRAFT: 'Esboço',
      DISCARD: 'Descartar',
      CMP_MSG: 'Escrever um Email',
      SEND: 'Enviar',
      EMAIL_BOX: 'Caixa de Correio',
      FOLDER: 'Pasta',
      REMOVE: 'Remover',

      RECHARGEMENT: 'Atualizar',
      MARKREAD: 'Marque lido',
      MARKNOREAD: 'Marcar não lido',
      MARKIMPORTANT: 'Marcar importante',
      MARKTRASH: 'Deletar',

      ADDEMAIL: 'Adicionar um contato',

      SEND_OK: 'Mensagem enviada',
      SEND_SAVE: 'Mensagem gravada',
      SEND_NO: 'Erro ao enviar mensagem',
      SEND_NO_T: 'Tente novamente',

      ADDUSER: 'Adicionar usuário',
      SIGNATURE: 'Assinaturas',
      SIGNATURE_SEND: 'Para terminar',
      SIGNATURE_ERROR: 'Erro',
      SIGNATURE_CREATE: 'Criar uma assinatura',
      SIGNATURE_ADD: 'Crio',
      SIGNATURE_TITLE: 'Título da Assinatura',
      SIGNATURE_DESIGN: 'Designação',
      SIGNATURE_ANNULER: 'Cancelar',
      SIGNATURE_AJOUTER: 'Adicionar',
      SIGNATURE_MODIFIER: 'Editar',
      SIGNATURE_LIST: 'Lista de assinaturas',
      SIGNATURE_DEFAULT: 'Selecione como assinatura padrão',
      SIGNATURE_NODEFAULT: 'Desmarque como assinatura padrão',

      ERROR: {
        TO: 'Por favor, selecione o destinatário!',
        SUBJECT: 'Digite o Assunto',
        EM: 'Digite o e-mail'
      }
    },
    LOGIN: {
      PASSWORD_EXPIRED_S: 'Sua senha expira em {{days}} dias',
      PASSWORD_EXPIRED: 'Sua senha irá expirar em 1 dia',
      PROFILE: 'Perfil',
      LOGOUT: 'Sair'
    },
    HELP: {
      LEGAL: 'Informação jurídica',
      QUICK_START: 'Ajuda',
      USER_MANUAL: 'Manual do Usuário',
      FAQ: 'FAQ',
      WEBINAIRS: 'Webinars',
      SUPPORT: 'Contatar o suporte técnico',
      LEGAL_NOTICE_LINK: 'https://delta-monitoring.com/service-level-agreement/',
      USER_FEEDBACK: "Feedback do Usuário"
    },
    ALERT: {
      ACTIVATE: 'Ativar alerta de problema',
      SMS: 'SMS',
      EMAIL: 'O email',
      IND_TEXT: 'Alertas Indicadores',
      DOCUMENT: 'novos documentos',
      MORE: 'Ver Todos os Alertas',
      ALL: 'Todos os Alertas',
      NBR_J_L: 'Enviar alerta',
      SEND: 'dia(s) antes da data de vencimento',
      HELP: {
        TITLE: 'O alerta é configurado para enviar uma mensagem de alerta aos membros da equipe para lembrá-los da entrada de dados de atividades e indicadores atribuídos'
      }
    },
    TODO: {
      TITLE: 'Lista De Tarefas ',
      LABEL: 'Rótulo'
    },
    HOME: {
      WELCOME: 'Software colaborativo baseado na Web para M&E',
      USER: 'Nome do Usuário',
      PASS: 'Senha',
      LOGIN: 'Início de Sessão',
      FOOTER: 'Monitoramento DELTA',
      CONNECTED_PLURAL: 'Atualmente conectado: {{user}}</b> Usuários',
      CONNECTED_SINGLE: 'Atualmente conectado: 1</b> Usuário'
    },
    HEADER: {
      LOGO: 'Logotipo',
      TITLE: 'Cabeçalho do relatório',
      FIRST_LINE: 'Primeira Linha',
      SECOND_LINE: 'Segunda Linha',
      THIRD_LINE: 'Terceira Linha',
      REPORT_TITLE: 'Título do Relatório'
    },
    COMMON: {
      ACTIVE_SUP: "Ative a exclusão temporária",
      ALERTE_UNIT: 'Selecione uma unidade de tipo % de tipo.',
      STEPONE: 'Estágio',
      STEP: 'Passos',
      VALUE: 'Valor',
      ADDSTEP: 'Defina as etapas de conclusão',
      ADDSTEP_VIEW: 'O tipo de atividade selecionada indica que a atividade será seguida como um processo com etapas de conclusão',
      EXPORT_POWERBI: 'Exportar para Power BI',
      LOADED_MSG: 'Por favor, espere, tratamento em andamento ...',
      EMAIL_UPLOADED: 'Você importou {{_nb}} e-mail(s)',
      UPLOAD_CSV: 'Importar do arquivo .CSV',
      UPLOAD_CSV_RECOMMENDATION: "Certifique-se de nomear o cabeçalho de cada coluna no arquivo .CSV",
      UPLOAD_CSV_RECOMMENDATION_HEADERS: 'Email (obrigatório), Nome e Número (se aplicável).',
      DATE_MAJ: 'Data da última atualização',
      SELECT_ALL: 'Selecionar tudo',
      UNSELECT_ALL: 'Desmarcar Todos',
      ANALYSE_ERR_EXISTE: 'Título já usado',
      FORMULAIRE_ERR_EXISTE: 'O formulário já existe',
      ERR_MSG: 'Por favor, preencha este campo.',
      COL: 'Colunas',
      COL_S: 'Selecionar Colunas',
      SV: 'Salvar',
      SV_R: 'Salvar relatório',

      UPLOAD_DOC: 'Anexo',
      SELECT_OPTION_: 'Selecione uma opção',
      SELECT_OPTION: 'Selecione uma opção',
      REQUIRED: 'Requerido',
      CHECKING: 'Checando ...',
      ERROR_AUTHORIZE_LOCATION: 'Você não tem direitos sobre esta localidade',
      ONLINE: 'Conectados',
      OFFLINE: 'Deconectados',
      ALREADY_EXIST: 'A variável já existe!',
      SEE_MORE: 'Ver Mais ...',
      UNAVAILABLE: 'Indisponível',
      SELECT_UNIT: 'Selecione uma Unidade',
      CONDITIONS: 'Filtros',
      FORMATTING: 'Aplicar Formato',
      CHART: 'Gráfico',
      OVERWRITE_DATA: 'Sobrescrever dados',
      SHARE: 'Compartilhar',
      GOOD: 'Bom',
      BAD: 'Mau',
      MEDIUM: 'Médio',
      PORTFOLIO_VISIBLE: 'Visível no Portfólio',
      NO_DATA: 'Sem Dados',
      HELP: 'Ajuda',
      TASK_DISPLAY_FORMAT: 'day dd month yyyy',
      DAY_MAJOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      WEEK_MINOR_DISPLAY_FORMAT: 'mon yyyy - Week ww',
      FLAVOR: 'favoritos',
      CHECK_ALL: 'Marcar todos',
      UNCHECK_ALL: 'Desmarcar todos',
      PLANNING: 'Programado',
      NOT_PLANNING: 'Não Programado',
      UPDATE: 'Atualizar',
      BY: 'de',
      CHANGE_PHOTO: 'Mudar foto',
      ENTER: 'Entrar',
      FILTER: 'Filtrar por',
      AGGREGATE: 'Agregar',
      DESEGREGATE: 'Desagregar',
      TAKE_PICTURE: 'Tire uma foto',
      REPLY_TREE: 'Clique para navegar na visualização em árvore',
      VALIDATE_DATA: 'Validar dados',
      VALIDATED_DATA: 'Dados Validados',
      ALL_DATA: 'Todos os dados',
      VALIDATION: 'Validação',
      SATELLITE: 'Satélite',
      GRAYSCALE: 'Escala de cinza',
      CITIES: 'Cidades',
      DAY: 'Dia',
      EXPORT_DATA: 'Exportar Dados',
      IMPORT_DATA: 'Importar Dados',
      DENIED_LOGIN: 'Vá para a sua página de Início',
      DENIED: 'Acesso Negado',
      FULLSCREEN: 'Ver Tela Inteira',
      EXIT_FULLSCREEN: 'Sair da Tela Inteira',
      FULL_GRAPH: 'Preencher',
      WORKFLOW: 'Fluxo de Trabalho',
      DATA: 'Dados',
      GPS: {
        LONGITUDE: 'Longitude',
        LATITUDE: 'Latitude',
        ALTITUDE: 'Altitude'
      },
      RIGHT: {
        VALIDATION_SELECT: 'Validar selecionado',
        ONLYVALDATE: 'Apenas dados validados',
        NONVALIDATED: 'Inválido',
        INVALIDATED: 'Em processo de validação',
        VALIDATED: 'Válido',
        DELVALIDATED: 'Deletar',
        SELECTVAL: 'Selecione um estado',
        VALIDATE: 'Validação',
        VALIDATE_STATUS: 'Status de validação',
        VIEWING: 'Visualizar',
        UPDATING: 'Editar',
        DELETING: 'Excluir',
        PAGE: 'Função',
        EDIT_DELETE: 'Editar / Excluir',
        DATA: 'Dados',
        METADATA: 'MetaDados'
      },
      STATUT: {
        NOSTART: 'Não foi iniciado',
        PROGRESS: 'Em progresso',
        DONE: 'Concluído'
      },
      SAVE: 'Salvar',
      VIRGULE: '.',
      NEXT: 'Próximo',
      PREV: 'Anterior',
      ADD: 'Adicionar',
      EDIT: 'Editar',
      DELETE: 'Excluir',
      PRINT: 'Impressão',
      SELECT: 'Selecionar',
      YEAR: 'Ano',
      SEM: 'Semestre',
      QUATR: 'Quadrimestre',
      TRIM: 'Trimestre',
      BIM: 'Bimestre',
      MOIS: 'Mês',
      PERIOD: 'Período',
      YEAR_SHORT: 'A',
      SEM_SHORT: 'S',
      QUATR_SHORT: 'Q',
      TRIM_SHORT: 'T',
      BIM_SHORT: 'B',
      MOIS_SHORT: 'M',
      DISPLAY: 'Mostrar',
      VALIDATE: 'OK',
      CANCEL: 'Cancelar',
      CONTINUE: 'Continuar',
      CONFIRM: 'Confirmar exclusão',
      CONFIRM_RESTORE: 'Confirmar restauração',
      YES: 'Sim',
      NO: 'Não',
      ERROR_DELETE: 'Não é possível excluir!',
      ERROR_: 'Erro',
      ERROR_UPDATE: 'Não é possível atualizar!',
      ERROR_ADD: 'Não foi possível adicionar!',
      ERROR_CODE: 'Código já está a uso',
      TITLE_ERROR_ADD: 'Se detectou um erro durante a atualização',
      TITLE_ERROR_DELETE: 'Os dados têm dependências',
      DATE_FORMAT: 'DD/MM/YYYY',
      DATE_FORMAT_COMPLET: 'DD/MM/YYYY HH:mm',
      DATE_FORMAT_MM_YYYY: 'MMMM YYYY',
      HISTORQ: 'Recente',
      CLOSE: 'Perto',
      DATE_DP: 'Data de Início',
      ACTUAL: 'Data Atual',
      DATE_DR: 'Data de Início',
      DATE_FP: 'Data Final',
      DATE_FR: 'Data Final',
      H_DEB: 'Hora de Início',
      H_FIN: 'Hora Final',
      LIEU: 'Localização',
      MSG_ADD: 'Registro adicionado!',
      MSG_MODIF: 'Atualização concluída!',
      MSG_DEL: 'Exclusão concluída!',
      TBORD: 'Painel de Controle',
      YEAR_ALL: 'Tudo (*)',
      ALL: 'Todo (*)',
      ALL_E: 'Toda (*)',
      NONE: 'Nenhum',
      NONE_E: 'Nenhuma',
      SEARCH: 'Procurar',
      TYPECHART: 'Tipo de Gráfico',
      LINECHART: 'Gráfico de Linha',
      BARCHART: 'Gráfico de Barras',
      PIECHART: 'Gráfico Circular',
      POLARCHART: 'Gráfico de Radar',
      MIXED_CHART: 'Gráfico Misto',
      BEGIN: 'Início',
      END: 'Fim',
      LESS_THAN: 'Menor que',
      GREAT_THAN: 'Maior que',
      EGAL_TO: 'Igual a',
      LESS_THAN_E: 'Menor que',
      GREAT_THAN_E: 'Maior que',
      EGAL_TO_E: 'Igual a',
      OPEN: 'Abrir',
      DOWNLOAD: 'Baixar',
      QUIT: 'Cancelar',
      OPEN_DASHBOARD: 'Clique para mostrar o Painel de controle',
      DESCRIPTION: 'Descrição',
      MORE: 'Mais...',
      MORE_DBL: 'clique duas vezes para obter detalhes',
      DASHBOARD: 'Painel de controle',
      ERROR: {
        NOMATCH: 'Nenhum resultado corresponde',
        REQUIRED: 'Requerido',
        DBL: 'O código já está atribuído',
        DBL_REF: 'A referência já está atribuída',
        DBL_LAB: 'Rótulo já atribuído',
        DBL_: 'já atribuído',
        NONE_DATA: 'Nenhum dado selecionado',
        MIN_CAR: 'O comprimento mínimo de {{field}} é {{value}}',
        MAX_CAR: 'O comprimento míáximo de {{field}} é {{value}}',
        EMAIL: 'O e-mail não é válido',
        CODE_ERROR: 'O comprimento deve ser igual a {{value}}',
        CODE_ERROR_COM: 'O comprimento deve ser igual a {{value}} e começãr com {{begin}}',
        DATE_RANGE: 'A data de início deve ser maior que a data de término',
        DATE: 'Data inválida',
        FINANCETOTAL: 'O total deve ser igual a 100%',
        INVALID_URL: 'O URL não é válido'
      },
      EXPORT_WORD: 'Exportar para Word',
      EXPORT_EXCEL: 'Exportar para Excel',
      EXPORT_PDF: 'Exportar para PDF',
      EXPORT_JSON: 'Exportar para JSON',
      STREETMAP: 'Mapa de Ruas',
      NEW_FEAUTURE: 'Apresentação de novas funcionalidades',
      DATE_FORMAT_DISPLAY: 'Formato das datas'
    },
    MENU_SHORT: {
      LAYERS: 'Fundo de mapa',
      LAYER: 'Fundo de mapa',
      RISKS_MANAGEMENT: 'Gerenciamento de riscos',
      PROBLEM_MANAGEMENT: 'Problemas',
      RISKS: 'Riscos',
      PROBLEM: 'Problemas',
      MISSION_MANAGEMENT: 'Gestão de missões',
      MISSION_TYPES: 'Tipos de missão',
      COMMUNITIES: 'Comunidades',
      REPORT_PLAN: 'Planejamento de Relatório',
      REPORT_TRACKING: 'Rastreamento de Relatório',
      FUNDS: 'Desembolso de Fundos',
      ACTIVITY_PLAN_BUDGET: 'Plano e orçamento',
      PROJECT_TIMELINE: 'Cronograma do Projeto',
      RISK_MANAGEMENT: 'Gestão de Riscos',
      PROJECT_MGT: 'Gestão',
      ACTIVITY_REPORT: 'Relatórios do Projeto',
      CUSTOM_RIGHT: 'Direitos personalizados',
      HOME: 'Início',
      ALERT: 'Alertas',
      D_B: 'Configurações',
      DO: 'Portfólio de Projetos',
      PROJECT: 'Projetos',
      PROJECT_SINGLE: 'Projeto',
      D_T: 'Divisão de tempo',
      D_P: 'Período do relatório',
      D_PL: 'Planejamento de 3 níveis',
      UN: 'Unidades',
      AGENCIES: 'Agências',
      AC: 'Acionistas',
      FINANCING: 'Financiamento',
      S_F: 'Fontes de Financiamento',
      P_G: 'Localizações',
      S_G: 'Divisão de Geográfica',
      T_I: 'Categ. KPIs',
      C_I: 'Desagregação KPI',
      IN: 'Indicadores (KPIs)',
      IN_T: 'Indicadores (KPIs)',
      IN_P: 'KPIs de projetos cruzados',
      R_F: 'Estrutura de Resultados de KPIs',
      R_F_: 'Estrutura de Resultados',
      P_L: 'Personalizar Etiqueta',
      P_LO: 'Quadro de registro',
      C_B: 'Cat. Orçamento.',
      P_CL: 'Quadro lógico',
      P_CUSTOM: 'Modelos',
      P_CR: 'Cadeia de Resultados',
      ST: 'Modelo de quadro de Registro',
      ST_CL: 'Estrutura do Quadro Lógico ',
      ST_CR: 'Estrututa da Cadeia de Resultados ',
      P_B: 'Plano de Orçamento',
      P_M: 'Plano de Aquisições',
      FL: 'Bandeira',
      T_A: 'Cat. Atividade',
      AGENDA: 'Agenda',
      FILE: 'Sala de chat',
      ANALYTICS: 'Paineles de Controle',
      PL: 'Atividades',
      P_A: 'Monitoramento de Atividades',
      P_AN_PTBA: 'Plano anual (AWPB)',
      IMP_BUDG: 'Orçamento de Importação',
      G_F_PF: 'Gerar arquivo pré-formatado',
      I_B: 'Orçamentos de importação',
      P_I: 'Indicadores de Plano',
      S_E: 'Monitoramento',
      S_A: '{{level}} Monitoramento',
      I_C: 'Custos de Importação',
      I_COUT: 'Custos de Importação',
      S_PTBA: 'Monitoramento',
      S_PTBP: 'Desempenho Anual ',
      T_B_A: 'Painel de Atividades',
      C_GANTT: 'Cronograma do Projeto',
      P_AC: 'Plano de ação',
      R_P: 'Consultas Personalizadas',
      MA: 'Contratos',
      S_M: 'Monitor de Contratos',
      S_PPM: 'Monitoramento PP',
      S_I: 'Monitoramento do Indicador',
      S_I_IOV: 'Indicadores',
      S_I_G: 'Monitoreamento geográfico de indicadores',
      T_B_I: 'Painel de Indicadores',
      SAP_VIS: 'Visualização Espacial',
      SY: 'Resumo',
      C_L: 'Quadro de registro',
      S_C_L: 'Monitoramento do quadro de registro',
      S_C: 'Contexto',
      G_R: 'Gestão de riscos',
      P_M_R: 'Plano de mitigação de riscos',
      F_P: 'Fatores Positivos',
      E_T: 'Lições Aprendidas',
      B_D: 'Aprendendo',
      B_D_: 'Partilhar Conhecimento e Aprendizagem',
      DB_P: 'Bancos de Dados Personalizados',
      T_RA: 'Relatórios da Equipe',
      CA_R: 'Rastreamento de Relatório',
      C_D: 'Documents',
      PH: 'Biblioteca de Mídia',
      AD: 'Usuários ',
      G_U: 'Usuários ',
      GR_U: 'Grupos',
      U_C: 'Usuários Conectados',
      J_C: 'Diário de Conexões',
      R_I: 'Perfis',
      FORMULAR_: 'Coleta de Dados',
      FORMULAR: 'Coleta de Dados',
      DESIGN_FORMS: 'Formulários de Design',
      COLLECT_DATA: 'Coleta de Dados',
      ANALYZE_DATA: 'Análise e Relatórios',
      ANALYSE: 'Relatórios Personalizados',
      DASHBOARD: 'Paineles de Controle',
      DASHBOARD_: 'Paineles de Controle',
      ANALYTICS_GIS: 'Análise Espacial',
      AUDITS: 'Trilha de Auditoria',
      SECURITY: 'Segurança',
      DELETED_ITEMS: 'Itens Excluídos',
      ADMIN: 'Admin'
    },
    MENU: {
      LAYERS: 'Fundo de mapa',
      LAYER: 'Fundo de mapa',
      RECOMMENDATIONS: 'Recomendação',
      RISKS_MANAGEMENT: 'Gerenciamento de riscos',
      PROBLEM_MANAGEMENT: 'Problemas',
      RISKS: 'Riscos',
      PROBLEM: 'Problemas',
      MISSION_MANAGEMENT: 'Gestão de missões',
      MISSION: 'Misiones',
      MISSION_TYPES: 'Tipos de misión',
      FOLDER: 'Pasta',
      COMMUNITIES: 'Comunidades',
      REPORT_PLAN: 'Planejamento do Relatório',
      REPORT_TRACKING: 'Rastreamento do Relatório',
      FUNDS: 'Desembolso de Fundos',
      SECTORS: 'Setores',
      PROJECT_MGT: 'Gestão',
      ACTIVITY_REPORT: 'Relatórios do Projeto',
      DASHBOARDS: 'Paineles de Controle',
      PROJECTS: 'Projetos',
      HOME: 'Início',
      ALERT: 'Alertas',
      D_B: 'Configurações',
      DO: 'Portfólio de Projetos',
      PROJECT: 'Projetos',
      D_T: 'Divisão de Tempo',
      D_P: 'Período de Relatório',
      D_PL: 'Planejamento de 3 níveis',
      UN: 'Unidades de medida',
      FINANCING: 'Financiamento',
      AGENCIES: 'Agências de Implementação',
      AC: 'Acionistas',
      S_F: 'Fontes de financiamento',
      P_G: 'Localizações',
      Plan_G: 'Localizações',
      S_G: 'Estrutura Geográfica',
      STRUCT_G: 'Estrutura geográfica',
      T_I: 'Categorias de KPIs',
      C_I: 'Desagregação de KPI',
      IN: 'Indicadores (KPIs)',
      IN_T: 'Indicadores (KPIs)',
      IN_P: 'KPIs de Projetos Cruzados',
      R_F: 'Quadro de resultados de KPIs',
      R_F_: 'Quadro de Resultados de Indicadores',
      P_L: 'Personalizar Etiqueta',
      P_LO: 'Quadro de registro',
      C_B: 'Categorias de orçamento',
      P_CL: 'Quadro de Registro',
      P_CUSTOM: 'Modelos de Quadro de Registro',
      P_CR: 'Cadeia de Resultados',
      ST: 'Modelo de quadro de registro',
      ST_CL: 'Estrutura da Quadro Lógica',
      ST_CR: 'Quadro da Cadeia de resultados ',
      P_B: 'Plano de Orçamento',
      P_M: 'Plano de Aquisições',
      FL: 'Bandeira',
      T_A: 'Categorias de Atividades',
      AGENDA: 'Agenda',
      FEEDBACK: 'Comentários',
      FILE: 'Notícias',
      PL: 'Atividades',
      P_A: 'Monitoramento de Atividades',
      P_AN_PTBA: 'Plano Anual (AWPB)',
      IMP_BUDG: 'Importação de Orçamento',
      G_F_PF: 'Gerar arquivo pré-formatado',
      I_B: 'Orçamentos de importação',
      P_I: 'Indicadores de Planejamento',
      S_E: 'Monitoramento',
      S_A: '{{level}} Monitoramento ',
      I_C: 'Importação de Custos',
      I_COUT: 'Importar Custos',
      S_PTBA: 'Monitoramento',
      S_PTBP: 'Desempenho Anual do Projeto',
      T_B_A: 'Painel de Atividades',
      C_GANTT: 'Cronograma do Projeto',
      P_AC: 'Plano de Ação',
      R_P: 'Consultas Personalizadas',
      MA: 'Contratos',
      S_M: 'Monitoramento de Contratos',
      S_I: 'Monitoramento do Indicador',
      S_I_IOV: 'Indicadores',
      S_I_G: 'Monitoramento geográfico de Indicadores',
      T_B_I: 'Painel de Indicadores',
      SAP_VIS: 'Visualização Espacial',
      SY: 'Resumo',
      C_L: 'Quadro de registro',
      S_C_L: 'Monitoramento do quadro de registro',
      S_C: 'Contexto',
      G_R: 'Gestão de riscos',
      P_M_R: 'Plano de mitigação de risco',
      F_P: 'Fatores Positivos',
      E_T: 'Lições Aprendidas',
      B_D: 'Aprendendo',
      B_D_: 'Partilhar Conhecimento e Aprendizagem',
      DB_P: 'Bancos de Dados Personalizados',
      T_RA: 'Relatórios de Equipe',
      CA_R: 'Rastreamento de Relatório',
      C_D: 'Documentos',
      PH: 'Biblioteca de Mídia',
      AD: 'Usuários',
      G_U: 'Gestão de Usuários',
      GR_U: 'Grupos de Usuários',
      U_C: 'Usuários Conectados',
      J_C: 'Diário de Conexões',
      R_I: 'Perfis de Usuário',
      FORMULAR_: 'Coleta de dados',
      FORMULAR: 'Coleta de dados',
      ANALYTICS: 'Paineles de Controle',
      ANALYTICS_CUSTOM_REPORT: 'Relatórios Personalizados',
      ANALYTICS_CUSTOM_DASHBOARD: 'Paineles de Controle',
      ANALYTICS_GIS: 'Análise Espacial',
      DESIGN_FORMS: 'Design de Formulários',
      COLLECT_DATA: 'Coleta de dados',
      ANALYZE_DATA: 'Análise e Relatórios',
      ANALYSE: 'Relatórios Personalizados',
      DASHBOARD: 'Coleta de dados',
      DASHBOARD_: 'Coleta de dados',
      AUDITS: 'Trilha de Auditoria',
      SECURITY: 'Configurações de Segurança',
      DATA: 'Formulários',
      ADMIN: 'Administração'
    },
    LAYER: {
      NAME: 'título',
      URL: 'Url',
      HELP: {
        NAME: 'título',
        URL: 'A URL do fundo de mapa (https://...{x}{y}{z})'
      },
      ERROR: {
        URL: 'URL inválida'
      }
    },
    PROGRAM: {
      EDIT: 'Editar Portfólio',
      EDIT_PROGRAM: 'Edição do Portfólio',
      EDIT_HEADERS: {
        DETAIL: 'Detalhes do Portfólio',
        DESCRIPTION: 'Descrição do Portfólio',
        ME: 'Definindo períodos e marcos',
        PERIODS: 'Parametrização de Períodos',
        MILESTONES: 'Milestones',
        OPTIONS: 'Opções'
      },
      TITLE: 'Portfólio',
      NAME: 'Nome',
      DESCRIPTION: 'Descrição',
      HELP: {
        NAME: 'Insira o Nome do Portfólio',
        DESCRIPTION: 'Insira a Descrição do Portfólio'
      }
    },
    DEFAULT_LOGFRAME: {
      TITLE: 'Modelos de Quadro de Registro',
      SUBTITLE: 'Modelo de Quadro de Registro',
      LABEL: 'Nome',
      LEVELS: 'Nível do Quadro de Registro',
      SEARCH: 'Procura por nome',
      HELP: {
        NEW_LEVEL: 'Adicionar novo nível',
        LABEL: 'Insira o nome doquadro de reistro'
      }
    },
    SECTORS: {
      NAME: 'Nome'
    },
    FINANCING: {
      EDIT_TITLE: 'Financiamento',
      SOURCE: 'Fonte de Financiamento',
      AGREEMENT: 'Contrato de Financiamento #',
      TYPE: 'Modelo',
      TYPES: {
        GRANT: 'Conceder',
        LOAN: 'Empréstimo',
        DONATION: 'Doação',
        BUDGET_SUPPORT: 'Apoio ao orçamento'
      }
    },
    PORTFOLIO: {
      GO_TO_PORTFOLIO: 'Ver projetos ...',
      EDIT_TITLE: 'portfólio',
      CLOSE: 'Voltar para portfólios',
      OPEN: 'Gerenciar portfólio',
      CODE: 'Acrônimo',
      LABEL: 'Nome',
      TITLE: 'Portfólios',
      ADD: 'Adicionar portfólio',
      ERROR: {
        DBL: 'O acrônimo da carteira já está atribuído',
        DATE: 'A data de término deve ser maior que a data de início',
        DATE_BEGIN_SUP: 'A data de início deve ser maior que {{X}}',
        DATE_BEGIN_INF: 'A data de início deve ser menor que {{X}}',
        DATE_END_SUP: 'A data de término deve ser maior que {{X}}',
        DATE_END_INF: 'A data de término deve ser menor que {{X}}',
        DELETE: 'Este setor está sendo usado atualmente'
      }
    },
    PROJECT: {
      ACTIVE_SMS: 'Ativar o envio de alertas SMS',
      ACTIVE_SMS_WARNING: 'A ativação desta opção terá custos adicionais.\n' + 'Entre em contato com a DELTAGIS para a efetiva ativação da opção.',
      NEWS: 'Notícia',

      CUSTOM_FIELD_1: 'Campos de legenda 1',
      CUSTOM_FIELD_2: 'Campos de legenda 2',
      OPTION_ACTIVITIES: 'Opções de gerenciamento',
      OPTION_INDICATORS: 'Indicadores de Opções',
      OPTION_OTHERS: 'Outras opções',
      CUSTOM_FIELD: 'Use campos personalizados',
      INDEX_PERFORMANCE: 'Performance',
      CROSS_CUTTING_KPIS: 'Gerenciar indicadores de desempenho',
      CROSS_CUTTING_KPIS_LIST: 'indicadores de desempenho',
      PORTFOLIO_CROSS_CUTTING_KPIS: 'Isolar indicadores transversais de portfólio',
      MISSIONS: 'Acompanhamento da missão',
      RISK: 'Gerenciamento de riscos',
      FOLLOW_RISK: 'monitoramento de risco',
      FOLLOW_PERFORM: 'Monitoramento de desempenho',
      TYPE: 'Modelo',
      TYPES: {
        PROJECT: 'Projeto',
        PROGRAM: 'Programa',
        OTHER: 'Outros'
      },
      ACTOR: 'Gestor',
      ACTORS: 'Gestor',
      ACTOR_NONE: 'Não',
      STATUS_PROGRESS: 'Progresso',
      STATUS_PROGRESS_: 'Progresso',
      DASHBOARDS: 'Painel do projeto',
      GO_TO_PROJECT: 'Vá para o Projeto',
      SUMMARY: 'Desempenho do projeto',
      SELECT_SECTORS: 'Setores',
      SECTORS: 'Setores',
      PROJECTS: 'Projetos',
      PROJECTS_COL: 'Projeto',
      EDIT: 'Editar',
      GEO_MAP: 'Mapa Geográfico',
      SECTOR: 'Setor',
      FINANCING: 'Fonte de Financiamento',
      AMOUNT: 'Montante',
      ADD_: 'Adicionar',
      FINANCIAL_DATA: 'Dados Financeiros',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} projetos)',
      PAGING_ELEMENT_SUMMERY: '{{current}} de {{total}} páginas ({{count}} elementos)',
      AGENCIES: 'Agências de Implementação',
      SELECT_AGENCIES: 'Selecione Agências de Implementação',
      DISPLAY_DATA: 'Use o portal para mostrar dados',
      LOCATION: 'Coordenadas GPS',
      ADD: {
        OPTION_1: 'Projeto',
        OPTION_2: 'Portfólio'
      },
      LINK: {
        TITLE: 'Vincule os seguintes dados aos portfólios',
        UNITS: 'Unidades',
        FINANCIAL_SOURCES: 'Fontes de Financiamento',
        LOCALITIES: 'Localizações',
        LOGICAL_FRAME: 'Quadro de Registro',
        FLAG: 'Bandeira',
        TYPE_ACTIVITIES: 'Categorias de Atividades',
        TYPE_INDICATORS: 'Categorias de KPIs',
        TYPE_REPORT: 'Relatórios',
        FORM: 'Formulários'
      },
      SELECT_PERIOD: 'Selecione um período',
      SEARCH_PROJECT: 'Pesquisar por código ou etiqueta',
      FILTER: 'Filtrar por:',
      MILESTONES: {
        REALIZE: 'Concluído',
        TITLE: 'Marco',
        MILESTONE: 'Marco',
        PLANNED_DATE: 'Data Planejada',
        ACTUAL_DATE: 'Data Atual',
        ACTUAL: 'Real',
        COMMENTS: 'Comentários',
        PLANNED: 'Planejado'
      },

      FILTERS: 'Filtros',
      CLOSE: 'Fechar',
      TO_FILTER: 'filtro',
      CLEAR_FILTERS: 'limpar',
      DESCRIPTION: 'Descrição',
      GLOBAL_PLAN: 'Use o planejamento global',
      PERIODIC_FOLLOW: 'Frequência de Relatórios',
      SETTING_FOLLOW: 'Configurações de Monitoramento',
      ACTIVITY_WEIGHT: 'Usar Ponderação',
      ACTIVITY_WEIGHT_AN: 'Por ano',
      START_MONTH: 'Começo do Ano Fiscal ',
      FISCAL_YEAR: 'Ano fiscal',
      LOGFRAME: 'Quadro de Registro',
      RESBAS_MONIT: 'Monitoramento Baseado em Resultados',
      CUSTOM_LOGFRAME: 'Quadro de Registro Personalizado',
      ME_METHOD: 'Modelos de Quadros Registro',
      ME_METHOD_SELECT: 'Selecione o Modelo de Quadro de Registro do Projeto',
      FILTER_BY: 'Filtrar por',
      PRJ: 'Portfólio',
      DASHBOARD: 'KPIs de Projetos Cruzados',
      TITLE: 'Portfólio de Projetos',
      NEWP: 'Adicionar',
      FROM: 'de',
      TO: 'para',
      ADDRESS: 'Endereço',
      CONTACT: 'Pessoa de contato (Ponto Focal)',
      PROCESS: 'Progresso...',
      COMPLETE: 'Conclusão com',
      PROJECT: 'Projeto',
      EDIT_PROJECT: 'Editar Projeto',
      INFO: 'Informações ',
      CODE: 'Acrônimo do Projeto',
      LABEL: 'Nome do Projeto',
      CURRENCY: 'Moeda',
      D_DEB: 'Data de Início',
      D_FIN: 'Data Final',
      D_DEB_: 'Início',
      D_FIN_: 'Final',
      COOR: 'Informações de Contato',
      LINK_GEO_PLAN: 'Use as localidades do Programa',
      ADR: 'Cidade',
      PAYS: 'País',
      TEL: 'Telefone',
      FAX: 'Fax',
      MAIL: 'E-mail',
      OTHER: 'Detalhes',
      AGENC: 'Agência de Implementação',
      DESCR: 'Descrição',
      CODE_ERR: 'código não disponível',
      CONFIRM_DELETE: 'Deseja excluir este projeto?',
      SELECT: 'Selecione',
      CHANGE_PIC: 'Alterar Imagem (100x100)',
      STATUT_BUDGET: 'Publique dados financeiros',
      GOAL: 'objectivo global',
      TARGET: 'Beneficiários',
      AREA: 'Área de execução',
      LOCATION_FIELD: 'Localização geográfica',
      SPECIFIC_OBJECTIVES: 'Objetivos específicos',
      IMPACT: 'Impacto esperado',
      DATE_MID: 'Revisão Intermediária',
      STATUS: 'Status',
      COST: 'Custo Total do Projeto',
      FINAN: 'Financiamento',
      MAP: 'Localização dos Projetos',
      GLOBAL_HEADERS: {
        PROJECTS: 'Lista',
        MAP: 'Mapa',
        INDICATOR: 'KPIs de projetos cruzados'
      },
      EDIT_HEADERS: {
        DETAIL: 'Detalhes',
        DESCRIPTION: 'Descrição',
        ME: 'Definindo períodos e marcos',
        PERIODS: 'Parametrização de Períodos',
        MILESTONES: 'Milestones',
        OPTIONS: 'Opções'
      },
      STATUTS: {
        NOT_STARTED: 'Não Começou',
        ACTIVE: 'Activo',
        SUSPENDED: 'Suspenso',
        COMPLETED: 'Concluído',
        ARCHIVED: 'Arquivado'
      },
      TOOLTIP: {
        CURRENCY: 'Máximo de 5 caracteres',
        STATUT_BUDGET: 'Se esta opção estiver marcada, os dados financeiros serão divulgados como parte das informações mostradas no portal.'
      },
      ERROR: {
        CUSTOM_FIELD: 'Por favor, preencha um dos campos',
        DELETE: 'O projeto tem dados e não pode ser excluído!',
        DBL: 'O acrônimo já está atribuído',
        DATE: 'A data final deve ser depois da data inicial',
        CURRENCY: 'O comprimento da moeda deve ter menos de 5 caracteres',
        SAVING: 'Se você atualizou a data de início do ano fiscal ou a frequência de relatórios, todos os dados relacionados (planejados e reais) serão excluídos.'
      },
      MAP_VIEW: {
        NBR_PROJECT: 'Número de Projetos',
        NBR: 'Número',
        INVESTEMENT: 'Investimentos ({{currency}} millhões)',
        MILLIONS: '({{currency}} millhões)',
        AGENC: 'Agência de Implementação',
        PROJECTS: 'Projetos',
        BUDGET: 'Montante ({{currency}} millhões)',
        LOCATIONS: 'Localidades',
        BACK: 'Voltar',
        STATUS: 'Status',
        SECTORS: 'Setores',
        FUNDING_SOURCE: 'Fontes de Financiamento',
        SUB_LOCATIONS: 'Sub-localidades',
        TOTAL: 'Total'
      },
      DASHBOARD_VIEW: {
        FINANCING_PLAN: 'Plano financeiro',
        DASHBOARD_TYPE: {
          PROJECTS: 'Painel',
          RISK: 'Risco',
          PROBLEM: 'Incidentes',
          MISSION: 'Missões'
        },
        FINANCING_TYPE_: 'Tipo de financiamento',
        DESCRIPTION: 'Descrição',
        FINANCIAL_DATA: 'Desempenho financeiro',
        INDICATOR_PERFORMANCE: 'Indicadores de desempenho',
        INDICATOR_RESULT: 'Estrutura de resultados',
        EXCHANGE: 'Taxa de Câmbio',
        TOOLTIP_SECTOR_PLURALS: '{{project}} projetos totalizando {{amount}} ({{currency}} milhões)',
        TOOLTIP_SECTOR_SINGLE: '1 projeto totalizando {{amount}} ({{currency}} milhões)',
        NUMBER_OF_PROJECT: 'Número de projetos',
        DECIMAL_CURRENCY: '{{currency}} milhões',
        FINANCING: 'Financiamento',
        CURRENCY: 'Moeda',
        PROJECT: 'Projetos',
        AMOUNT: 'Montante',
        DISBURSED: 'Desembolsado',
        PROJECT_BY_SECTOR: 'Discriminação por Setor',
        INVESTING_BY_FUNDING_SOURCE: 'Investimento por Fontes de Financiamento',
        PROJECT_BY_FUNDING_SOURCE: 'Discriminação por Fontes de Financiamento',
        SOLD: 'Acessível',
        RESULTS_FRAMEWORK_STANDARD: 'Estrutura de resultados dos indicadores (status atual)',
        RESULTS_FRAMEWORK_MULTI_YEAR: 'Estrutura de resultados dos indicadores (progresso anual)',
        RESULTS_FRAMEWORK: 'Quadro de Resultados',
        TOTAL_COST: 'Custo Total do Projeto',
        // DISBURSED_RATE: 'Taxa de Desembolso',
        DISBURSED_RATE: 'Evolução da taxa global de desembolso',
        TOTAL_DISBURSED_BY_FUNDING: 'Total desembolsado por fonte de financiamento',
        FILTER: 'Filtro',
        FINANCING_TYPE: 'Fonte de Financiamento',
        AGENCIES: 'Agências de Implementação',
        STATUS: 'Status',
        SECTORS: 'Setor',
        LOCATIONS: 'Localizações',
        LOCATION: 'Localizaçõe'
      }
    },
    BTN: {
      ADD: 'Adicionar',
      MOD: 'Editar',
      DEL: 'Excluir',
      CAN: 'Cancelar',
      PRI: 'Impressão',
      VAL: 'OK',
      NEX: 'Próxima Página',
      PRE: 'Página Anterior'
    },
    DEVISE: {
      TITLE: 'Moeda',
      CODE: 'Código',
      LABEL: 'Nome'
    },
    FUNDS: {
      AVAILABLE: 'Disponível',
      MAPPING: 'Importar Dados',
      USE_SELECTED_FINANCING: 'Usar Financiamento Selecionado',
      USE_CURRENT_DATE: 'Use a data de hoje',
      PLEASE_SELECT_COLUMN: 'Por favor selecione uma coluna',
      REFERENCE: '#',
      REFERENCE_FIELD: 'Desembolso #',
      LABEL: 'Desembolso',
      EDIT_TITLE: 'Desembolso',
      DATE_FUNDS: 'Data',
      AMOUNT: 'Montante',
      DEVISE: 'Moeda ',
      RATE: 'Taxa',
      AMOUNT_RATE: 'Montante * Taxa ',
      HELP: {
        REFERENCE: '# ou Ref. Do Desembolso',
        LABEL: 'Natureza ou finalidade do desembolso',
        DATE_FUNDS: 'Data de desembolso',
        AMOUNT: 'Montante desembolsado',
        DEVISE: 'Moeda do valor desembolsado',
        RATE: 'Taxa de câmbio em relação à moeda de financiamento',
        AMOUNT_RATE: 'Montante (moeda do financiamento)'
      },
      FINANCING: 'Financiamento',
      DISBURSED: 'Desembolsado',
      BAR_CHART: 'TAXA DE DESEMBOLSO POR ANO',
      PIE_CHART: 'TOTAL DESEMBOLSADO POR ANO'
    },
    FN_UNIT: {
      HELP: {
        COD: 'Digite o código da unidade (10 caracteres)',
        LAB: 'Nome da unidade'
      },
      ERROR_CODE: 'O comprimento do código não deve exceder 10 caracteres',
      SEARCH: 'Pesquise unidades por código ou nome',
      LST_TI: 'Unidades de medida (atividades e indicadores)',
      ED_TI: 'Detalhes da unidade',
      COD: 'Código',
      LAB: 'Rótulo',
      TYP: 'Tipo',
      FOR: 'Formato',
      CB_N: 'Numérico',
      CB_P: '%',
      CB_B: 'Boleano',
      CB_T: 'Texto',
      CB_D: 'Data',
      NEWU: 'Criar nova Unidade',
      COLU: 'Unidade',
      CONFIRM_DELETE: 'Deseja excluir esta unidade?',
      DECIMAL: 'Número de dígitos depois do ponto decimal'
    },
    CATEGORIE_BUDGET: {
      HELP: {
        COD: 'Digite o código da unidade (10 caracteres)',
        LAB: 'Nome da categoria'
      },
      CODE: 'Código',
      CATEGORIE_BUDGET: 'Categoria de orçamento',
      TITLE: 'Categorias de orçamento',
      LABEL: 'Nome',
      CONFIRM_DELETE: 'Deseja excluir esta categoria?'
    },
    AGENCIES: {
      TITLE: 'Agências de Implementação',
      NAME: 'Nome',
      SHORT_NAME: 'Nome Curto',
      ADDRESS: 'Endereço',
      CITY: 'Cidade',
      COUNTRY: 'País',
      TEL: 'Telefone',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      URL: 'Local na rede Internet',
      HELP: {
        NAME: 'Inserir nome da agência',
        SHORT_NAME: 'Inserir nome abreviado da agência',
        ADDRESS: 'Inserir endereço da agência',
        CITY: 'Inserir cidade',
        COUNTRY: 'País',
        TEL: 'Inserir Telefone',
        FAX: 'Inserir Fax',
        EMAIL: 'Inserir E-mail',
        URL: 'Inserir O Site'
      },
      SEARCH: 'Pesquisar por nome, endereço, cidade ou país',
      ERROR: {
        DBL_NAME: 'Nome já atribuído'
      }
    },
    FN_ACTORS: {
      HELP: {
        COD: 'Código, ID ou Sigla',
        NOM: 'Nome do acionista ',
        PRE: 'Nome do acionista ',
        FON: 'Função / responsabilidade atribuída ao acionista',
        ORGANIZATION: 'Organização à qual o acionista pertence.',
        GRO: 'Grupo de acionistas',
        EMAIL: 'Email do acionista.'
      },
      PERIOD_RAPPORT: 'Período',
      EDITION_RAPPORT: 'Data da Edição',
      SEARCH: 'Pesquisa por Código, Nome',
      CATEGORY: 'Categoria',
      CATEGORY_TEAM: 'Equipe do Projeto',
      CATEGORY_STAKE: 'Acionistas',
      LST_AC: 'Acionistas',
      ED_AC: 'Membro da Equipe',
      COD: 'Código',
      NOM: 'Nome',
      PRE: 'Nome',
      FON: 'Papel / Responsabilidade',
      IMG: 'Imagem',
      ORGANIZATION: 'Organização',
      GRO: 'Grupo',
      GRO_: 'Grupo',
      SELECT_GRO: 'Selecione um grupo',
      NEWA: 'Criar novo Acionista',
      LAB_AC: 'Atividades',
      LAB_LSTAC: 'Atividades',
      LAB_MI: 'Missões',
      LAB_LSTMI: 'Missões',
      LAB_RE: 'Encontro',
      LAB_LSTRE: 'Encontro',
      LAB_RA: 'Relatórios',
      LAB_LSTRA: 'Relatórios',
      LAB_TYPRA: 'Tipo de Relatório',
      FILT: 'Filtro',
      ETAV: 'Status de atividade',
      RESNIVO: 'Nível de Responsabilidade',
      CHECK_A1: 'Em progresso',
      CHECK_A2: 'Concluído',
      CHECK_A3: 'Depois do previsto',
      CHECK_A4: 'Antes do previsto',
      CHECK_A5: 'Global',
      CHECK_A6: 'Local',
      CONFIRM_DELETE: 'Deseja excluir este Ator?'
    },
    FN_SFIN: {
      APPROVE_DATE: 'Data de aprovação',
      CLOSE_DATE: 'Data de Fechamento',
      SEARCH: 'Pesquisa por Código ou Nome',
      NAME: 'Nome',
      NAME_CONTACT: 'Nome',
      TEL_CONTACT: 'Telefone',
      EMAIL_CONTACT: 'Endereço de E-mail',

      NAME_CONTACT_LONG: 'Contato (Nome)',
      TEL_CONTACT_LONG: 'Contato (telefone)',
      EMAIL_CONTACT_LONG: 'Contacto (Email)',

      CONTACT: 'Pessoa de Contato',
      FINANCE_AMOUNT: 'Montante de Financiamento',
      AMOUNT: 'Montante',
      CURRENCY: 'Moeda',
      EXCHANGE_RATE: 'Taxa',
      LST_FIN: 'Fontes de financiamento',
      ED_FIN: 'Detalhes da fonte de financiamento',
      CONFIRM_DELETE: 'Deseja excluir esta fonte?',
      EQUIV: 'Equiv. {{CURRENCY}} ',
      ERROR_DATE: 'A data de aprovação deve ser menor que a data de fechamento'
    },
    FN_TYPINDIC: {
      LST_TYP: 'Lista de tipos de Indicadores',
      ED_TYP: 'Detalhes do tipo de indicador',
      COL_COLOR: 'Cor',
      COLOR_BLUE: 'Azul',
      COLOR_CRYAN: 'Ciano',
      COLOR_GREEN: 'Verde',
      COLOR_YELOW: 'Amarelo',
      COLOR_ORANGE: 'Laranja',
      COLOR_RED: 'Vermelho',
      COLOR_PINK: 'Rosa',
      COLOR_PURPLE: 'Roxo',
      COLOR_GREY: 'Cinzento',
      COLOR_BLACK: 'Preto',
      CONFIRM_DELETE: 'Deseja excluir este tipo de indicador?'
    },
    DASHBOARD: {
      PROGRESS_REPORT: 'Relatório de progresso',
      ACTIVITIES_TAB: {
        PHYSIC: '% Físico',
        FINANCIAL: '% Orçamento',
        DISBURSED: '% Desembolsado',
        TITLE: 'Progresso da Atividade',
        COMPLETED_ACTIVITIES: 'Concluído',
        NOT_CARRIED_OUT_ACTIVITIES: 'Não Realizado',
        ON_GOING_ACTIVITIES: 'Em Andamento',
        STATUS: 'Status'
      },
      LOCATION: 'Localização',
      ACTIVITY_COUNT: 'Número de Atividades: {{value}}',
      PROJECT_ACTIVITY: '{{value}} das atividades do projeto',
      ACTIVITY_PROGRESS_LABEL: '{{percent}}% das atividades ({{count}}) têm uma taxa de conclusão entre  {{min}}% e {{max}}%',
      INDICATOR_PROGRESS: 'Progresso do Indicador',
      SPATIAL_VIEW: 'Vista Espacial',
      INDICATOR_PROGRESS_COMPARED: 'Progresso do indicador comparado ao objetivo final do projeto (fim do projeto)',
      INDICATOR: 'Painel do Indicador',
      ACTIVITY: 'Painel de  {{activity}}',
      BOOKMARKED: 'Indicador Marcado',
      REFERENCE: 'Linha de Base',
      FINAL: 'Fim do Projeto',
      END_TARGET: 'Alvo Final',
      ACTUAL_VALUE: 'Real',
      END_VALUE: 'Fim do Projeto',
      MAP: 'Mapa',
      TITLE: 'Painel do {{code}}',
      TITLE_PORTFOLIO: 'Painel',
      V_R: 'Valor de Referência',
      V_C: 'Valor alvo',
      FROM: 'de',
      TO: 'para',
      PROCESS: 'Progresso...',
      COMPLETE: 'Concluído',
      SIT_INDIC: 'Status dos Indicadores',
      REP_ACTIVITE: 'Desempenho do Projeto',
      PHY: '% do Alvo',
      FIN: '% do Orçamento',
      GRAPHE_PERF: 'Gráfico de Desempenho',
      SUIV_PTBA: 'Desempenho Anual do Projeto',
      DASHBOARD: 'Painel de Controle',
      RESUME_PROJECT: 'Resumo do Projeto',
      EVOLUTION_TABLE: 'Progresso do Indicador',
      REALIZE: '% Final do Projeto Atingido',
      YEAR: 'Ano',
      PERIOD: 'Período',
      INDICATORS: 'Indicadores',
      ACTIVITIES: 'Activities',
      MSG_DBCLK: 'Clique duas vezes para obter mais detalhes'
    },
    TIME_DIVISION: {
      PERIOD: 'Período',
      PTBA: 'PPO atual',
      PLAN_AN: 'Planejamento anual',
      LEVEL: 'Nível',
      LEVEL_1: 'Global',
      LEVEL_2: 'Anual',
      LEVEL_3: 'Período',
      ALLOW_TOTAL_GLOBAL: 'Permitir que o total anual exceda o global',
      ALLOW_TOTAL_ANNUAL: 'Permitir que o período total exceda o anual',
      START_LEVEL: 'Nível Inicial de Planejamento',
      GLOBAL: 'Global',
      ANNUAL: 'Anual',
      PERIODIC: 'Período',
      HELP: {
        LEVEL_1: 'O plano global permite definir metas e orçamentos para toda a duração do projeto',
        LEVEL_2: 'O plano global está dividido em planos anuais para cada ano do Projeto',
        LEVEL_3: 'Cada plano anual está dividido em planos periódicos de acordo com os períodos definidos anteriormente'
      },
      BEGIN: 'Início',
      END: 'Fim',
      LABEL: 'Rótulo'
    },
    GEOGRAPHIC_PLAN: {

      IMPORT_SHP: {
        INFO: 'Por favor, selecione a propriedade que contém o nome dos locais',
        PROPERTY: 'Propriedade'
      },
      REFRESH_GEO_CODING_FILE: 'Atualizar Geocodificação a partir de um arquivo (geojson/shapefile)',
      REFRESH_GEO_CODING: 'Atualizar Georreferenciamento',
      COUNTRY: 'País',
      ADD__: 'Adicionar localização',
      ADD: 'Adicionar sub-localização',
      SUM: 'Soma',
      MOY: 'Média',
      MAXI: 'Máximo',
      MINI: 'Mínimo',
      AGGREGA: 'Agregação',
      GENERATE: 'Importar do servidor geográfico',
      LEVEL: 'Nível',
      CHAMPPERSO: 'Campos de dados definidos pelo usuário',
      LABEL: 'Rótulo',
      CONFIRM_DELETE: 'Deseja excluir este campo?',
      LONG: 'Comprimento',
      ERROR_UPDATE_LONG: 'Não é possível atualizar o comprimento.',
      CONFIRM_DELETE_LEVEL: 'Deseja excluir este nível?',
      CONFIRM_DELETE_LOCALTION: 'Deseja excluir esta localização?',
      CODE: 'Código',
      DESCRP: 'Descrição',
      IMPORT: 'Importar do Arquivo Excel',
      IMPORT_SHAPEFILE: 'Importar de um shapefile ou arquivo geojson',
      ERROR_STRUC: 'Um erro foi detectado na estrutura do arquivo',
      ERROR_FORMAT: 'Por favor, use um arquivo *.xsl!',
      ERROR_CANADD: 'Localidades são usadas!',
      UPLOAD_LABEL: 'Nível {{level}} ...',
      EXPORT: 'Exportar para Excel'
    },
    INDICATOR_CAT: {
      TITLE_EDIT: 'EDITAR CATEGORIAS',
      TITLE: 'Categorias de Desagregação',
      CODE: 'Código',
      LABEL: 'Nome',
      AGGREGATE: 'Agregação',
      OPTIONS: 'Opções',
      OPTION: 'Opção'
    },
    INDICATOR: {
      VALUE_TO_DISPLAY: 'Propriedade a ser exibida',
      PORTFOLIOS: 'Portfólios',
      DATA_REFRESH: 'Atualizar valores',
      FIXED_DENOMINATOR: 'Denominador fixo',
      FROM_CALCULATE_DATA_NUM_DEMO: 'Numerador / Denominador',
      NUMERATOR: 'Numerador',
      DENOMINATOR: 'Denominador',
      FROM_CALCULATE_DATA_NUM_DEMO_HELP: '',
      WEIGHT: 'Peso',
      MOY_WEIGHT: 'Média ponderada',
      PROJECT_INDICATORS: 'Indicadores de projeto',
      PROJECT_INDICATOR_SHARE: 'Indicadores compartilhados',
      ADD_ITEM: 'Adicionar Indicador',
      OPTION_CATEGORY: 'Opção',
      PROJECTS: 'Projetos',
      CROSS_INDICATOR: 'Indicador de Projeto Cruzado',
      CALCULATE_DATA: 'Calcular',
      ADD_ACTIVITY: 'Novo Indicador',
      EDIT_ACTIVITY: 'Adicionar a partir de Indicadores Existentes',
      ADD: 'Adicionar Indicador',
      LAYER_URL: 'URL de camadas',
      EDIT_LAYERS: 'Insira o URL da Camada',
      USE_COLORS: 'Configurações de cores do mapa',
      USE_LAYERS: 'Configuração das camadas do mapa',
      OUTPUT: 'Resultado',
      CROSS_PROJECT: {
        NAME: 'Nome',
        DESCRIPTION: 'Descrição'
      },
      LINK_LOGFRAME: 'Link para o Quadro de Registro',
      NONE_AGGREGATE: 'Nenhum',
      UNIT_COST: 'Custo unitário',
      INDICATOR_BASE: 'Lista de Indicadores',
      SEARCH: 'Pesquisar um Indicador',
      SELECT_INDICATOR: 'Selecione um Indicador',
      SEARCH_LOCATION: 'Pesquisar uma localização',
      SEARCH_PERIOD: 'Pesquise um período',
      PERFORMANCE_INDICATOR: 'Indicadores de desempenho',
      CROSS_INDICATORS: 'Indicadores transversais',
      MAPPING: 'Mapeamento',
      CATEGORY: 'Categorias de Desagregação',
      CROSS_DISAGGREGATION: 'Desagregação Cruzada',
      PARALLEL_DISAGGREGATION: 'Desagregação Paralela',
      DESCRIPTION_ONG: 'Detalhes',
      POSSIBLEVALUE: 'Valores Possíveis',
      LESS_THAN: 'O valor deve ser menor ou igual a {{valeur}}',
      GREAT_THAN: 'O valor deve ser maior ou igual a {{valeur}}',
      EGAL_TO: 'O valor deve ser igual a {{valeur}}',
      BEETWEEB: 'O valor deve estar entre {{valeur_min}} e {{valeur_max}}',
      ERROR_UPDATE: 'Ocorreu um erro durante a atualização!',
      LEGEND: 'Configurações GIS',
      LEGEND_COLOR: 'Cores da legenda (mapa)',
      ERROR_DECOUP_PLAN: {
        DATE_COLLECT: 'A data de coleta deve pertencer ao período',
        TITLE: 'Erro',
        VALUE: 'por favor, insira um valor',
        DATE_RELEVE: 'Por favor, insira a data planejada',
        DATE_ALERT: 'Por favor, insira a data do alerta',
        DATE_A: 'A data do alerta deve ser inferior à data planejada.',
        DATE_RELEVE_T: 'A data planejada deve estar dentro do período e dentro da duração do indicador',
        CATEGORY_VALUE: 'A soma dos valores desagregados não é igual ao valor agregado',
        CATEGORY_VALUE_POSITIF: 'O valor desagregado deve ser maior ou igual a 0',
        CATEGORY_VALUE_PARALLELE: 'Verifique se o valor inserido foi desagregado corretamente para cada categoria',
        DENOMINATOR_ERROR: 'O valor do denominador deve ser diferente de 0'
      },
      ENTRY_DATA_HELP: 'Os resultados do indicador são inseridos manualmente',
      PROJECT: {
        VALUE: 'Valor',
        VALUES: 'Valores'
      },
      HELP: {
        TRANS: 'Um indicador comum é um indicador que é comum para todas as atividades (ver detalhes no manual)'
      },
      COUNT_ALL: 'Contando',
      DATA_TABLE: {
        TEMPLATE: 'Arquivo de Dados',
        CALCULATION: 'Cálculo',
        LOCALITIES: 'Geografia',
        PERIODS: 'Período',
        QUERY: 'Consulta',
        CALCULATION_ITEM: 'Campo'
      },
      DOCUMENTS: 'Documentos',
      COMPOSITE: 'Fonte de Dados',
      SELECT: 'Lista',
      LOCATION: 'Localização',
      CALMETHOD: 'Método de cálculo',
      CRITERIA: 'Critério',
      CODE: 'Código',
      INDICATOR_EDIT: 'Detalhes do indicador',
      KEY_KPI: 'Núcleo',
      NAME: 'Nome',
      NAME_FRENCH: 'Nome (francês)',
      CALCULATE_RATE: 'No cálculo do % de realização {{lastLevel}}',
      NAME_ENG: 'Nome (EInglês)',
      DEF: 'Definição / Descrição',
      UNIT: 'Unidade de medida',
      TYPE: 'Categoria',
      CLASS: 'Tipo',
      KEY: 'Indicadores (KPIs)',
      VAL_POSS: 'Valores Possíveis',
      FORMULA: {
        REQUIRED_ALL: 'Todos os indicadores da fórmula são obrigatórios',
        CLEAR: 'Apagar',
        INVALID: 'Fórmula Incorreta',
        NONEINDIC: 'Não há indicador na fórmula'
      },
      BEGIN: 'Data de Início',
      END: 'Data Final',
      FREQUENCE: 'Frequência de Coleta',
      NIV_GEO: 'Nível geográfico de Entrada',
      _NIV_GEO: 'Geografia',
      AGGREGA: 'Método de agregação geográfica',
      GROUP_CIBLE: 'Grupo Alvo',
      HYPOTHESE: 'Premissas',
      MOY_VERIF: 'Meios de verificação',
      TECH_COLL: 'Técnica de coleta',
      TARGET: 'Alvo Final (término do projeto)',
      REF: 'Linha de Base',
      TARGET_VAL: 'Alvos',
      REAL_VAL: 'Reais',
      VARIANCE: 'Variância',
      TRANS: 'Indicador de Corte Transversal',
      DISPLAY_TRANS: 'Só indicadores de corte transversal',
      SIMPL: 'Simples',
      COMPO: 'Complexo',
      CUMUL: 'Cumulativo',
      COMPL: 'Complexo',
      SUM: 'Soma',
      MOY: 'Média',
      MAXI: 'Máxima',
      MINI: 'Mínima',
      NONE: 'Nenhum',
      MOD_UN: 'Alterar a unidade resultará na perda de todos os registros._x000D_\nVocê quer continuar?',
      MOD_NG: 'Alterar o nível geográfico resultará na perda de todos os registros._x000D_\nVocê quer continuar?',
      MOD_DATE: 'Alterar as datas pode resultar na perda de vários registros._x000D_\nVocê quer continuar?',
      MOD_PER: 'Alterar a periodicidade pode resultar na perda de vários registros._x000D_\nVocê quer continuar?',
      MOD_CLS: 'Alterar a fonte de dados resultará na perda de todos os registros._x000D_\nVocê quer continuar?',
      LABEL_VP: 'Rótulo',
      POUND: 'Ponderação',
      PLAN: 'Planejamento de Indicadores',
      PLAN_: {
        TITLE: 'Definir Alvos',
        VALUE: 'Alvo'
      },
      TRACK_: {
        ACTUAL_DATE: 'Data atual',
        VALUE: 'Insira o valor real',
        TITLE: 'Indicador Real'
      },
      DATE_R: 'Data Planejada',
      VALUE: 'Valor',
      BUDGET: 'Orçamento',
      COST: 'Custo',
      REL: 'Coleta de Dados do Indicador',
      RAPP: 'Relatório de Indicador',
      PIC: 'Imagem',
      IN_MON: 'Monitoramento de Indicador',
      MON: 'Monitor',
      DATA_FIN: 'Dados Financeiros',
      DATA_FIN_: 'Dados Financeiros',
      FOLLOW_FIN: 'Acompanhar Orçamento/Custo',
      DISPLAY_CODE_LOC: 'Exibir códigos geográficos',
      COMMENTS: 'Comentários',
      RESPO: 'Entidade Responsável',
      DISPLAY_PL: 'Mostrar',
      ACHEVE: '% Atingido',
      SOCIO_ECO: 'Socio-econômico',
      SPATIAL: 'Espacial',
      GRAPHE_COMBINE: 'Gráficos Mistos',
      CONFIRM_DELETE_RELEVE: 'Deseja excluir esses dados?',
      CONFIRM_DELETE_PLAN: 'Deseja excluir este planejamento?',
      CONFIRM_DELETE: 'Deseja excluir este indicador?',
      CONFIRM_DELETE_CROSS: 'Remover o indicador irá deletar dados em projetos',
      DATE_ALERT: 'Data do alerta',
      ERRORS: {
        VP_LABEL: 'O nome já está atribuído',
        VP_VALUE: 'A ponderação já está atribuída'
      },
      GLOBAL: {
        TITLE: 'Linhas de Base dos Indicadores e Alvos Finais',
        TITLE_: 'Valores-Alvo e reais',
        LOCATION: 'Localização',
        BEGIN: 'Data de início',
        END: 'Data final',
        TARGET: 'Meta Final (Término do Projeto)',
        REF: 'Valor de referência',
        COMMENTS: 'Comentários',
        ERROR: {
          REF_VALUE_DESEGREGATE: 'A soma dos valores de referência desagregados não é igual ao valor de referência',
          TARGET_VALUE_DESEGREGATE: 'A soma dos valores alvo desagregados não é igual ao valor alvo',
          DATE: 'A data final deve ser depois da data inicial',
          Target_Value: 'Por favor, insira o alvo!',
          REF_VALUE: 'Por favor, insira o valor de referência!',
          DATE_D: 'Por favor, insira a data de início!',
          DATE_F: 'Por favor, insira a data de término!'
        }
      },
      FROM_ENTRY_DATA: 'Entrada direta',
      FROM_CALCULATE_DATA: 'Calcular a partir de uma fórmula',
      FROM_DATABASE: 'Preencher a partir de um arquivo de dados',
      OVERVIEW_MENU: {
        SETTINGS: 'Configurações do Indicador',
        BASELINE: 'Definir Valores de referência',
        TARGET: 'Fixar Objetivos',
        ACTUAL: 'Montoramentos Reais',
        REPORT: 'Relatórios'
      },
      MENU: {
        EDIT: 'Editar ',
        PLAN: 'Valores alvo',
        RELE: 'Valores registrados',
        FICHE: 'Folha de Referência do Indicador',
        IMAGE: 'Imagem (300x400)',
        RIGHT: 'Definir Permissões',
        GLOB_RELE: 'Valores Alvo Inicial e Final',
        RAPPORT: 'Nota',
        SPATIAL: 'Mapa',
        LEGEND: 'Lenda',
        WORKFLOW: 'Fluxo de Trabalho',
        CUSTOM_REPORT: 'Relatórios Personalizados'
      },
      RIGHT: {
        TITLE: 'Definir as Permissões do usuário',
        BASELINE: 'Linhas de Base e Metas Finais',
        TARGET: 'Valores alvo',
        ACTUAL: 'Valores registrados'
      },
      PLAN_TARGET: {
        TITLE: 'Alvos Indicadores'
      },
      TRACK: {
        TITLE: 'Rastreamento de Indicadores'
      },
      DASHBOARD: {
        TAB: 'Relatório de Indicadores',
        G: 'Projeto Total',
        Y: 'Ano',
        P: 'Período',
        TIMESCALE: 'Escala de tempo',
        DIFFERENCE: 'Diferença',
        FINAL_TARGET: 'Alvo Final do Projeto',
        BASELINE: 'Valor de referência',
        TARGETS: 'Alvos',
        ACTUALS: 'Reais',
        RATE_TARGET: '% do Alvo',
        MAP: 'Mapa',
        TITLE2: '% de Alvos por',
        TITLE2_: '% de Alvos por projetos',
        TITLE1_1: 'Reais vs Alvos',
        TITLE1_2: 'Progresso do Indicador por ano',
        LEGEND: '% de Alvos',
        TITLE: 'Relatório de Indicador',
        PAGE1TITLE: 'Desempenho (Reais x Alvos)',
        PAGE2TITLE: 'Progresso do Indicador',
        PAGE2SUBTITLE1: 'Mudar para Indicador',
        PAGE2SUBTITLE2: 'Progresso do Indicador para',
        TITLE_1_3: 'Reais vs Alvos por',
        TITLE_1_3_: 'Reais x Alvos por Projetos'
      },
      SHEET: {
        SHEET: 'Folha',
        TITLE: 'Folha de Referência do Indicador',
        NAME: 'Nome do Indicador',
        DESCRP: 'Descrição',
        TYPE: 'Categoria',
        RAT_CL: 'Link para o Quadro de Registro',
        U_MES: 'Unidade de medida',
        PER_COL_SUI: 'Frequência de relatórios',
        NG: 'Nível Geográfico',
        RESPO: 'Responsável(is) Individual(is)',
        TECH: 'Técnica de coleta',
        SRC: 'Fonte de dados',
        FORMULA: 'Fórmula de Cálculo',
        VAL_REF: 'Valor de referência',
        VAL: 'Valor',
        EVO: 'Mudar para Indicador',
        FINAL_TARGET: 'Meta final (Final do Projeto)',
        BASELINE: 'Valor de referência',
        TARGETS: 'Alvos',
        ACTUALS: 'Reais'
      },
      SPATIALS: {
        DATA: 'Camadas de Dados',
        LAYERS: 'Camadas',
        TITLE: 'Configurações de camada',
        LAYER: 'Camada',
        LABEL: 'Rótulo',
        PERIODIC: 'Periódico',
        URL: 'URL',
        PROPERTY: 'Modelo',
        DATA_LAYER: 'Camada de Dados',
        PIN: 'Marcador',
        SERVICE: 'Serviço',
        SERVER_URL: 'Fonte de dados (URL do servidor)',
        LAYERS_ID: 'ID da camada',
        ADD_PARAMS: 'Parâmetros Adicionais',
        AUTHORIZATION: 'Autenticação',
        AUTHORIZATION_TOKEN: 'Token',
        DISPLAY_POPUP: 'Exibir informações'
      },
      USER: 'Dados inseridos por'
    },
    INDICATOR_VISUALISATION: {
      CATEGORIES: 'Categoria',
      DESEGREGATE_VALUE: 'Mostrar valores desagregados',
      CIRCLE: 'Círculo',
      DELTA: 'Camada Padrão',
      DATA: 'Dados',
      LAYERS: 'Camadas',
      LEGEND: 'Lenda',
      FORMATTING: 'Formatação',
      BRIGHTNESS: 'Brilho',
      INDICATOR: 'Indicador',
      VALUE: 'Valor',
      YEAR: 'Ano',
      PERIOD: 'Período',
      TITLE: 'Visualização Espacial',
      SELECT_INDICATOR: 'Selecione um Indicador',
      DISPLAY_DATA: 'Mostrar dados',
      SELECT_GEO: 'Selecione um nível geográfico',
      SELECT_LAYER: 'Selecione Camadas',
      COLLECTED_DATA: 'Dados Coletados',
      ERROR_INTERVAL: 'Erro nos intervalos'
    },
    LOGICAL_PLAN: {
      CROSS_CUTTING_KPIS: 'Exibir indicadores transversais',
      RATE_INDIC_HELP: 'Ao marcar esta opção, as atividades serão monitoradas apenas por meio dos indicadores do módulo Indicador do DELTA.',
      ADD: 'Adicionar',
      HAS_INDIC: 'Atribuir Indicador',
      LABEL: 'Nome',
      LONG: 'comprimento',
      ERROR_UPDATE_LONG: 'Não é possível atualizar o comprimento.',
      CONFIRM_DELETE_LEVEL: 'Deseja excluir este nível?',
      CONFIRM_DELETE: 'Deseja excluir esses dados?',
      CODE: 'Código',
      DESCRP: 'Descrição',
      LABEL_C: 'Nome',
      LABEL_L: 'Descrição',
      IMPORT: 'Importar Quadro de Registro',
      WEIGHT_AUTO: 'Peso Automático',
      BUDGET: 'Orçamento',
      COST: 'Custo real',
      SUM_WEIGHT: 'Σ Peso',
      BY: 'Baseado em',
      WEIGHT: 'Peso',
      RATE_INDIC: 'Progresso da atividade medido por KPIs',
      ERROR_STRUC: 'Um erro foi detectado na estrutura do arquivo',
      ERROR_FORMAT: 'Por favor, use um arquivo *.xsl!',
      ERROR_CANADD: 'Elementos são usados!',
      UPLOAD_LABEL: 'Nível {{level}} ...',
      HELP: {
        CODE: 'Insira còdigo {{LIBELLE_NCL}} ',
        LABEL_C: 'Insira {{LIBELLE_NCL}}',
        LABEL_L: 'Descrição {{LIBELLE_NCL}}'
      },
      ERROR: {
        DELETE_ACTIVITY: 'A atividade tem dados e não pode ser excluída!',
        DELETE: 'O elemento tem dados e não pode ser excluído!',
        DELETE_INDICATOR: 'Pelo menos um indicador está vinculado ao elemento!'
      }
    },
    FLAG: {
      LABEL: 'Legenda de cores',
      MIN_VALUE_INDICATOR: 'Indicador de valor maior ou igual a',
      MIN: 'Mín.',
      MAX: 'Max.',
      COLOR: 'Cor',
      MIN_VALUE: 'Valor maior ou igual a',
      ERROR: {
        LABEL: 'A etiqueta já existe',
        CHEVAL: 'O intervalo está se sobrepondo a outro',
        IN: 'O intervalo está incluído em outro',
        OUT: 'O intervalo inclui outro'
      }
    },
    FRAME_RESULT: {

      DISPLAY_NO_CUMUL_DATA: 'Exibir valores não cumulativos',
      TITLE_2: 'Estrutura de resultados (status atual)',
      TITLE_1: 'Estrutura de resultados (progresso anual)',
      FINAL_TARGET: 'Valor Alvo Final',
      OPEN_CHART: 'Mostrar gráfico',
      DETAILS: 'Mostra os detalhes',
      ACTUAL: 'Atual',
      VALUE: 'valor',
      DATE: 'Data',
      ALL_LOCATION: 'Todos os locais',
      ALL_PROJECT: 'Todos os projetos',
      COMMENT: 'Comentário ',
      FORMAT: 'Formato Padrão',
      INDIC: 'Indicador',
      T_I: 'Filtro',
      IMPACT: 'Impacto',
      TARGET: 'Alvo',
      REF: 'Referência',
      UNIT: 'Unidade',
      TREEVIEW: 'Visualização de Árvore',
      LISTVIEW: 'Quadro de Resultados de Indicadores',
      ONLY_CROSS: 'Somente corte transversal',
      DISPLAY_LEVEL: 'Níveis de Exibição',
      DISPLAY: 'Mostrar'
    },
    ACTIVITY: {
      UPLOAD_INDEX: 'Link de software de contabilidade',
      ENDING_ACTIVITY: 'Esta atividade acabou',
      NAME_ACT: 'Descrição',
      LINK_PL: 'Link do framework lógico',
      SETTINGS: 'Configurações',
      YTD: 'Total',
      UNIT_: 'Unidade',

      ONLY_CROSS: 'Cruz',
      ONLY_PLAN: 'Planejado',
      ONLY_FINANCIAL: 'Orçamento',
      COMPLETED_ACTIVITIES: 'Concluído',
      ON_GOING_ACTIVITIES: 'Em andamento',
      NOT_CARRIED_OUT_ACTIVITIES: 'Não iniciado',
      REVIEW_PLAN: 'Plano revisado',

      BUDGET_BREAKDOWN: 'Repartição do Orçamento',
      TREEVIEW: 'Visualização de Árvore',
      DISPLAY_LEVEL: 'Níveis de Exibição',
      ENDING: 'Concluído',
      DISPLAY: 'Mostrar',
      ACTIVITIES: 'Atividades',
      ADD: 'Adicionar',
      DBL_PERIOD: 'Clique duas vezes para períodos',
      UPLOAD: {
        USE_BUDGET_BREAKDOWN: 'Com detalhamento do orçamento',
        ACTIVITY: 'Atividade',
        LOCALITY: 'Localidade',
        DATE: 'Data',
        AMOUNT: 'Montante',
        CATEGORYBUDGET: 'Categoria de Orçamento',
        FINANCING: 'Financiamento'
      },
      DISTRIBUTE: {
        CATEGORY: 'Categoria',
        FINANCE: 'Fonte de Financiamento',
        AMOUNT: 'Montante',
        TITLE: 'Distribuir quantidade',
        SUBTITLE: 'Quantidade a distribuir',
        SELECT_CATEGORY: 'Selecione uma categoria',
        SELECT_FINANCE: 'Selecione uma fonte de financiamento',
        ERROR_FINANCE: 'O total deve ser igual ao valor a distribuir'
      },
      FROM: {
        FROM: 'De',
        DOCUMENT: 'Biblioteca de Documentos',
        LOCAL: 'Unidade local',
        CLOUD: 'Nuvem'
      },
      CROSS_ACTIVITY: 'Atividade de Corte transversal',
      DISPLAY_CROSS_ACTIVITY: 'Só Atividades de Corte transversal',
      OUTPUT: 'Saídas',
      RATE: 'Percentagem',
      FUNDING_SOURCE: 'Fonte de Financiamento',
      PROJECT: 'Projeto',
      AGENCIES: 'Agências de Implementação',
      RELATED_INDICATOR: 'Indicadores',
      SEARCH: 'Pesquisar uma Atividade',
      URL: 'link Externo',
      DOCUMENT: 'Documento',
      DOCUMENTS: 'Documentos',
      ERROR_DOCUMENT: 'Por favor preencha todos os campos.',
      NAME: 'Nome do Arquivo',
      START: 'Início',
      END: 'Fim',
      UNIT: 'Unidade de medida',
      TYPE: 'Categoria',
      DISPLAY_UNIT_COST: 'Mostrar custo unitário',
      UNIT_COST: 'Custo unitário',
      CAL_AUTO: 'Cálculo Automático',
      BUDGET: 'Orçamento',
      COST_A: 'Custo Real',
      RESPO: 'Entidade Responsável',
      COMMENT: 'Comentários',
      IMPUT: 'Entradas',
      TOTAL: 'Total',
      CONT_LOC: 'Seguir contrato por local',
      CONT_UNK: 'Somente Contrato',
      NUM: 'Número',
      OWN: 'Proprietário',
      OBJECT: 'Assunto',
      COST: 'Custo',
      SIGN: 'Assinatura',
      BEGIN_CONTRACT: 'Início',
      END_CONTRACT: 'Fim',
      BEGIN_D: 'Data de Início',
      END_D: 'Data Final',
      SELECT_ACT: 'Selecione um Acionista ',
      ACTOR_IMPL: 'Acionistas',
      UPLOAD_FINANCE: 'Importação de Orçamento/Custo',
      UPLOAD_FINANCE_CATEGORY: 'Importação de Desdobramento de Custo/Orçamento',
      DEFINITION: 'Detalhes',
      NOTE: 'Notas',
      ROLE: 'Função',
      TOTAL_ALERT: 'O total deve ser igual a 100s',
      STEP: 'Estágio',
      ERROR: {
        SOURCE: 'A porcentagem deve estar entre 0 e 100'
      },
      HELP: {
        CAL_AUTO: 'Se for marcado, o orçamento e/ou custo real serão calculados automaticamente com base na quantidade e custo unitário'
      },
      MENU: {
        PLAN: 'Planificar Atividade',
        EDIT: 'Editar',
        REAL: 'Monitorar Atividade',
        IMAGE: 'Imagem (300x400)',
        PERMISSIONS: 'Permissões',
        WORKFLOW: 'Fluxo de Trabalho'
      },
      WORKFLOW: {
        TITLE: 'Fluxo de Trabalho',
        LEVEL: 'Nível',
        NAME: 'Nome',
        COLOR: 'Cor',
        USER: 'Usuário',
        REAL: 'Monitorar Atividade',
        SELECT_USER: 'Selecionar Usuário'
      },
      PLAN: {
        PLANNING: 'Planejado',
        TO_REVIEW: 'Rever',
        ERROR_PERIOD_YEAR: 'Não há período definido para este Ano',
        CONFIRM_DELETE: 'Deseja excluir este planejamento?',
        TITLE: 'Planejamento',
        REVIEW: 'Revisado',
        INIT: 'Inicial',
        REVIEW_: 'Planejamento Revisado',
        INIT_: 'Planejamento Inicial',
        GLOBAL: 'Global',
        DURATION: 'Planejamento',
        TYPE: 'Tipo',
        YEAR: 'Ano',
        PERIOD: 'Período',
        PLANNED: 'Planejado',
        LOCATION: 'Localização',
        AMOUNT: 'Alvo',
        COST: 'Orçamento',
        BEGIN: 'Começar',
        END: 'Fim',
        AMOUNT_PLANNED: 'Planejado',
        COST_PLANNED: 'Orçamento',
        BEGIN_PLANNED: 'InícioPlanejado',
        END_PLANNED: 'FimPlanejado',
        ERROR_FINANCE: 'As porcentagens totais devem ser 100%',
        ERROR_SUP_QTE: 'A quantidade deve ser maior ou igual a {{QTE_ERROR}}',
        ERROR_INF_QTE: 'A quantidade deve ser menor ou igual a {{QTE_ERROR}}',
        ERROR_SUP_BUDGET: 'O orçamento deve ser maior ou igual a {{BUDGET_ERROR}}',
        ERROR_INF_BUDGET: 'O orçamento deve ser menor ou igual a {{BUDGET_ERROR}}',
        ERROR_PLAN_ANNUEL: 'O planejamento anual não foi definido',
        ERROR_DATE: 'A data de término deve ser posterior à data de início.',
        ERROR_QTE: 'A quantidade deve ser positiva',
        ERROR_BUDGET: 'O orçamento deve ser positivo',
        ERROR_COST: 'O custo deve ser positivo',
        ERROR_PERIOD: 'O período deve estar entre {{BEGIN}} e {{END}}',
        HELP: {
          AMOUNT: 'Insira a alvo planejado',
          COST: 'Insira o orçamento alocado'
        },
        SEARCH_LOCATION: 'Pesquise um local'
      },

      REAL: {
        ACTUAL: 'Real',
        TITLE: 'Rastreio',
        LOCATION: 'Localização',
        AMOUNT: 'Real',
        COST: 'Custo',
        BEGIN: 'Data de início',
        END: 'Data final',
        AMOUNT_ACTUAL: 'Real',
        COST_ACTUAL: 'Custo',
        BEGIN_ACTUAL: 'InícioReal',
        END_ACTUAL: 'FimReal',
        COMMENTS: 'Comentários',
        HELP: {
          AMOUNT: 'Insira o valor real',
          COST: 'Insira o Custo'
        }
      },
      DASHBOARD: {
        REFERENCE: 'Linha de Base',
        FINAL: 'Alvo Final',
        PROJECT: 'Projeto',
        YEAR: 'Ano',
        HELP: {
          SHARE: 'Compartilhar Painel'
        },
        RATE_TARGET: '% do Alvo',
        RATE_BUDGET: '% do Orçamento',
        DISPLAY_COST: 'Mostrar Custo/Orçamento ',
        ALL_LOC: 'Todos os Locais',
        ANY_LOC: 'Qualquer Local',
        BY_LOC: 'Por Local',
        TITLE: 'Relatório',
        RATE_PHY: '% do Alvo',
        RATE_FINANC: '% do Orçamento',
        TITLE2: '% de conclusão por local',
        TITLE2_: '% conclusão por projeto',
        TITLE1_1: 'Indicador de Monitoramento - Conquistas / Metas',
        TITLE1_2: 'Progresso do Indicador por ano',
        TITLE_GRAPHE_SECOND_PERIOD: '% de Alvos e Orçamentos atingidos pela Localização de',
        TITLE_GRAPHE_SECOND_YEAR: '% de Alvos e Orçamentos atingidos por Local - ano',
        TITLE_GRAPHE_SECOND_YEAR_PROJECT: '% de Alvos e Orçamentos atingidos pelo Projeto - ano',
        TITLE_GRAPHE_SECOND: 'Relatório de Atividade',
        TITLE_GRAPHE_SECOND_PROJECT: '% de Alvos e Orçamentos atingidos pelo Projeto',
        TITLE_GRAPHE_FIRST_YEAR: '% de Alvos e Orçamentos atingidos (ano {{year}}) Localização:',
        TITLE_GRAPHE_FIRST: '% de Alvos e Orçamentos atingidos por Local',
        TITLE_GRAPHE_FIRST_PROJECT: '% de Alvos e Orçamentos atingidos pelo Projeto'
      },
      AWPB: {
        TITLE: 'Plano de Trabalho Anual e Orçamento (AWPB)',
        TITLE_YEAR: 'Programa de Trabalho Multianual e Orçamento'
      }
    },
    CROSS_ACTIVITY: {
      CODE_ACTIVITY: 'Código',
      NAME_ACTIVITY: 'Name',
      DATE_BEGIN_ACTIVITY: 'Início',
      DATE_END_ACTIVITY: 'Fim',
      UNIT_COST_ACTIVITY: 'Costo Unitário',
      CAL_AUTO: 'Cálculo Automático',
      COMMENTS_ACTIVITY: 'Comentários'
    },
    PLAN_BUDGET_ACTIVITY: {
      ACTUAL_RATE: '% do Real',
      BUDGET_RATE: '% do Orçamento',
      STATUS_GRAPH: 'Gráfico de Status de Atividades',
      ACTUALS: 'Real',
      TARGETS: 'Alvo',
      ACTIVITY_PROGRESS: 'Progresso das {{activity}}'
    },
    GANTT: {
      TITLE: 'Gráfico de Gantt de Atividades',
      LIMIT: 'Limites do Cronograma',
      TYPE: 'Planejamento',
      START: 'Início',
      END: 'Fim',
      DISPLAY: 'Exibir barras de nível superior',
      LEGEND: 'Lenda',
      PLAN: 'Planejado',
      ACTUAL: 'Real',
      ASSIGN_TO: 'Selecionar ',
      ACTOR: 'Atribuído a',
      GEO: 'Geografia',
      ZOOM: 'Zoom',
      REAL: 'Atividade Real',
      ACTIVITY: 'Atividade',
      ACTUAL_BEGIN: 'Início',
      ACTUAL_END: 'Fim',
      PLAN_BEGIN: 'Planejado',
      PLAN_END: 'Fim',
      YEAR: 'Ano',
      MONTH: 'Mês',
      WEEK: 'Semana',
      TIMELINE: 'Exibir Linha de Tempo',
      ON_GOING_ACTIVITY: 'Atividades em andamento',
      DELAYED_ACTIVITY: 'Atividades atrasadas',
      NOT_STARTED_ACTIVITY: 'Atividades não iniciadas',
      COMPLETED_ACTIVITY: 'Atividades concluídas'
    },
    CONTRACT: {
      LOC: 'Localização',
      N_MAR: 'N° Contrato',
      PROGRESS: 'Progresso',
      STEP: 'Etapa'
    },
    PROCUREMENT: {
      CONFIRM_DELETE: 'Deseja excluir este contrato?',
      CODE: 'Código',
      LABEL: 'Nome'
    },
    REPORT_CAT: {
      LABEL: 'Categoria',
      PERIOD: 'Periodicidade',
      DELAI: 'Data limite',
      RECIPIENT: 'Destinatário principal',
      FORMAT: 'Modelo de Relatório',
      RESPO: 'Atribuído a',
      SELECT: 'Importar Modelo',
      CHANGE: 'Mudar ',
      REMOVE: 'Remover',
      CONFIRM_DELETE: 'Deseja excluir esta categoria de relatório?',
      PERSON_ALERT: 'Acionistas para alertar',
      HELP: {
        DELAI: 'Número de dias',
        LABEL: 'Insira a Categoria do Documento'
      }
    },
    DOCUMENT: {
      DELETE_FOLDER: 'Excluir pasta',
      DELETE_FILE: 'Excluir arquivo',
      SUCCESS_ADD_FOLDER: 'Pasta adicionada',
      DOCUMENT_DATA: 'Dados do documento',
      COVER: 'Capa',
      VIEW: 'Visualizações',
      NEWFOLDER: 'Criar nova pasta',
      DOWNLOAD: 'Transferências',
      NOT_VALID_EMAIL_OR_URL: 'Não é um e-mail ou URL válido',
      CONTACT: 'Contato (e-mail ou site)',
      LANGUAGE: 'Idióma',
      ADD_INFO: 'Selecione uma pasta para fazer upload de um documento',
      RIGHT_CLICK_INFO: 'Use o botão direito para adicionar ou editar o item',
      DBL_CLICK_INFO: 'Clique duas vezes para abrir o item',
      SEE_DETAILS: 'Veja detalhes',
      FILTERS: 'Filtros',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} Arquivos)',
      FORMAT: 'Formato do Documento',
      FORMATS: {
        TEXT: 'Texto',
        IMAGE: 'Imagem',
        VIDEO: 'Vídeo',
        AUDIO: 'Áudio',
        PDF: 'PDF'
      },
      FILTER: {
        PROPERTY: 'Campo',
        OPERAND: 'Condição',
        VALUE: 'Valor'
      },
      ACTIONS: {
        ADD_FOLDER: 'Nova Pasta',
        EDIT_FOLDER: 'Editar Pasta',
        REFRESH: 'Atualizar',
        ADD_FROM_FILE: 'Carregar um documento',
        ADD_FROM_LINK: 'Carregar de um link',
        ADD_EDIT_DOC: 'Escrever um documento'
      },
      FILE_NAME: 'Arquivo',
      REPORT: 'Conteúdo',
      INSERT_DELTA: 'Inserir item de DELTA',
      CLS: 'Pasta de Documentos',
      MAIN: 'Descrição do Documento',
      ADD_FILE: 'Nova Pasta',
      ADD_FILE_: 'Pasta',
      ADD_DOC: 'Carregar um documento ...',
      ADD_DOC_EXT: 'Enviar um link',
      ADD_DOC_RED: 'Escrever um documento',
      CODE: 'Código',
      DESCRIP: 'Descrição',
      CONFIRM_DELETE_FOLDER: 'Tem certeza de que deseja excluir esta pasta e todo o seu conteúdo?',
      CONFIRM_DELETE_THEME: 'Deseja excluir este tema?',
      CONFIRM_DELETE_TYPE: 'Deseja excluir este tipo?',
      CONFIRM_DELETE_PHYS: 'Deseja excluir este local?',
      CONFIRM_DELETE_FILE: 'Deseja excluir este documento?',
      RECIPIENT: 'Compartilhar Documentos',
      RECIPIENT_TOOLTIP: 'Compartilhar Documento com',
      REFERENCE: 'Referência',
      PUBLICATION: 'Data de Publicação',
      PUBLIC: 'Mostrar no portal',
      PUBLIC_HELP: 'Se esta opção for selecionada, o documento ficará acessível no portal público',
      TITLE: 'Título',
      TITLE_HELP: 'Insira o Título do Documento',
      URL_HELP: 'Insira o link do URL para o documento',
      AUTHOR: 'Autor(es)',
      DOC_TYPE: 'Categoria',
      DOC_TYPES: 'Categorias',
      THEME: 'Assunto',
      THEMES: 'Assuntos',
      PHYS_LOC: 'Localização',
      SUMMARY: 'Resumo',
      SUMMARY_HELP: 'Insira aqui um breve resumo do documento',
      LABEL: 'Rótulo',
      USERS: 'Usuários',
      UPLOAD: 'Carregar um documento',
      SELECT_DOC_TYPE: 'Selecione uma categoria de documento',
      SELECT_PHYS_LOC: 'Selecione um local',
      SELECT_THEME: 'Escolha um assunto'
    },
    COMMUNITIES: {
      CHATS: 'Conversar',
      SUCCESS_ADD: 'A comunidade foi adicionada',
      SUCCESS_UPDATE: 'A comunidade mudou',
      SUCCESS_DELETE: 'A comunidade foi excluída',
      IMAGE: 'Imagem',
      NAME: 'Nome',
      DESCRIPTION: 'Descrição',
      MEMBERS: 'Members',
      EDIT_COMMUNITY: 'Editar Comunidade',
      REQUIRED: 'Requeridos!',
      DOUBLE: 'Nome já atribuído!'
    },
    DOCUMENT_CALENDAR: {
      NEW_DOC: 'Adicionar um novo Documento',
      EXIST: 'Selecione na Biblioteca de Documentos',
      DOCUMENT: 'Documento',
      SELECT_FOLDER: 'Selecione uma Pasta para armazenar o documento'
    },
    PHOTOLIBRARY: {
      PHOTOS: 'Imagens',
      VIDEOS: 'Vídeos',
      REF: 'Referência',
      TITLE: 'Título',
      DATE: 'Data',
      PUBLIC: 'Exibir no portal',
      LOCATION: 'Localização',
      LAT: 'Latitude (GPS)',
      LONG: 'Longitude (GPS)',
      AUTHOR: 'Autor',
      SUMMARY: 'Resumo',
      URL_VIDEO: 'Vídeo URL (YouTube)',
      ADD_DOC: 'Adicionar Foto',
      ADD_DOC_: 'Foto',
      FOLDER: 'Pasta',
      ADD_FILE_: 'Pasta',
      ADD_FILE: 'Nova pasta',
      ADD_VIDEO: 'Adicionar vídeo',
      ADD_VIDEO_: 'Vídeo',
      CONFIRM_DELETE_PHOTO: 'Deseja excluir esta foto?',
      CONFIRM_DELETE_VIDEO: 'Deseja excluir este vídeo?',
      TITLE_ERROR_DELETE: 'A pasta não está vazia'
    },
    G_U: {
      RIGHT_INFO: 'Especifique o perfil do usuário de acordo com os projetos aos quais ele tem acesso',
      PASSWORD_CHANGED: 'A senha foi alterada',
      DISPLAY_DASHBOARD: 'Exibir painel',
      DISPLAY_MAP: 'Exibir mapa',
      FUNCTION: 'Função',
      STANDARD_USER: 'Usuário padrão',
      ADMIN_USER: 'Administrador',
      VIEW_USER: 'Visualizador',
      COLLECT_USER: 'Coleta de Dados',
      COLLECTER_USER: 'Coleta de Dados',
      CUSTOM_USER: 'Direitos Personalizados',
      NO_ACCESS: 'Sem Acesso',
      NO_ACCESS_PORTFOLIO: 'Sem acesso a projetos',
      PROJECT: 'Projeto',
      DETAIL: 'Detalhes do Usuario',
      PROFILE: 'Perfil de Usuário',
      EMAIL: 'E-mail',
      NAME: 'Apelido',
      SUP: 'Superusuário',
      PRENOM: 'Nome',
      AGENCIES: 'Agência de Implementação',
      MDP: 'Senha',
      CONF: 'Confirmar Senha',
      ACTOR: 'Acionistas',
      STAFF: 'Acionista',
      CHANGE_PASSWORD: 'Mudar senha',
      NEW_PASSWORD: 'Nova senha',
      SEARCH: 'Pesquisar por e-mail e apelido',
      SELECT_AGENCIES: 'Selecione uma agência de implementação',
      SELECT_STAFF: 'Selecione o Acionista',
      EXPIRE_HELP: 'Data de Expiração da Senha',
      EXPIRE: 'Data de Validade',
      ACTORLIST: 'Atribuir Acionistas',
      CONFIRM_DELETE: 'Deseja excluir este usuário?',
      ERROR_EMAIL: 'O usuário já existe',
      ERROR_PSW: '8 caracteres no mínimo',
      ERROR_CONF_PSW: 'As senhas nao são iguais',
      VALIDATION_RIGHT: 'Direitos de Validação',
      VALIDATION_RIGHT_SET: 'Definir Permissões de Validação de Dados',
      VALIDATION_RIGHT_TITLE: 'Selecione os usuários cujos dados são validados por {{users}}',
      PORTFOLIO_USER: 'Acesso ao portfólio',
      EDIT_PROFIL: 'Clique para detalhes',
      EDIT_PROFIL_USER: 'Conta de usuário',
      ERROR_PSW_NOT_CONFORM: 'Senha não corresponde',
      ERROR_PSW_NOT_EXIST: 'Não autorizar a atualização',
      ERROR__NOT_ALLOW: 'Não autorizar a atualização',
      SEX: 'Sexo',
      MALE: 'Masculino',
      FEMALE: 'Feminino',
      UNLOCK: 'Desbloquear',
      LOCK: 'Bloquear',
      HELP: {
        EMAIL: 'E-mail é usado para login'
      },
      FEEDBACK: 'Suas sugestões',
      FEEDBACK_ADD: 'Compartilhe suas sugestões',
      FEEDBACK_DETAIL: "Detalhes da sugestão"

    },
    GR_U: {
      HELP: {
        TITLE: 'Criar Grupo e atribuir geografia e quadro de registro aos membros do grupo',
        CODE: 'Digite o Nome do Grupo',
        LABEL: 'Insira a Descrição do Grupo'
      },
      CODE: 'Nome',
      LABEL: 'Descrição',
      CONFIRM_DELETE: 'Deseja excluir este grupo?',
      USER: 'Atribuir Usuários',
      LOCATION: 'Localizações',
      LOCATION_ONLY: 'Só localização',
      LOCATION_ALL: 'Sub-localizações',
      C_ONLY: 'Só ',
      C_ALL: 'Incluir subitens'
    },
    RIGHT: {
      HELP: {
        TITLE: 'Atribuir Permissões e Privilégios de Usuário de acordo com seus perfis',
        LABEL: 'Insira o nome do perfil',
        DESCRIPTION: 'Descrição'
      },
      USER: 'Usuários',
      ADMIN: 'Administrador',
      PRIVIL: 'Permissões',
      LABEL: 'Perfil',
      DESCRIPTION: 'Descrição',
      CONFIRM_DELETE: 'Deseja excluir este perfil?'
    },
    TYPACTIVITY: {
      LST_TYP: 'Tipos de Atividade',
      ED_TYP: 'Tipo de Detalhes de indicador',
      COL_COLOR: 'Cor',
      COLOR_BLUE: 'Azul',
      COLOR_CRYAN: 'Ciano',
      COLOR_GREEN: 'Verde',
      COLOR_YELOW: 'Amarelo',
      COLOR_ORANGE: 'Laranja',
      COLOR_RED: 'Vermelho',
      COLOR_PINK: 'Rosa',
      COLOR_PURPLE: 'Roxo',
      COLOR_GREY: 'Cinzento',
      COLOR_BLACK: 'Preto',
      CONFIRM_DELETE: 'Deseja excluir este tipo de atividade?'
    },
    FORMULAR: {
      SURVEY_DELETE: "Esmagar as convulsões",
      SURVEY_VERSION: "Versão",
      IMPORT_SURVEY: "Formulários de soluções de pesquisa",
      IMPORT_INTERVIEW: "Entrevista (s)",
      SURVEY_JETON: "Modifique o token",
      IMPORTED_INTERVIEW: "Entrevista (s) importada (s)",
      IMPORTE_DATA: "Importadora",
      SURVEY_JETON_LIB: "Soluções de pesquisa Jeton",
      SURVEY_SET_JETON: "Gerar um token?",
      SURVEY_USER: "nome de usuário",
      SURVEY_PASSE: "Senhas",
      SURVEY_GENERER: "Gerar",

      COLLECT_MODE_SURVEY: 'Importar dados das soluções de pesquisa',
      COLLECTORS: 'Colecionadores',
      LINK_GEO: 'Link para o plano geográfico',
      ERRORVIDEVAL: 'Por favor, insira a opção e o valor',
      ERRORVIDE: 'Por favor, digite a opção',
      ERROREXISTEVAL: 'Opção ou valor já existe',
      ERROREXISTE: 'Opção já existe',

      ARCHIVATE: 'Arquivo',
      KOBO_API_EDIT: 'Kobotools API Token',
      ADD_FROM_KOBO: 'Adicionar do Kobotool',
      KOBO_API: 'API Token',
      ERROR_KOBO_API: 'Token de API incorreto',
      ALREADY_SAVE: 'Sempre salvar',
      HOW_TO_FIND_TOKEN: 'Como faço para encontrar o token API?',
      ATUALIZAR: 'Atualizar dados',
      KOBO_FORM_LIST: 'Lista de projetos',
      FORM_SAVED: 'Formulário Salvo!',
      SUCCESS_IMPORT: 'Os dados foram importados!',
      FAIL_IMPORT: 'Falha na importação',
      CHART_STRUCTURE: 'Configurações',
      CHART_STRUCTURE_TITLE: 'Configurações do Gráfico',
      SURVEY: 'Pesquisa',
      ERROR: {
        NAME_REQUIRED: 'Nome é necessário',
        OPTION_REQUIRED: 'A opção é necessária',
        UNKNOWN_ERROR: 'Ocorreu um erro ao importar',
        KOBO_TOKEN_ERROR: 'O token da API não está correto'
      },
      SAVE: 'Salvar',
      SAVE_MSG: 'Registro salvo',
      DOWNLOAD_QR_CODE: 'Baixe o código QR',
      GENERATE_URL: 'Gerar URL para entrada externa',
      COPIED_URL: 'Copiado',
      COPY_URL: 'Cópia',
      COLLECTION: 'FORMULÁRIOS DE COBRANÇA',
      COLLECTIONS: 'FORMULÁRIOS DE COBRANÇA',
      DISPLAY_IMAGE: 'Mostrar imagem',
      ADD: 'Adicionar Formulário',
      LAYOUT: 'Deseño',
      STRUCTURE: 'Campos',
      ADD_SECTION: 'Nova seção',
      ADD_DATA: 'Adicionar Dados',
      COLOR: 'Cor',
      SEARCH: 'Pesquisar um Formulário',
      SEARCH_ANALYZE: 'Pesquisar uma análise',
      TITLE: 'Coleta de Dados - Design de Formulários',
      SELECT_TEMPLATE: 'Selecione um formulário',
      CONDITION: 'Condição',
      CONDITION_HELP: 'Definir condição para o campo',
      PRESET: 'Opções predefinidas',
      HELP_OPTION: 'Digite uma opção',
      TITLE_I: 'Nome do Formulario',
      PRIVATE: 'Privado',
      SINGLUAR: 'Um Item',
      MAIN: 'Único',
      PLURAL: 'Vários Itens',
      MULTILINE: 'Multi linha',
      LIMIT: 'Limite de Caracteres',
      FORMAT: 'Formato',
      LIST_OPTION: 'OPÇÕES DE LISTA',
      MULTI_CHOISE: 'Permitir seleção múltipla',
      CUSTOM_FIELD: 'Opções de lista',
      CUSTOM_DATA: 'Formulários',
      REQUIRED: 'Requerido',
      REQUIRED_NO_FIELD: 'Arraste um campo para esta área',
      COLUMN: 'Coluna',
      CONFIRM_STRUCTURE: 'Pelo menos um subformulário foi excluído.\nDeseja continuar? ',
      ERROR_GLOBAL: 'Alguns campos obrigatórios não foram preenchidos',
      USERS: 'Usuário',
      IMPORT_DATA: 'Importar Dados',
      COLLECT_DATA: 'Coleta e Revisão de Dados',
      PIN: 'Marcador',
      CHOISE_COLLECT_MODE: 'Selecione o modo de Coleta de Dados',
      COLLECT_MODE_INTERN: 'Mostrar Tela de Coleta de Dados',
      COLLECT_MODE_MOBILE: 'Colete Dados Via Dispositivo Móvel',
      COLLECT_MODE_LINK: 'Enviar link para Entrada de Dados Externos',
      COLLECT_MODE_EXCEL: 'Importar Dados do Arquivo Excel',
      FIELD: {
        EXCEL: 'Excel',
        PPTX: 'PowerPoint',
        TEXT: 'Texto',
        LIST: 'Lista',
        DATE: 'Data',
        NUMBER: 'Número',
        CHOISE: 'Escolha',
        FILE: 'Arquivo',
        PICTURE: 'Imagem',
        VIDEO: 'Vídeo',
        AUDIO: 'Áudio',
        LOCATION: 'Coordenadas GPS',
        FORMULAR: 'Subformulário',
        PATH: 'Trilha',
        SCORE: 'Ponto',
        OPTION: 'Opção',
        VALUE: 'Valor',
        POLYGON: 'Polígono'
      },
      MENU: {
        STRUCTURE: 'Estrutura',
        RECORD: 'Registro(s)',
        RIGHT: 'Permissões',
        EDIT: 'Editar Formulário',
        COLLECT: 'Coletar Dados',
        REVIEW: 'Revisar Datos',
        ANALYZE: 'Análise e Relatórios'
      },
      CONFIRM_DELETE: 'Deseja excluir este modelo?',
      CONFIRM_RECORD_DELETE: 'Deseja excluir este item?',
      OWN: 'Meus Modelos',
      ALL: 'Todos os Modelos',
      REQUEST: 'Analíticas',
      REQUEST_TITLE: 'Título',
      TEMPLATE: 'Formato',
      ANALYSE: {
        SAVE: 'Salvar',
        TABS: {
          TABLE_PIVOT: 'Tabela Dinâmica',
          TABLE_SIMPLE: 'Tabela',
          GRAPH: 'Gráfico',
          TABLE: 'Tabela',
          MAP: 'Mapa',
          PIE_CHART: 'Gráfico Circular',
          MIXED_CHART: 'Gráficos Mistos'
        },
        TABLE: {
          COLUMNS: 'Colunas'
        },
        MAP: {
          CATEGORY: 'Categoria',
          VALUE: 'Valor',
          FIELD: 'Campo',
          LEGEND: 'Lenda'
        },
        CHART: {
          FIELD: 'Campo',
          CATEGORY: 'Categoria',
          OPERATION: 'Agregação',
          NONE: 'Nenhuma',
          COUNT: 'Contains',
          VALUE: 'Valor',
          SUM: 'Soma',
          AVG: 'Média',
          MAX: 'Máximo',
          MIN: 'Mínimo',
          VARIANCE: 'Variância',
          TYPE: 'Tipo'
        },
        TYPE_CHART: {
          AREACHART: 'Gráfico de Área',
          BARCHART: 'Gráfico de Barras',
          COLUMNCHART: 'Gráfico de Barras',
          LINECHART: 'Gráfico de Lnha',
          PIECHART: 'Gráfico Circular'
        },
        LAYERS: {
          TITLE: 'Camadas',
          NAME: 'Nome',
          FIELD: 'Campo',
          COLOR: 'Cor'
        }
      }
    },
    ANALYSE: {
      BACKGROUND_COLOR_TITLE: 'Cor de fundo do título',
      COLOR_TITLE: 'Cor do título',
      SUCCESS_ADD: 'O relatório foi adicionado',
      FIX_VALUE: 'Valor Fixo',
      VARIABLE_VALUE: 'Valor Variável',
      VARIABLE_NAME: 'Nome Variável',
      VARIABLES: 'Variáveis',
      AS_PORTFOLIO: 'Portfólio',
      AS_PORTFOLIO_: 'Compartilhar com a carteira',
      COLLECTED_DATA: 'Dados coletados',
      SERIES: 'Series',
      SEARCH: 'Pesquisar um relatório',
      SWITCH: 'Definir variáveis na coluna',
      TYPE_REP: 'Representação de Tipo',
      TYPE_1: 'Tabela',
      TYPE_2: 'Gráfico',
      TYPE_3: 'Dados',
      DIM: 'Baseado em',
      DIM1: 'Linhas',
      DIM2: 'Exibir dados por',
      COLUMNS: 'Colunas',
      FIELD: 'Campo',
      INDICATOR: 'Indicador',
      DESCRIPTION: 'Descrição',
      SETTINGS: 'Configurações',
      FILTERS: 'Filtros',
      SELECT_ALL: 'Selecionar tudo',
      UNSELECT_ALL: 'Desmarcar Todos',
      FIELDS: {
        DATE: {
          BEGIN_PLAN: 'Início Planejado',
          END_PLAN: 'Fim Planejado',
          BEGIN_ACTUAL: 'Início Real',
          END_ACTUAL: 'Final Real',
          T: 'Conclusão'
        },
        PHYSIC: {
          P: 'Alvo',
          R: 'Real',
          E: 'Variância',
          T: '% do Alvo'
        },
        PHYSIC_INDIC: {
          P: 'Valor Alvo',
          R: 'Valor Atual'
        },
        BUDGET: {
          P: 'Orçamento',
          R: 'Custo real',
          E: 'Variação do orçamento',
          T: '% do Orçamento'
        }
      },
      MAIN: 'Parâmetros',
      PUBLIC: 'Público',
      AS_DASHBORD: 'Salvar nos Painéis',
      OPERATION: 'Operação',
      CONDITION: 'Filtros',
      PG: 'Localizações',
      TITLE: 'Título do Relatório',
      DESCRIPTION_REPORT: 'Descrição do Relatório',
      PERIODE: 'Período',
      FINANCING_SOURCE: 'Fonte de Financiamento',
      CAT_BUDGET: 'Categoria de Orçamento',
      RATE: 'Taxa',
      RUBRIQUE: 'Campo',
      SUBRUBRIQUE: 'Subcampo',
      VALEUR: 'Valor',
      PARAMETER: 'Parâmetro',
      DEFAULT_VALUE: 'Valor Padrão',
      ERROR_SYNTAXE: 'Erro de sintaxe',
      ERROR_COND_EXIST: 'Algumas condições não existem',
      ERROR_COND_NOT_EXIST: 'Nem todas as condições são usadas',
      LIST_COND: 'Adicionar Filtro',
      RUBOP: {
        EGAL: 'Igual',
        DIFF: 'Diferente',
        SUPP: 'Maior que',
        SUPP_EGAL: 'Maior ou Igual a',
        INF: 'Menor que',
        INF_EGAL: 'Menos que ou igual a',
        BEGIN: 'Começar com',
        NOT_BEGIN: 'Não comece com',
        END: 'Termina com',
        NOT_END: 'Não termine com',
        CONTENT: 'Contém',
        NOT_CONTENT: 'não contém',
        NULL: 'É nulo',
        NOT_NULL: 'não é nulo'
      },
      RUB: {
        ACTEUR: 'Entidade Responsável',
        LOCAL: 'Código de Localização',
        CAD_LOG: 'Código \'{{planlog}}\'',
        YEAR: 'Ano',
        FINANCING_SOURCE: 'Código Fonte de Financiamento',
        CAT_BUDGET: 'Categoria de Orçamento'
      },
      OPS: {
        MIN: 'Mínimo',
        MAX: 'Máximo',
        AVG: 'Média',
        SUM: 'Soma',
        NONE: 'Nenhum',
        COUNT: 'Contagem'
      },
      ENCH_COND: 'Filtros de encadeamento E / OU',
      CONFIRM_DELETE: 'Deseja excluir esta visualização?',
      TYPE: {
        TABLE: 'Tabela',
        DONUT: 'Gráfico Circular',
        COU_HISTO: 'Gráfico Misto',
        WIDGET: 'Ferramenta'
      },
      SERIE: 'Series',
      FILL: 'Preencher',
      TYPE_COU_HISTO: {
        LINE: 'Gráfico de Linha',
        BAR: 'Gráfico de Barras'
      }
    },
    CUSTOM_DASHBOARD: {
      DISPLAY_MAIN_PAGE: 'Exibir na página principal',
      SEARCH: 'Pesquisar em Painéis',
      ACTIVITIES: 'Atividades',
      INDICATORS: 'Indicadores',
      FORMS: 'Formulários',
      GLOBAL_TITLE: 'Painel de Controle',
      TITLE: 'Título',
      VIEWS: 'Selecione Analiticas',
      VIEWS_OPTION: 'Lista de Consultas',
      ANALYSE: 'Análise',
      HEIGHT: 'Altura',
      WIDTH: 'Largura',
      CONFIRM_DELETE: 'Deseja excluir este painel?',
      DISPLAY: 'Mostrar todos os painéis',
      OWN: 'Meus Painéis',
      FAVORITE: 'Meus Painéis Favoritos',
      OTHER: 'Outros'
    },
    AGENDA: {
      LIST: 'Listagem',
      MONTH: 'Mês',
      WEEK: 'Semana',
      DAY: 'Dia',
      TODAY: 'Hoje',
      TITLE: 'Título',
      DESCRIPTION: 'Descrição',
      BEGIN: 'Início',
      END: 'Fim',
      INFO: 'Tarefa / Evento',
      USER: 'Compartilhar com'
    },
    COMMENT: {
      NEW_COMMENTS: 'Novos posts',
      SUCCESS_ADD: 'O comentário foi adicionado',
      COMMENT: 'Comentários',
      SHARE: 'Compartilhar comentários',
      USER: 'Usuários',
      WRITE: 'Escreva comentário',
      FILE: 'Feed de notícias da equipe',
      TO_COMMENT: 'Comentário',
      CANCEL_TO_COMMENT: 'Cancelar',
      DISPLAY_COMMENT: 'Mostrar comentários',
      HIDE_COMMENT: 'Ocultar comentários',
      DISPLAY_MORE_COMMENT: 'Mostrar mais comentários'
    },
    WORKFLOW: {
      MENU: {
        ACTIVITIES: 'Atividades',
        INDICATOR: 'Indicadores',
        FORM: 'Formulários',
        DOCUMENTS: 'Documentos'
      },
      ACTIVITIES: {
        TRACK: 'Rastreio'
      },
      VALIDATE: 'Validar Dados',
      COMMENTS: 'Comentários'
    },
    DELETEDITEMS: {
      OBJECT: 'Item',
      PROJECT: 'Projeto',
      DATE: 'Data',
      REFRESH: 'Restaurar'
    },
    AUDITS: {
      USERS: 'Usuários',
      FILTER: 'Filtro',
      ACTIONS: 'Ações',
      DELETE: 'Excluir',
      UPDATE: 'Atualizar',
      CREATE: 'Criar ',
      TO_DATE: 'Para',
      FROM_DATE: 'De',
      PAGING_SUMMERY: '{{current}} de {{total}} páginas ({{count}} itens)',
      ACTION: 'Açao',
      ENTITY: 'Entidade',
      DESCRIPTION: 'Descrição',
      USER: 'Usuário',
      DATE: 'Data',
      ENTITIES: {
        TYPE_MISSION: 'Tipo de Missão',
        MISSION: 'missão',
        DISBURSEMENT: 'Desembolso',
        AGREEMENT: 'Financiamento',
        AGENCIES: 'Agência',
        PROJET: 'Projeto',
        UNITES: 'Unidade',
        ACTEUR: 'Acionista',
        FINANCEMENT: 'Fonte de Financiamento',
        NIVEAUGEO: 'Nível (Geográfico)',
        CHAMPSPERSO: 'Campo Customizado',
        LOCALITE: 'Localidade',
        NIVEAUCL: 'Nível (Lógica)',
        CADRELOGIQUE: 'Quadro de Registro',
        TYPEACTIVITY: 'Categoria da Atividade',
        DERNIERNIVEAU: 'Atividade',
        TYPEINDICATEUR: 'Categoria dos KPIs',
        INDICATEURSCATEGORIE: 'Categoria de Desagregação',
        INDICATEURSCATEGORIEOPTION: 'Opção (Categoria de Desagregação)',
        INDICATEURS: 'Indicador',
        ANALYSE: 'Relatório Personalizado',
        DASHBOARD: 'Painel de Controle',
        TYPERAPPORT: 'Categoria de Documento',
        CALENDARRAPPORT: 'Relatório',
        CLASSEUR: 'Pasta',
        RAPPORTS: 'Documento',
        CLASSEURPHOTOTHEQUE: 'Pasta (Biblioteca de Mídia)',
        PHOTOTHEQUE: 'Biblioteca de Mídia',
        FORMULAR: 'Formulário',
        RECORDS: 'Registro (Formulário)',
        FORMLARREQUEST: 'Consulta (Formulário)',
        USER: 'Usuário',
        GROUPUSER: 'Grupo de Usuários',
        PROFILUSER: 'Perfil de Usuários',
        CATEGORYBUDGET: 'Categoria de Orçamento',
        DISTRIBUTEAMOUNT: 'Ventilação',
        GLOBAL_PLAN: 'Planejamento (Global)',
        ANNUAL_PLAN: 'Planejamento (Anual)',
        PERIODIC_PLAN: 'Planejamento (Período)',
        REALIZE: 'Acompanhamento de Atividades'
      }
    },
    SECURITY: {
      ATTEMPTS: 'Tentativas sucessivas com falha',
      TIMEOUT: 'Tempo esgotado'
    },
    MISSIONS: {
      TITLE_MISSION: 'Missão',
      SELECT_TYPE: 'Selecione um tipo de missão',
      LIEU_MISSION: 'Localização',
      OBJECT_MISSION: 'Objeto',
      MEMBERS: 'Membros',
      SELECT_MEMBERS: 'Selecione os membros da missão',
      MISSION: 'Missão',
      TYPE: 'Modelo',
      DATE_BEGIN: 'Data de início',
      DATE_END: 'Data final',
      RESPONSIBLE: 'Responsável',
      REPORT: 'Relatório',
      NO_REPORT: 'Nenhum documento associado'
    },
    REQUEST: {
      DATE_COURRIER: 'Data da solicitação',
      COMMENTAIRE: 'Comentários',
      DOCS_SUPPORT: 'Anotações de apoio',
      STATUT: 'Status da solicitação',
      DATE_STATUT: 'Data do status',
      DATE_ECHEANCE: 'Prazo',
      BUDGET: 'Orçamento',
      MONTANTDMD: 'Valor solicitado (FCFA)',
      SITUATION_REQUETE: 'Situação da solicitação',
      DATE: 'Data',
      RESPONSABLE: 'Responsável',
      ECHEANCE: 'Prazo',
      PJ: 'Anexo',
      OBJET: 'Objeto da solicitação',
      // MONTANTDMD: 'Valor solicitado',
      PERSCONTACTS: 'Pessoas para alertar',
      ADDRQTE: 'Adicionar solicitação',
      ADDSUIVIRQTE: 'Adicionar acompanhamento da solicitação',
      HISTORQ: 'Histórico de acompanhamento'
    },
    RISK: {
      CRITICALITY: {
        TITLE: 'Criticamente',
        HIGH: 'Alto',
        MEDIUM: 'Médio',
        WEAK: 'Fraco'
      },
      DELAY: 'Duração (dias)',
      TYPE: 'Modelo',
      RISK: 'Risco',
      PROBLEM: 'Problema',
      CURRENT_POSITION: 'Salvar posição atual',
      CURRENT_POSITION_MARK: 'Mover marcador',
      CURRENT_POSITION_MARK_: 'no mapa para recuperar a posição',
      NATURE_RISK: 'Natureza',
      DESCRIPTION_: 'Descrição of',
      DESCRIPTION: 'Descrição',
      IMPACT_FINANCE: 'Impacto financeiro',
      IMPACT_DELAY: 'Atraso de Impacto',
      TITLE_RISK: 'Risco/Problema',
      FINANCE: 'Financeiro',
      DELAY_: 'Atraso',
      RESOLVE_DATE_RISK: 'Data de resolução',
      IMPACT: 'Impacto',

      PROBABILITY: 'Probabilidade',
      DATE: 'Datado',
      STATUS: 'Status',
      RECOMMANDATIONS: 'Recomendações',
      IMPACTS: {
        HIGH: 'Alto',
        MEDIUM: 'Médio',
        LOW: 'Baixo'
      },
      STATUS_LIST: {
        UNRESOLVED: 'Não resolvido',
        IN_PROGRESS: 'Em andamento',
        RESOLVED: 'Resolvido'

      }

    },
    PROBLEM: {
      PROBLEM: 'Problemas encontrados',
      DATE_PROBLEM: 'Data',
      NATURE_PROBLEM: 'Natureza',
      DESCRIPTION: 'Descrição',
      IMPACT_DELAY: 'Delay impacto',
      IMPACT_FINANCE: 'Impacto financeiro',
      RESPONSIBLE: 'Reportado por',
      RECOMMANDATIONS: 'Recomendações'
    },

    RECOMMENDATION: {
      DESCRIPTION: 'Recomendação',
      RESPONSIBLE: 'Responsável',
      DEADLINE: 'Prazo final',
      STATUS: 'Status',
      STATUS_LIST: {
        EXECUTED: 'Alcançou',
        NOT_EXECUTED: 'Não executado',
        IN_PROGRESS: 'Em andamento'
      }
    },
    EXPORT_DATA: {
      TYPES: 'Tipos de dados',
      EXPORT: 'Exportação de dados',
      LOADED: 'Dados gerados',
      SHARE: 'Compartilhar com...'
    },
    FEEDBACK: {
      EMPTY_FEEDBACK: 'Não há sugestões',
      NOTE_LEVEL: 'Qual é o seu nível de satisfação?',
      LAST_UPDATE_DATE: 'Última atualização em {{date}}',
      SEARCH_FEEDBACK: 'Pesquisar por assunto ou mensagem',
      SEND: 'Enviar',
      BACK: 'Voltar',
      STATUS_SEND: 'Status de envio',
      FORM: {
        OBJECT_PLACEHOLDER: 'Assunto',
        MESSAGE_PLACEHOLDER: 'Mensagem',
        ERROR_SEND_FEEDBACK: 'Erro ao enviar, por favor tente novamente',
        SUCCESS_SEND_FEEDBACK: 'Sua sugestão foi enviada com sucesso'
      }
    }
  });

  // Appeler la fonction pour récupérer les traductions
  var CODE_LANG = (cov_26hbl2eucb.s[2]++, 'pt');
  var CODE_LANG_STORE = (cov_26hbl2eucb.s[3]++, 'TRANSLATE_' + CODE_LANG);
  var CHECK_TRANSLATE = (cov_26hbl2eucb.s[4]++, 'DELTA_TRANSLATE');

  var translated = (cov_26hbl2eucb.s[5]++, false);
  // Translate not active use data origine
  cov_26hbl2eucb.s[6]++;
  if ((cov_26hbl2eucb.b[1][0]++, localStorage.getItem(CHECK_TRANSLATE)) && (cov_26hbl2eucb.b[1][1]++, localStorage.getItem(CHECK_TRANSLATE) == 0)) {
    cov_26hbl2eucb.b[0][0]++;
    cov_26hbl2eucb.s[7]++;

    translated = true;
    cov_26hbl2eucb.s[8]++;
    console.log('TRANSLATE ORIGNINALE ' + CODE_LANG);
    cov_26hbl2eucb.s[9]++;
    fetchTranslations(data, false);
  } else {
    cov_26hbl2eucb.b[0][1]++;
  }

  cov_26hbl2eucb.s[10]++;
  if (!translated) {
    cov_26hbl2eucb.b[2][0]++;
    cov_26hbl2eucb.s[11]++;

    // Translate activated
    if ((cov_26hbl2eucb.b[4][0]++, !localStorage.getItem(CODE_LANG_STORE)) || (cov_26hbl2eucb.b[4][1]++, angular.isUndefined(localStorage.getItem(CODE_LANG_STORE))) || (cov_26hbl2eucb.b[4][2]++, localStorage.getItem(CODE_LANG_STORE) == '')) {
      cov_26hbl2eucb.b[3][0]++;


      var params = (cov_26hbl2eucb.s[12]++, {
        action: "getTranslateDataByLang",
        li_bksb: "Connexion",
        lang_bksb: CODE_LANG,
        method: 1,
        t_bksb: 'bk' + new Date().getTime() + 'sb',
        n: window.location.search.substr(1),
        space: (cov_26hbl2eucb.b[5][0]++, localStorage.getItem('DELTA_TOKEN')) || (cov_26hbl2eucb.b[5][1]++, null),
        data: JSON.stringify(data)
      });
      var URL = (cov_26hbl2eucb.s[13]++, "/app/data/web_server.php");
      // Convertir l'objet params en une chaîne JSON
      var CONTENT = (cov_26hbl2eucb.s[14]++, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      });

      // Effectuer la requête POST
      cov_26hbl2eucb.s[15]++;
      fetch(URL, CONTENT).then(function (response) {
        cov_26hbl2eucb.f[1]++;
        cov_26hbl2eucb.s[16]++;

        // console.log('response 1');
        // console.log(response);
        if (response.ok) {
          cov_26hbl2eucb.b[6][0]++;
          cov_26hbl2eucb.s[17]++;

          return response.json();
        } else {
          cov_26hbl2eucb.b[6][1]++;
        }
        cov_26hbl2eucb.s[18]++;
        console.log('Erreur HTTP : ' + response.status);
        cov_26hbl2eucb.s[19]++;
        return { error_state: true };
      }).then(function (dataReponse) {
        cov_26hbl2eucb.f[2]++;

        var TAILLE_ORIGINE = (cov_26hbl2eucb.s[20]++, Object.keys(data).length);
        var TAILLE_NEW = (cov_26hbl2eucb.s[21]++, Object.keys(dataReponse).length);
        cov_26hbl2eucb.s[22]++;
        if ((cov_26hbl2eucb.b[8][0]++, dataReponse) && (cov_26hbl2eucb.b[8][1]++, dataReponse.COUNTRIES) && (cov_26hbl2eucb.b[8][2]++, angular.isUndefined(dataReponse.error_state)) && (cov_26hbl2eucb.b[8][3]++, TAILLE_NEW >= TAILLE_ORIGINE - 10)) {
          cov_26hbl2eucb.b[7][0]++;
          cov_26hbl2eucb.s[23]++;

          console.log('(200) - TRANSLATE OK  ' + CODE_LANG + ': (' + TAILLE_NEW + ' - vs - ' + TAILLE_ORIGINE + ')');
          cov_26hbl2eucb.s[24]++;
          fetchTranslations(dataReponse, true);
        } else {
          cov_26hbl2eucb.b[7][1]++;
          cov_26hbl2eucb.s[25]++;

          console.log('(200 & ERROR)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
          cov_26hbl2eucb.s[26]++;
          if ((cov_26hbl2eucb.b[10][0]++, dataReponse.error_state) && (cov_26hbl2eucb.b[10][1]++, dataReponse.edit_language) && (cov_26hbl2eucb.b[10][2]++, dataReponse.edit_language == 0)) {
            cov_26hbl2eucb.b[9][0]++;
            cov_26hbl2eucb.s[27]++;

            console.log('edit_language: ' + dataReponse.edit_language);
            cov_26hbl2eucb.s[28]++;
            localStorage.setItem(CHECK_TRANSLATE, dataReponse.edit_language);
          } else {
            cov_26hbl2eucb.b[9][1]++;
          }
          cov_26hbl2eucb.s[29]++;
          fetchTranslations(data, false);
        }
      }).catch(function (error) {
        cov_26hbl2eucb.f[3]++;
        cov_26hbl2eucb.s[30]++;

        console.log('(500)-TRANSLATE DEFAULT ORIGINE' + CODE_LANG);
        cov_26hbl2eucb.s[31]++;
        fetchTranslations(data, false);
      });
    } else {
      cov_26hbl2eucb.b[3][1]++;
      cov_26hbl2eucb.s[32]++;

      console.log('TRANSLATE LOCALSTORAGE ' + CODE_LANG);
      cov_26hbl2eucb.s[33]++;
      fetchTranslations(JSON.parse(localStorage.getItem(CODE_LANG_STORE)), false);
    }
  } else {
    cov_26hbl2eucb.b[2][1]++;
  }

  function fetchTranslations(dataLang, save) {
    cov_26hbl2eucb.f[4]++;
    cov_26hbl2eucb.s[34]++;

    $translateProvider.translations(CODE_LANG, dataLang);
    cov_26hbl2eucb.s[35]++;
    if (save) {
      cov_26hbl2eucb.b[11][0]++;
      cov_26hbl2eucb.s[36]++;

      if (localStorage.getItem(CHECK_TRANSLATE)) {
        cov_26hbl2eucb.b[12][0]++;
        cov_26hbl2eucb.s[37]++;

        localStorage.removeItem(CHECK_TRANSLATE);
      } else {
        cov_26hbl2eucb.b[12][1]++;
      }
      cov_26hbl2eucb.s[38]++;
      if (localStorage.getItem(CODE_LANG_STORE)) {
        cov_26hbl2eucb.b[13][0]++;
        cov_26hbl2eucb.s[39]++;

        localStorage.removeItem(CODE_LANG_STORE);
      } else {
        cov_26hbl2eucb.b[13][1]++;
      }
      cov_26hbl2eucb.s[40]++;
      localStorage.setItem(CODE_LANG_STORE, JSON.stringify(dataLang));
    } else {
      cov_26hbl2eucb.b[11][1]++;
    }
  };
});