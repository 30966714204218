'use strict';

var cov_1t3aqi3744 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/activities/right/rightCtrl.js',
      hash = '01453fdda019dff3c9b40a3d1f25287a7e9f846a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/activities/right/rightCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 151,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 51
        }
      },
      '2': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 30
        }
      },
      '3': {
        start: {
          line: 4,
          column: 20
        },
        end: {
          line: 4,
          column: 31
        }
      },
      '4': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 6,
          column: 36
        }
      },
      '5': {
        start: {
          line: 7,
          column: 23
        },
        end: {
          line: 7,
          column: 31
        }
      },
      '6': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '7': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 12,
          column: 4
        }
      },
      '8': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 137
        }
      },
      '9': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 17,
          column: 4
        }
      },
      '10': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '11': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 22,
          column: 5
        }
      },
      '12': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 57
        }
      },
      '13': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 28,
          column: 4
        }
      },
      '14': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '15': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 58
        }
      },
      '16': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 38,
          column: 4
        }
      },
      '17': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '18': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 18
        }
      },
      '19': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '20': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 18
        }
      },
      '21': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 37,
          column: 17
        }
      },
      '22': {
        start: {
          line: 39,
          column: 2
        },
        end: {
          line: 47,
          column: 4
        }
      },
      '23': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '24': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 18
        }
      },
      '25': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '26': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 18
        }
      },
      '27': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 17
        }
      },
      '28': {
        start: {
          line: 48,
          column: 2
        },
        end: {
          line: 56,
          column: 4
        }
      },
      '29': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 52,
          column: 6
        }
      },
      '30': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 53,
          column: 20
        }
      },
      '31': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 35
        }
      },
      '32': {
        start: {
          line: 57,
          column: 2
        },
        end: {
          line: 77,
          column: 4
        }
      },
      '33': {
        start: {
          line: 58,
          column: 14
        },
        end: {
          line: 58,
          column: 17
        }
      },
      '34': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 64,
          column: 5
        }
      },
      '35': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 62,
          column: 7
        }
      },
      '36': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 17
        }
      },
      '37': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 24
        }
      },
      '38': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 17
        }
      },
      '39': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '40': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '41': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 17
        }
      },
      '42': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 24
        }
      },
      '43': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 64
        }
      },
      '44': {
        start: {
          line: 73,
          column: 4
        },
        end: {
          line: 75,
          column: 7
        }
      },
      '45': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 20
        }
      },
      '46': {
        start: {
          line: 78,
          column: 2
        },
        end: {
          line: 80,
          column: 4
        }
      },
      '47': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 79,
          column: 20
        }
      },
      '48': {
        start: {
          line: 81,
          column: 2
        },
        end: {
          line: 88,
          column: 4
        }
      },
      '49': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 87,
          column: 7
        }
      },
      '50': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 86,
          column: 9
        }
      },
      '51': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 84,
          column: 59
        }
      },
      '52': {
        start: {
          line: 85,
          column: 8
        },
        end: {
          line: 85,
          column: 20
        }
      },
      '53': {
        start: {
          line: 90,
          column: 2
        },
        end: {
          line: 150,
          column: 5
        }
      },
      '54': {
        start: {
          line: 91,
          column: 4
        },
        end: {
          line: 139,
          column: 5
        }
      },
      '55': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '56': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 17
        }
      },
      '57': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 58
        }
      },
      '58': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 54
        }
      },
      '59': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 97,
          column: 61
        }
      },
      '60': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 98,
          column: 68
        }
      },
      '61': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 99,
          column: 66
        }
      },
      '62': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '63': {
        start: {
          line: 101,
          column: 8
        },
        end: {
          line: 101,
          column: 72
        }
      },
      '64': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 39
        }
      },
      '65': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 109,
          column: 7
        }
      },
      '66': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 70
        }
      },
      '67': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 108,
          column: 38
        }
      },
      '68': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 114,
          column: 7
        }
      },
      '69': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 66
        }
      },
      '70': {
        start: {
          line: 113,
          column: 8
        },
        end: {
          line: 113,
          column: 36
        }
      },
      '71': {
        start: {
          line: 115,
          column: 26
        },
        end: {
          line: 118,
          column: 7
        }
      },
      '72': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '73': {
        start: {
          line: 120,
          column: 20
        },
        end: {
          line: 120,
          column: 44
        }
      },
      '74': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 136,
          column: 9
        }
      },
      '75': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 135,
          column: 11
        }
      },
      '76': {
        start: {
          line: 123,
          column: 23
        },
        end: {
          line: 123,
          column: 40
        }
      },
      '77': {
        start: {
          line: 124,
          column: 12
        },
        end: {
          line: 134,
          column: 13
        }
      },
      '78': {
        start: {
          line: 125,
          column: 14
        },
        end: {
          line: 127,
          column: 15
        }
      },
      '79': {
        start: {
          line: 126,
          column: 16
        },
        end: {
          line: 126,
          column: 25
        }
      },
      '80': {
        start: {
          line: 128,
          column: 14
        },
        end: {
          line: 130,
          column: 15
        }
      },
      '81': {
        start: {
          line: 129,
          column: 16
        },
        end: {
          line: 129,
          column: 44
        }
      },
      '82': {
        start: {
          line: 131,
          column: 14
        },
        end: {
          line: 133,
          column: 15
        }
      },
      '83': {
        start: {
          line: 132,
          column: 16
        },
        end: {
          line: 132,
          column: 44
        }
      },
      '84': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 138,
          column: 33
        }
      },
      '85': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 140,
          column: 28
        }
      },
      '86': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 141,
          column: 38
        }
      },
      '87': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 146,
          column: 6
        }
      },
      '88': {
        start: {
          line: 148,
          column: 4
        },
        end: {
          line: 148,
          column: 142
        }
      },
      '89': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 149,
          column: 21
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 144
          },
          end: {
            line: 151,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 20
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 36
          },
          end: {
            line: 12,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 26
          },
          end: {
            line: 19,
            column: 27
          }
        },
        loc: {
          start: {
            line: 19,
            column: 38
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 26
          },
          end: {
            line: 24,
            column: 27
          }
        },
        loc: {
          start: {
            line: 24,
            column: 38
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 30,
            column: 27
          },
          end: {
            line: 30,
            column: 28
          }
        },
        loc: {
          start: {
            line: 30,
            column: 63
          },
          end: {
            line: 38,
            column: 3
          }
        },
        line: 30
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 39,
            column: 26
          },
          end: {
            line: 39,
            column: 27
          }
        },
        loc: {
          start: {
            line: 39,
            column: 62
          },
          end: {
            line: 47,
            column: 3
          }
        },
        line: 39
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 48,
            column: 16
          },
          end: {
            line: 48,
            column: 17
          }
        },
        loc: {
          start: {
            line: 48,
            column: 32
          },
          end: {
            line: 56,
            column: 3
          }
        },
        line: 48
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 57,
            column: 16
          },
          end: {
            line: 57,
            column: 17
          }
        },
        loc: {
          start: {
            line: 57,
            column: 28
          },
          end: {
            line: 77,
            column: 3
          }
        },
        line: 57
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 73,
            column: 97
          },
          end: {
            line: 73,
            column: 98
          }
        },
        loc: {
          start: {
            line: 73,
            column: 105
          },
          end: {
            line: 75,
            column: 5
          }
        },
        line: 73
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 78,
            column: 17
          },
          end: {
            line: 78,
            column: 18
          }
        },
        loc: {
          start: {
            line: 78,
            column: 29
          },
          end: {
            line: 80,
            column: 3
          }
        },
        line: 78
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 81,
            column: 19
          },
          end: {
            line: 81,
            column: 20
          }
        },
        loc: {
          start: {
            line: 81,
            column: 31
          },
          end: {
            line: 88,
            column: 3
          }
        },
        line: 81
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 82,
            column: 51
          },
          end: {
            line: 82,
            column: 52
          }
        },
        loc: {
          start: {
            line: 82,
            column: 59
          },
          end: {
            line: 87,
            column: 5
          }
        },
        line: 82
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 83,
            column: 30
          },
          end: {
            line: 83,
            column: 31
          }
        },
        loc: {
          start: {
            line: 83,
            column: 38
          },
          end: {
            line: 86,
            column: 7
          }
        },
        line: 83
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 90,
            column: 71
          },
          end: {
            line: 90,
            column: 72
          }
        },
        loc: {
          start: {
            line: 90,
            column: 79
          },
          end: {
            line: 150,
            column: 3
          }
        },
        line: 90
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }, {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }],
        line: 31
      },
      '1': {
        loc: {
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        }, {
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        }],
        line: 34
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }, {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }],
        line: 40
      },
      '3': {
        loc: {
          start: {
            line: 43,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 43,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        }, {
          start: {
            line: 43,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        }],
        line: 43
      },
      '4': {
        loc: {
          start: {
            line: 49,
            column: 16
          },
          end: {
            line: 52,
            column: 5
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 49,
            column: 16
          },
          end: {
            line: 49,
            column: 30
          }
        }, {
          start: {
            line: 49,
            column: 34
          },
          end: {
            line: 52,
            column: 5
          }
        }],
        line: 49
      },
      '5': {
        loc: {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }, {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }],
        line: 60
      },
      '6': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }],
        line: 67
      },
      '7': {
        loc: {
          start: {
            line: 90,
            column: 48
          },
          end: {
            line: 90,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 90,
            column: 48
          },
          end: {
            line: 90,
            column: 63
          }
        }, {
          start: {
            line: 90,
            column: 67
          },
          end: {
            line: 90,
            column: 68
          }
        }],
        line: 90
      },
      '8': {
        loc: {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }, {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }],
        line: 92
      },
      '9': {
        loc: {
          start: {
            line: 98,
            column: 26
          },
          end: {
            line: 98,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 98,
            column: 26
          },
          end: {
            line: 98,
            column: 44
          }
        }, {
          start: {
            line: 98,
            column: 48
          },
          end: {
            line: 98,
            column: 66
          }
        }],
        line: 98
      },
      '10': {
        loc: {
          start: {
            line: 99,
            column: 24
          },
          end: {
            line: 99,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 99,
            column: 24
          },
          end: {
            line: 99,
            column: 42
          }
        }, {
          start: {
            line: 99,
            column: 46
          },
          end: {
            line: 99,
            column: 64
          }
        }],
        line: 99
      },
      '11': {
        loc: {
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 100
      },
      '12': {
        loc: {
          start: {
            line: 100,
            column: 10
          },
          end: {
            line: 100,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 100,
            column: 10
          },
          end: {
            line: 100,
            column: 33
          }
        }, {
          start: {
            line: 100,
            column: 37
          },
          end: {
            line: 100,
            column: 76
          }
        }],
        line: 100
      },
      '13': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        }],
        line: 105
      },
      '14': {
        loc: {
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 105,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 105,
            column: 32
          }
        }, {
          start: {
            line: 105,
            column: 36
          },
          end: {
            line: 105,
            column: 74
          }
        }],
        line: 105
      },
      '15': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 114,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 114,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 114,
            column: 7
          }
        }],
        line: 110
      },
      '16': {
        loc: {
          start: {
            line: 110,
            column: 10
          },
          end: {
            line: 110,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 110,
            column: 10
          },
          end: {
            line: 110,
            column: 30
          }
        }, {
          start: {
            line: 110,
            column: 34
          },
          end: {
            line: 110,
            column: 70
          }
        }],
        line: 110
      },
      '17': {
        loc: {
          start: {
            line: 119,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 119,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }, {
          start: {
            line: 119,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }],
        line: 119
      },
      '18': {
        loc: {
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 136,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 136,
            column: 9
          }
        }, {
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 136,
            column: 9
          }
        }],
        line: 121
      },
      '19': {
        loc: {
          start: {
            line: 125,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        }, {
          start: {
            line: 125,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        }],
        line: 125
      },
      '20': {
        loc: {
          start: {
            line: 125,
            column: 18
          },
          end: {
            line: 125,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 125,
            column: 18
          },
          end: {
            line: 125,
            column: 31
          }
        }, {
          start: {
            line: 125,
            column: 35
          },
          end: {
            line: 125,
            column: 45
          }
        }],
        line: 125
      },
      '21': {
        loc: {
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 130,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 130,
            column: 15
          }
        }, {
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 130,
            column: 15
          }
        }],
        line: 128
      },
      '22': {
        loc: {
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        }, {
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        }],
        line: 131
      },
      '23': {
        loc: {
          start: {
            line: 143,
            column: 18
          },
          end: {
            line: 146,
            column: 5
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 143,
            column: 18
          },
          end: {
            line: 143,
            column: 51
          }
        }, {
          start: {
            line: 143,
            column: 55
          },
          end: {
            line: 146,
            column: 5
          }
        }],
        line: 143
      },
      '24': {
        loc: {
          start: {
            line: 148,
            column: 79
          },
          end: {
            line: 148,
            column: 120
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 148,
            column: 79
          },
          end: {
            line: 148,
            column: 102
          }
        }, {
          start: {
            line: 148,
            column: 106
          },
          end: {
            line: 148,
            column: 120
          }
        }],
        line: 148
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1t3aqi3744.s[0]++;
angular.module('app').controller('activitiesRightCtrl', function ($deltahttp, $scope, $stateParams, $CRUDService, $filter, $deltaNumber, $deltaUnite) {
  cov_1t3aqi3744.f[0]++;
  cov_1t3aqi3744.s[1]++;

  $scope.portfolio = $deltahttp.getProjet() == 0;
  var PATH = (cov_1t3aqi3744.s[2]++, 'DernierNiveau');
  var PATHACTIV = (cov_1t3aqi3744.s[3]++, 'Activites');

  var PATHINDIC = (cov_1t3aqi3744.s[4]++, 'DecoupageIndic');
  var PATH_PROJECT = (cov_1t3aqi3744.s[5]++, 'Projet');
  cov_1t3aqi3744.s[6]++;
  $deltaUnite.addController($scope);

  cov_1t3aqi3744.s[7]++;
  $scope.getImage = function (item) {
    cov_1t3aqi3744.f[1]++;
    cov_1t3aqi3744.s[8]++;

    return $deltahttp.getDataPath('Connexion') + '?' + $CRUDService.getComplement(2, true) + '&get=pictures&type=actors&item=' + item.id;
  };

  cov_1t3aqi3744.s[9]++;
  $scope.privileges = {
    collect: false,
    planning: false
  };

  cov_1t3aqi3744.s[10]++;
  $scope.selectAllUserC = function () {
    cov_1t3aqi3744.f[2]++;
    cov_1t3aqi3744.s[11]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var user = _step.value;
        cov_1t3aqi3744.s[12]++;

        $scope.data.c[user.id] = $scope.privileges.collect;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };
  cov_1t3aqi3744.s[13]++;
  $scope.selectAllUserP = function () {
    cov_1t3aqi3744.f[3]++;
    cov_1t3aqi3744.s[14]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.users[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var user = _step2.value;
        cov_1t3aqi3744.s[15]++;

        $scope.data.p[user.id] = $scope.privileges.planning;
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }
  };

  cov_1t3aqi3744.s[16]++;
  $scope.PlanningControl = function (item, idUser, superAdmin) {
    cov_1t3aqi3744.f[4]++;
    cov_1t3aqi3744.s[17]++;

    if (superAdmin) {
      cov_1t3aqi3744.b[0][0]++;
      cov_1t3aqi3744.s[18]++;

      return true;
    } else {
      cov_1t3aqi3744.b[0][1]++;
    }
    cov_1t3aqi3744.s[19]++;
    if (item.DROITPLAN.p[idUser] == true) {
      cov_1t3aqi3744.b[1][0]++;
      cov_1t3aqi3744.s[20]++;

      return true;
    } else {
      cov_1t3aqi3744.b[1][1]++;
    }
    cov_1t3aqi3744.s[21]++;
    return false;
  };
  cov_1t3aqi3744.s[22]++;
  $scope.collectControl = function (item, idUser, superAdmin) {
    cov_1t3aqi3744.f[5]++;
    cov_1t3aqi3744.s[23]++;

    if (superAdmin) {
      cov_1t3aqi3744.b[2][0]++;
      cov_1t3aqi3744.s[24]++;

      return true;
    } else {
      cov_1t3aqi3744.b[2][1]++;
    }
    cov_1t3aqi3744.s[25]++;
    if (item.DROITPLAN.c[idUser] == true) {
      cov_1t3aqi3744.b[3][0]++;
      cov_1t3aqi3744.s[26]++;

      return true;
    } else {
      cov_1t3aqi3744.b[3][1]++;
    }
    cov_1t3aqi3744.s[27]++;
    return false;
  };
  cov_1t3aqi3744.s[28]++;
  $scope.open = function (item) {
    cov_1t3aqi3744.f[6]++;
    cov_1t3aqi3744.s[29]++;

    this.data = (cov_1t3aqi3744.b[4][0]++, item.DROITPLAN) || (cov_1t3aqi3744.b[4][1]++, {
      p: {},
      c: {}
    });
    cov_1t3aqi3744.s[30]++;
    $scope.view = 6;
    // $scope.getGlobalValue(item);
    cov_1t3aqi3744.s[31]++;
    $scope.selectedActivity = item;
  };
  cov_1t3aqi3744.s[32]++;
  $scope.save = function () {
    cov_1t3aqi3744.f[7]++;

    var tab = (cov_1t3aqi3744.s[33]++, ';');
    cov_1t3aqi3744.s[34]++;
    for (var step in this.data.p) {
      cov_1t3aqi3744.s[35]++;

      if (!this.data.p[step]) {
        cov_1t3aqi3744.b[5][0]++;
        cov_1t3aqi3744.s[36]++;

        continue;
      } else {
        cov_1t3aqi3744.b[5][1]++;
      }
      cov_1t3aqi3744.s[37]++;
      tab += step + ';';
    }
    cov_1t3aqi3744.s[38]++;
    tab += '\t;';
    cov_1t3aqi3744.s[39]++;
    for (var _step3 in this.data.c) {
      cov_1t3aqi3744.s[40]++;

      if (!this.data.c[_step3]) {
        cov_1t3aqi3744.b[6][0]++;
        cov_1t3aqi3744.s[41]++;

        continue;
      } else {
        cov_1t3aqi3744.b[6][1]++;
      }
      cov_1t3aqi3744.s[42]++;
      tab += _step3 + ';';
    }
    cov_1t3aqi3744.s[43]++;
    $scope.selectedActivity.DROITPLAN = angular.copy(this.data);
    cov_1t3aqi3744.s[44]++;
    $CRUDService.save(PATHACTIV, { action: 'right', id: $scope.selectedActivity.id, valeur: tab }, function (data) {
      // $scope.isloading = false;

      cov_1t3aqi3744.f[8]++;
    });
    cov_1t3aqi3744.s[45]++;
    $scope.view = 1;
  };
  cov_1t3aqi3744.s[46]++;
  $scope.close = function () {
    cov_1t3aqi3744.f[9]++;
    cov_1t3aqi3744.s[47]++;

    $scope.view = 1;
  };
  cov_1t3aqi3744.s[48]++;
  $scope.getUser = function () {
    cov_1t3aqi3744.f[10]++;
    cov_1t3aqi3744.s[49]++;

    $CRUDService.getAll('User', { get: 'NonAdmin' }, function (data) {
      cov_1t3aqi3744.f[11]++;
      cov_1t3aqi3744.s[50]++;

      $scope.users = data.map(function (user) {
        cov_1t3aqi3744.f[12]++;
        cov_1t3aqi3744.s[51]++;

        user.imageUrl = $deltahttp.getUserPicture(user.id);
        cov_1t3aqi3744.s[52]++;
        return user;
      });
    });
  };

  cov_1t3aqi3744.s[53]++;
  $CRUDService.getAll(PATH, { get: 'single', id: (cov_1t3aqi3744.b[7][0]++, $stateParams.id) || (cov_1t3aqi3744.b[7][1]++, 0) }, function (data) {
    cov_1t3aqi3744.f[13]++;
    cov_1t3aqi3744.s[54]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion3 = (_step4 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step4.value;
        cov_1t3aqi3744.s[55]++;

        if (el.ID_UNITE < 1) {
          cov_1t3aqi3744.b[8][0]++;
          cov_1t3aqi3744.s[56]++;

          continue;
        } else {
          cov_1t3aqi3744.b[8][1]++;
        }
        cov_1t3aqi3744.s[57]++;
        el.DATE_DEMARRAGE = Date.newDate(el.DATE_DEMARRAGE);
        cov_1t3aqi3744.s[58]++;
        el.DATE_FIN_ACT = Date.newDate(el.DATE_FIN_ACT);
        cov_1t3aqi3744.s[59]++;
        el.COUT_U_DN = $deltaNumber.formatNumber(el.COUT_U_DN);
        cov_1t3aqi3744.s[60]++;
        el.BUDGET_FOLLOW = (cov_1t3aqi3744.b[9][0]++, el.CALCULAUTO == 1) || (cov_1t3aqi3744.b[9][1]++, el.CALCULAUTO == 3);
        cov_1t3aqi3744.s[61]++;
        el.COST_FOLLOW = (cov_1t3aqi3744.b[10][0]++, el.CALCULAUTO == 2) || (cov_1t3aqi3744.b[10][1]++, el.CALCULAUTO == 3);
        cov_1t3aqi3744.s[62]++;
        if ((cov_1t3aqi3744.b[12][0]++, el.DATE_REVISION_GLOBAL) && (cov_1t3aqi3744.b[12][1]++, el.DATE_REVISION_GLOBAL != '0000-00-00')) {
          cov_1t3aqi3744.b[11][0]++;
          cov_1t3aqi3744.s[63]++;

          el.DATE_REVISION_GLOBAL = Date.newDate(el.DATE_REVISION_GLOBAL);
        } else {
          cov_1t3aqi3744.b[11][1]++;
          cov_1t3aqi3744.s[64]++;

          el.DATE_REVISION_GLOBAL = null;
        }
        cov_1t3aqi3744.s[65]++;
        if ((cov_1t3aqi3744.b[14][0]++, el.DATE_REVISION_ANNEE) && (cov_1t3aqi3744.b[14][1]++, el.DATE_REVISION_ANNEE != '0000-00-00')) {
          cov_1t3aqi3744.b[13][0]++;
          cov_1t3aqi3744.s[66]++;

          el.DATE_REVISION_ANNEE = Date.newDate(el.DATE_REVISION_ANNEE);
        } else {
          cov_1t3aqi3744.b[13][1]++;
          cov_1t3aqi3744.s[67]++;

          el.DATE_REVISION_ANNEE = null;
        }
        cov_1t3aqi3744.s[68]++;
        if ((cov_1t3aqi3744.b[16][0]++, el.DATE_REVISION_PER) && (cov_1t3aqi3744.b[16][1]++, el.DATE_REVISION_PER != '0000-00-00')) {
          cov_1t3aqi3744.b[15][0]++;
          cov_1t3aqi3744.s[69]++;

          el.DATE_REVISION_PER = Date.newDate(el.DATE_REVISION_PER);
        } else {
          cov_1t3aqi3744.b[15][1]++;
          cov_1t3aqi3744.s[70]++;

          el.DATE_REVISION_PER = null;
        }
        var tamponDroit = (cov_1t3aqi3744.s[71]++, {
          p: {},
          c: {}
        });
        cov_1t3aqi3744.s[72]++;
        if (el.DROITPLAN) {
          cov_1t3aqi3744.b[17][0]++;

          var tab = (cov_1t3aqi3744.s[73]++, el.DROITPLAN.split('\t'));
          cov_1t3aqi3744.s[74]++;
          if (tab.length == 2) {
            cov_1t3aqi3744.b[18][0]++;
            cov_1t3aqi3744.s[75]++;

            for (var j = 0; j < tab.length; j++) {
              var ch = (cov_1t3aqi3744.s[76]++, tab[j].split(';'));
              cov_1t3aqi3744.s[77]++;
              var _iteratorNormalCompletion4 = true;
              var _didIteratorError4 = false;
              var _iteratorError4 = undefined;

              try {
                for (var _iterator4 = ch[Symbol.iterator](), _step5; !(_iteratorNormalCompletion4 = (_step5 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                  var itema = _step5.value;
                  cov_1t3aqi3744.s[78]++;

                  if ((cov_1t3aqi3744.b[20][0]++, itema == '') || (cov_1t3aqi3744.b[20][1]++, itema == 0)) {
                    cov_1t3aqi3744.b[19][0]++;
                    cov_1t3aqi3744.s[79]++;

                    continue;
                  } else {
                    cov_1t3aqi3744.b[19][1]++;
                  }
                  cov_1t3aqi3744.s[80]++;
                  if (j == 0) {
                    cov_1t3aqi3744.b[21][0]++;
                    cov_1t3aqi3744.s[81]++;

                    tamponDroit.p[itema] = true;
                  } else {
                    cov_1t3aqi3744.b[21][1]++;
                  }
                  cov_1t3aqi3744.s[82]++;
                  if (j == 1) {
                    cov_1t3aqi3744.b[22][0]++;
                    cov_1t3aqi3744.s[83]++;

                    tamponDroit.c[itema] = true;
                  } else {
                    cov_1t3aqi3744.b[22][1]++;
                  }
                }
              } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion4 && _iterator4.return) {
                    _iterator4.return();
                  }
                } finally {
                  if (_didIteratorError4) {
                    throw _iteratorError4;
                  }
                }
              }
            }
          } else {
            cov_1t3aqi3744.b[18][1]++;
          }
        } else {
          cov_1t3aqi3744.b[17][1]++;
        }
        cov_1t3aqi3744.s[84]++;
        el.DROITPLAN = tamponDroit;
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_1t3aqi3744.s[85]++;
    $scope.indexLoading = 0;
    cov_1t3aqi3744.s[86]++;
    $scope.selectedActivity = data[0];

    cov_1t3aqi3744.s[87]++;
    $scope.data = (cov_1t3aqi3744.b[23][0]++, $scope.selectedActivity.DROITPLAN) || (cov_1t3aqi3744.b[23][1]++, {
      p: {},
      c: {}
    });

    cov_1t3aqi3744.s[88]++;
    $scope.unitSelected = $filter('filter')($scope.listData_unites_bksb, { id: ((cov_1t3aqi3744.b[24][0]++, $scope.selectedActivity) || (cov_1t3aqi3744.b[24][1]++, { ID_UNITE: -1 })).ID_UNITE }, true)[0];
    cov_1t3aqi3744.s[89]++;
    $scope.getUser();
  });
});